import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, styled, tooltipClasses } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

interface TooltipProps extends MuiTooltipProps {
  enterDelay?: number;
  className?: string;
}

export const Tooltip = styled(({ className, enterDelay = 500, ...props }: TooltipProps) => {
  const tipRef = useRef<HTMLElement>(null)
  const [inView, setInView] = useState(false)

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries
    setInView(entry.isIntersecting)
  }

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
    }
    const observer = new IntersectionObserver(handleIntersection, options)
    const currentRef = tipRef.current

    if (currentRef) observer.observe(currentRef)

    return () => {
      if (currentRef) observer.unobserve(currentRef)
    }
  }, [tipRef])

  return (
    <MuiTooltip
      { ...props }
      ref={ tipRef }
      enterDelay={ enterDelay }
      classes={{ popper: className }}
      PopperProps={{
        sx: { display: inView ? 'block' : 'none' },
      }} />
  )
})(({ theme: { borders } }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    position: 'relative',
    backgroundColor: 'white',
    border: borders.default(),
    borderRadius: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 12,
    padding: '2px 6px',
    margin: '0 4px !important',
  },
}))
