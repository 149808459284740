import { RefObject, useEffect } from 'react'

export function useClickOutside(ref: RefObject<HTMLDivElement>, onOutsideClicked?: null | ((event: MouseEvent) => void)) {
  useEffect(() => {
    if (!onOutsideClicked) return

    const handleClickOutside = (event: MouseEvent): void => {
      const target = event?.target as Element

      // Clicking on or inside the container doesn't close it:
      if (ref.current && ref.current.contains(target)) return

      // If the click happened inside a .MuiModal-root, then it's closing something else (e.g. <Select>):
      if (target.closest('.MuiModal-root')) return

      // The mousedown events are ignored if they happen on <a> or <button> elements, as those might open a different
      // menu and we don't want to close the currently open one before the other one is about to open (when the click event
      // fires):
      if (event.type === 'mousedown' && (target.nodeName === 'A' || target.nodeName === 'BUTTON')) return

      onOutsideClicked(event)
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('mouseup', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [ref, onOutsideClicked])
}
