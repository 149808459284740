import { Trait } from '../../traits/traits.types'

import { NFMeDownloadConfig, NFMeFileMimeType, NFMeFileType } from './avatar-download.types'

export const DEFAULT_DOWNLOAD_CONFIG: NFMeDownloadConfig = {
  fileName: '',
  fileType: 'png',
  fileSize: '2048',
  fileBackgroundType: 'default',
  fileOverlays: 'keep',
  fileWatermarkVariant: 'right',
}

export const NO_DOWNLOAD_CONFIG: NFMeDownloadConfig = {
  fileName: '',
  fileType: 'png',
  fileSize: '2048',
  fileBackgroundType: 'default',
  fileOverlays: 'keep',
  fileWatermarkVariant: 'none',
}

export const DOWNLOAD_FILE_TYPE_LABELS: Record<NFMeFileType, string> = {
  bmp: 'BMP',
  png: 'PNG',
  jpg: 'JPG',
  webp: 'WEBP',
  avif: 'AVIF',
  svg: 'SVG',
  json: 'JSON',
  'json-trait-values': 'JSON',
}

export const DOWNLOAD_FILE_TYPE_MIME_TYPES: Record<NFMeFileType, NFMeFileMimeType> = {
  bmp: 'image/bmp',
  png: 'image/png',
  jpg: 'image/jpeg',
  webp: 'image/webp',
  avif: 'image/avif',
  svg: 'image/svg+xml',
  json: 'application/json',
  'json-trait-values': 'application/json',
}

export const DOWNLOAD_MIME_TYPE_FILE_TYPE: Record<NFMeFileMimeType, NFMeFileType> = {
  'image/bmp': 'bmp',
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/webp': 'webp',
  'image/avif': 'avif',
  'image/svg+xml': 'svg',
  'application/json': 'json',
}

// TODO (Dani): This should probably come from the backend (config) rather than being hardcoded:

export const SOLID_BACKGROUND_TRAIT: Trait = {
  id: 'background.1.colour',
  name: 'Colour',
  type: 'background',
  slots: {
    back: '<g><g><path style="fill: var(--fill-background-0-6, #FFFFFF); " d="M201.852 -2.35742H-2.38281V201.383H201.852V-2.35742Z"></path></g></g>',
  },
  colors: {
    0: [
      6,
    ],
  },
  masks: [],
}
