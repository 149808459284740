import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'
import { MutationResult } from '../../utils/server/mutation/server-mutation.types'

import { BackendUserData, BackendUserInfo } from './user.types'

export async function fetchUser(axiosConfig?: AxiosRequestConfig) {
  return axios.get<BackendUserInfo>(
    endpoints.fetchUser,
    axiosConfig,
  )
}

export interface UpdateUserParams {
  updatedUserData: BackendUserData;
}

export async function updateUser({ updatedUserData }: UpdateUserParams, axiosConfig?: AxiosRequestConfig) {
  return axios.put<MutationResult>(endpoints.updateUser, updatedUserData, axiosConfig)
}

export const UserService = {
  fetchUser,
  updateUser,
}
