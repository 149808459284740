import { RoutePaths } from '@ng-mono/sdk'

import { MenuItemConfig, MenuItemType } from '../../../libs/ui/components/menu/items/base/menu-base-item.types'

/*
export const APP_MENU_ITEMS: MenuItemConfig[] = [{
  type: MenuItemType.Link,
  label: 'Home',
  href: RoutePaths.Home,
}, {
  type: MenuItemType.Link,
  label: 'Account',
  href: RoutePaths.Account,
}, {
  type: MenuItemType.Action,
  label: 'Logout',
  action: 'logout',
}, {
  type: MenuItemType.Divider,
  category: 'Customize',
  availableTo: ['admin', 'partner'],
}, {
  type: MenuItemType.Link,
  label: 'NFMe Creator',
  href: RoutePaths.NFMeCreator,
  availableTo: ['admin', 'partner'],
}, {
  type: MenuItemType.Divider,
  category: 'Dev',
  availableTo: ['admin'],
}, {
  type: MenuItemType.Link,
  label: 'Open Graph Image Generator',
  href: RoutePaths.OgGenerator,
  availableTo: ['admin'],
}, {
  type: MenuItemType.Link,
  label: 'Error Test',
  href: RoutePaths.ErrorTests,
  availableTo: ['admin'],
}]
*/

export const APP_MENU_ITEMS: MenuItemConfig[] = [{
  type: MenuItemType.Link,
  label: 'NFMe Creator',
  href: RoutePaths.Home,
}, {
  type: MenuItemType.Link,
  label: 'Team',
  href: RoutePaths.Team,
}]
