import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '../../axios/axios.constants'
import { FormOption } from '../../utils/common/form-options/form-option.types'
import { sortOptionsByLabel } from '../../utils/common/form-options/form-options.utils'
import { endpoints, FetchAvatarOutfitURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { isMutationResultSuccess, MutationResult } from '../../utils/server/mutation/server-mutation.types'
import { to } from '../../utils/typescript/typescript.utils'
import { getCreatorRendererConfig } from '../creator/renderer-config/creator-renderer-config.service'
import { TraitTypeId } from '../creator/renderer-config/creator-renderer-config.types'
import { Trait } from '../traits/traits.types'

import { AvatarOutfitAndConfig, AvatarTraitValue, BackendAvatarOutfit, BackendAvatarTraitValue, BackendAvatarOutfitAndConfig, TraitValues, BackendTraitValues } from './avatar.types'

export function fetchAvatarOutfitOptions(axiosConfig?: AxiosRequestConfig) {
  return axios.get<FormOption<string>[]>(endpoints.fetchAvatarOutfitOptions, axiosConfig).then((response) => ({
    ...response,
    data: sortOptionsByLabel(response.data),
  }))
}

function transformAvatarOutfits(
  response: AxiosResponse<BackendAvatarOutfitAndConfig>,
): AxiosResponse<AvatarOutfitAndConfig> {
  const backendAvatarOutfitAndConfig = response.data

  const traitsByID = backendAvatarOutfitAndConfig.outfitTraits.reduce((acc, trait) => {
    acc[trait.id] = trait

    return acc
  }, {} as Record<string, Trait>)

  const traitValues = (Object.entries(backendAvatarOutfitAndConfig.outfit.traitValues) as [TraitTypeId, BackendAvatarTraitValue][])
    .reduce((acc, [traitTypeID, backendAvatarTraitValue]) => {
      acc[traitTypeID] = to<AvatarTraitValue>({
        trait: traitsByID[backendAvatarTraitValue.traitId] || null,
        traitColors: backendAvatarTraitValue.traitColors,
      })

      return acc
    }, {} as TraitValues)

  return to<AxiosResponse<AvatarOutfitAndConfig>>({
    ...response,
    data: {
      outfit: {
        ...backendAvatarOutfitAndConfig.outfit,
        traitValues,
      },
      config: getCreatorRendererConfig(backendAvatarOutfitAndConfig.config, backendAvatarOutfitAndConfig.configDefaultTraits),
    },
  })
}

export async function fetchAvatarOutfitAndConfig(params: FetchAvatarOutfitURLParams, axiosConfig?: AxiosRequestConfig) {
  return axios.get<BackendAvatarOutfitAndConfig>(
    endpoints.fetchAvatarOutfit(params),
    axiosConfig,
  ).then(transformAvatarOutfits)
}

export interface CreateAvatarOutfitParams {
  label: string;
  backendTraitValues?: BackendTraitValues;
}

export async function createAvatarOutfit(
  params: CreateAvatarOutfitParams,
  axiosConfig?: AxiosRequestConfig,
) {
  return axios.put<MutationResult<BackendAvatarOutfitAndConfig>>(
    endpoints.createAvatarOutfit,
    params,
    axiosConfig,
  ).then((response) => {
    if (isMutationResultSuccess(response.data)) {
      const transformedResponse = transformAvatarOutfits({ data: response.data.data } as AxiosResponse<BackendAvatarOutfitAndConfig>)

      response.data.data = transformedResponse.data as any
    }

    return response as AxiosResponse<MutationResult<AvatarOutfitAndConfig>>
  })
}

export interface UpdateAvatarOutfitParams {
  updatedAvatarOutfit: Omit<BackendAvatarOutfit, 'traits'>;
}

export async function updateAvatarOutfit(
  params: UpdateAvatarOutfitParams,
  axiosConfig?: AxiosRequestConfig,
) {
  return axios.put<MutationResult>(
    endpoints.updateAvatarOutfit({ outfitID: params.updatedAvatarOutfit.id }),
    params,
    axiosConfig,
  )
}

export interface DeleteAvatarOutfitParams {
  outfitID: string;
}

export async function deleteAvatarOutfit(
  params: DeleteAvatarOutfitParams,
  axiosConfig?: AxiosRequestConfig,
) {
  return axios.put<MutationResult>(
    endpoints.deleteAvatarOutfit(params),
    axiosConfig,
  )
}

export const AvatarService = {
  // NFMe options list (for the selector):
  fetchAvatarOutfitOptions,

  // Actual NFMes loaded in the editor:
  fetchAvatarOutfitAndConfig,
  createAvatarOutfit,
  updateAvatarOutfit,
  deleteAvatarOutfit,
}
