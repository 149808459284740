import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { isMutationResultFailure, isMutationResultSuccess } from '../../utils/server/mutation/server-mutation.types'
import { Activity } from '../activities/activities.types'
import { AnswerValue } from '../answers/answers.types'
import { Path } from '../paths/paths.types'
import { isPushPath, PushPath } from '../push-paths/push-paths.types'

import { FlowControllerStatus } from './flow-controller.types'

export class FlowControllerStore {
  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  get pathResource() {
    const pathResource = this.rootStore.pathsStore?.pathResource
    const pushPathResource = this.rootStore?.pushPathsStore?.pushPathResource

    return pushPathResource?.data ? pushPathResource : pathResource
  }

  get path(): Path | PushPath | null {
    const path = this.rootStore.pathsStore?.path
    const pushPath = this.rootStore?.pushPathsStore?.pushPath

    return pushPath || path || null
  }

  get isPushPath() {
    return this.path ? isPushPath(this.path) : false
  }

  get pathProgress() {
    const path = this.rootStore.pathsStore?.path
    const pushPath = this.rootStore?.pushPathsStore?.pushPath

    /*
    // This version makes the push path activities move the progress in fractions or a step (but is missing a way to
    // preserve the last partial step progress for later):

    const stepIndex = path && pushPath ? (
      path.activityIndex + pushPath.activityIndex / pushPath.activities.length
    ) : (
      (path || pushPath)?.activityIndex || 0
    )
    */

    const stepIndex = (path || pushPath)?.activityIndex || 0
    const totalSteps = (path || pushPath)?.activities.length || 0

    return [stepIndex, totalSteps]
  }

  get mutation() {
    if (!this.path) return

    return this.isPushPath
      ? this.rootStore.pushPathsStore?.pushPathsMutation
      : this.rootStore.pathsStore?.pathMutation
  }

  get currentActivity(): Activity | null {
    return (this.path?.activities || [])[this.path?.activityIndex || 0] || null
  }

  get canGoBack() {
    return (this.path?.activityIndex || 0) > 0
  }

  goBack() {
    if (!this.path) return

    this.path.activityIndex = Math.max(
      0,
      this.path.activityIndex - 1,
    )

    this.rootStore.pushPathsStore?.updateStats('back')
  }

  goNext() {
    if (!this.path) return

    // TODO (Dani): We can start preloading assets (images, videos, etc) for the upcoming images.

    this.path.activityIndex = Math.min(
      this.path.totalActivities - 1,
      this.path.activityIndex + 1,
    )

    this.rootStore.pushPathsStore?.updateStats('next')
  }

  setAnswer(value: AnswerValue) {
    // TODO (Dani): Handle error?:
    if (!this.path) return

    const activity = this.path.activities[this.path.activityIndex]

    // TODO (Dani): Handle error:
    if (!activity) return

    // TODO (Dani): Validate here?
    activity.userAnswer = {
      value,
      timestamp: Date.now(),
    }

    if (this.path.activityIndex === this.path.totalActivities - 1) {
      this.path.activityIndex = 0

      return this.submitAnswers()
    }
  }

  submitAnswers() {
    if (!this.path) return

    this.rootStore.pushPathsStore?.updateStats('submit')

    // TODO (Dani): Instead, add a common interface for both Stores to implement:
    return this.isPushPath
      ? this.rootStore.pushPathsStore?.updatePushPathAnswers()
      : this.rootStore.pathsStore?.updatePathAnswers()
  }

  clearPathAnswers() {
    // TODO (Dani): Handle error?:
    if (!this.path) return

    this.path.activities = this.path.activities.map(({ userAnswer, ...activityWithoutAnswer }) => activityWithoutAnswer)
  }

  finishPath() {
    this.rootStore.pathsStore.clearUpdatePathAnswersMutation()
    this.rootStore.pushPathsStore.finishPath()
  }

  exitPath() {
    const { pushPathsStore } = this.rootStore

    if (!pushPathsStore) return

    if (this.status === FlowControllerStatus.Passed || this.status === FlowControllerStatus.Failed) {
      pushPathsStore?.finishPath()
    } else {
      pushPathsStore?.abortPath()
    }
  }

  retryPath() {
    if (!this.path) return

    this.clearPathAnswers()

    this.path.activityIndex = 0

    if (this.isPushPath) {
      this.rootStore.pushPathsStore?.clearUpdatePushPathAnswersMutation()
    } else {
      this.rootStore.pathsStore?.clearUpdatePathAnswersMutation()

      // We do not reset PushPathsStore's stats as we want to allow users to focus on retries:
      // this.rootStore.pushPathsStore?.finishPath()
    }
  }

  get status(): FlowControllerStatus {
    const { path, pathResource } = this

    if (!path || !pathResource || pathResource.isLoading) return FlowControllerStatus.Loading

    const answersUpdateMutation = this.mutation

    if (!answersUpdateMutation) return FlowControllerStatus.Loading

    const result = answersUpdateMutation?.result

    if (isMutationResultSuccess(result)) {
      return result.data.passed ? FlowControllerStatus.Passed : FlowControllerStatus.Failed
    }

    if (isMutationResultFailure(result)) {
      // TODO (Dani): FlowControllerStatus.Error instead?

      return FlowControllerStatus.Failed
    }

    if (answersUpdateMutation.isUpdating && path.activityIndex === 0) return FlowControllerStatus.Validating

    return FlowControllerStatus.InProgress
  }
}
