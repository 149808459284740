import { CacheSet } from '../cache/cache.utils'
import { ServiceFnWithoutParams, ServiceFnWithParams } from '../common/common-store.types'
import { ReadOnlyStoreResource } from '../readonly/readonly-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../readonly/readonly-store.utils'

import { ReadOnlyStoreMapOptions, ResourcesCacheSetOptions } from './readonly-map-store.types'

export function createResourcesCacheSet<K extends string | number = string | number, V = any>({
  initialValues,
  ...options
}: ResourcesCacheSetOptions<K, V> = {}): CacheSet<K, ReadOnlyStoreResource<V>> {
  const cacheSet = new CacheSet<K, ReadOnlyStoreResource<V>>(options)

  if (initialValues) {
    Object.entries(initialValues).forEach(([key, value]) => {
      cacheSet.set(key as K, createEmptyReadOnlyStoreResource(value))
    })
  }

  return cacheSet as any
}

export function clearResourcesCacheSet<K extends string | number = string | number, V = any>(
  resourcesCacheSet: CacheSet<K, ReadOnlyStoreResource<V>>,
) {
  resourcesCacheSet.forEach((resource) => {
    clearReadOnlyStoreResource(resource)
  })
}

export async function fetchStoreResourceMap<K extends string | number, V>(
  key: K,
  resourcesCacheSet: CacheSet<K, ReadOnlyStoreResource<V>>,
  serviceFn: ServiceFnWithoutParams<V>,
): Promise<V | Error>
export async function fetchStoreResourceMap<K extends string | number, V, P>(
  key: K,
  resourcesCacheSet: CacheSet<K, ReadOnlyStoreResource<V>>,
  serviceFn: ServiceFnWithParams<V, P>,
  params: P,
  storeOptions?: ReadOnlyStoreMapOptions<V>,
): Promise<V | Error>
export async function fetchStoreResourceMap<K extends string | number, V >(
  key: K,
  resourcesCacheSet: CacheSet<K, ReadOnlyStoreResource<V>>,
  serviceFn: ServiceFnWithoutParams<V>,
  params: null,
  storeOptions: ReadOnlyStoreMapOptions<V>,
): Promise<V | Error>
export async function fetchStoreResourceMap<K extends string | number, V, P>(
  key: K,
  resourcesCacheSet: CacheSet<K, ReadOnlyStoreResource<V>>,
  serviceFn: ServiceFnWithoutParams<V> | ServiceFnWithParams<V, P>,
  params?: null | P,
  { createAs, ...storeOptions }: ReadOnlyStoreMapOptions<V> = {},
): Promise<null | V | Error> {
  let storeResource = resourcesCacheSet.get(key)

  if (!storeResource) {
    if (createAs !== undefined) {
      storeResource = createEmptyReadOnlyStoreResource(createAs)

      resourcesCacheSet.set(key, storeResource)
    } else {
      throw new Error(`Can't find value ${key}.`)
    }
  }

  return fetchStoreResource(storeResource, serviceFn as any, params, storeOptions)
}
