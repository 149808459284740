import { AudioMedia, ImageMedia, VideoMedia, YouTubeMedia } from '../media/media.types'

export enum DocNodeType {
  Caption = 'caption',
  Text = 'text',
  LargeText = 'largeText',
  List = 'list',
  Audio = 'audio',
  Image = 'image',
  Video = 'video',
  YouTube = 'youtube',
}

export interface BaseDocNode {
  type: DocNodeType;
  gutterTop?: boolean;
  gutterBottom?: boolean;
}

export interface CaptionDocNode extends BaseDocNode {
  type: DocNodeType.Caption;
  caption: string;
}

export interface TextDocNode extends BaseDocNode {
  type: DocNodeType.Text;
  text: string;
}

export type LargeTextDocNodeSize = 'auto' | 'md' | 'lg'
export type LargeTextDocNodeTextAlign = 'auto' | 'left' | 'center' | 'right'

export interface LargeTextDocNode extends BaseDocNode {
  type: DocNodeType.LargeText;
  text: string;
  size?: LargeTextDocNodeSize;
  textAlign?: LargeTextDocNodeTextAlign;
}

export type ListItemDocNode = ListDocNode | TextDocNode | TextDocNode[]

export interface ListDocNode extends BaseDocNode {
  type: DocNodeType.List;
  items: ListItemDocNode[];
  // TODO (Dani): Export this from SDK:
  listType?: 'ul' | 'ol';
}

export interface AudioDocNode extends BaseDocNode {
  type: DocNodeType.Audio;
  media: AudioMedia;
}

export interface ImageDocNode extends BaseDocNode {
  type: DocNodeType.Image;
  media: ImageMedia;
}

export interface VideoDocNode extends BaseDocNode {
  type: DocNodeType.Video;
  media: VideoMedia;
}

export interface YouTubeDocNode extends BaseDocNode {
  type: DocNodeType.YouTube;
  media: YouTubeMedia;
}

export type DefaultDocNode = CaptionDocNode | TextDocNode | ListDocNode | ImageDocNode
export type DefaultDocNodeType = DefaultDocNode['type']

export type MediaDocNode = AudioDocNode | ImageDocNode | VideoDocNode | YouTubeDocNode
export type MediaDocNodeType = MediaDocNode['type']

export type ActivityDocNode = LargeTextDocNode | TextDocNode | ListDocNode | MediaDocNode
export type ActivityDocNodeType = ActivityDocNode['type']

export type AnyDocNode = CaptionDocNode | TextDocNode | ListDocNode | LargeTextDocNode | MediaDocNode
