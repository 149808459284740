import { Params } from 'react-router-dom'

import { campaignFlowGuard } from '../campaign/campaign-guard.utils'
import { defaultFlowGuard } from '../default/default-guard.utils'
import { GuardProps } from '../guards.types'

export function rootGuard<P extends Params<string>>(guardProps: GuardProps<P>) {
  const { pathname } = guardProps

  const guardFn = pathname && pathname.startsWith('/c/') ? campaignFlowGuard : defaultFlowGuard

  return guardFn(guardProps)
}
