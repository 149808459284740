import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'

import { Palette } from './palettes.types'

export function fetchColorPalettes(axiosConfig?: AxiosRequestConfig) {
  return axios.get<Palette[]>(endpoints.fetchColorPalettes, axiosConfig)
}

export const PaletteService = {
  fetchColorPalettes,
}
