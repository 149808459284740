import { IconButton } from '@mui/material'
import { motion } from 'framer-motion'

import { NavbarMenuButtonComponentProps } from '../../../../libs/ui/components/nav/base-navbar.types'
import { withHasActiveClassName, withHasHoverClassName } from '../../../../libs/ui/hooks/hover/hover.hook'

const TOP_VARIANTS = {
  open: {
    y: 9,
    originX: 'center',
    rotate: 45,
  },
  closed: {
    y: 0,
    rotate: 0,
    originX: 0,
  },
}

const MIDDLE_VARIANTS = {
  open: {
    scaleX: 0,
  },
  closed: {
    scaleX: 1,
  },
}

const BOTTOM_VARIANTS = {
  open: {
    x: -10,
    width: 28,
    rotate: -45,
    originX: 0,
  },
  closed: {
    x: 0,
    width: 14,
    rotate: 0,
    originX: 0,
  },
}

const TRANSITION = {
  duration: 0.2,
}

export function HamburgerButton({
  id,
  sx,
  circle,
  height,
  isOpen,
  onToggle,
}: NavbarMenuButtonComponentProps) {
  return (
    <IconButton
      id={ id }
      onClick={ onToggle }
      aria-label={ isOpen ? 'Open menu' : 'Close menu' }
      sx={{
        p: 0,
        height,
        aspectRatio: '1',
        overflow: circle ? 'visible' : 'hidden',

        '& > *': {
          transition: 'border-radius linear 250ms, transform linear 150ms',
          pointerEvents: 'none',
          transform: circle ? 'scale(1)' : 'scale(1.05)',
        },

        [withHasHoverClassName('&:not(:disabled):hover > *')]: {
          transform: circle ? 'scale(1.05)' : 'scale(1.1)',
        },

        [withHasActiveClassName('&:not(:disabled):active > *')]: {
          transform: circle ? 'scale(0.95)' : 'scale(1)',
        },

        ...sx,
      }}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <motion.rect
          x="0"
          y="4"
          width="28"
          height="2"
          rx="1"
          fill="#F96868"
          animate={ isOpen ? 'open' : 'closed' }
          variants={ TOP_VARIANTS }
          transition={ TRANSITION } />
        <motion.rect
          x="8"
          y="13"
          width="20"
          height="2"
          rx="1"
          fill="#F96868"
          animate={ isOpen ? 'open' : 'closed' }
          variants={ MIDDLE_VARIANTS }
          transition={ TRANSITION } />
        <motion.rect
          x="14"
          y="22"
          width="14"
          height="2"
          rx="1"
          fill="#F96868"
          animate={ isOpen ? 'open' : 'closed' }
          variants={ BOTTOM_VARIANTS }
          transition={ TRANSITION } />
      </svg>
    </IconButton>
  )
}
