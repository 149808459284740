import { canUserSeeAs, RoutePaths } from '@ng-mono/sdk'
import { Params } from 'react-router-dom'

import { RouteAuth } from '../../routes/routes.types'
import { GuardProps } from '../guards.types'
import { arePathnamesEqual } from '../guards.utils'

export function defaultFlowGuard<P extends Params<string>>({
  pathname,
  path,
  auth,
  availableTo,
  isAuthenticated,
  userRole,
  realUserRole,
  emailVerified,
  profileCompleted,
}: GuardProps<P>) {
  // No match, so we just redirect to home (instead of showing a 404 page):
  if (path === null || auth === null) return isAuthenticated ? RoutePaths.Home : RoutePaths.Login

  // Authenticated users get trapped into the Verify page until they verify their email:
  if (isAuthenticated && !emailVerified) {
    return arePathnamesEqual(pathname, RoutePaths.EmailVerification) ? null : RoutePaths.EmailVerification
  }

  // Authenticated users get trapped into the Onboarding until they complete their profile details:
  if (isAuthenticated && !profileCompleted) {
    return arePathnamesEqual(pathname, RoutePaths.Onboarding) ? null : RoutePaths.Onboarding
  }

  // This line is commented on purpose. Even if the email is verified, the user can go into the EmailVerification page,
  // as he might be changing it. The checks to kick them out if they don't come from the ChangeEmail page are there too.
  // if (isAuthenticated && emailVerified && profileCompleted && path === RoutePaths.EmailVerification) return RoutePaths.Account

  // Once these flows are completed, users can't get back to these views:
  if (isAuthenticated && emailVerified && profileCompleted && arePathnamesEqual(pathname, RoutePaths.Onboarding)) {
    return RoutePaths.Home
  }

  // Auth required but missing, redirect to login:
  if (auth === RouteAuth.Required && !isAuthenticated) return RoutePaths.Login

  // Auth is required and present but the user doesn't have tie right role:
  if (canUserSeeAs(userRole, realUserRole, availableTo).isHidden) return RoutePaths.Home

  // Only for non-authenticated users, but authenticated (e.g. /login), redirect to home:
  if (auth === RouteAuth.Anonymous && isAuthenticated) return RoutePaths.Home

  // Anything else is fine:
  return null
}
