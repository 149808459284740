import { ResponseComposition, RestContext, RestRequest } from 'msw'

import { getSinglePathParam } from '../../mocks/mock-helpers.utils'
import { FormOption } from '../../utils/common/form-options/form-option.types'
import { MutationResultSuccess, MutationType } from '../../utils/server/mutation/server-mutation.types'
import { to } from '../../utils/typescript/typescript.utils'
import { MOCKED_CREATOR_RENDERER_CONFIG } from '../creator/renderer-config/creator-renderer-config.mocks'
import { TraitTypeConfig, TraitTypeId } from '../creator/renderer-config/creator-renderer-config.types'
import { TRAIT_MOCKS_BY_ID } from '../traits/traits.mocks'
import { Trait } from '../traits/traits.types'

import { createAvatarOutfit, deleteAvatarOutfit, getAvatarOutfit, getAvatarOutfits, saveAvatarOutfit } from './avatar.mockUtils'
import { CreateAvatarOutfitParams, UpdateAvatarOutfitParams } from './avatar.service'
import { BackendAvatarOutfitAndConfig, BackendTraitValues } from './avatar.types'

export function fetchAvatarOutfitOptionsHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  return res(
    ctx.status(200),
    ctx.json(to<FormOption<string>[]>(
      Object.values(getAvatarOutfits()).map((outfit) => ({
        value: outfit.id,
        label: outfit.label,
      })),
    )),
  )
}

export function fetchAvatarOutfitHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  const outfitID = getSinglePathParam(req, 'outfitID')

  const {
    layeringVersion = MOCKED_CREATOR_RENDERER_CONFIG.layeringVersion,
    id,
    label,
    traitValues: savedTraitValues,
    paletteID = MOCKED_CREATOR_RENDERER_CONFIG.defaultPaletteID,
  } = getAvatarOutfit(outfitID)

  const traitsByTraitId: Record<string, Trait> = {}
  const configDefaultTraitsById: Record<string, Trait> = {}

  const traitValues = (Object.entries(MOCKED_CREATOR_RENDERER_CONFIG.traitTypeConfigs) as [TraitTypeId, TraitTypeConfig][])
    .reduce((acc, [traitTypeID, traitTypeConfig]) => {
      const savedTraitValue = savedTraitValues[traitTypeID as TraitTypeId]
      const traitId = savedTraitValue?.traitId
      const defaultTraitId = traitTypeConfig.defaultTrait

      acc[traitTypeID as TraitTypeId] = {
        traitId: traitId || defaultTraitId,
        traitColors: savedTraitValue?.traitColors || traitTypeConfig.defaultTraitColors || {},
      }

      const trait = traitId ? TRAIT_MOCKS_BY_ID[traitId] : null
      const defaultTrait = defaultTraitId ? TRAIT_MOCKS_BY_ID[defaultTraitId] : null

      // We need to send both the traits that are currently used/equipped, as well as the default ones (even if they
      // are not used) so that the reset functionality works:
      if (trait && !traitsByTraitId[trait.id]) traitsByTraitId[trait.id] = trait
      if (defaultTrait && !configDefaultTraitsById[defaultTrait.id]) configDefaultTraitsById[defaultTrait.id] = defaultTrait

      return acc
    }, {} as BackendTraitValues)

  return res(
    ctx.status(200),
    ctx.json(to<BackendAvatarOutfitAndConfig>({
      outfit: {
        layeringVersion,
        id,
        label,
        traitValues,
        paletteID,
      },
      outfitTraits: Object.values(traitsByTraitId),
      config: MOCKED_CREATOR_RENDERER_CONFIG,
      configDefaultTraits: Object.values(configDefaultTraitsById),
    })),
  )
}

export async function createAvatarOutfitHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  const params = await req.json<CreateAvatarOutfitParams>()

  const {
    layeringVersion = MOCKED_CREATOR_RENDERER_CONFIG.layeringVersion,
    id,
    label,
    traitValues: backendTraitValues,
    paletteID = MOCKED_CREATOR_RENDERER_CONFIG.defaultPaletteID,
  } = createAvatarOutfit(params.label, params.backendTraitValues)

  const traitsByTraitId: Record<string, Trait> = {}
  const configDefaultTraitsById: Record<string, Trait> = {}

  const traitValues = (Object.entries(MOCKED_CREATOR_RENDERER_CONFIG.traitTypeConfigs) as [TraitTypeId, TraitTypeConfig][])
    .reduce((acc, [traitTypeID, traitTypeConfig]) => {
      const defaultTraitId = traitTypeConfig.defaultTrait
      const traitId = backendTraitValues?.[traitTypeID]?.traitId || defaultTraitId
      const traitColors = backendTraitValues?.[traitTypeID]?.traitColors || traitTypeConfig.defaultTraitColors || {}

      acc[traitTypeID] = { traitId, traitColors }

      const trait = traitId ? TRAIT_MOCKS_BY_ID[traitId] : null
      const defaultTrait = defaultTraitId ? TRAIT_MOCKS_BY_ID[defaultTraitId] : null

      if (trait && !traitsByTraitId[traitId]) traitsByTraitId[traitId] = trait
      if (defaultTrait && !configDefaultTraitsById[defaultTrait.id]) configDefaultTraitsById[defaultTrait.id] = defaultTrait

      return acc
    }, {} as BackendTraitValues)

  return res(
    ctx.status(200),
    ctx.json(to<MutationResultSuccess<BackendAvatarOutfitAndConfig>>({
      type: MutationType.Create,
      data: {
        outfit: {
          layeringVersion,
          id,
          label,
          traitValues,
          paletteID,
        },
        outfitTraits: Object.values(traitsByTraitId),
        // config: getMockedCreatorRendererConfigForVersion(layeringVersion)
        config: MOCKED_CREATOR_RENDERER_CONFIG,
        configDefaultTraits: Object.values(configDefaultTraitsById),
      },
    })),
  )
}

export async function updateAvatarOutfitHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  const outfitID = getSinglePathParam(req, 'outfitID')

  const { updatedAvatarOutfit } = await req.json<UpdateAvatarOutfitParams>()

  saveAvatarOutfit(outfitID, updatedAvatarOutfit)

  return res(
    ctx.status(200),
    ctx.json(to<MutationResultSuccess>({ type: MutationType.Upsert })),
  )
}

export async function deleteAvatarOutfitHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  const outfitID = getSinglePathParam(req, 'outfitID')

  deleteAvatarOutfit(outfitID)

  return res(
    ctx.status(200),
    ctx.json(to<MutationResultSuccess>({ type: MutationType.Delete })),
  )
}
