import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'

import { UnlockedTraits } from './traits.types'

export async function fetchTraits(axiosConfig?: AxiosRequestConfig) {
  return axios.get<UnlockedTraits>(endpoints.fetchTraits, { ...axiosConfig })
}

export const TraitsService = {
  fetchTraits,
}
