import { SdkRootStore } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

import type { AppRootStore, BaseAppStore } from '../app-root/app-root.store'

import { NotificationType, StoreNotification } from './notifications.types'

export class NotificationsStore implements BaseAppStore {
  notifications: Required<StoreNotification>[] = []

  appStore: AppRootStore

  sdkStore: SdkRootStore

  constructor(appStore: AppRootStore, sdkStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.appStore = appStore
    this.sdkStore = sdkStore
  }

  addNotification(notification: StoreNotification): string {
    const id = notification.id || uuidv4()

    this.notifications.push({
      id,
      type: notification.type || NotificationType.Info,
      message: notification.message,
      duration: notification.duration || 2000,
    })

    // If there are more than 4 notifications, remove the oldest one
    if (this.notifications.length > 4) {
      this.removeNotification(this.notifications[0].id)
    }

    return id
  }

  removeNotification(id?: string) {
    this.notifications = this.notifications.filter((notification) => notification.id !== id)
  }
}
