import { AnswerValue } from '../../answers/answers.types'
import { Media } from '../../media/media.types'
import { ActivityTypes, BaseActivity } from '../base/base-activity.types'

export enum OptionsQuestionSubtypes {
  Radio = 'radio',
  Select = 'select',
  MultiSelect = 'multiSelect',
  Checkbox = 'checkbox',
  MultiCheckbox = 'multiCheckbox',
  Binary = 'binary',
  Buttons = 'buttons',
}

// TODO (Dani): Think about i18n here:
export interface QuestionOption<V = AnswerValue> {
  value: V;
  label?: string;
  iconSrc?: string;
  media?: Media;
}

// TODO (Dani): Think about i18n here:
export interface OptionsQuestionActivity<V extends AnswerValue = AnswerValue> extends BaseActivity<V> {
  type: ActivityTypes.Question;
  subtype: OptionsQuestionSubtypes;
  question: string;
  options: QuestionOption<V>[];
}

export interface DBOptionsQuestionActivity <V extends AnswerValue = AnswerValue> extends OptionsQuestionActivity<V> {
  correctAnswer?: V;
  feedbackOk?: string;
  feedbackWrong?: string;
}

export enum FreeQuestionSubtypes {
  Text = 'text',
  MultilineText = 'multilineText',
  Number = 'number',
  Slider = 'slider',
}

export interface FreeQuestionActivityTextInputValidation {
  type: 'text';
  minLength?: number;
  maxLength?: number;
  regExp: string;
}

export interface FreeQuestionActivityTextInputConfig extends FreeQuestionActivityTextInputValidation {
  showCharacters: boolean;
}

export interface FreeQuestionActivityNumberInputValidation {
  type: 'number';
  min?: number;
  max?: number;
}

export interface FreeQuestionActivityNumberInputConfig extends FreeQuestionActivityNumberInputValidation {
  step?: number;
  decimals?: number;
}

export interface FreeQuestionActivitySliderInputValidation extends Omit<FreeQuestionActivityNumberInputValidation, 'type'> {
  type: 'slider';
}

export interface FreeQuestionActivitySliderInputConfig extends FreeQuestionActivitySliderInputValidation {
  showInput?: boolean;
}

export type FreeQuestionActivityInputValidation =
  FreeQuestionActivityTextInputValidation |
  FreeQuestionActivityNumberInputValidation |
  FreeQuestionActivitySliderInputValidation

export type FreeQuestionActivityInputConfig =
  FreeQuestionActivityTextInputConfig |
  FreeQuestionActivityNumberInputConfig |
  FreeQuestionActivitySliderInputConfig

export type FreeQuestionActivityInputConfigType = 'text' | 'number' | 'slider'

// TODO (Dani): Think about i18n here:
export interface FreeQuestionActivity<V extends AnswerValue = string | number> extends BaseActivity<V> {
  type: ActivityTypes.Question;
  subtype: FreeQuestionSubtypes;
  question: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputConfig?: FreeQuestionActivityInputConfig;
}

export interface DBFreeQuestionActivity <V extends AnswerValue = AnswerValue> extends FreeQuestionActivity<V> {
  inputValidation?: FreeQuestionActivityInputValidation;
  feedbackOk?: string;
  feedbackWrong?: string;
}

export function isFreeQuestionActivityTextInputValidation(
  validation: FreeQuestionActivityInputValidation,
): validation is FreeQuestionActivityTextInputValidation {
  return validation.type === 'text'
}

export function isFreeQuestionActivityNumberInputValidation(
  validation: FreeQuestionActivityInputValidation,
): validation is FreeQuestionActivityNumberInputValidation {
  return validation.type === 'number'
}

export function isFreeQuestionActivitySliderInputValidation(
  validation: FreeQuestionActivityInputValidation,
): validation is FreeQuestionActivitySliderInputValidation {
  return validation.type === 'slider'
}

export function isFreeQuestionActivityTextInputConfig(
  config: FreeQuestionActivityInputConfig,
): config is FreeQuestionActivityTextInputConfig {
  return config.type === 'text'
}

export function isFreeQuestionActivityNumberInputConfig(
  config: FreeQuestionActivityInputConfig,
): config is FreeQuestionActivityNumberInputConfig {
  return config.type === 'number'
}

export function isFreeQuestionActivitySliderInputConfig(
  config: FreeQuestionActivityInputConfig,
): config is FreeQuestionActivitySliderInputConfig {
  return config.type === 'slider'
}
