import { useRef, useCallback } from 'react'

import { Galaxy, GalaxyPhysicsUpdate } from './animation/galaxy/galaxy.class'

export function useStarsBackground() {
  const galaxyRef = useRef<Galaxy | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const setCanvasRef = useCallback((canvasElement: HTMLCanvasElement) => {
    if (canvasElement) {
      const galaxy = new Galaxy(canvasElement)

      canvasRef.current = canvasElement
      galaxyRef.current = galaxy

      galaxy.tick()
    } else {
      canvasRef.current = null
      galaxyRef.current = null
    }
  }, [])

  const updateStarsBackground = useCallback((galaxyPhysicsUpdate: GalaxyPhysicsUpdate) => {
    const galaxy = galaxyRef.current

    if (!galaxy) return

    galaxy.updatePhysics(galaxyPhysicsUpdate)
  }, [])

  return [setCanvasRef, updateStarsBackground] as const
}
