import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { ReadOnlyStoreOptions } from '../../utils/stores/readonly/readonly-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'

import { PaletteService } from './palettes.service'
import { Palette } from './palettes.types'

export class PalettesStore implements BaseStore {
  colorPalettesResource = createEmptyReadOnlyStoreResource<Palette[]>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore
  }

  get colorPalettes() {
    return this.colorPalettesResource?.data || null
  }

  fetchColorPalettes(storeOptions: ReadOnlyStoreOptions = {}) {
    return fetchStoreResource(
      this.colorPalettesResource,
      PaletteService.fetchColorPalettes,
      null,
      storeOptions,
    )
  }

  clearColorPalettes() {
    clearReadOnlyStoreResource(this.colorPalettesResource)
  }
}
