import { rest, setupWorker } from 'msw'

import { createAvatarOutfitHandler, deleteAvatarOutfitHandler, fetchAvatarOutfitHandler, fetchAvatarOutfitOptionsHandler, updateAvatarOutfitHandler } from '../domains/avatar/avatar.handlers'
import { fetchCreatorRendererConfigHandler } from '../domains/creator/renderer-config/creator-renderer-config.handlers'
import { fetchColorPalettesHandler } from '../domains/palettes/palettes.handler'
import { fetchTraitsHandler } from '../domains/traits/traits.handlers'
import { IS_SERVER } from '../utils/common/common.constants'
import { endpoints, getMockEndpoint } from '../utils/server/endpoints/server-endpoints.constants'

import { bypassHandler } from './mock-helpers.utils'

const handlers = [

  // User:

  /**
   *
   */
  rest.get(getMockEndpoint(endpoints.fetchUser), bypassHandler),

  /**
   *
   */
  rest.put(getMockEndpoint(endpoints.updateUser), bypassHandler),

  // Topics:

  /**
   * Returns all available Topics for the current user.
   *
   * In order for a Topic to qualify as available, a few conditions must be satisfied:
   * - The Topic has no `rules` or all of them are satisfied, which might be expensive in terms of computation (both Lambda and DB).
   * - The Topic's progress must be < 100%. The progress is computed based on the passed Path within the Topic, which, at the same time
   *   also need to check if the `rules` on those Topics are satisfied.
   *
   * Possible solution:
   * - We need a table unlockedTopics (PK: userID+topicID). Think how to manage this, as it involves filtering ALL TOPICS in the DB
   *   on every Activity/Path/Topic/Achievement. Alternatively, this happens on a cronjob, so Topics won't unlock immediately.
   *   Otherwise, we normalize rules so that we can find them by Path ID, Topic ID or Achievement ID, and each time one of these
   *   entities gets updated, we pull all rules that depend on them to re-compute them.
   * - We need a table startedTopics (PK: userID+topicID, holds progress %).
   */
  // rest.get(getMockEndpoint(endpoints.fetchAllTopics), fetchAllTopicsHandler),

  // Paths:

  /**
   * Returns the next Path for a given Topic for he current user, considering a Path can belong to multiple Topics.
   *
   * In order to find the next Path for a given Topic we must:
   * - Get all Paths for the a given Topic and filter out those that have already been passed.
   * - The Path has no `rules` or all of them are satisfied, which might be expensive in terms of computation (both Lambda and DB).
   *
   * Possible solution:
   * - We need an endpoint to indicate we want to start a Topic.
   * - We need the table startedTopics from above (PK: userID+topicID, holds progress %), which also holds a reference to the next Path.
   * - Every time the answers for a Path are saved, we do the filtering needed to update the next Path ref, and return the next
   *   Path already (preloading).
   *
   */
  // rest.get(getMockEndpoint(endpoints.fetchNextPath({ topicID: '' })), fetchNextPathHandler),

  /**
   * Updates the answer for a given Path.
   *
   * In order to do that, and as each activity type's answer is validated differently, the backend needs to load all activities in
   * the Path.
   *
   * This endpoint only returns the answers state and feedback (e.g. success, error, etc.). The preloading of the next Path happens
   * separately, either on the WebSocket or with a different request.
   */
  // rest.put(getMockEndpoint(endpoints.updatePathAnswers({ pathID: ':pathID' })), updatePathAnswersHandler),

  // Paths Answered:

  /**
   * Returns all answered Paths (must be paginated).
   *
   * Think whether it makes sense to already include these in the same request:
   *
   * - Activities.
   * - Last attempt's answers.
   * - All attempt's answers.
   *
   * Maybe instead of this (or on top of this), we want to return all Paths within a given Topic to create some kind of
   * table of contents view.
   */
  // rest.get(getMockEndpoint(endpoints.fetchAnsweredPaths({ page: 0 })), fetchAnsweredPathsHandler),

  // PushPaths:

  /**
   * Returns all Push Paths.
   *
   * This should eventually come from a WebSocket. Right now the endpoint is just polled regularly.
   */
  // rest.get(getMockEndpoint(endpoints.fetchPushPaths), fetchPushPathsHandler),

  /**
   * Updates the answer for a given Push Path.
   *
   * Right now Push Paths are similar to regular Paths, but are considered a different entities. Therefore, they
   * can't be used in Rules. Maybe we want to change this, either by adding new Rule types or by adding PushPaths
   * into the same table and just distinguish them from regular Paths with a flag.
   */
  // rest.put(getMockEndpoint(endpoints.updatePushPathAnswers({ pushPathID: ':pushPathID' })), updatePushPathAnswersHandler),

  // Rewards:

  /**
   * Returns all Rewards (must be paginated).
   *
   * Right now it doesn't include any contextual information about where that Reward came from or what other Rewards came
   * with it, so we need to discuss whether we need that or whether rewards are actually consumed like this at all. Maybe
   * we just want to show Rewards inside something else (Topic, Path or Achievement), or maybe we want to have both options
   * (Rewards inside something or Rewards in a standalone view sorted by date and filtered by type, with links to the other
   * contextual view.)
   */
  // rest.get(getMockEndpoint(endpoints.fetchRewards), fetchRewardsHandler),

  // Achievements:

  /**
   * Returns all Achievements (must be paginated).
   *
   * Right now we return all of them by always re-computing the progress %.
   *
   * We probably need a pivot table to "activate" Achievements for specific users instead, and keep the progress updated there.
   */
  // rest.get(getMockEndpoint(endpoints.fetchAchievements), fetchAchievementHandler),

  // Traits:

  /**
   * Similarly to `fetchRewards`, but returns only Rewards with type=Trait (must be paginated).
   *
   */
  rest.get(getMockEndpoint(endpoints.fetchTraits), fetchTraitsHandler),

  // Palettes:

  /**
   * Fetch all color palettes with their respective cofigs:
   */
  rest.get(getMockEndpoint(endpoints.fetchColorPalettes), fetchColorPalettesHandler),

  // Avatar Values & Config:

  /**
   * Returns the latest creator renderer config:
   */
  rest.get(getMockEndpoint(endpoints.fetchCreatorRendererConfig), fetchCreatorRendererConfigHandler),

  /**
   * Returns a list of FormOptions for the avatar outfits for the current user.
   */
  rest.get(getMockEndpoint(endpoints.fetchAvatarOutfitOptions), fetchAvatarOutfitOptionsHandler),

  /**
   * Returns the selected avatar outfit and rendering config for the current user.
   */
  rest.get(getMockEndpoint(endpoints.fetchAvatarOutfit({ outfitID: ':outfitID' })), fetchAvatarOutfitHandler),

  /**
   * Updates the selected outfit state for the current user.
   */
  rest.put(getMockEndpoint(endpoints.createAvatarOutfit), createAvatarOutfitHandler),

  /**
   * Updates the current avatar state for the current user (assumes only one avatar per user).
   */
  rest.put(getMockEndpoint(endpoints.updateAvatarOutfit({ outfitID: ':outfitID' })), updateAvatarOutfitHandler),

  /**
   * Deletes the selected outfit state for the current user.
   */
  rest.put(getMockEndpoint(endpoints.deleteAvatarOutfit({ outfitID: ':outfitID ' })), deleteAvatarOutfitHandler),

  // User Analytics:
  rest.post(getMockEndpoint(endpoints.postUserAnalyticsEvent), bypassHandler),
]

export const worker = IS_SERVER ? { start: (() => {}) } : setupWorker(...handlers)
