import { Box } from '@mui/material'
import { useEffect } from 'react'

import { CommonBackgroundConfig, CommonBackgroundProps } from '../../../../libs/ui/components/background/base-background.types'

import { useStarsBackground } from './stars-background.hook'

const BACKGROUND_COLOR = '#060b16'

export type AppStarsBackgroundConfig = CommonBackgroundConfig

export type AppStarsBackgroundProps = CommonBackgroundProps & AppStarsBackgroundConfig

export function AppStarsBackground({
  scrollableRef,
}: AppStarsBackgroundProps) {
  const [setCanvasRef, updateStarsBackground] = useStarsBackground()

  useEffect(() => {
    const scrollable = scrollableRef.current

    if (!scrollable) return

    let prevScrollTop = 0
    let prevScrollTime = 0
    let prevDirection: 'up' | 'down' = 'down'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let deltaSum = 0
    let timeoutID = 0

    function handleScroll() {
      if (!scrollable) return

      window.clearTimeout(timeoutID)

      timeoutID = window.setTimeout(() => {
        deltaSum = 0
      }, 50)

      const { scrollTop } = scrollable
      const now = Date.now()

      const delta = scrollTop - prevScrollTop
      const deltaTime = now - prevScrollTime
      const direction = delta < 0 ? 'up' : 'down'

      if (direction === prevDirection) {
        deltaSum += Math.abs(delta)
      } else {
        deltaSum = 0
      }

      prevScrollTop = scrollTop
      prevScrollTime = now
      prevDirection = direction

      updateStarsBackground({
        accelerateY: (direction === 'up' ? 1 : -1) * (delta / deltaTime) * (delta / deltaTime),
      })
    }

    scrollable.addEventListener('scroll', handleScroll)

    // eslint-disable-next-line consistent-return
    return () => {
      window.clearTimeout(timeoutID)
      scrollable.removeEventListener('scroll', handleScroll)
    }
  }, [scrollableRef, updateStarsBackground])

  return (
    <Box
      component="canvas"
      ref={ setCanvasRef }
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -2,
        background: BACKGROUND_COLOR,
        transform: 'translateZ(0)',
      }} />
  )
}
