import { SDK_STORE, WithChildrenProp } from '@ng-mono/sdk'
import { configure } from 'mobx'
import { createContext, useContext } from 'react'

import { AppRootStore } from './app-root/app-root.store'

configure({
  enforceActions: 'never',
})

// We make the store available outside of react
export const APP_STORE = new AppRootStore(SDK_STORE)

const StoreContext = createContext<AppRootStore>(APP_STORE)

export function AppStoreProvider({
  children,
}: WithChildrenProp) {
  return (
    <StoreContext.Provider value={ APP_STORE }>
      {children}
    </StoreContext.Provider>
  )
}

export function useAppStore() {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error('A AppRootStore instance must be provided to AppStoreProvider.')
  }

  return context
}
