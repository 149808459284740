import { UserRole } from '@ng-mono/sdk'
import React from 'react'

import { AppBackgroundConfig } from '../../components/app/background/app-background.component'
import { AppFooterConfig } from '../../components/app/footer/app-footer.component'
import { AppLayoutConfig } from '../../components/app/layout/app-layout.component'
import { AppNavbarConfig } from '../../components/app/navbar/app-navbar.component'

export enum RouteAuth {
  None = 'none',
  Anonymous = 'anonymous',
  Required = 'required',
}

export interface RouteConfig {
  path: string;
  title: string;
  analyticsLabel?: string;
  auth: RouteAuth;
  availableTo?: UserRole[];
  page: React.ComponentType;
  layout?: AppLayoutConfig;
  nav?: boolean | AppNavbarConfig;
  footer?: boolean | AppFooterConfig;
  background?: boolean | AppBackgroundConfig;
  disabled?: boolean;
  children?: RouteConfig[];
}

export interface RouteMatch extends RouteConfig {
  isRedirect?: boolean;
}
