import { GenericError } from '../errors.types'
import { isGenericError } from '../errors.utils'

export class SentryError<C extends string = string> extends Error implements GenericError<C> {
  name: string

  message: string

  code?: C

  cause: null | unknown | GenericError = null

  constructor(
    name: string,
    message: string,
    cause: null | unknown = null,
  ) {
    super()

    this.name = name
    this.message = message
    this.cause = cause

    if (isGenericError(cause)) this.code = cause.name as C
  }
}
