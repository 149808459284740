import * as Yup from 'yup'

export const PASSWORD_MIN = 8
export const PASSWORD_MAX = 100
export const PASSWORD_SYMBOLS = ['^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '"', '!', '@', '#', '%', '&', '/', '\\', ',', '>', '<', "'", ':', ';', '|', '_', '~', '`', '=', '+', '-', ' ']

export const passwordHasNumberValidatorFn = (value?: string | null) => !!value && /\d/.test(value)
export const passwordHasLowerCaseValidatorFn = (value?: string | null) => !!value && value.toUpperCase() !== value
export const passwordHasUpperCaseValidatorFn = (value?: string | null) => !!value && value.toLocaleLowerCase() !== value
export const passwordHasSymbolValidatorFn = (value?: string | null) => !!value && /[\^$*.[\]{}()?!"@#%&/\\,><':;|_~`=+\- ]/.test(value)

if (process.env.NODE_ENV === 'development') {
  PASSWORD_SYMBOLS.forEach((validSymbol) => {
    if (!passwordHasSymbolValidatorFn(validSymbol)) throw new Error(`${validSymbol} should be an accepted symbol.`)
  })
}

export const yupPasswordValidation = Yup.string().required('Password is required')
  .min(PASSWORD_MIN, `Password can't have less than ${PASSWORD_MIN} characters`)
  .max(PASSWORD_MAX, `Password can't have more than ${PASSWORD_MAX} characters`)
  .test(
    'has-number',
    'Password must have at least one number',
    passwordHasNumberValidatorFn as any,
  )
  .test(
    'has-lower',
    'Password must have at least one lower case letter',
    passwordHasLowerCaseValidatorFn as any,
  )
  .test(
    'has-upper',
    'Password must have at least one upper case letter',
    passwordHasUpperCaseValidatorFn as any,
  )
  .test(
    'has-symbol',
    'Password must have at least one symbol',
    passwordHasSymbolValidatorFn as any,
  )

export function getYupRepeatPasswordValidation(repeatPasswordLabel: string, newPasswordID: string) {
  return Yup.string().required(`${repeatPasswordLabel} is required`).nullable().oneOf([Yup.ref(newPasswordID), null], 'Passwords don\'t match')
}

export const yupCodeValidation = Yup.string()
  .required('Code is required')
  .matches(/[\S]+/, { message: 'Code must not include any spaces.' })
