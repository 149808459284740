import { useSentryError, WithChildrenProp } from '@ng-mono/sdk'
import { useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { AppErrorBoundaryFallback } from '../../../error-handling/fallbacks/AppErrorBoundaryFallback/AppErrorBoundaryFallback'

export function InnerErrorBoundary({ children }: WithChildrenProp) {
  const { reportSentryError } = useSentryError()

  const errorHandler = useCallback((error: Error, { componentStack }: { componentStack: string }) => {
    // Router and stores data injected from `useErrorReporting` automatically:
    reportSentryError({ error, componentStack, errorBoundary: 'in' })
  }, [reportSentryError])

  return (
    <ErrorBoundary FallbackComponent={ AppErrorBoundaryFallback } onError={ errorHandler }>
      { children }
    </ErrorBoundary>
  )
}
