import { WithChildrenProp } from '@ng-mono/sdk'
import { motion } from 'framer-motion'

export function PageMotion({ children }: WithChildrenProp) {
  // this line makes loading a new page smoother and less 'flikkery'
  // specially when using react motion
  // Basically we set the min width to 100%
  return (
    <motion.div
      initial={{
        x: '-100%',
      }}
      animate={{
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: '100%',
      }}
      style={{
        minHeight: '100%',
        minWidth: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      {children}
    </motion.div>
  )
}
