import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'

import { AchievementsService } from './achievements.service'
import { Achievement } from './achievements.types'

export class AchievementsStore implements BaseStore {
  achievementsResource = createEmptyReadOnlyStoreResource<Achievement[]>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  get achievements() {
    return this.achievementsResource.data
  }

  fetchAchievements() {
    return fetchStoreResource(this.achievementsResource, AchievementsService.fetchAchievements)
  }

  clearAchievements() {
    clearReadOnlyStoreResource(this.achievementsResource)
  }
}
