import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '../../../axios/axios.constants'
import { to } from '../../../utils/typescript/typescript.utils'
import { CampaignFlowData, CampaignAvatarData, CampaignData } from '../campaigns.types'

export async function fetchCampaign(
  flowEndpoint: string,
  avatarEndpoint: string,
  axiosConfig?: AxiosRequestConfig,
): Promise<AxiosResponse<CampaignData>> {
  try {
    const flowPromise = axios.get<CampaignFlowData>(flowEndpoint, {
      ...axiosConfig,
      baseURL: '',
    })

    const avatarPromise = axios.get<CampaignAvatarData>(avatarEndpoint, {
      ...axiosConfig,
      baseURL: '',
    })

    const [flowData, avatarData] = await Promise.all([
      flowPromise,
      avatarPromise,
    ])

    if (!flowData.data.isActive || !avatarData.data.isActive) {
      throw new Error('Flow not active')
    }

    const reponse = {
      ...flowData,
      data: {
        ...flowData.data,
        avatarTraitValues: avatarData.data.avatarTraitValues,
      },
    }

    return to<AxiosResponse<CampaignData>>(reponse)
  } catch (err) {
    return Promise.reject(err)
  }
}
