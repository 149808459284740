import { Box } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { useNotifications } from '../../store/notifications/notifications.hook'

import { Toast } from './toast/toast.component'

function NotificationsComponent() {
  const { notifications } = useNotifications()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        bottom: 0,
        p: 1,
        gap: 1,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%)',
        width: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5))',
        pointerEvents: 'none',
        zIndex: 2000,
        transition: 'padding ease-in-out 0.2s',

        '&:empty': {
          py: 0,
        },
      }}>
      <AnimatePresence initial={ false }>
        {notifications.map(({ id, type, message, duration }) => (
          <Toast
            key={ id }
            id={ id }
            type={ type }
            message={ message }
            duration={ duration } />
        ))}
      </AnimatePresence>
    </Box>
  )
}

export const Notifications = observer(NotificationsComponent)
