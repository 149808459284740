import { IS_SERVER } from '../../../utils/common/common.constants'

import { DEFAULT_DOWNLOAD_CONFIG } from './avatar-download.constants'
import { NFMeDownloadConfig } from './avatar-download.types'

// TODO (Dani): DRY this (AvatarDownloadService / AvatarSettingsService)

const DOWNLOAD_CONFIG_LS_KEY = 'DOWNLOAD_CONFIG_LS_KEY'

function loadConfig(): NFMeDownloadConfig {
  if (IS_SERVER) return DEFAULT_DOWNLOAD_CONFIG

  try {
    const item = localStorage.getItem(DOWNLOAD_CONFIG_LS_KEY)

    if (!item) return DEFAULT_DOWNLOAD_CONFIG

    const downloadConfig = JSON.parse(item)

    return { ...DEFAULT_DOWNLOAD_CONFIG, ...downloadConfig }
  } catch {
    localStorage.removeItem(DOWNLOAD_CONFIG_LS_KEY)

    return DEFAULT_DOWNLOAD_CONFIG
  }
}

function saveConfig(downloadConfig: NFMeDownloadConfig) {
  if (IS_SERVER) return

  localStorage.setItem(DOWNLOAD_CONFIG_LS_KEY, JSON.stringify(downloadConfig))
}

export const AvatarDownloadService = {
  loadConfig,
  saveConfig,
}
