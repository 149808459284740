import { NFMeSettings } from './avatar-settings.types'

export const DEFAULT_SETTINGS: NFMeSettings = {
  // Canvas:
  canvasMaxHeight: '50%',
  canvasBackground: 'none',

  // Item selector:
  // itemSelectorRandomizer: 'visible',
  itemSelectorColumns: '5',
  itemSelectorPreviewVariant: 'shaded-body',
  itemSelectorChangeBehavior: 'none',
  itemSelectorHoldBehavior: 'immediately',

  // Data:
  useMockedItems: 'no',
}
