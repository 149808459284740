import { Box, Chip, Stack, SxProps, Theme, Typography } from '@mui/material'
import { getBuildDate, getAppVersion } from '@ng-mono/sdk'

import { CommonFooterConfig, CommonFooterProps } from '../../../libs/ui/components/footer/base-footer.types'
import { useLinkComponent } from '../../../libs/ui/components/link/base/base-link.provider'
import { Logo } from '../../../libs/ui/components/logo/logo.component'
import { SocialMedia } from '../../../libs/ui/components/social-media/social-media.component'

const copyrightLabel = `Copyright © ${new Date().getFullYear()} NFMe Galaxy`

const trademarkLabel = `
  "NFMe Galaxy", "NFME", "NFMe" and "nfme" are trademark claimed and copyrighted, registered or in process of registration, with all rights asserted globally.
`

export interface AppFooterConfig extends CommonFooterConfig {
  withLogo?: boolean;
  withSocial?: boolean;
  withVersion?: boolean;
}

export type AppFooterProps = CommonFooterProps & AppFooterConfig

const FOOTER_ROW_SX: SxProps<Theme> = {
  position: 'relative',
  py: 2.5,
  px: 4.5,
  borderTop: ({ borders }) => borders.default(),
  color: ({ palette }) => palette.primary.main,
}

const FOOTER_SOCIAL_ROW_SX: SxProps<Theme> = {
  ...FOOTER_ROW_SX,
  py: 1,
  px: 2,
}

export function AppFooter({
  withLogo = false,
  withSocial = true,
  withVersion = true,
}: AppFooterProps) {
  const Link = useLinkComponent()

  return (
    <Stack
      component="footer"
      id="footer"
      sx={{ background: 'linear-gradient(to bottom, rgba(0,0,0,0), black)' }}>

      { withLogo && (
        <Box sx={ FOOTER_ROW_SX }>
          <Logo />
        </Box>
      ) }

      { withSocial && (
        <Box sx={ FOOTER_SOCIAL_ROW_SX }>
          <SocialMedia size="md" fullWidth />
        </Box>
      ) }

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          ...FOOTER_ROW_SX,
          gap: 1.5,
        }}>
        <Link href="https://nfmegalaxy.com/privacy-policy" underline="always" sx={{ width: 'fit-content' }}>
          <Typography variant="body2">
            Privacy Policy
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: ({ palette }) => palette.primary.main }}>|</Typography>
        <Link href="https://nfmegalaxy.com/terms-and-conditions" underline="always" sx={{ width: 'fit-content' }}>
          <Typography variant="body2">
            Terms & Conditions
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ color: ({ palette }) => palette.primary.main }}>|</Typography>
        <Link href="https://nfmegalaxy.com/impressum" underline="always" sx={{ width: 'fit-content' }}>
          <Typography variant="body2">
            Impressum
          </Typography>
        </Link>
      </Stack>

      <Box sx={ FOOTER_ROW_SX }>
        <Typography variant="body2">
          {copyrightLabel}
        </Typography>

        { withVersion && (
          <Chip
            label={ getAppVersion() }
            title={ getBuildDate() }
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              position: 'absolute',
              top: '50%',
              right: ({ spacing }) => spacing(2),
              transform: 'translate(0, -50%)',
            }} />
        ) }
      </Box>
      <Box
        sx={ FOOTER_ROW_SX }>
        <Typography
          variant="body2"
          sx={{
            fontSize: '12px',
          }}>
          {trademarkLabel}
        </Typography>
      </Box>
    </Stack>
  )
}
