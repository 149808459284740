import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../../stores/sdk/sdk.store'
import { BaseStore } from '../../../utils/stores/common/common-store.types'
import { ReadOnlyStoreOptions } from '../../../utils/stores/readonly/readonly-store.types'
import { createEmptyReadOnlyStoreResource, fetchStoreResource, clearReadOnlyStoreResource } from '../../../utils/stores/readonly/readonly-store.utils'

import { CreatorRendererConfigService } from './creator-renderer-config.service'
import { AvatarConfig } from './creator-renderer-config.types'

export class CreatorRendererConfigStore implements BaseStore {
  // Latest NFMe config:

  creatorRendererConfigResource = createEmptyReadOnlyStoreResource<AvatarConfig>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore
  }

  get creatorRendererConfig() {
    return this.creatorRendererConfigResource?.data || null
  }

  fetchCreatorRendererConfig(storeOptions: ReadOnlyStoreOptions = {}) {
    return fetchStoreResource(
      this.creatorRendererConfigResource,
      CreatorRendererConfigService.fetchCreatorRendererConfig,
      null,
      storeOptions,
    )
  }

  clearCreatorRendererConfig() {
    clearReadOnlyStoreResource(this.creatorRendererConfigResource)
  }
}
