import { SxProps, Theme } from '@mui/material'
import type { UserRole } from '@ng-mono/sdk'
import React from 'react'

import type { ListItemRoot } from '../../../base-item/base-item.types'
import type { MenuVariant } from '../../base-menu.types'
import type { MenuActionItemConfig } from '../action/menu-action-item.component'
import type { MenuCaptionItemConfig } from '../caption/menu-caption-item.component'
import type { MenuChildrenItemConfig } from '../children/menu-children-item.component'
import type { MenuCustomItemBaseProps, MenuCustomItemConfig } from '../custom/menu-custom-item.component'
import type { MenuDividerItemConfig } from '../divider/menu-divider-item.component'
import type { MenuLinkItemConfig } from '../link/menu-link-item.component'

export enum MenuItemType {
  Action = 'action',
  Caption = 'caption',
  Children = 'children',
  Divider = 'divider',
  Link = 'link',
  Custom = 'custom',
}

export interface BaseMenuItemConfig {
  id?: string;
  title?: string; // TODO: Needed?
  availableTo?: UserRole[];
  hidden?: boolean;
}

export interface BaseMenuItemProps {
  root?: ListItemRoot;
  sx?: SxProps<Theme>;
  variant?: MenuVariant;
  userRole?: null | UserRole;
  realUserRole?: null | UserRole;
  onAction?: (idOrEvent: string | React.MouseEvent<HTMLButtonElement>, actionOrUndefined?: string, valueOrUndefined?: string) => void;
}

export type BaseMenuItemPropsAnConfig = BaseMenuItemConfig & BaseMenuItemProps

export type MenuItemConfig<A extends string = string, P extends MenuCustomItemBaseProps = MenuCustomItemBaseProps> =
  | MenuActionItemConfig<A>
  | MenuCaptionItemConfig
  | MenuChildrenItemConfig
  | MenuDividerItemConfig
  | MenuLinkItemConfig
  | MenuCustomItemConfig<P>
