import { IS_SERVER } from '../../../utils/common/common.constants'

import { DEFAULT_SETTINGS } from './avatar-settings.constants'
import { NFMeSettings } from './avatar-settings.types'

// TODO (Dani): DRY this (AvatarDownloadService / AvatarSettingsService)

const CREATOR_SETTINGS_LS_KEY = 'CREATOR_SETTINGS_LS_KEY'

function loadSettings(): NFMeSettings {
  if (IS_SERVER) return DEFAULT_SETTINGS

  try {
    const item = localStorage.getItem(CREATOR_SETTINGS_LS_KEY)

    if (!item) return DEFAULT_SETTINGS

    const settings = JSON.parse(item)

    return { ...DEFAULT_SETTINGS, ...settings }
  } catch {
    localStorage.removeItem(CREATOR_SETTINGS_LS_KEY)

    return DEFAULT_SETTINGS
  }
}

function saveSettings(settings: NFMeSettings) {
  if (IS_SERVER) return

  localStorage.setItem(CREATOR_SETTINGS_LS_KEY, JSON.stringify(settings))
}

export const AvatarSettingsService = {
  loadSettings,
  saveSettings,
}
