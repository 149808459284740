import { SentryError, UserAnalyticsService } from '@ng-mono/sdk'

export function findDuplicateIDsOnThePage() {
  return Array
    .from(document.querySelectorAll('[id]'))
    .map((element) => element.id)
    .filter((id, index, ids) => id && ids.lastIndexOf(id) !== index)
    .sort()
}

let intervalID = 0

let lastDuplicateIDsUniqueKey = ''

export function watchForDuplicateIDsOnThePage() {
  window.clearInterval(intervalID)

  intervalID = window.setInterval(() => {
    const duplicateIDs = findDuplicateIDsOnThePage()
    const duplicateIDsUniqueKey = duplicateIDs.join(', ')

    if (lastDuplicateIDsUniqueKey === duplicateIDsUniqueKey) return

    lastDuplicateIDsUniqueKey = duplicateIDsUniqueKey

    // eslint-disable-next-line no-console
    if (duplicateIDs.length > 0) {
      const errorMessage = `Found duplicate IDs in:\n\n${window.location.href}\n\n${duplicateIDs.map((id) => `- #${id}`).join('\n')}\n`

      UserAnalyticsService.captureException(new SentryError('DuplicateIdError', errorMessage))
    }
  }, 1000)
}
