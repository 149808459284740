import { TraitTypeId } from '../creator/renderer-config/creator-renderer-config.types'

import { CampaignFlowStep, CampaignTypeSlug } from './campaigns.types'

export const VISITED_CAMPAIGNS_SLUGS_LS_KEY = 'VISITED_CAMPAIGNS_SLUGS_LS_KEY'

export const LAST_CHANNEL_LS_KEY = 'LAST_CHANNEL_LS_KEY'

export const LAST_CHANNEL_LS_TTL = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

export const CAMPAIGN_TYPE_SLUGS: CampaignTypeSlug[] = ['celebrities', 'nfme-of-the-week', 'a-fresh-start']

export const CAMPAIGN_FLOW_STEPS: CampaignFlowStep[] = ['create', 'sign-in', 'forgot-password', 'sign-up', 'account-confirmation', 'profile', 'download-and-share', 'thank-you']

export const DISABLED_ITEMS_CELEBRITY_CAMPAIGN: string[] = [
  'background.2.galaxy',
  'background.3.pride-flag',
  'background.4.snow-capped-rockies-forest',
  'background.5.haring-art',
  'background.6.stamp',
  'background.7.desert',
  'background.8.sun-n-surf',
  'background.9.christmas-tree-and-snow',
  'background.10.neon-city',
  'background.11.mona-lisa',
  'background.12.king-kong-noir',
  'background.13.cash',
  'background.14.explosion',
  'background.15.matrix',
  'background.16.wonder-circle',
  'background.17.super',
  'background.18.super-s',
  'background.19.spidey-spider',
  'background.20.long-beach',
]

export const DISABLED_CATEGORIES_CELEBRITY_CAMPAIGN: TraitTypeId[] = [
  'background-overlay',
  'frame-overlay',
  'overlay',
]
