import { getCompareByNumberFn } from '@swyg/sorteo'
import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { devLog, DevLogGroup } from '../../utils/logs/logs.utils'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearMutableStoreResource, createEmptyMutableStoreResource, updateStoreResource } from '../../utils/stores/mutation/mutable-store.utils'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'
import { PathResult } from '../answers/answers.types'

import { PushPathsService, UpdatePushPathAnswerParams } from './push-paths.service'
import { isBlockingPushPathPriority, isInPathPushPathPriority, PushPath } from './push-paths.types'

const PUSH_PATHS_DISABLED = true

export type UpdateStatsAction = 'next' | 'back' | 'submit' | 'finish' | 'abort'

export class PushPathsStore implements BaseStore {
  pushPathsResource = createEmptyReadOnlyStoreResource<PushPath[]>()

  pushPathsMutation = createEmptyMutableStoreResource<PathResult>()

  pushPath: PushPath | null = null

  private _wait = false

  private _alreadyHadPushPath = false

  // Stats:

  private _activitiesSinceLastPushPath = 0

  private _pathsSinceLastPushPath = 0

  // TODO (Dani): STAT IMPROVEMENTS:
  // - Consider adding additional checks (do not show between content and question activities).
  // - Do we need manually-defined insertion points?
  // - Use negative indexes and/or progress percent for _activitiesSinceLastPushPath?
  // - Min. time without interruptions (_lastPushPathFinishedAt)
  // - Streak (positive/negative) filter and/or analytic-oriented metrics/features (_recentPathsStatus)?
  // - Account for insertAt and other options currently not implemented.
  // - Count route changes as "_activitiesSinceLastPushPath"?
  // - Move stats to their own store.

  // TODO (Dani): BUGS:
  // - DONE: Stop PushPaths while in the summary.
  // - DONE: Fix Terms & Conditions PushPath opening in Summary already.
  // - DONE: After the terms update push path, the progress for the normal path is lost.
  // - When leaving an unfinished path, stats should be decreased.
  // - When showing the PushPath Summary, we might want to keep the progress bar in place.
  // - Should rewards for Push Paths (particularly those without Summary) be shown at the end with the normal Path?
  // - Allow retry/skip for PushPaths.

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  updateNextPushPath(reason: 'stats' | 'routing') {
    if (PUSH_PATHS_DISABLED) return null

    const pushPaths = this.pushPathsResource.data || []
    const activitiesSinceLastPushPath = this._activitiesSinceLastPushPath
    const pathsSinceLastPushPath = this._pathsSinceLastPushPath

    // To simplify the logic here, a PushPath can't be replaced by another PushPath. If the user is already on a
    // PushPath and a new one is received, even if it's a Blocking one, it will not be displayed until the user is
    // past the Summary:
    if (pushPaths.length === 0 || this.pushPath || this._wait) return this.pushPath

    const nextPushPath = pushPaths.filter((pushPath) => {
      const { priority } = pushPath

      // If `this.pushPath` is already set, we skip all PushPath candidates that are not Blocking. Blocking PushPaths
      // can actually interrupt other PushPaths (blocking or not) if their `priority.level` is higher.
      // if ((this.pushPath || this._wait || this._alreadyHadPushPath) && !isBlockingPushPathPriority(priority)) return false
      if (this._alreadyHadPushPath && !isBlockingPushPathPriority(priority)) return false

      const activitiesSinceLastPushPathNeeded = isBlockingPushPathPriority(priority) ? 0 : priority.activitiesNeeded
      const pathsSinceLastPushPathNeeded = isBlockingPushPathPriority(priority) ? 0 : priority.pathsNeeded

      // When updating the next PushPath due to a change in stats (coming from the Path page/component), any PushPath is
      // elegible. However, when updating due to a change in routing, InPath PushPaths are not elegible (as we are
      // outside the Path page/component):
      return (reason === 'stats' || !isInPathPushPathPriority(priority))
        && activitiesSinceLastPushPath >= activitiesSinceLastPushPathNeeded
        && pathsSinceLastPushPath >= pathsSinceLastPushPathNeeded
    }).sort(getCompareByNumberFn((pushPath) => pushPath.priority.level))[0] || null

    if (nextPushPath) {
      devLog(DevLogGroup.PushPaths, `updateNextPushPath(${reason}): ${this.pushPath ? 'Update' : 'Set'} pushPath =`, nextPushPath)

      this.pushPath = nextPushPath
    }

    return this.pushPath
  }

  get pushPaths() {
    return this.pushPathsResource.data
  }

  get pushPathResource() {
    return createEmptyReadOnlyStoreResource(this.pushPath)
  }

  fetchPushPaths() {
    if (PUSH_PATHS_DISABLED) return

    return fetchStoreResource(this.pushPathsResource, PushPathsService.fetchPushPaths)
  }

  clearPushPaths() {
    this.pushPath = null

    clearReadOnlyStoreResource(this.pushPathsResource)
  }

  /**
   * Called when exiting a Path or PushPath (from the Summary component, either manually or automatically):
   */
  finishPath() {
    let isPushPath = false

    if (this.pushPath) {
      devLog(DevLogGroup.PushPaths, 'finishPath(): pushPath = null')

      // The user has just finished a PushPath, so...

      // 1. We remove the current PushPath from the queue of PushPaths:
      const pushPaths = this.pushPathsResource.data
      const currentPushPathID = this.pushPath.id

      if (pushPaths && currentPushPathID) this.pushPathsResource.data = pushPaths.filter((pushPath) => pushPath.id !== currentPushPathID)

      // 2. We reset the current PushPath:
      this.pushPath = null

      // 3. And clear the mutation data for the PushPath:
      this.clearUpdatePushPathAnswersMutation()

      isPushPath = true
    }

    this.updateStats('finish', isPushPath)
  }

  abortPath() {
    let isPushPath = false

    if (this.pushPath) {
      this.pushPath = null

      this.clearUpdatePushPathAnswersMutation()

      isPushPath = true
    }

    this.updateStats('abort', isPushPath)
  }

  updateStats(action: UpdateStatsAction, isPushPath = !!this.pushPath) {
    if (PUSH_PATHS_DISABLED) return

    if (isPushPath) {
      switch (action) {
        case 'back':
        case 'next':
          this._activitiesSinceLastPushPath = 0
          break

        case 'submit':
          this._activitiesSinceLastPushPath = 0
          this._pathsSinceLastPushPath = 0

          // After submitting PushPaths' answers, a new PushPath can't be displayed after the Summary component renders
          // and calls `finishPath()` (manually or automatically):
          this._wait = true

          break

        case 'finish':
        case 'abort':
          // Once a PushPath is finished (the user moves past the Summary), new PushPaths can be elected again (but
          // only Blocking ones, as otherwise only one PushPath per regular Path is allowed):
          this._wait = false
          this._alreadyHadPushPath = true

          break

        default:
      }
    } else {
      switch (action) {
        case 'back':
          --this._activitiesSinceLastPushPath
          break

        case 'next':
          ++this._activitiesSinceLastPushPath
          break

        case 'submit':
          ++this._activitiesSinceLastPushPath
          ++this._pathsSinceLastPushPath

          this._wait = true

          break

        case 'finish':
        case 'abort':
          this._wait = false

          if (this._alreadyHadPushPath) {
            this._alreadyHadPushPath = false
            this._activitiesSinceLastPushPath = 0
            this._pathsSinceLastPushPath = 0
          }

          break

        default:
      }
    }

    devLog(DevLogGroup.PushPaths, `updateStats(${action}, isPushPath = ${isPushPath}): Stats =`, {
      _wait: this._wait,
      _alreadyHadPushPath: this._alreadyHadPushPath,
      _activitiesSinceLastPushPath: this._activitiesSinceLastPushPath,
      _pathsSinceLastPushPath: this._pathsSinceLastPushPath,
    })

    this.updateNextPushPath('stats')
  }

  // This is automatically called after saving the last answer (from FlowController.store.ts):
  updatePushPathAnswers() {
    const { pushPath } = this

    // TODO (Dani): Handle error:
    if (!pushPath) return

    const pushPathID = pushPath.id
    const activities = pushPath.activities || []

    // TODO (Dani): Handle error:
    if (activities.length === 0) return

    const params: UpdatePushPathAnswerParams = {
      pushPathID,
      answers: activities.map((activity) => activity.userAnswer).filter((value) => value !== undefined) as any,
    }

    return updateStoreResource(this.pushPathsMutation, PushPathsService.updatePushPathAnswers, params)
  }

  clearUpdatePushPathAnswersMutation() {
    clearMutableStoreResource(this.pushPathsMutation)
  }
}
