/**
 * somethingFooBar => Something Foo Bar
 */
export function camelCaseToTitleCase(str: string) {
  const result = str.replace(/([A-Z])/g, ' $1')

  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`
}

/**
 * Something-foo-bar => Something Foo Bar
 */
export function kebabCaseToTitleCase(str: string) {
  return str.split('-').map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`).join(' ')
}

/**
 * Something foo bar => somethingFooBar
 */
export function regularCaseToCamelCase(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

/**
 * Something foo bar => Something Foo Bar
 */
export function capitalizeSentence(sentence: string): string {
  return sentence.split(' ').map((word) => word[0].toUpperCase() + word.substring(1)).join(' ')
}
