import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints, FetchNextPathURLParams, UpdatePathAnswersURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { MutationResult } from '../../utils/server/mutation/server-mutation.types'
import { to } from '../../utils/typescript/typescript.utils'
import { Answer } from '../answers/answers.types'

import { BackendPath, Path } from './paths.types'

// TODO (Dani): Frontend should be able to skip a path as there might be a frontend error on submission?

function transformFetchNextPath(response: AxiosResponse<BackendPath>): AxiosResponse<Path> {
  const backendPath = response.data

  return to<AxiosResponse<Path>>({
    ...response,
    data: {
      ...backendPath,
      activityIndex: 0,
      totalActivities: backendPath.activities.length,
    },
  })
}

export function fetchNextPath(params: FetchNextPathURLParams, axiosConfig?: AxiosRequestConfig) {
  return axios.get<Path>(endpoints.fetchNextPath(params), axiosConfig).then(transformFetchNextPath)
}

export interface UpdatePathAnswerParams extends UpdatePathAnswersURLParams {
  answers: Answer[];
}

export function updatePathAnswers({ answers, ...urlParams }: UpdatePathAnswerParams, axiosConfig?: AxiosRequestConfig) {
  return axios.put<MutationResult>(endpoints.updatePathAnswers(urlParams), answers, axiosConfig)
}

export const PathsService = {
  fetchNextPath,
  updatePathAnswers,
}
