import { SocialNetwork } from '@ng-mono/sdk'

export interface SocialMediaItemConfig {
  label: string;
  socialNetwork: SocialNetwork;
  iconSRC: string;
  link: string;
}

export const SOCIAL_MEDIA: SocialMediaItemConfig[] = [{
  label: 'LinkedIn',
  socialNetwork: 'linkedin',
  iconSRC: '/icons/linkedin.svg',
  link: 'https://www.linkedin.com/company/nfmegalaxy',
}, {
  label: 'Twitter',
  socialNetwork: 'twitter',
  iconSRC: '/icons/twitter.svg',
  link: 'https://twitter.com/nfmegalaxy',
}, {
  label: 'Instagram',
  socialNetwork: 'instagram',
  iconSRC: '/icons/instagram.svg',
  link: 'https://instagram.com/nfmegalaxy',
}, {
  label: 'Facebook',
  socialNetwork: 'facebook',
  iconSRC: '/icons/facebook.svg',
  link: 'https://www.facebook.com/nfmegalaxy',
}]
