import { MenuItem } from '@mui/material'
import { canUserSeeAs, UserRole, FormOption, FormOptionItem, isFormOptionItem, ZWS } from '@ng-mono/sdk'
import { useMemo } from 'react'

import { TextField, TextFieldProps } from '../text/TextField'

export interface SelectFieldProps<V extends string | number> extends TextFieldProps {
  options: (FormOption<V> | FormOptionItem<V>)[];
  userRole?: UserRole | null;
  realUserRole?: UserRole | null;
}

export function SelectField<V extends string | number>({
  value,
  options,
  userRole = null,
  realUserRole = null,
  ...textFieldProps
}: SelectFieldProps<V>) {
  const optionsElements = useMemo(() => options.map((option) => {
    // TODO (Dani): option.iconSrc is no used.

    const { isHidden, visibilitySx } = canUserSeeAs(userRole, realUserRole, option.availableTo)

    if (isHidden) return null

    return (
      <MenuItem key={ `${option.value}` } value={ option.value } disabled={ option.disabled } sx={ visibilitySx }>
        { isFormOptionItem(option) ? option.element : (option.label || ZWS) }
      </MenuItem>
    )
  }), [options, userRole, realUserRole])

  return (
    <TextField
      select
      fullWidth
      value={ value || '' }
      { ...textFieldProps }>
      {optionsElements}
    </TextField>
  )
}
