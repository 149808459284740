import { SxProps, Theme } from '@mui/material'

import { withHasActiveClassName, withHasHoverClassName } from '../../hooks/hover/hover.hook'

export const interactiveRootSx: SxProps<Theme> = {
  transition: 'transform linear 100ms',

  [withHasHoverClassName('&:not(:disabled):hover')]: {
    transform: 'scale(1.05)',
  },

  [withHasActiveClassName('&:not(:disabled):active')]: {
    transform: 'scale(0.95)',
  },
}

export const interactiveSvgChildSx: SxProps<Theme> = {
  '& svg': {
    transition: 'transform linear 100ms',
    pointerEvents: 'none',
  },

  [withHasHoverClassName('&:not(:disabled):hover svg')]: {
    transform: 'scale(1.05)',
  },

  [withHasActiveClassName('&:not(:disabled):active svg')]: {
    transform: 'scale(0.95)',
  },
}

export function asCSSVars(obj: Record<string, string | number | undefined>) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) acc[`--${key}`] = typeof value === 'number' ? `${value}px` : value

    return acc
  }, {} as Record<string, string>)
}
