import { Box, CircularProgress, Button as MuiButton, ButtonProps as MUIButtonProps, Stack } from '@mui/material'
import { useMixpanelClick, EventExtra, isPromise } from '@ng-mono/sdk'
import React, { useState } from 'react'

import { useLinkComponent } from '../../link/base/base-link.provider'

export interface BaseButtonProps extends MUIButtonProps {
  analyticsLabel: string;
  analyticsExtra?: EventExtra;
  target?: string;
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
}

const loaderSize = {
  small: 14,
  medium: 20,
  large: 30,
}

function BaseButtonComponent({
  analyticsLabel,
  analyticsExtra,
  href,
  onClick,
  buttonRef,
  children,
  size = 'large',
  disabled,
  sx,
  ...props
}: BaseButtonProps) {
  const [isLoading, setIsLoading] = useState(false)
  const LinkComponent = useLinkComponent()

  const handleAsyncClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let returnValue

    if (onClick) {
      returnValue = onClick(event)
    }

    if (isPromise(returnValue)) {
      try {
        setIsLoading(true)
        await returnValue
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleClick = useMixpanelClick((event) => {
    handleAsyncClick(event)
  }, analyticsExtra)

  let component: 'div' | 'button' | typeof LinkComponent = 'div'

  if (disabled) {
    component = 'div'
  } else if (href) {
    component = LinkComponent
  } else if (!!onClick || props.type === 'submit') {
    component = 'button'
  }

  return (
    <MuiButton
      { ...props }
      ref={ buttonRef }
      component={ component }
      href={ href }
      onClick={ handleClick }
      size={ size }
      disabled={ isLoading || disabled }
      data-analytics-label={ analyticsLabel }
      sx={{
        position: 'relative',
        lineHeight: size === 'small' ? '14px' : 'inherit',
        ...sx,
      }}>

      { isLoading ? (<Box sx={{ opacity: 0 }}>{ children }</Box>) : children }

      { isLoading && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            color: ({ palette }) => (props.variant === 'text' ? palette.primary.main : palette.common.white),
          }}>
          <CircularProgress color="inherit" size={ loaderSize[size] } />
        </Stack>
      )}

    </MuiButton>
  )
}

// eslint-disable-next-line react/no-multi-comp
export const BaseButton = React.forwardRef<
HTMLButtonElement, BaseButtonProps
>((props, ref) => <BaseButtonComponent { ...props } buttonRef={ ref } />)

BaseButton.displayName = 'BaseButton'
