import { CssBaseline } from '@mui/material'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { SdkStoreProvider } from '@ng-mono/sdk'
import { BrowserRouter, HashRouter } from 'react-router-dom'

import { Link } from './components/common/navigation/link/link.component'
import { InnerErrorBoundary } from './components/error-boundary/inner/inner-error-boundary.component'
import { OuterErrorBoundary } from './components/error-boundary/outer/outer-error-boundary.component'
import { FormSubmissionProvider } from './components/forms/form/form-submission.provider'
import { WEEKLY_AVATAR_S3_URL } from './libs/ui/components/app-config/app-config.constants'
import { LinkComponentProvider } from './libs/ui/components/link/base/base-link.provider'
import { DynamicLogoProvider } from './libs/ui/components/logo/logo.provider'
import { DEMO_MODE_ENABLED } from './pages/demo/demo.constants'
import { AppRoutes } from './routing/AppRoutes'
import { ACTIVE_ROUTES } from './routing/routes/routes.config'
import { AppStoreProvider } from './store/app.provider'
import { theme } from './theme/theme'

import './theme/main.css'

const Router = DEMO_MODE_ENABLED ? HashRouter : BrowserRouter

export function App() {
  return (
    <OuterErrorBoundary>
      <Router>
        <SdkStoreProvider>
          <AppStoreProvider>
            <InnerErrorBoundary>
              <ThemeProvider theme={ theme }>
                <DynamicLogoProvider initialSrc={ WEEKLY_AVATAR_S3_URL }>
                  <FormSubmissionProvider>
                    <LinkComponentProvider linkComponent={ Link }>
                      <CssBaseline />
                      <AppRoutes routes={ ACTIVE_ROUTES } />
                    </LinkComponentProvider>
                  </FormSubmissionProvider>
                </DynamicLogoProvider>
              </ThemeProvider>
            </InnerErrorBoundary>
          </AppStoreProvider>
        </SdkStoreProvider>
      </Router>
    </OuterErrorBoundary>
  )
}
