import { Palette, PaletteShadesConfig } from './palettes.types'

export const COLOR_BLACK = '#000000'
export const COLOR_WHITE = '#FFFFFF'

export const PASTEL_COLOR_SWATCHES = [
  COLOR_WHITE,
  '#333333',
  '#F96868',
  '#FF4131',
  '#FFB478',
  '#FF8B2D',
  '#E9CA99',
  '#FFFF99',
  '#FFFF79',
  '#D9FF89',
  '#B9FFA5',
  '#58B5ED',
  '#225273',
  '#6C8CA3',
  '#7ABBFF',
  '#A793FF',
  '#423953',
  '#C375FF',
  '#FFAFFF',
  '#FF7ABC',
  '#FF8CAC',
]

const NEON_COLOR_SWATCHES = [
  COLOR_WHITE,
  '#E2FCDB',
  '#A4B6CB',
  '#95F3FB',
  '#49F0FE',
  '#E6FB04',
  '#0DC7B0',
  '#848484',
  '#33FF00',
  '#5FA70E',
  '#018C99',
  '#FF005C',
  '#F20070',
  '#FC0615',
  '#A831F4',
  '#BA2979',
  '#9015D2',
  '#0033FF',
  '#6F1CA3',
  '#0D04F2',
]

export const SHADES_CONFIG: PaletteShadesConfig = {
  shadeOffsets: [4, 6, 8],
  shadeBaseIndex: 6,
}

export const COLOR_PALETTES: Palette[] = [{
  id: 'pastelSKUID',
  label: 'Pastel',
  shadesConfig: SHADES_CONFIG,
  colors: PASTEL_COLOR_SWATCHES,
}, {
  id: 'neonSKUD',
  label: 'Neon',
  shadesConfig: SHADES_CONFIG,
  colors: NEON_COLOR_SWATCHES,
}]

export const MOCKED_DEFAULT_PALETTE_ID = COLOR_PALETTES[0].id
