import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'

import { Achievement } from './achievements.types'

export async function fetchAchievements(axiosConfig?: AxiosRequestConfig) {
  return axios.get<Achievement[]>(endpoints.fetchAchievements, { ...axiosConfig })
}

export const AchievementsService = {
  fetchAchievements,
}
