import { BaseMenuItemConfig, MenuItemType } from '../base/menu-base-item.types'
import { MenuButtonItem, MenuButtonItemProps } from '../button/menu-button-item.component'

export interface MenuLinkItemConfig extends BaseMenuItemConfig {
  type: MenuItemType.Link;
  label: string;
  href: string;
}

export type MenuLinkItemProps = MenuButtonItemProps & Omit<MenuLinkItemConfig, 'type'>

export function MenuLinkItem(menuLinkItemsProps: MenuLinkItemProps) {
  return <MenuButtonItem { ...menuLinkItemsProps } />
}
