import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'

import { TopicsService } from './topics-n-categories.service'
import { TopicsAndCategories } from './topics-n-categories.types'

export class TopicsAndCategoriesStore implements BaseStore {
  topicsAndCategoriesResource = createEmptyReadOnlyStoreResource<TopicsAndCategories>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  get topicsAndCategories() {
    return this.topicsAndCategoriesResource.data
  }

  fetchTopicsAndCategories() {
    // TODO (Dani): Replace this with fetchTopics = getFetchStoreResourceHandler(this._topicsResource, PathsService.fetchAllTopics)
    return fetchStoreResource(this.topicsAndCategoriesResource, TopicsService.fetchAllTopics)
  }

  clearTopicsAndCategories() {
    clearReadOnlyStoreResource(this.topicsAndCategoriesResource)
  }
}
