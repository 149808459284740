import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../../stores/sdk/sdk.store'
import { BaseStore } from '../../../utils/stores/common/common-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../../utils/stores/readonly/readonly-store.utils'
import { CampaignData } from '../campaigns.types'

import { NFMeOfTheWeekCampaignService } from './nfme-of-the-week-campaign.service'

export class NFMeOfTheWeekCampaignStore implements BaseStore {
  campaignDataResource = createEmptyReadOnlyStoreResource<CampaignData>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore
  }

  get campaignData() {
    return this.campaignDataResource.data || null
  }

  async fetchCampaignData() {
    return fetchStoreResource(
      this.campaignDataResource,
      NFMeOfTheWeekCampaignService.fetchNFMeOfTheWeekCampaign,
    )
  }

  clearCampaignData() {
    clearReadOnlyStoreResource(this.campaignDataResource)
  }
}
