import { getCompareByStringFn } from '@swyg/sorteo'

import { FormOption, FormOptionItem } from './form-option.types'

export function sortOptionsByLabel<V>(formOptions: FormOption<V>[]) {
  const sortFn = getCompareByStringFn<FormOption<V>>((formOption) => formOption.label || '')

  return formOptions.sort(sortFn)
}

export function isFormOptionItem<V>(formOption: FormOption<V> | FormOptionItem<V>): formOption is FormOptionItem<V> {
  return formOption.hasOwnProperty('element')
}
