/* eslint-disable max-len */
import { Box, Theme, SxProps, Stack } from '@mui/material'

import { useLinkComponent } from '../link/base/base-link.provider'

import { useDynamicLogo } from './logo.provider'

export type LogoSize = 'inherit' | 'sm' | 'md' | 'lg'
export type LogoVariant = 'logotype' | 'isotype' | 'isologotype'

export interface LogoProps {
  sx?: SxProps<Theme>;
  size?: LogoSize | number;
  logoLink?: boolean | string;
  variant?: LogoVariant;
}

export interface DynamicLogoContextData {
  imageSrc: string;
  loading: boolean;
}

const logoHeights: Record<LogoSize, number> = {
  inherit: 16,
  sm: 30,
  md: 40,
  lg: 50,
}

export function Logo({
  sx,
  size = 'md',
  logoLink,
  variant = 'isotype',
}: LogoProps) {
  const { imageSrc, loading } = useDynamicLogo()
  const LinkComponent = useLinkComponent()

  const sizeNumber = typeof size === 'string' ? logoHeights[size] : size
  const borderWeight = sizeNumber * 0.04
  const isotypeYPadding = variant === 'isologotype' ? sizeNumber - sizeNumber * 0.9 : 0
  const isotypeSize = sizeNumber - isotypeYPadding * 2

  const isotype = (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: `${isotypeSize}px`,
        width: `${isotypeSize * 2}px`,
      }}>
      <img
        src="/nfme-galaxy-logo.svg"
        alt="logo"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }} />
    </Box>
  )

  const logotype = (
    <Box
      sx={{
        position: 'relative',
        width: `${sizeNumber}px`,
        height: `${sizeNumber}px`,
        border: `${borderWeight}px solid #F96868`,
        backgroundColor: '#F9BDBD',
        overflow: 'hidden',
        borderRadius: '50%',
      }}>
      {!loading && (
        <img
          src={ imageSrc }
          alt="logo"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }} />
      )}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: '50%',
          boxShadow: sizeNumber > 40 ? 'inset 0px 2px 10px #333333' : 'inset 0px 1px 2px #333333',
        }} />
    </Box>
  )

  const variants = {
    isotype,
    logotype,
    isologotype: (
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={ `${isotypeYPadding * 2}px` }>
        { logotype }
        { isotype }
      </Stack>
    ),
  }

  const rootSx: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    ...sx,
  }

  return logoLink ? (
    <LinkComponent
      sx={ rootSx }
      href={ typeof logoLink === 'string' ? logoLink : '/' }
      target="_self">
      {variants[variant]}
    </LinkComponent>
  ) : (
    <Box sx={ rootSx }>
      { variants[variant] }
    </Box>
  )
}
