import { TraitValues, ColorKey, Trait, TraitTypeId, EditorLabel, UserAnalyticsService, removeKeys } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AvatarControllerStore } from '../avatar-controller.store'

export type RandomizerBehaviour = 'all-items' | 'current-item' | 'all-colors' | 'current-color'

export class AvatarRandomizerControllerStore {
  static ANALYTICS_RANDOMIZER_LABELS: Record<RandomizerBehaviour, EditorLabel> = {
    'all-items': 'Shuffle All Items',
    'current-item': 'Shuffle Item',
    'all-colors': 'Shuffle All Colors',
    'current-color': 'Shuffle Color',
  }

  static DEFAULT_DEFAULT_TRAIT_PROBABILITY = 0

  avatarControllerStore: AvatarControllerStore

  constructor(avatarControllerStore: AvatarControllerStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.avatarControllerStore = avatarControllerStore
  }

  // Analytics:

  static log(label: EditorLabel, eventValue?: null | string) {
    UserAnalyticsService.pushEvent({
      name: 'NFMe Editor',
      label,
      eventValue,
    })
  }

  // Randomizer:

  private getTraitsToPickFrom(traitTypeID: TraitTypeId, unlockedTraits: Trait[]): (Trait | null)[] {
    const { nfmeConfig, disabledItems } = this.avatarControllerStore

    const traitsToPickFrom: (Trait | null)[] = [...unlockedTraits].filter((trait) => !disabledItems?.includes(trait.id))

    if (!nfmeConfig?.traitTypeConfigs[traitTypeID].required) {
      traitsToPickFrom.push(null)
    }

    return traitsToPickFrom
  }

  private getTrait(
    traitsToPickFrom: (Trait | null)[],
    defaultTraitProbability: number,
    isSelective: boolean,
    traitTypeID: TraitTypeId,
  ): Trait | null {
    const { nfmeConfig } = this.avatarControllerStore

    if (!isSelective && defaultTraitProbability > Math.random()) {
      return nfmeConfig?.defaultTraitValues[traitTypeID].trait || null
    }

    const index = Math.floor(Math.random() * traitsToPickFrom.length)

    return traitsToPickFrom[index]
  }

  private getTraitColors(
    isSelective: boolean,
    traitTypeID: TraitTypeId,
    trait: Trait | null,
  ): Partial<Record<ColorKey, string>> {
    const { nfmeItems } = this.avatarControllerStore

    if (isSelective || !trait) {
      return nfmeItems?.[traitTypeID].traitColors || {}
    }

    return this.getRandomizedItemColors(trait)
  }

  private getRandomizedItems(isSelective: boolean): TraitValues {
    const { nfmeConfig, selectedCategory, nfmeItems, disabledTraitTypes } = this.avatarControllerStore
    const { traitsStore } = this.avatarControllerStore.sdkStore
    const unlockedTraitsByTraitId = traitsStore?.traits

    if (!nfmeConfig || !unlockedTraitsByTraitId) throw new Error('Missing nfmeConfig or unlockedTraitsByTraitId.')

    // TODO (Seba): We should move this from here, as it is computed on every click of the random button.
    // Ideally the source data to randomize is already filtered from disabled items and traitTypes.

    const traitTypes = disabledTraitTypes.length
      ? removeKeys(unlockedTraitsByTraitId, disabledTraitTypes)
      : unlockedTraitsByTraitId

    return (Object.entries(traitTypes) as [TraitTypeId, Trait[]][]).reduce((acc, [traitTypeID, unlockedTraits]) => {
      const isSelected = traitTypeID === selectedCategory
      const defaultTraitProbability = nfmeConfig.traitTypeConfigs[traitTypeID as TraitTypeId].defaultTraitProbability
        || AvatarRandomizerControllerStore.DEFAULT_DEFAULT_TRAIT_PROBABILITY

      const traitsToPickFrom = this.getTraitsToPickFrom(traitTypeID as TraitTypeId, unlockedTraits)

      let trait: Trait | null = null

      if ((isSelected && isSelective) || !isSelective) {
        trait = this.getTrait(traitsToPickFrom, defaultTraitProbability, isSelective, traitTypeID as TraitTypeId)
      } else {
        trait = nfmeItems?.[traitTypeID as TraitTypeId].trait || null
      }

      const traitColors = this.getTraitColors(isSelective, traitTypeID as TraitTypeId, trait)

      acc[traitTypeID as TraitTypeId] = { trait, traitColors: traitColors || {} }

      return acc
    }, {} as TraitValues)
  }

  private getRandomizedItemsColors(isSelective: boolean): TraitValues {
    const { nfmeItems, selectedCategory } = this.avatarControllerStore

    if (!nfmeItems) throw new Error('Missing nfmeConfig or nfmeItems.')

    return (Object.keys(nfmeItems) as TraitTypeId[]).reduce<TraitValues>((acc, traitTypeId) => {
      const { trait } = nfmeItems[traitTypeId]

      acc[traitTypeId] = !isSelective || traitTypeId === selectedCategory ? {
        trait: nfmeItems[traitTypeId].trait,
        traitColors: this.getRandomizedItemColors(trait as Trait),
      } : nfmeItems[traitTypeId]

      return acc
    }, {} as TraitValues)
  }

  private getRandomizedItemColors(trait: Trait) {
    const { nfmeConfig } = this.avatarControllerStore

    if (!trait || !nfmeConfig || nfmeConfig.traitTypeConfigs[trait.type].inheritColorsFrom) return {}

    const { getRandomColor } = this.avatarControllerStore.avatarPalettes

    return Object.keys(trait.colors).reduce((acc, key) => {
      acc[key as ColorKey] = getRandomColor()

      return acc
    }, {} as Record<ColorKey, string>)
  }

  randomizeNFMe(behaviour: RandomizerBehaviour, isUserAction?: boolean) {
    if (isUserAction) AvatarRandomizerControllerStore.log(AvatarRandomizerControllerStore.ANALYTICS_RANDOMIZER_LABELS[behaviour])

    const isSelective = behaviour.startsWith('current-')
    const traitValues = /-items?$/.test(behaviour)
      ? this.getRandomizedItems(isSelective)
      : this.getRandomizedItemsColors(isSelective)

    this.avatarControllerStore.avatarHistory.pushChange('random')

    return this.avatarControllerStore.updateNFMe({ traitValues })
  }
}
