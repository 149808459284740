import { Box } from '@mui/material'
import { useAuth, RoutePaths } from '@ng-mono/sdk'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'

import { BaseNavbar } from '../../../libs/ui/components/nav/base-navbar.component'
import { CommonNavbarConfig, CommonNavbarProps, NavbarAction, NavbarContent, NavbarMenuButtonComponentType } from '../../../libs/ui/components/nav/base-navbar.types'
import { ButtonSecondary } from '../../common/buttons/secondary/secondary-button.component'
import { AvatarButton } from '../../nav/button/avatar/avatar-button.component'
import { AppMenu } from '../menu/app-menu.component'

import { NAVBAR_TOOLBAR_PORTAL_ID } from './toolbar-portal/nav-toolbar-portal.component'

export type AppNavbarContent = 'none' | 'toolbar'
export type AppNavbarAction = 'none' | 'avatar' | 'logIn' | 'signUp' | 'logOut'

export interface AppNavbarConfig extends CommonNavbarConfig {
  logo?: boolean;
  content?: AppNavbarContent;
  action?: AppNavbarAction;
}

export type AppNavbarProps = CommonNavbarProps & AppNavbarConfig

// TODO (Dani): AppMenu and AvatarButton (or its child AvatarCanvas ) dynamic import!

function AppNavbarComponent({
  logo = true,
  content = 'none',
  action = 'avatar',
  ...baseNavbarProps
}: AppNavbarProps) {
  const { signOut, isAuthLoading, isAuthenticated, canUserSeeAs } = useAuth()

  let contentSlot: NavbarContent = null

  if (content === 'toolbar') {
    contentSlot = (
      <AnimatePresence mode="wait">
        <Box
          id={ NAVBAR_TOOLBAR_PORTAL_ID }
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',

            '&::before': {
              content: '""',
              position: 'absolute',
              top: 12,
              left: 8,
              right: 8,
              bottom: 12,
              borderRadius: ({ shape }) => shape.roundedBorderRadius,
              background: logo ? 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, .125))' : 'rgba(255, 255, 255, .125)',
              pointerEvents: 'none',
              opacity: 0,
              transition: 'opacity linear 150ms, transform linear 150ms',
              transform: 'scale(2)',
            },

            '&:empty::before': {
              opacity: 1,
              transform: 'scale(1)',
            },
          }} />
      </AnimatePresence>
    )
  }

  let actionSlot: NavbarAction | NavbarMenuButtonComponentType = null

  switch (action) {
    case 'avatar': {
      actionSlot = AvatarButton
      break
    }

    case 'logIn': {
      actionSlot = isAuthenticated && !isAuthLoading ? null : (
        <ButtonSecondary
          analyticsLabel="Nav Login"
          href={ RoutePaths.Login }
          size="small"
          disabled={ isAuthLoading }>
          Login
        </ButtonSecondary>
      )
      break
    }

    case 'signUp': {
      actionSlot = isAuthenticated && !isAuthLoading ? null : (
        <ButtonSecondary
          analyticsLabel="Nav Sign Up"
          href={ RoutePaths.SignUp }
          size="small"
          disabled={ isAuthLoading }>
          Sign Up
        </ButtonSecondary>
      )
      break
    }

    case 'logOut': {
      actionSlot = !isAuthenticated && !isAuthLoading ? null : (
        <ButtonSecondary
          analyticsLabel="Nav Logout"
          onClick={ signOut }
          size="small"
          disabled={ isAuthLoading }>
          Logout
        </ButtonSecondary>
      )
      break
    }

    default:
      // Do nothing...
  }

  return (
    <BaseNavbar
      { ...baseNavbarProps }
      showLogo={ logo }
      logoLink={ canUserSeeAs(['admin', 'partner']).isVisible ? '/' : undefined }
      contentSlot={ contentSlot }
      actionSlot={ actionSlot }
      menu={ AppMenu } />
  )
}

export const AppNavbar = observer(AppNavbarComponent)
