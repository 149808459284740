import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { PaginatedResourceURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'
import { Path } from '../paths/paths.types'

import { PathsAnsweredService } from './paths-answered.service'

export class PathsAnsweredStore implements BaseStore {
  pathsAnsweredResource = createEmptyReadOnlyStoreResource<Path[]>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  get pathsAnswered() {
    return this.pathsAnsweredResource.data
  }

  fetchPathsAnswered(params: PaginatedResourceURLParams) {
    return fetchStoreResource(this.pathsAnsweredResource, PathsAnsweredService.fetchAnsweredPaths, params)
  }

  clearPathsAnswered() {
    clearReadOnlyStoreResource(this.pathsAnsweredResource)
  }
}
