import { ACTIVITIES_MOCK_BLOCKCHAIN, ACTIVITIES_MOCK_BLOCKCHAIN_2, ACTIVITIES_MOCK_CLOGS } from '../activities/activities.mock'
import { LifecycleStage } from '../lifecycle/lifecycle.types'
import { REWARD_MOCKS_BY_ID } from '../rewards/rewards.mock'
import { TOPICS_MOCK_BY_ID } from '../topics-n-categories/topics-n-categories.mock'

import { DBPath } from './paths.types'

const PATH_MOCK_BLOCKCHAIN_1: DBPath[] = [{
  id: 'PATH-1',
  stage: LifecycleStage.Active,
  toc: {
    name: 'What is digital currency',
    description: 'Understand what makes tokens different than fiat (how traditional currency is called)',
  },
  summaryConfig: true,
  activities: ACTIVITIES_MOCK_BLOCKCHAIN,
  topics: [
    TOPICS_MOCK_BY_ID.blockchainTopic1,
  ],
  rewards: [
    REWARD_MOCKS_BY_ID.hairStacy,
    REWARD_MOCKS_BY_ID.hairBuns,
    REWARD_MOCKS_BY_ID.hairPonytail,
    REWARD_MOCKS_BY_ID.hairFemaleBeach,
    REWARD_MOCKS_BY_ID.hairLilo,
    REWARD_MOCKS_BY_ID.hairNormie,
    REWARD_MOCKS_BY_ID.hairProdigy,
    REWARD_MOCKS_BY_ID.hairQuiff,
    REWARD_MOCKS_BY_ID.hairRobert,
    REWARD_MOCKS_BY_ID.hairShaved,
    REWARD_MOCKS_BY_ID.hairSidepartLeft,
    REWARD_MOCKS_BY_ID.hairSidepartRight,
    REWARD_MOCKS_BY_ID.hairTerminator,
    REWARD_MOCKS_BY_ID.clothingCardigan,
    REWARD_MOCKS_BY_ID.clothingJacket,
    REWARD_MOCKS_BY_ID.clothingShirtTwoPick,
    REWARD_MOCKS_BY_ID.clothingShirtThreePick,
    REWARD_MOCKS_BY_ID.clothingShirtVNeck,
    REWARD_MOCKS_BY_ID.clothingStrapDress,
    REWARD_MOCKS_BY_ID.clothingVestVNeck,
  ],
}]

const PATH_MOCK_BLOCKCHAIN_2: DBPath[] = [{
  id: 'PATH-4',
  stage: LifecycleStage.Active,
  toc: {
    name: 'What is a CBDC',
    description: 'Understand what makes CBDC (Central Back Digital Currency) different than non-regulated cryptocurrencies',
  },
  summaryConfig: true,
  activities: ACTIVITIES_MOCK_BLOCKCHAIN_2,
  topics: [
    TOPICS_MOCK_BY_ID.blockchainTopic1,
  ],
  rewards: [
    REWARD_MOCKS_BY_ID.faceHairChinLine,
    REWARD_MOCKS_BY_ID.faceHairFiveOClockRound,
    REWARD_MOCKS_BY_ID.faceHairGoatee,
    REWARD_MOCKS_BY_ID.faceHairHalfFace,
    REWARD_MOCKS_BY_ID.faceHairSoulStubble,
    REWARD_MOCKS_BY_ID.faceHairStachJazzdot,
    REWARD_MOCKS_BY_ID.faceHairStachJazzdotSoulstubble,
    REWARD_MOCKS_BY_ID.faceHairStachJazzdotChinline,
    REWARD_MOCKS_BY_ID.faceHairDutchBeardShort,
  ],
}]

const PATH_MOCK_CLOGS_1: DBPath[] = [{
  id: 'PATH-2',
  stage: LifecycleStage.Active,
  toc: {
    name: 'Co-create your clogs with Clogs',
    description: 'Help Clogs to create the perfect clogs',
  },
  summaryConfig: true,
  activities: ACTIVITIES_MOCK_CLOGS,
  topics: [
    TOPICS_MOCK_BY_ID.clogsTopic,
  ],
  rewards: [
    REWARD_MOCKS_BY_ID.eyewearBigThickOvalCroppedTopBottomGlasses,
    REWARD_MOCKS_BY_ID.eyewearBigThickRoundGlasses,
    REWARD_MOCKS_BY_ID.eyewearMediumOvalCroppedTopGlasses,
    REWARD_MOCKS_BY_ID.eyewearMediumThinOvalGlasses,
  ],
}]

const PATH_MOCK_DRAFT: DBPath[] = [{
  id: 'PATH-3',
  stage: LifecycleStage.Active,
  toc: {
    name: '',
    description: '',
  },
  summaryConfig: true,
  activities: [],
  topics: [
    TOPICS_MOCK_BY_ID.howMNworksTopic,
  ],
}]

export const ALL_PATHS_MOCKS: DBPath[] = [
  ...PATH_MOCK_BLOCKCHAIN_1,
  ...PATH_MOCK_BLOCKCHAIN_2,
  ...PATH_MOCK_CLOGS_1,
  ...PATH_MOCK_DRAFT,
]
