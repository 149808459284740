import { GenericError } from './errors.types'

export function isGenericError(error: unknown): error is GenericError {
  return (
    typeof error === 'object'
        && error !== null
        && 'name' in error
        && 'message' in error
        && typeof (error as Record<string, unknown>).message === 'string'
  )
}

export function toGenericError(maybeError: unknown): GenericError {
  if (isGenericError(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    return new Error(String(maybeError))
  }
}
