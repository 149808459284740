import { Box, SxProps, Theme } from '@mui/material'
import { canUserSeeAs } from '@ng-mono/sdk'

import { BaseButton } from '../../../buttons/base/base-button.component'
import { BaseMenuItemConfig, BaseMenuItemProps } from '../base/menu-base-item.types'

export const NAVIGATION_BUTTON_SX: SxProps<Theme> = {
  justifyContent: 'flex-start',
  minHeight: '48px',
  textTransform: 'uppercase',
}

export const TOOLBAR_BUTTON_SX: SxProps<Theme> = {
  justifyContent: 'flex-start',
  minHeight: '48px',
  textTransform: 'uppercase',
  borderRadius: 0,
  px: 2,
  py: 0,
}

export interface MenuButtonItemProps extends BaseMenuItemConfig, BaseMenuItemProps {
  label: string;
  action?: string;
  href?: string;
}

export function MenuButtonItem({
  // BaseMenuItemProps:
  root = 'div',
  sx: sxProp,
  variant,
  userRole = null,
  realUserRole = null,
  onAction,

  // BaseMenuItemConfig:
  title,
  availableTo,
  hidden,

  // MenuButtonItemProps:
  label,
  action,
  href,
}: MenuButtonItemProps) {
  const { isHidden, visibilitySx } = canUserSeeAs(userRole, realUserRole, availableTo, hidden)

  if (isHidden) return null

  const rootSx = visibilitySx ? { ...sxProp, ...visibilitySx as any } : sxProp
  const buttonSx: SxProps<Theme> = variant === 'navigation' ? NAVIGATION_BUTTON_SX : TOOLBAR_BUTTON_SX

  return (
    <Box component={ root } sx={ rootSx }>
      <BaseButton
        analyticsLabel=""
        variant="text"
        title={ title }
        color={ variant === 'navigation' ? 'primary' : 'inherit' }
        fullWidth
        size={ variant === 'navigation' ? 'large' : 'small' }
        href={ href }
        data-action={ action }
        onClick={ onAction }
        sx={ buttonSx }>
        { label }
      </BaseButton>
    </Box>
  )
}
