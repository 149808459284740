import { Box, CircularProgress, IconButton } from '@mui/material'
import { useAuth } from '@ng-mono/sdk'
import { observer } from 'mobx-react-lite'
import { MdAccountCircle } from 'react-icons/md'

import { NavbarMenuButtonComponentProps } from '../../../../libs/ui/components/nav/base-navbar.types'
import { withHasActiveClassName, withHasHoverClassName } from '../../../../libs/ui/hooks/hover/hover.hook'
import { DEMO_MODE_ENABLED } from '../../../../pages/demo/demo.constants'
import { TOOLBAR_BUTTON_DEFAULT_ICON_SIZE } from '../../toolbar/buttons/base/base-toolbar-button.component'
import { HamburgerButton } from '../hamburger/hamburger-button.component'

function AvatarButtonComponent(buttonProps: NavbarMenuButtonComponentProps) {
  const {
    id,
    sx,
    circle,
    height,
    isOpen,
    onToggle,
  } = buttonProps

  const { isAuthLoading, isAuthenticated, userError, canUserSeeAs } = useAuth()

  if (!DEMO_MODE_ENABLED && !isAuthenticated) return null

  let buttonContent = null

  const { isHidden, visibilitySx } = canUserSeeAs('admin')

  if (!DEMO_MODE_ENABLED && isAuthLoading) {
    buttonContent = <CircularProgress size={ TOOLBAR_BUTTON_DEFAULT_ICON_SIZE } sx={{ color: ({ palette }) => palette.common.white }} />
  } else if (!DEMO_MODE_ENABLED && userError) {
    buttonContent = <MdAccountCircle size="100%" color="white" />
  } else if (isHidden) {
    return <HamburgerButton { ...buttonProps } />
  } else {
    buttonContent = (
      <Box
        component="img"
        src={ DEMO_MODE_ENABLED ? '/nfmes/default-nfme-salmon-background.svg' : '/default-avatar.svg' }
        sx={{
          borderRadius: circle ? '100%' : '0',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          background: '#F96868',
          ...visibilitySx,
        }} />
    )
  }

  return (
    <IconButton
      id={ id }
      onClick={ onToggle }
      aria-label={ isOpen ? 'Open menu' : 'Close menu' }
      sx={{
        p: 0,
        height,
        aspectRatio: '1',
        overflow: circle ? 'visible' : 'hidden',

        '& > *': {
          transition: 'border-radius linear 250ms, transform linear 150ms',
          pointerEvents: 'none',
          transform: circle ? 'scale(1)' : 'scale(1.05)',
        },

        [withHasHoverClassName('&:not(:disabled):hover > *')]: {
          transform: circle ? 'scale(1.05)' : 'scale(1.1)',
        },

        [withHasActiveClassName('&:not(:disabled):active > *')]: {
          transform: circle ? 'scale(0.95)' : 'scale(1)',
        },

        ...sx,
      }}>
      { buttonContent }
    </IconButton>
  )
}

export const AvatarButton = observer(AvatarButtonComponent)
