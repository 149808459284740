import { UNKNOWN_PROPERTY } from '../user-analytics.constants'

export function getEventLabelWithCampaign(
  analyticsLabel: string,
  campaignName = '',
) {
  return campaignName.trim()
    ? `${analyticsLabel || UNKNOWN_PROPERTY} - Campaign ${campaignName}`.trim()
    : analyticsLabel
}
