import { Country } from './countries.types'

export const COUNTRIES: Country[] = [
  { isoName: 'Afghanistan', isoCode: 'AF' },
  { isoName: 'Åland Islands', isoCode: 'AX' },
  { isoName: 'Albania', isoCode: 'AL' },
  { isoName: 'Algeria', isoCode: 'DZ' },
  { isoName: 'American Samoa', isoCode: 'AS' },
  { isoName: 'Andorra', isoCode: 'AD' },
  { isoName: 'Angola', isoCode: 'AO' },
  { isoName: 'Anguilla', isoCode: 'AI' },
  { isoName: 'Antarctica', isoCode: 'AQ' },
  { isoName: 'Antigua and Barbuda', isoCode: 'AG' },
  { isoName: 'Argentina', isoCode: 'AR' },
  { isoName: 'Armenia', isoCode: 'AM' },
  { isoName: 'Aruba', isoCode: 'AW' },
  { isoName: 'Australia', isoCode: 'AU' },
  { isoName: 'Austria', isoCode: 'AT' },
  { isoName: 'Azerbaijan', isoCode: 'AZ' },
  { isoName: 'Bahamas', isoCode: 'BS' },
  { isoName: 'Bahrain', isoCode: 'BH' },
  { isoName: 'Bangladesh', isoCode: 'BD' },
  { isoName: 'Barbados', isoCode: 'BB' },
  { isoName: 'Belarus', isoCode: 'BY' },
  { isoName: 'Belgium', isoCode: 'BE' },
  { isoName: 'Belize', isoCode: 'BZ' },
  { isoName: 'Benin', isoCode: 'BJ' },
  { isoName: 'Bermuda', isoCode: 'BM' },
  { isoName: 'Bhutan', isoCode: 'BT' },
  { isoName: 'Bolivia', isoCode: 'BO' },
  { isoName: 'Bosnia and Herzegovina', isoCode: 'BA' },
  { isoName: 'Botswana', isoCode: 'BW' },
  { isoName: 'Bouvet Island', isoCode: 'BV' },
  { isoName: 'Brazil', isoCode: 'BR' },
  { isoName: 'British Indian Ocean Territory', isoCode: 'IO' },
  { isoName: 'Brunei Darussalam', isoCode: 'BN' },
  { isoName: 'Bulgaria', isoCode: 'BG' },
  { isoName: 'Burkina Faso', isoCode: 'BF' },
  { isoName: 'Burundi', isoCode: 'BI' },
  { isoName: 'Cambodia', isoCode: 'KH' },
  { isoName: 'Cameroon', isoCode: 'CM' },
  { isoName: 'Canada', isoCode: 'CA' },
  { isoName: 'Cape Verde', isoCode: 'CV' },
  { isoName: 'Cayman Islands', isoCode: 'KY' },
  { isoName: 'Central African Republic', isoCode: 'CF' },
  { isoName: 'Chad', isoCode: 'TD' },
  { isoName: 'Chile', isoCode: 'CL' },
  { isoName: 'China', isoCode: 'CN' },
  { isoName: 'Christmas Island', isoCode: 'CX' },
  { isoName: 'Cocos (Keeling) Islands', isoCode: 'CC' },
  { isoName: 'Colombia', isoCode: 'CO' },
  { isoName: 'Comoros', isoCode: 'KM' },
  { isoName: 'Congo', isoCode: 'CG' },
  { isoName: 'Congo, The Democratic Republic of the', isoCode: 'CD' },
  { isoName: 'Cook Islands', isoCode: 'CK' },
  { isoName: 'Costa Rica', isoCode: 'CR' },
  { isoName: 'Cote D"Ivoire', isoCode: 'CI' },
  { isoName: 'Croatia', isoCode: 'HR' },
  { isoName: 'Cuba', isoCode: 'CU' },
  { isoName: 'Cyprus', isoCode: 'CY' },
  { isoName: 'Czech Republic', isoCode: 'CZ' },
  { isoName: 'Denmark', isoCode: 'DK' },
  { isoName: 'Djibouti', isoCode: 'DJ' },
  { isoName: 'Dominica', isoCode: 'DM' },
  { isoName: 'Dominican Republic', isoCode: 'DO' },
  { isoName: 'Ecuador', isoCode: 'EC' },
  { isoName: 'Egypt', isoCode: 'EG' },
  { isoName: 'El Salvador', isoCode: 'SV' },
  { isoName: 'Equatorial Guinea', isoCode: 'GQ' },
  { isoName: 'Eritrea', isoCode: 'ER' },
  { isoName: 'Estonia', isoCode: 'EE' },
  { isoName: 'Ethiopia', isoCode: 'ET' },
  { isoName: 'Falkland Islands (Malvinas)', isoCode: 'FK' },
  { isoName: 'Faroe Islands', isoCode: 'FO' },
  { isoName: 'Fiji', isoCode: 'FJ' },
  { isoName: 'Finland', isoCode: 'FI' },
  { isoName: 'France', isoCode: 'FR' },
  { isoName: 'French Guiana', isoCode: 'GF' },
  { isoName: 'French Polynesia', isoCode: 'PF' },
  { isoName: 'French Southern Territories', isoCode: 'TF' },
  { isoName: 'Gabon', isoCode: 'GA' },
  { isoName: 'Gambia', isoCode: 'GM' },
  { isoName: 'Georgia', isoCode: 'GE' },
  { isoName: 'Germany', isoCode: 'DE' },
  { isoName: 'Ghana', isoCode: 'GH' },
  { isoName: 'Gibraltar', isoCode: 'GI' },
  { isoName: 'Greece', isoCode: 'GR' },
  { isoName: 'Greenland', isoCode: 'GL' },
  { isoName: 'Grenada', isoCode: 'GD' },
  { isoName: 'Guadeloupe', isoCode: 'GP' },
  { isoName: 'Guam', isoCode: 'GU' },
  { isoName: 'Guatemala', isoCode: 'GT' },
  { isoName: 'Guernsey', isoCode: 'GG' },
  { isoName: 'Guinea', isoCode: 'GN' },
  { isoName: 'Guinea-Bissau', isoCode: 'GW' },
  { isoName: 'Guyana', isoCode: 'GY' },
  { isoName: 'Haiti', isoCode: 'HT' },
  { isoName: 'Heard Island and Mcdonald Islands', isoCode: 'HM' },
  { isoName: 'Holy See (Vatican City State)', isoCode: 'VA' },
  { isoName: 'Honduras', isoCode: 'HN' },
  { isoName: 'Hong Kong', isoCode: 'HK' },
  { isoName: 'Hungary', isoCode: 'HU' },
  { isoName: 'Iceland', isoCode: 'IS' },
  { isoName: 'India', isoCode: 'IN' },
  { isoName: 'Indonesia', isoCode: 'ID' },
  { isoName: 'Iran, Islamic Republic Of', isoCode: 'IR' },
  { isoName: 'Iraq', isoCode: 'IQ' },
  { isoName: 'Ireland', isoCode: 'IE' },
  { isoName: 'Isle of Man', isoCode: 'IM' },
  { isoName: 'Israel', isoCode: 'IL' },
  { isoName: 'Italy', isoCode: 'IT' },
  { isoName: 'Jamaica', isoCode: 'JM' },
  { isoName: 'Japan', isoCode: 'JP' },
  { isoName: 'Jersey', isoCode: 'JE' },
  { isoName: 'Jordan', isoCode: 'JO' },
  { isoName: 'Kazakhstan', isoCode: 'KZ' },
  { isoName: 'Kenya', isoCode: 'KE' },
  { isoName: 'Kiribati', isoCode: 'KI' },
  { isoName: 'Korea, Democratic People\'s Republic of', isoCode: 'KP' },
  { isoName: 'Korea, Republic of', isoCode: 'KR' },
  { isoName: 'Kuwait', isoCode: 'KW' },
  { isoName: 'Kyrgyzstan', isoCode: 'KG' },
  { isoName: 'Lao People\'s Democratic Republic', isoCode: 'LA' },
  { isoName: 'Latvia', isoCode: 'LV' },
  { isoName: 'Lebanon', isoCode: 'LB' },
  { isoName: 'Lesotho', isoCode: 'LS' },
  { isoName: 'Liberia', isoCode: 'LR' },
  { isoName: 'Libyan Arab Jamahiriya', isoCode: 'LY' },
  { isoName: 'Liechtenstein', isoCode: 'LI' },
  { isoName: 'Lithuania', isoCode: 'LT' },
  { isoName: 'Luxembourg', isoCode: 'LU' },
  { isoName: 'Macao', isoCode: 'MO' },
  { isoName: 'Macedonia, The Former Yugoslav Republic of', isoCode: 'MK' },
  { isoName: 'Madagascar', isoCode: 'MG' },
  { isoName: 'Malawi', isoCode: 'MW' },
  { isoName: 'Malaysia', isoCode: 'MY' },
  { isoName: 'Maldives', isoCode: 'MV' },
  { isoName: 'Mali', isoCode: 'ML' },
  { isoName: 'Malta', isoCode: 'MT' },
  { isoName: 'Marshall Islands', isoCode: 'MH' },
  { isoName: 'Martinique', isoCode: 'MQ' },
  { isoName: 'Mauritania', isoCode: 'MR' },
  { isoName: 'Mauritius', isoCode: 'MU' },
  { isoName: 'Mayotte', isoCode: 'YT' },
  { isoName: 'Mexico', isoCode: 'MX' },
  { isoName: 'Micronesia, Federated States of', isoCode: 'FM' },
  { isoName: 'Moldova, Republic of', isoCode: 'MD' },
  { isoName: 'Monaco', isoCode: 'MC' },
  { isoName: 'Mongolia', isoCode: 'MN' },
  { isoName: 'Montserrat', isoCode: 'MS' },
  { isoName: 'Morocco', isoCode: 'MA' },
  { isoName: 'Mozambique', isoCode: 'MZ' },
  { isoName: 'Myanmar', isoCode: 'MM' },
  { isoName: 'Namibia', isoCode: 'NA' },
  { isoName: 'Nauru', isoCode: 'NR' },
  { isoName: 'Nepal', isoCode: 'NP' },
  { isoName: 'Netherlands', isoCode: 'NL' },
  { isoName: 'Netherlands Antilles', isoCode: 'AN' },
  { isoName: 'New Caledonia', isoCode: 'NC' },
  { isoName: 'New Zealand', isoCode: 'NZ' },
  { isoName: 'Nicaragua', isoCode: 'NI' },
  { isoName: 'Niger', isoCode: 'NE' },
  { isoName: 'Nigeria', isoCode: 'NG' },
  { isoName: 'Niue', isoCode: 'NU' },
  { isoName: 'Norfolk Island', isoCode: 'NF' },
  { isoName: 'Northern Mariana Islands', isoCode: 'MP' },
  { isoName: 'Norway', isoCode: 'NO' },
  { isoName: 'Oman', isoCode: 'OM' },
  { isoName: 'Pakistan', isoCode: 'PK' },
  { isoName: 'Palau', isoCode: 'PW' },
  { isoName: 'Palestinian Territory, Occupied', isoCode: 'PS' },
  { isoName: 'Panama', isoCode: 'PA' },
  { isoName: 'Papua New Guinea', isoCode: 'PG' },
  { isoName: 'Paraguay', isoCode: 'PY' },
  { isoName: 'Peru', isoCode: 'PE' },
  { isoName: 'Philippines', isoCode: 'PH' },
  { isoName: 'Pitcairn', isoCode: 'PN' },
  { isoName: 'Poland', isoCode: 'PL' },
  { isoName: 'Portugal', isoCode: 'PT' },
  { isoName: 'Puerto Rico', isoCode: 'PR' },
  { isoName: 'Qatar', isoCode: 'QA' },
  { isoName: 'Reunion', isoCode: 'RE' },
  { isoName: 'Romania', isoCode: 'RO' },
  { isoName: 'Russian Federation', isoCode: 'RU' },
  { isoName: 'RWANDA', isoCode: 'RW' },
  { isoName: 'Saint Helena', isoCode: 'SH' },
  { isoName: 'Saint Kitts and Nevis', isoCode: 'KN' },
  { isoName: 'Saint Lucia', isoCode: 'LC' },
  { isoName: 'Saint Pierre and Miquelon', isoCode: 'PM' },
  { isoName: 'Saint Vincent and the Grenadines', isoCode: 'VC' },
  { isoName: 'Samoa', isoCode: 'WS' },
  { isoName: 'San Marino', isoCode: 'SM' },
  { isoName: 'Sao Tome and Principe', isoCode: 'ST' },
  { isoName: 'Saudi Arabia', isoCode: 'SA' },
  { isoName: 'Senegal', isoCode: 'SN' },
  { isoName: 'Serbia and Montenegro', isoCode: 'CS' },
  { isoName: 'Seychelles', isoCode: 'SC' },
  { isoName: 'Sierra Leone', isoCode: 'SL' },
  { isoName: 'Singapore', isoCode: 'SG' },
  { isoName: 'Slovakia', isoCode: 'SK' },
  { isoName: 'Slovenia', isoCode: 'SI' },
  { isoName: 'Solomon Islands', isoCode: 'SB' },
  { isoName: 'Somalia', isoCode: 'SO' },
  { isoName: 'South Africa', isoCode: 'ZA' },
  { isoName: 'South Georgia and the South Sandwich Islands', isoCode: 'GS' },
  { isoName: 'Spain', isoCode: 'ES' },
  { isoName: 'Sri Lanka', isoCode: 'LK' },
  { isoName: 'Sudan', isoCode: 'SD' },
  { isoName: 'SuriisoName', isoCode: 'SR' },
  { isoName: 'Svalbard and Jan Mayen', isoCode: 'SJ' },
  { isoName: 'Swaziland', isoCode: 'SZ' },
  { isoName: 'Sweden', isoCode: 'SE' },
  { isoName: 'Switzerland', isoCode: 'CH' },
  { isoName: 'Syrian Arab Republic', isoCode: 'SY' },
  { isoName: 'Taiwan', isoCode: 'TW' },
  { isoName: 'Tajikistan', isoCode: 'TJ' },
  { isoName: 'Tanzania, United Republic of', isoCode: 'TZ' },
  { isoName: 'Thailand', isoCode: 'TH' },
  { isoName: 'Timor-Leste', isoCode: 'TL' },
  { isoName: 'Togo', isoCode: 'TG' },
  { isoName: 'Tokelau', isoCode: 'TK' },
  { isoName: 'Tonga', isoCode: 'TO' },
  { isoName: 'Trinidad and Tobago', isoCode: 'TT' },
  { isoName: 'Tunisia', isoCode: 'TN' },
  { isoName: 'Turkey', isoCode: 'TR' },
  { isoName: 'Turkmenistan', isoCode: 'TM' },
  { isoName: 'Turks and Caicos Islands', isoCode: 'TC' },
  { isoName: 'Tuvalu', isoCode: 'TV' },
  { isoName: 'Uganda', isoCode: 'UG' },
  { isoName: 'Ukraine', isoCode: 'UA' },
  { isoName: 'United Arab Emirates', isoCode: 'AE' },
  { isoName: 'United Kingdom', isoCode: 'GB' },
  { isoName: 'United States', isoCode: 'US' },
  { isoName: 'United States Minor Outlying Islands', isoCode: 'UM' },
  { isoName: 'Uruguay', isoCode: 'UY' },
  { isoName: 'Uzbekistan', isoCode: 'UZ' },
  { isoName: 'Vanuatu', isoCode: 'VU' },
  { isoName: 'Venezuela', isoCode: 'VE' },
  { isoName: 'Viet Nam', isoCode: 'VN' },
  { isoName: 'Virgin Islands, British', isoCode: 'VG' },
  { isoName: 'Virgin Islands, U.S.', isoCode: 'VI' },
  { isoName: 'Wallis and Futuna', isoCode: 'WF' },
  { isoName: 'Western Sahara', isoCode: 'EH' },
  { isoName: 'Yemen', isoCode: 'YE' },
  { isoName: 'Zambia', isoCode: 'ZM' },
  { isoName: 'Zimbabwe', isoCode: 'ZW' },
]
