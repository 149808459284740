export const CATEGORY_MOCK_BLOCKCHAIN = {
  id: 'C1',
  name: 'Blockchain',
}

export const CATEGORY_MOCK_BRANDS = {
  id: 'C2',
  name: 'Brands',
}

export const CATEGORY_MOCK_TECHNOLOGY = {
  id: 'C3',
  name: 'Technology',
}
