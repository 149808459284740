import React, { useCallback } from 'react'

import { NOT_AVAILABLE } from '../../../user-analytics.constants'
import { UserAnalyticsService } from '../../../user-analytics.service'
import { ButtonClickEvent, EventExtra } from '../../user-analytics-mixpanel.types'

export function useMixpanelClick(
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  extra?: EventExtra,
) {
  return useCallback((
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (onClick) onClick(e)

    const { currentTarget } = e
    const { analyticsLabel } = currentTarget.dataset

    // Do not log clicks in links (or buttons that actually have a link), as routing events are already logged:
    if (!analyticsLabel) return

    const analyticsPayload: ButtonClickEvent = {
      name: 'Click',
      label: analyticsLabel,
      buttonLabel: currentTarget.textContent || NOT_AVAILABLE,
      buttonTitle: currentTarget.title || NOT_AVAILABLE,
      buttonDisabled: currentTarget.disabled || false,
      extra,
    }

    UserAnalyticsService.pushEvent(analyticsPayload)
  }, [onClick, extra])
}
