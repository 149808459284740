import { configure } from 'mobx'
import { createContext, useContext } from 'react'

import { WithChildrenProp } from '../../utils/react/react.types'

import { SdkRootStore } from './sdk.store'

configure({
  enforceActions: 'never',
})

// We make the store available outside of react
export const SDK_STORE: SdkRootStore = new SdkRootStore()

const StoreContext = createContext<SdkRootStore>(SDK_STORE)

export function SdkStoreProvider({
  children,
}: WithChildrenProp) {
  return (
    <StoreContext.Provider value={ SDK_STORE }>
      {children}
    </StoreContext.Provider>
  )
}

export function useSdkStore() {
  const context = useContext(StoreContext)

  if (context === undefined) {
    throw new Error('A SdkRootStore instance must be provided to SdkStoreProvider.')
  }

  return context
}
