import { SentryError } from '../sentry/sentry-error.class'

export class AnalyticsError extends SentryError {
  static NAME = 'AnalyticsError'

  constructor(
    message: string,
    cause: null | unknown = null,
  ) {
    super(AnalyticsError.NAME, message, cause)
  }
}
