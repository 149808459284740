export function arePathnamesEqual(
  pathnameA: string,
  pathnameB: string,
) {
  // TODO (Dani): We should instead forge the trailing slash to be there with redirects:

  const normalizedPathnameA = pathnameA.trim().replace(/\/$/, '')
  const normalizedPathnameB = pathnameB.trim().replace(/\/$/, '')

  return normalizedPathnameA === normalizedPathnameB
}
