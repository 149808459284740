import { randomInRange } from '../../utils/common/math/math.utils'
import { to } from '../../utils/typescript/typescript.utils'
import { TRAIT_MOCKS_BY_ID } from '../traits/traits.mocks'

import { NFTTraitReward, Reward, RewardTypes } from './rewards.types'

export function getRandomNFTTraitReward() {
  const traitIDs = Object.keys(TRAIT_MOCKS_BY_ID).slice(1)
  const randomTraitID = traitIDs[randomInRange(traitIDs.length - 1)]
  const trait = TRAIT_MOCKS_BY_ID[randomTraitID]

  return to<NFTTraitReward>({
    id: `${trait.id}-${Math.random()}` as any,
    type: RewardTypes.NFTTrait,
    trait,
  })
}

export function getRewardKey(reward: Reward) {
  return reward.id
}
