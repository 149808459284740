import { SentryError } from '../sentry/sentry-error.class'

export class AuthError extends SentryError {
  static NAME = 'AuthError'

  constructor(
    message: string,
    cause: null | unknown = null,
  ) {
    super(AuthError.NAME, message, cause)
  }
}
