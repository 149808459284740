import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'
import { Category } from '../categories/categories.types'

import { Topic, TopicsAndCategories } from './topics-n-categories.types'

function transformFetchAllTopics(response: AxiosResponse<Topic[]>): AxiosResponse<TopicsAndCategories> {
  const topics = response.data || []
  const transformedResponse: AxiosResponse<TopicsAndCategories> = {
    ...response,
    data: {
      topics,
      categories: [],
    },
  }

  if (topics) {
    const categoriesMap: Record<string, Category> = {}

    topics.flatMap((topic) => topic.categories).forEach((category) => {
      if (!categoriesMap[category.id]) categoriesMap[category.id] = category
    }, [])

    transformedResponse.data = {
      topics,
      categories: Object.values(categoriesMap),
    }
  }

  return transformedResponse
}

export async function fetchAllTopics(axiosConfig?: AxiosRequestConfig) {
  return axios.get<Topic[]>(endpoints.fetchAllTopics, { ...axiosConfig }).then(transformFetchAllTopics)
}

export const TopicsService = {
  fetchAllTopics,
}
