import { DocNodeType, TextDocNode } from './doc.types'

const LOREM_IPSUM_PARAGRAPHS = `
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sollicitudin tincidunt diam eu consequat. Duis sodales metus at turpis elementum, a convallis mi mattis. Suspendisse turpis mi, aliquet eget pulvinar vel, vulputate vel felis. Fusce facilisis odio a congue eleifend. Curabitur pellentesque aliquam arcu sed convallis. Nullam leo risus, venenatis at vestibulum sit amet, dignissim ut tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.

  Proin rhoncus tempor finibus. Praesent fermentum nisi non eros tempor, sit amet vehicula dolor convallis. Nunc tortor odio, consectetur id ultricies et, commodo quis quam. Aenean hendrerit dictum tortor ac pulvinar. Pellentesque a nisi nulla. Ut at lectus lacus. Praesent fermentum, libero non vulputate blandit, mauris velit scelerisque felis, sed faucibus sem nisl ac leo. Vestibulum in tortor et quam porttitor feugiat.

  Nunc porta molestie orci vel mollis. Aenean a semper justo. Vivamus auctor lacus sit amet ultricies porttitor. Praesent et dolor efficitur augue lobortis gravida non vitae ipsum. Nam molestie sem non urna sollicitudin euismod. Curabitur in tortor at elit tempus euismod in sed sem. Vivamus molestie odio sed quam semper pellentesque. Aliquam a commodo sapien. Duis a dictum lectus. Phasellus pulvinar rhoncus ligula ut pellentesque.

  Nam sit amet turpis in urna venenatis tincidunt. Donec pharetra rutrum lectus at semper. In egestas elit ac congue cursus. Quisque in euismod ligula. In consequat massa et orci fringilla dignissim. Ut odio massa, dapibus vitae enim placerat, luctus blandit ex. Etiam sed fringilla tortor, sit amet euismod enim. Aliquam porta blandit nibh, vitae venenatis leo fringilla nec. Curabitur dapibus rutrum massa nec commodo. In id justo non arcu convallis malesuada. Donec id molestie nunc.

  Etiam scelerisque semper velit, quis semper neque posuere in. Etiam sed pretium nisl, eu tempus turpis. Curabitur tellus augue, euismod sed posuere nec, lobortis eu urna. Fusce tempus magna nisl, a maximus risus sollicitudin vel. Donec eget sem purus. Donec et lacus urna. Sed sit amet venenatis sapien, quis iaculis ante. Sed auctor porttitor ultricies. Aenean vitae eleifend nisi, quis mollis nulla. Vivamus sed metus nibh. Vivamus hendrerit nisi at finibus ornare. Nullam id dolor vel lacus efficitur laoreet. Pellentesque malesuada suscipit urna, a tincidunt augue euismod quis.
`.split('\n').map((p) => p.trim()).filter(Boolean)

const LOREM_IPSUM_HALF_PARAGRAPHS = LOREM_IPSUM_PARAGRAPHS.flatMap((p) => {
  const half = p.length / 2 | 0

  return [`${p.slice(0, half)}...`, `...${p.slice(half)}`]
})

const LOREM_IPSUM_SENTENCES = LOREM_IPSUM_PARAGRAPHS.flatMap((p) => p.split('.').map((s) => s.trim()).filter(Boolean))

const LOREM_IPSUM_ENTITIES: Record<LoremIpsumEntity, string[]> = {
  paragraph: LOREM_IPSUM_HALF_PARAGRAPHS,
  halfParagraph: LOREM_IPSUM_HALF_PARAGRAPHS,
  sentence: LOREM_IPSUM_SENTENCES,
}

export type LoremIpsumEntity = 'paragraph' | 'halfParagraph' | 'sentence'

export function getLoremIpsumTextNodes(units: number, entity: LoremIpsumEntity): TextDocNode[] {
  return LOREM_IPSUM_ENTITIES[entity].slice(0, units).map((text) => ({ type: DocNodeType.Text, text }))
}
