/* eslint-disable max-len */

import { ColorShadeIndex } from '../../traits/traits.types'

// Shades:

export const SHADE_ITEM_COUNT = 13
export const SHADE_LAST_INDEX = SHADE_ITEM_COUNT - 1
export const SHADE_BASE_INDEX = (SHADE_LAST_INDEX / 2) as ColorShadeIndex

// Slots:

export const SLOTS_IDS = [
  'back',
  'middle',
  'front',
  'top',
] as const

export type SlotIdsType = typeof SLOTS_IDS[number]

// HTML / Rendering:

// TODO (Dani): Do not export this. Instead, export utils that use it:

export const TRAIT_SEPARATOR_SLOT_ID = '--'

// Traits & Colors:

export const NONE_TRAIT_ID = 'none'

export const DEFAULT_TRAIT_COLOR = '#FFFFFF'

export const PREVIEW_SILHOUETTE_COLOR = '#2A2E38'
