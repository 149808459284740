import { TextField as MUITextField, OutlinedTextFieldProps } from '@mui/material'

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, 'variant' | 'error'> {
  readOnly?: boolean;
  touched?: boolean;
  error?: boolean | string;
}

export function TextField({
  readOnly,
  touched,
  error,
  helperText,
  ...textFieldProps
}: TextFieldProps) {
  const helperTextMessage = (touched && typeof error === 'string' && error) || helperText

  return (
    <MUITextField
      type="text"
      variant="outlined"
      fullWidth
      { ...textFieldProps }
      error={ !!(touched && error) }
      helperText={ helperTextMessage }
      InputProps={{ readOnly, ...textFieldProps.InputProps }} />
  )
}
