import { AxiosRequestConfig, AxiosResponse } from 'axios'

import type { SdkRootStore } from '../../../stores/sdk/sdk.store'

export type ServiceFnWithoutParams<D> = (axiosConfig?: AxiosRequestConfig) => Promise<AxiosResponse<D>>
export type ServiceFnWithParams<D, P> = (params: P, axiosConfig?: AxiosRequestConfig) => Promise<AxiosResponse<D>>

export function isServiceFnWithParams<D, P>(fn: ServiceFnWithoutParams<D> | ServiceFnWithParams<D, P>): fn is ServiceFnWithParams<D, P> {
  return fn.length === 2
}

export interface BaseStore {
  rootStore: SdkRootStore,
}

/*
// Not possible like this due to https://github.com/mobxjs/mobx/discussions/2850

export abstract class BaseStore {
  protected _stores: Partial<Stores> = {}

  protected init(stores: Stores): void {
    this._stores = stores
  }
}
*/
