import { v4 as uuidv4 } from 'uuid'

import { Attempt } from '../domains/answers/answers.types'
import { BackendAvatarOutfit } from '../domains/avatar/avatar.types'
import { NFME_DEFAULT_MENU_ITEM_LABEL } from '../domains/brand/brand.constants'
import { MOCKED_CREATOR_RENDERER_CONFIG, MOCKED_DEFAULT_TRAIT_VALUES } from '../domains/creator/renderer-config/creator-renderer-config.mocks'
import { devLog, DevLogGroup } from '../utils/logs/logs.utils'

const ATTEMPTS_BY_PATH_ID_LS_KEY = 'ATTEMPTS_BY_PATH_ID_LS_KEY'
const OUTFITS_LS_KEY = 'OUTFITS_LS_KEY'

export interface MocksState {
  attemptsByPathID: Record<string, Attempt[]>;
  outfits: Record<string, BackendAvatarOutfit>;
}

const defaultAvatarUUID = uuidv4()

let mocksState: MocksState = {
  attemptsByPathID: {},
  outfits: {
    [defaultAvatarUUID]: {
      layeringVersion: MOCKED_CREATOR_RENDERER_CONFIG.layeringVersion,
      id: defaultAvatarUUID,
      label: NFME_DEFAULT_MENU_ITEM_LABEL,
      traitValues: MOCKED_DEFAULT_TRAIT_VALUES,
      paletteID: MOCKED_CREATOR_RENDERER_CONFIG.defaultPaletteID,
    },
  },
}

// Read / Write whole state:

function setMocksState(nextMocksState: MocksState) {
  const {
    attemptsByPathID,
    outfits,
  } = nextMocksState

  // Required:

  if (!attemptsByPathID) {
    throw new Error('Missing attemptsByPathID mock data.')
  }

  if (!outfits) {
    throw new Error('Missing avatar outfits data.')
  }

  // Type:

  if (typeof attemptsByPathID !== 'object') {
    throw new Error(`Invalid attemptsByPathID mock data type (received: ${typeof attemptsByPathID}, expected: Object).`)
  }

  if (typeof outfits !== 'object') {
    throw new Error(`Invalid avatar mock data type (received: ${typeof outfits}, expected: Object).`)
  }

  // Shape:

  if (Object.keys(attemptsByPathID).some((key) => typeof key !== 'string')) {
    throw new Error('Invalid attemptsByPathID mock data shape (unexpected non-string key type).')
  }

  if (Object.values(attemptsByPathID).some((arr) => !Array.isArray(arr))) {
    throw new Error('Invalid attemptsByPathID mock data shape (unexpected non-Array value type).')
  }

  if (Object.values(attemptsByPathID).some((arr) => arr.some((attempt) => !attempt.hasOwnProperty('answers') || !attempt.hasOwnProperty('result')))) {
    throw new Error('Invalid attemptsByPathID mock data shape (unexpected non-Attempt value).')
  }

  mocksState = {
    attemptsByPathID,
    outfits,
  }

  devLog(DevLogGroup.Mocks, 'Mocks restored!')
}

export function importMocks(nextMocksState: MocksState) {
  setMocksState(nextMocksState)
}

export function restoreMocks() {
  devLog(DevLogGroup.Mocks, 'Restoring mocks...')

  try {
    const attemptsByPathID: Record<string, Attempt[]> = JSON.parse(localStorage.getItem(ATTEMPTS_BY_PATH_ID_LS_KEY) || 'null')
    const outfits: Record<string, BackendAvatarOutfit> = JSON.parse(localStorage.getItem(OUTFITS_LS_KEY) || 'null')

    setMocksState({
      attemptsByPathID,
      outfits,
    })
  } catch (err) {
    devLog(DevLogGroup.Mocks, 'Could not restore attempts:', (err as Error).message)
  }
}

export function getMocks() {
  return mocksState
}

export function exportMocks() {
  devLog(DevLogGroup.Mocks, `\n${JSON.stringify(mocksState, null, '  ')}\n`)

  return getMocks()
}

export function persistMocks() {
  localStorage.setItem(ATTEMPTS_BY_PATH_ID_LS_KEY, JSON.stringify(mocksState.attemptsByPathID))
  localStorage.setItem(OUTFITS_LS_KEY, JSON.stringify(mocksState.outfits))
}

export function clearMocks() {
  localStorage.removeItem(ATTEMPTS_BY_PATH_ID_LS_KEY)
  localStorage.removeItem(OUTFITS_LS_KEY)

  window.location.reload()
}
