/* eslint-disable max-len */

import { ResponseComposition, RestContext, RestRequest } from 'msw'

import { to } from '../../utils/typescript/typescript.utils'
import { MOCKED_CREATOR_RENDERER_CONFIG } from '../creator/renderer-config/creator-renderer-config.mocks'
import { TraitTypeId } from '../creator/renderer-config/creator-renderer-config.types'
import { getUnlockedTraits } from '../rewards/rewards.mockUtils'

import { TRAIT_MOCKS_BY_ID } from './traits.mocks'
import { Trait, UnlockedTraits } from './traits.types'

export function fetchTraitsHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  const traits = process.env.GH_GOD_MODE
    ? Object.values(TRAIT_MOCKS_BY_ID).filter(Boolean)
    : getUnlockedTraits().map((traitReward) => TRAIT_MOCKS_BY_ID[traitReward.id])

  const traitsByType = traits.reduce((acc, trait) => {
    if (acc[trait.type]) acc[trait.type].push(trait)
    else acc[trait.type] = [trait]

    return acc
  }, {} as Record<TraitTypeId, Trait[]>)

  // Always send a nude body:

  const { traitTypeConfigs } = MOCKED_CREATOR_RENDERER_CONFIG

  if (Object.keys(traitsByType).length > 0) {
    if (!traitsByType.body) traitsByType.body = []
    if (!traitsByType.head) traitsByType.head = []
    if (!traitsByType.background) traitsByType.background = []
    if (!traitsByType.eyes) traitsByType.eyes = []
    if (!traitsByType.nose) traitsByType.nose = []
    if (!traitsByType.mouth) traitsByType.mouth = []
    if (!traitsByType.ears) traitsByType.ears = []

    if (!traitsByType.body.find((trait) => trait.id === traitTypeConfigs.body.defaultTrait)) traitsByType.body.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.body.defaultTrait])
    if (!traitsByType.head.find((trait) => trait.id === traitTypeConfigs.head.defaultTrait)) traitsByType.head.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.head.defaultTrait])
    if (!traitsByType.background.find((trait) => trait.id === traitTypeConfigs.background.defaultTrait)) traitsByType.background.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.background.defaultTrait])
    if (!traitsByType.eyes.find((trait) => trait.id === traitTypeConfigs.eyes.defaultTrait)) { traitsByType.eyes.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.eyes.defaultTrait]) }
    if (!traitsByType.nose.find((trait) => trait.id === traitTypeConfigs.nose.defaultTrait)) { traitsByType.nose.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.nose.defaultTrait]) }
    if (!traitsByType.mouth.find((trait) => trait.id === traitTypeConfigs.mouth.defaultTrait)) { traitsByType.mouth.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.mouth.defaultTrait]) }
    if (!traitsByType.ears.find((trait) => trait.id === traitTypeConfigs.ears.defaultTrait)) { traitsByType.ears.push(TRAIT_MOCKS_BY_ID[traitTypeConfigs.ears.defaultTrait]) }
  }

  return res(
    ctx.status(200),
    ctx.json(to<UnlockedTraits>(traitsByType)),
  )
}
