import { getCanonicalURL } from '../../utils/url/url.utils'

import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_KEYWORDS_ARR, DEFAULT_PAGE_TITLE, DEFAULT_SHARE_IMAGE_SRC, PAGE_TITLE_PREFIX, PAGE_TITLE_SUFFIX } from './brand.constants'

export function getPageTitle(title: string) {
  const titleBase = (title || DEFAULT_PAGE_TITLE).trim()

  return `${titleBase}${titleBase.startsWith(PAGE_TITLE_PREFIX) ? '' : PAGE_TITLE_SUFFIX}`
}

export function getPageDescription(description: string) {
  return description || DEFAULT_PAGE_DESCRIPTION
}

export function getMetaTags(...extraTags: string[]) {
  return [...new Set([...DEFAULT_PAGE_KEYWORDS_ARR, ...extraTags])]
}

export function getShareImageSrc(shareImageSrc: string) {
  return getCanonicalURL(shareImageSrc || DEFAULT_SHARE_IMAGE_SRC)
}
