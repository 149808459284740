import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { IS_BROWSER } from '../../utils/common/common.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'
import { getAppEnvironment, getAppVersion } from '../../utils/versioning/versioning.utils'

import { AnalyticsAppEvent, AnalyticsEvent, BaseLabelledEvent, PutUserAnalyticsResponse } from './mixpanel/user-analytics-mixpanel.types'
import { AnalyticsError } from './sentry/errors/analytics/analytics-error.class'
import { toGenericError } from './sentry/errors/errors.utils'
import { SentryError } from './sentry/errors/sentry/sentry-error.class'
import { SentryReport, SentryReportInfo } from './sentry/user-analytics-sentry.types'
import { NOT_AVAILABLE, testErrorMessageReport } from './user-analytics.constants'
import { getCommonEventMeta, getDeviceInfo } from './user-analytics.utils'

class UserAnalyticsServiceClass {
  // This will log events to the Mixpanel (backend) or Sentry in both PREVIEW and PRODUCTION:
  static LOG_REAL_EVENTS = IS_BROWSER && getAppEnvironment() !== 'localhost'

  // Uncomment in localhost to send real Mixpanel events and Sentry reports:
  // static LOG_REAL_EVENTS = true

  isMixpanelEnabled = false

  isSentryEnabled = IS_BROWSER && getAppEnvironment() === 'localhost'

  enable() {
    this.isMixpanelEnabled = true

    if (process.env.GH_SENTRY_URI) {
      try {
        Sentry.init({
          dsn: process.env.GH_SENTRY_URI,

          integrations: [
            new BrowserTracing(),
          ],

          environment: getAppEnvironment(),

          release: getAppVersion(),

          // debug: true,

          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 0.1,

          // Prevent Sentry SDK from auto capturing and reporting:
          // defaultIntegrations: false,
        })

        this.isSentryEnabled = true
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Sentry initialization error:', err)

        this.isSentryEnabled = false
      }
    }
  }

  disable() {
    this.isMixpanelEnabled = false
    this.isSentryEnabled = false
  }

  pushEvent(appEvent: AnalyticsAppEvent, axiosConfig?: AxiosRequestConfig) {
    if (!this.isMixpanelEnabled) return

    const analyticsEvent: AnalyticsEvent = {
      appEvent,
      meta: {
        ...getCommonEventMeta(),
        deviceInfo: getDeviceInfo(),
      },
    }

    const eventName = analyticsEvent.appEvent.name
    const eventLabel = (analyticsEvent.appEvent as AnalyticsAppEvent & Partial<BaseLabelledEvent>).label
    const mixpanelEventName = `${eventName}${eventLabel ? ` - ${eventLabel}` : ''}`

    if (!!window || UserAnalyticsServiceClass.LOG_REAL_EVENTS) {
      return axios
        .put<PutUserAnalyticsResponse>(`${endpoints.postUserAnalyticsEvent}`, analyticsEvent, axiosConfig)
        .catch((error) => {
          this.captureException(
            new AnalyticsError(`Could not send ${mixpanelEventName}`, error),
            { appEvent },
          )
        })
    }
  }

  captureException(error: SentryError, extras?: Record<string, unknown>) {
    if (!this.isSentryEnabled) return

    const { cause } = error
    const { name, code, message } = toGenericError(cause)
    const transactionName = `${name || 'UnknownError'}${code ? ` (${code})` : ''}${message ? ` - ${message}` : ''}`

    // Log locally:

    if (getAppEnvironment() === 'localhost') {
      // eslint-disable-next-line no-console
      console.error(error)
      // eslint-disable-next-line no-console
      console.error({ extra: { ...extras, transactionName } })
    }

    // Log for real:

    if (UserAnalyticsServiceClass.LOG_REAL_EVENTS) {
      return Sentry.captureException(
        error,
        (scope) => {
          if (cause) scope.setTransactionName(transactionName)

          if (extras) scope.setExtras(extras)

          return scope
        },
      )
    }
  }

  captureCrash({
    error,
    componentStack,
    subId,
    errorBoundary,
  }: SentryReportInfo) {
    if (!this.isSentryEnabled) return

    const extra: SentryReport = {
      errorBoundary,
      componentStack,
      subId: subId || NOT_AVAILABLE,
    }

    // Log locally:

    if (getAppEnvironment() === 'localhost') {
      // eslint-disable-next-line no-console
      console.error(error)
      // eslint-disable-next-line no-console
      console.error({ extra: { ...extra } })
    }

    // Log for real:

    if (UserAnalyticsServiceClass.LOG_REAL_EVENTS || getAppEnvironment() === 'localhost' && error.message === testErrorMessageReport) {
      return Sentry.captureException(error, { extra })
    }
  }
}

export const UserAnalyticsService = new UserAnalyticsServiceClass()
