import { Typography } from '@mui/material'
import { canUserSeeAs } from '@ng-mono/sdk'

import { BaseMenuItemConfig, BaseMenuItemProps, MenuItemType } from '../base/menu-base-item.types'

export interface MenuCaptionItemConfig extends BaseMenuItemConfig {
  type: MenuItemType.Caption;
  caption: string;
}

export type MenuCaptionItemProps = BaseMenuItemProps & Omit<MenuCaptionItemConfig, 'type'>

export function MenuCaptionItem({
  // BaseMenuItemProps:
  root = 'div',
  sx: sxProp,
  // variant,
  userRole = null,
  realUserRole = null,
  // onAction,

  // BaseMenuItemConfig:
  title,
  availableTo,
  hidden,

  // MenuCaptionItemConfig:
  caption,
}: MenuCaptionItemProps) {
  const { isHidden, visibilitySx } = canUserSeeAs(userRole, realUserRole, availableTo, hidden)

  if (isHidden) return null

  const rootSx = visibilitySx ? { ...sxProp, ...visibilitySx as any } : sxProp

  return (
    <Typography
      component={ root }
      variant="caption"
      title={ title }
      sx={{
        px: 2.5,
        py: 1.5,
        textTransform: 'uppercase',
        color: ({ palette }) => palette.text.secondary,
        ...rootSx,
      }}>
      { caption }
    </Typography>
  )
}
