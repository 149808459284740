import { canUserSeeAs } from '@ng-mono/sdk'

import { BaseMenuItemConfig, BaseMenuItemProps, BaseMenuItemPropsAnConfig, MenuItemType } from '../base/menu-base-item.types'

export type MenuCustomItemBaseProps = Omit<BaseMenuItemPropsAnConfig, 'availableTo' | 'hidden' | 'userRole' | 'realUserRole'>

export interface MenuCustomItemConfig<P extends MenuCustomItemBaseProps> extends BaseMenuItemConfig {
  type: MenuItemType.Custom;
  component: React.ComponentType<P>;
  props: P;
}

export interface MenuCustomItemProps<P extends MenuCustomItemBaseProps> extends BaseMenuItemProps, Omit<MenuCustomItemConfig<P>, 'type'> {

}

export function MenuCustomItem<P extends MenuCustomItemBaseProps>({
  // BaseMenuItemProps:
  root = 'div',
  sx: sxProp,
  variant,
  userRole = null,
  realUserRole = null,
  onAction,

  // BaseMenuItemConfig:
  title,
  availableTo,
  hidden,

  // MenuCustomItemConfig:
  component: Component,
  props,
}: MenuCustomItemProps<P>) {
  const { isHidden, visibilitySx } = canUserSeeAs(userRole, realUserRole, availableTo, hidden)

  if (isHidden) return null

  const rootSx = visibilitySx ? { ...sxProp, ...visibilitySx as any } : sxProp

  return (
    <Component
      root={ root }
      sx={ rootSx }
      variant={ variant }
      onAction={ onAction }
      title={ title }
      { ...props } />
  )
}
