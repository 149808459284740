import type { SdkRootStore } from '@ng-mono/sdk'

import { AvatarControllerStore } from '../avatar/avatar-controller.store'
import { LayoutStore } from '../layout/layout.store'
import { NotificationsStore } from '../notifications/notifications.store'

export interface BaseAppStore {
  appStore: AppRootStore;
  sdkStore: SdkRootStore;
}

export class AppRootStore {
  layoutStore: LayoutStore

  notificationsStore: NotificationsStore

  avatarControllerStore: AvatarControllerStore

  constructor(sdkStore: SdkRootStore) {
    this.layoutStore = new LayoutStore(this, sdkStore)
    this.notificationsStore = new NotificationsStore(this, sdkStore)
    this.avatarControllerStore = new AvatarControllerStore(this, sdkStore)
  }
}
