import { DEFAULT_TRAIT_COLOR, NONE_TRAIT_ID, NFME_DEFAULT_MENU_ITEM_LABEL, AvatarConfig, AvatarOutfit, BackendTraitValues, TraitValues, isAvatarTraitValues, sanitizeBackgroundTraitValue, traitValuesToBackendTraitValues, ColorKey, Trait, TraitTypeId, EditorLabel, UserAnalyticsService, SdkRootStore, isMutationResultSuccess, ResourceStatus, forceUpdate } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'
import { v4 as uuidv4 } from 'uuid'

import type { AppRootStore, BaseAppStore } from '../app-root/app-root.store'

import { AvatarDownloaderControllerStore } from './downloader-controller/avatar-downloader-controller.store'
import { AvatarHighlighterControllerStore } from './highlighter-controller/avatar-highlighter-controller.store'
import { AvatarHistoryControllerStore } from './history-controller/avatar-history-controller.store'
import { PalettesControllerStore } from './palettes-controller/palettes-controller.store'
import { AvatarRandomizerControllerStore } from './randomizer-controller/avatar-randomizer-controller.store'
import { AvatarSettingsControllerStore } from './settings-controller/avatar-settings-controller.store'

export type AvatarControllerSelectedPanel = 'editor-settings' | 'download' | 'nfme-config' | 'import' | 'create' | 'colorPicker'

export type AvatarControllerSelectedToolbar = 'outfits'

export class AvatarControllerStore implements BaseAppStore {
  static INITIAL_SELECTED_CATEGORY: TraitTypeId = 'body'

  static INITIAL_SELECTED_COLOR: ColorKey = '0'

  static INHERITED_COLOR_KEY: ColorKey = '0'

  appStore: AppRootStore

  sdkStore: SdkRootStore

  // Selections:

  // TODO (Dani): Move to AvatarSelectorsControllerStore sub-controller:

  selectedNFMeID: string | null = null

  selectedCategory = AvatarControllerStore.INITIAL_SELECTED_CATEGORY

  selectedColorSlot: ColorKey | null = AvatarControllerStore.INITIAL_SELECTED_COLOR

  selectedPanel: AvatarControllerSelectedPanel | null = null

  selectedToolbar: AvatarControllerSelectedToolbar | null = null

  // Disabled items and categories:

  disabledItems: string[] = []

  disabledTraitTypes: TraitTypeId[] = []

  // Sub-controllers:

  avatarHighlighter = new AvatarHighlighterControllerStore(this)

  avatarHistory = new AvatarHistoryControllerStore(this)

  avatarRandomizer = new AvatarRandomizerControllerStore(this)

  avatarDownloader = new AvatarDownloaderControllerStore(this)

  avatarSettings = new AvatarSettingsControllerStore(this)

  // TODO (Dani): Move to AvatarSettingsControllerStore and/or AvatarStore:

  avatarPalettes = new PalettesControllerStore(this)

  // avatarConfigStore = new AvatarConfigControllerStore(this)

  // avatarInfoStore = new AvatarInfoControllerStore(this)

  // Saving flow:

  // saveMode: 'instant' | 'confirm' = 'instant'

  // wipAvatar: AvatarOutfit | null = null

  constructor(appStore: AppRootStore, sdkStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.appStore = appStore
    this.sdkStore = sdkStore
  }

  // Getters (shortcuts to different properties on the currently selected NFMe):

  get nfmeAndConfigResource() {
    const { selectedNFMeID } = this

    return (selectedNFMeID && this.sdkStore.avatarStore.avatarOutfitAndConfigResourcesCacheSet.get(selectedNFMeID)?.data) || null
  }

  get nfme(): AvatarOutfit | null {
    return this.nfmeAndConfigResource?.outfit || null
  }

  get nfmeConfig(): AvatarConfig | null {
    return this.nfmeAndConfigResource?.config || null
  }

  get nfmeStatus(): ResourceStatus {
    // TODO (Dani): Refactor this so that we have single action (one action per resource) or multi action resources maps:

    if (this.sdkStore.avatarStore.createAvatarOutfitMutation.isUpdating) return ResourceStatus.Creating

    if (this.sdkStore.avatarStore.updateAvatarOutfitMutation.isUpdating) return ResourceStatus.Updating

    if (this.sdkStore.avatarStore.deleteAvatarOutfitMutation.isUpdating) return ResourceStatus.Deleting

    return this.nfmeAndConfigResource ? ResourceStatus.Ready : ResourceStatus.Loading
  }

  get nfmeLabel() {
    const nfmeLabel = this.nfme?.label

    if (nfmeLabel) return nfmeLabel

    const { avatarOutfitOptions } = this.sdkStore.avatarStore
    const { selectedNFMeID } = this

    return (avatarOutfitOptions || []).find((avatarOutfitOption) => avatarOutfitOption.value === selectedNFMeID)?.label || ''
  }

  get nfmeItems(): TraitValues | null {
    return this.nfme?.traitValues || null
  }

  get nfmeSelectedItem() {
    return this.nfmeItems?.[this.selectedCategory]?.trait || null
  }

  get nfmeSelectedItemID() {
    return this.nfmeSelectedItem?.id || NONE_TRAIT_ID
  }

  get nfmeSelectedItemColors() {
    return this.nfmeItems?.[this.selectedCategory]?.traitColors || {}
  }

  get nfmeSelectedItemColor() {
    return this.selectedColorSlot ? (this.nfmeSelectedItemColors[this.selectedColorSlot] || null) : null
  }

  // Toolbars:

  toggleOutfitsMenu() {
    this.selectedToolbar = this.selectedToolbar === 'outfits' ? null : 'outfits'
  }

  closeMenu() {
    this.selectedToolbar = null
  }

  // Panels:

  selectPanel(selectedPanel: AvatarControllerSelectedPanel | null) {
    this.selectedPanel = selectedPanel
  }

  toggleSettingsPanel() {
    this.selectedPanel = this.selectedPanel === 'editor-settings' ? null : 'editor-settings'
  }

  toggleConfigPanel() {
    this.selectedPanel = this.selectedPanel === 'nfme-config' ? null : 'nfme-config'
  }

  toggleColorPickerPanel() {
    this.selectedPanel = this.selectedPanel === 'colorPicker' ? null : 'colorPicker'
  }

  closePanel() {
    this.selectedPanel = null
  }

  // Items:

  get activeItems() {
    const { traits } = this.sdkStore.traitsStore

    if (!traits) return null

    const currentCategoryItems = traits[this.selectedCategory] || []

    return this.disabledItems.length
      ? currentCategoryItems.filter(({ id }) => !this.disabledItems.includes(id))
      : currentCategoryItems
  }

  setDisabledItems(disabledItems: string[]) {
    this.disabledItems = disabledItems
  }

  setDisabledTraitTypes(disabledTraitTypes: TraitTypeId[]) {
    this.disabledTraitTypes = disabledTraitTypes
  }

  // Analytics:

  static log(label: EditorLabel, eventValue?: null | string) {
    UserAnalyticsService.pushEvent({
      name: 'NFMe Editor',
      label,
      eventValue,
    })
  }

  // NFMe selector:

  async selectNFMe(selectedNFMeID: string | null, isUserAction?: boolean) {
    const { avatarStore } = this.sdkStore

    this.selectedNFMeID = selectedNFMeID

    if (isUserAction) AvatarControllerStore.log('Pick NFMe', selectedNFMeID)

    const prevSelectedOutfitID = selectedNFMeID

    if (selectedNFMeID && !avatarStore.avatarOutfitAndConfigResourcesCacheSet.has(selectedNFMeID)) {
      await avatarStore.fetchAvatarOutfitAndConfig({ outfitID: selectedNFMeID })

      if (prevSelectedOutfitID === this.selectedNFMeID) {
        forceUpdate(this, 'selectedNFMeID')
      }
    }

    this.resetSelections()
  }

  // Category & color selectors:

  selectCategory(categoryID: TraitTypeId, isUserAction?: boolean) {
    if (isUserAction) AvatarControllerStore.log('Pick Trait Type', categoryID)

    this.selectedCategory = categoryID

    this.selectColorSlot(this.nfmeSelectedItem)
  }

  selectColorSlot(colorKeyOrTrait: ColorKey | Trait | null) {
    this.selectedColorSlot = (typeof colorKeyOrTrait === 'string' || colorKeyOrTrait === null)
      ? colorKeyOrTrait
      : (Object.keys(colorKeyOrTrait.colors).length > 0 ? AvatarControllerStore.INITIAL_SELECTED_COLOR : null)
  }

  // Reset selectors:

  resetSelections(resetOutfitID = false) {
    this.avatarHistory.clear()

    // If `resetSelections` is called from a useEffect's unmount function, this might cause issues in Development + Strict Mode.
    // Note this line is never used currently anyway.
    if (resetOutfitID) this.selectedNFMeID = null

    this.selectedCategory = AvatarControllerStore.INITIAL_SELECTED_CATEGORY
    this.selectedColorSlot = AvatarControllerStore.INITIAL_SELECTED_COLOR
    this.selectedPanel = null
    this.selectedToolbar = null
  }

  // Update NFMe's items and colors:

  updateNFMeItemAndColor(
    traitId: string,
    traitColor: string | null,
  ) {
    const { nfmeConfig, nfmeItems, selectedCategory } = this

    if (!nfmeConfig || !nfmeItems) return

    const trait = this.sdkStore.traitsStore.getTraitsByTypeAndId(selectedCategory, traitId)

    // Update colors:

    // Set selectedColorSlot to null if there's no trait or if the trait has no selectable colors; alternatively, set it to '0'

    const isColorOutOfBounds = trait && (parseInt(this.selectedColorSlot as string, 10) || 0) >= Object.keys(trait.colors).length

    if (this.selectedColorSlot === null || trait === null || isColorOutOfBounds) this.selectColorSlot(trait)

    const { selectedColorSlot, nfmeSelectedItemColors } = this

    const nextTraitColors: Partial<Record<ColorKey, string>> = {}

    // If there's no selectedColorSlot or trait, then reset `traitColors = {}`. Otherwise, fill `traitColors` with the selected
    // trait color (`traitColor || this.nfmeSelectedItemColor`). The values for the remaining color keys are either set to
    // `DEFAULT_TRAIT_COLOR` or to `null` (if that specific color key inherits from another trait)

    if (selectedColorSlot && trait) {
      const { inheritDefaultColorsFrom } = nfmeConfig.traitTypeConfigs[trait.type] || {}

      // Fill trait colors with the default color:
      Object.keys(trait.colors).forEach((colorKey) => {
        const defaultColor = inheritDefaultColorsFrom && colorKey === AvatarControllerStore.INHERITED_COLOR_KEY ? null : DEFAULT_TRAIT_COLOR
        const nextTraitColor = nfmeSelectedItemColors[colorKey as ColorKey] || defaultColor

        if (nextTraitColor) nextTraitColors[colorKey as ColorKey] = nextTraitColor
      })

      // And override the one currently selected:
      if (traitColor) nextTraitColors[selectedColorSlot] = traitColor
      else if (traitColor === null) delete nextTraitColors[selectedColorSlot]
    }

    // Update trait values (the one in the store, for the optimistic update):

    const traitValues: TraitValues = {
      ...nfmeItems,
      [selectedCategory]: {
        trait,
        traitColors: nextTraitColors,
      },
    }

    // Update trait (backend only needs trait ID + trait colors)
    this.updateNFMe({ traitValues })
  }

  changeItem(traitId: string, isUserAction?: boolean) {
    if (isUserAction) {
      AvatarControllerStore.log('Pick Item', traitId)

      if (traitId && traitId !== this.nfmeSelectedItemID) this.avatarHighlighter.highlightLastUpdatedTraitType()
    }

    if (traitId !== this.nfmeSelectedItemID) this.avatarHistory.pushChange('trait')

    this.updateNFMeItemAndColor(traitId, this.nfmeSelectedItemColor || '')
  }

  changeColor(traitColor: string | null, isUserAction?: boolean) {
    if (isUserAction) AvatarControllerStore.log('Pick Color', traitColor)

    if (traitColor !== this.nfmeSelectedItemColor) this.avatarHistory.pushChange('color')

    this.updateNFMeItemAndColor(this.nfmeSelectedItemID, traitColor)
  }

  // CRUD - UPDATE:

  updateNFMe({
    label,
    paletteID,
    traitValues,
  }: Partial<Pick<AvatarOutfit, 'label' | 'paletteID' | 'traitValues'>>) {
    const { nfme } = this

    if (!nfme) return

    const {
      label: prevLabel,
      paletteID: prevPaletteID,
      traitValues: prevTraitValues,
    } = nfme

    const nextLabel = label || prevLabel
    const nextPaletteID = paletteID || prevPaletteID

    let nextTraitValues: TraitValues | null = traitValues || prevTraitValues

    if (prevPaletteID !== nextPaletteID) nextTraitValues = this.avatarPalettes.migrateColors(nextPaletteID, nextTraitValues)

    if (!nextTraitValues) throw new Error('Palette could not be migrated.')

    const mutationResult = this.sdkStore.avatarStore.updateAvatarOutfit({
      updatedAvatarOutfit: {
        layeringVersion: nfme.layeringVersion,
        id: nfme.id,
        label: nextLabel,
        traitValues: traitValuesToBackendTraitValues(nextTraitValues),
        paletteID: nextPaletteID,
      },
    }, {
      partialOptimisticUpdateLimit: 2,
      partialOptimisticUpdate: {
        outfit: {
          label: nextLabel,
          traitValues: nextTraitValues,
          paletteID: nextPaletteID,
        },
      },
    })

    forceUpdate(this, 'selectedNFMeID')

    return mutationResult
  }

  // CRUD - CREATE:

  async createNFMe(label: string, backendTraitValuesOrDuplicateFromID?: BackendTraitValues | string) {
    const { selectedNFMeID: prevCurrentOutfitID } = this

    let backendTraitValues: BackendTraitValues | undefined

    if (backendTraitValuesOrDuplicateFromID) {
      if (typeof backendTraitValuesOrDuplicateFromID === 'string') {
        const { avatarStore } = this.sdkStore
        const { avatarOutfitAndConfigResourcesCacheSet } = avatarStore

        if (!avatarOutfitAndConfigResourcesCacheSet.has(backendTraitValuesOrDuplicateFromID)) {
          await avatarStore.fetchAvatarOutfitAndConfig({ outfitID: backendTraitValuesOrDuplicateFromID })
        }

        const traitValues = avatarOutfitAndConfigResourcesCacheSet.get(backendTraitValuesOrDuplicateFromID)?.data?.outfit.traitValues

        if (traitValues) backendTraitValues = traitValuesToBackendTraitValues(traitValues)
      } else {
        backendTraitValues = backendTraitValuesOrDuplicateFromID
      }
    }

    const tempID = uuidv4()

    const createAvatarOutfitPromise = this.sdkStore.avatarStore.createAvatarOutfit(tempID, label, backendTraitValues)

    this.avatarHistory.clear()

    this.selectCategory(AvatarControllerStore.INITIAL_SELECTED_CATEGORY)

    this.selectNFMe(tempID)

    const mutationResult = await createAvatarOutfitPromise

    if (mutationResult instanceof Error) {
      this.selectNFMe(prevCurrentOutfitID)
    } else if (isMutationResultSuccess(mutationResult)) {
      this.selectNFMe(mutationResult.data.outfit.id)
    }

    return mutationResult
  }

  // CRUD - CREATE (IMPORT):

  // TODO: The export should be updated to be the whole NFMe (including label, palette ID, etc)

  importNFMe(label: string, traitValuesParam: BackendTraitValues | TraitValues) {
    const traitValues = isAvatarTraitValues(traitValuesParam)
      ? traitValuesToBackendTraitValues(sanitizeBackgroundTraitValue(traitValuesParam))
      : traitValuesParam

    return this.createNFMe(label, traitValues)
  }

  // CRUD - UPDATE (RENAME):

  async renameNFMe(nfmeID: string, label: string) {
    const fetchAvatarResult = await this.sdkStore.avatarStore.fetchAvatarOutfitAndConfig({ outfitID: nfmeID })

    if (!fetchAvatarResult || (fetchAvatarResult instanceof Error)) return

    const nfme = fetchAvatarResult.outfit

    return this.sdkStore.avatarStore.updateAvatarOutfit({
      updatedAvatarOutfit: {
        layeringVersion: nfme.layeringVersion,
        id: nfme.id,
        label,
        traitValues: traitValuesToBackendTraitValues(nfme.traitValues),
        paletteID: nfme.paletteID,
      },
    }, {
      partialOptimisticUpdateLimit: 2,
      partialOptimisticUpdate: {
        outfit: {
          label,
        },
      },
    })
  }

  // CRUD - UPDATE (RESET):

  resetNFMe() {
    const { creatorRendererConfig } = this.sdkStore.creatorRendererConfigStore

    if (!creatorRendererConfig) return

    this.avatarHistory.clear()

    this.selectCategory(AvatarControllerStore.INITIAL_SELECTED_CATEGORY)

    return this.updateNFMe({ traitValues: creatorRendererConfig.defaultTraitValues })
  }

  // CRUD - DELETE:

  async deleteNFme(nfmeID: string) {
    const { avatarOutfitOptions } = this.sdkStore.avatarStore

    if (!nfmeID || !avatarOutfitOptions) return

    // Automatically re-select some other NFMe if the current one is deleted:

    if (nfmeID === this.selectedNFMeID) {
      const fistOptionID = avatarOutfitOptions[0]?.value || ''

      this.selectNFMe(fistOptionID)
    }

    // Do not let users delete their last NFMe:

    if (avatarOutfitOptions.length === 1) {
      await this.resetNFMe()
      await this.renameNFMe(nfmeID, NFME_DEFAULT_MENU_ITEM_LABEL)

      return
    }

    return this.sdkStore.avatarStore.deleteAvatarOutfit(nfmeID)
  }
}
