import { Link as MUILink } from '@mui/material'
import React, { useCallback } from 'react'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'

import { BaseLinkProps } from '../../../../libs/ui/components/link/base/base-link.types'

export type LinkProps = BaseLinkProps & Omit<ReactRouterLinkProps, 'to' | 'href' | 'color'>

function LinkComponent({
  href: hrefProp,
  cover = false,
  target: targetProp,
  rel: relProp,
  underline,
  component,
  children,
  sx,
  linkRef,
  disabled,
  onClick,
  ...props
}: LinkProps) {
  const isExternal = hrefProp.startsWith('http') || hrefProp.startsWith('mailto')
  const target = targetProp || (isExternal ? '_blank' : undefined)
  const rel = relProp || (isExternal ? 'noopener noreferrer' : undefined)

  let to: string | undefined
  let href: string | undefined
  let rootComponent = component

  if (!rootComponent) {
    if (href === '' || disabled) {
      rootComponent = 'button'
    } else if (isExternal) {
      rootComponent = 'a'
      href = hrefProp
    } else {
      rootComponent = ReactRouterLink
      to = hrefProp
    }
  }

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) e.preventDefault()
    if (onClick) onClick(e)
  }, [disabled, onClick])

  return (
    <MUILink
      { ...props }
      to={ to }
      href={ href }
      component={ rootComponent }
      ref={ linkRef }
      target={ target }
      rel={ rel }
      underline={ underline || (typeof children === 'string' ? 'always' : 'none') }
      onClick={ handleClick }
      sx={ cover ? {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        ...sx,
      } : sx }>
      { children }
    </MUILink>
  )
}

// eslint-disable-next-line react/no-multi-comp
export const Link = React.forwardRef<
HTMLAnchorElement, LinkProps
>((props, ref) => <LinkComponent { ...props } linkRef={ ref } />)
