import { Stack } from '@mui/material'
import { WithOptionalChildrenProp } from '@ng-mono/sdk'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { createPortal } from 'react-dom'

import { DEFAULT_BORDER_BOTTOM_SHADOW, NAV_MENU_BACKGROUND } from '../../../../../theme/theme'
import { useClickOutside } from '../../../hooks/events/click-outside/click-outside.hook'
import { NAVBAR_MENU_PORTAL_ID } from '../../app-config/app-config.constants'
import { BasePortalMenuProps } from '../../menu/base-menu.types'
import { getNavbarHeight } from '../base-navbar.utils'

function getMenuSlotElement() {
  return document.getElementById(NAVBAR_MENU_PORTAL_ID) as HTMLDivElement
}

export type NavbarMenuPortalOpenFrom = 'top' | 'bottom'

export interface NavbarMenuPortalProps extends BasePortalMenuProps, WithOptionalChildrenProp {
  id: string;
  scrollableRef: React.RefObject<HTMLElement>;
  ignoreSelector?: string;
  maxHeight?: number;
  component?: React.ComponentType<BasePortalMenuProps>;
}

export function NavbarMenuPortal({
  id,
  variant,
  onClose,
  scrollableRef,
  ignoreSelector,
  // eslint-disable-next-line no-mixed-operators
  maxHeight = 100 * 2 / 3,
  component: MenuComponent,
  children,
}: NavbarMenuPortalProps) {
  // Portal setup:

  const [menuSlotElement, setMenuSlotElement] = useState<HTMLDivElement>(getMenuSlotElement())

  useEffect(() => {
    if (menuSlotElement === null) {
      const nextToolbarSlotElement = getMenuSlotElement()

      if (nextToolbarSlotElement) setMenuSlotElement(nextToolbarSlotElement)

      // TODO (Dani): Alternatively, try again?
    }
  }, [menuSlotElement])

  // Close outside behavior:

  const isOpen = children || MenuComponent

  const menuRef = useRef<HTMLDivElement | null>(null)

  // TODO (Dani): Move the reusable logic in here into useClickOutside:

  const handleClickOutside = useCallback((event: MouseEvent) => {
    const target = event.target as HTMLElement

    if (ignoreSelector && target && target.matches(ignoreSelector)) return

    onClose()
  }, [ignoreSelector, onClose])

  useClickOutside(menuRef, isOpen ? handleClickOutside : null)

  // Block page scroll:

  useEffect(() => {
    const scrollableElement = scrollableRef.current

    if (isOpen && scrollableElement) scrollableElement.style.overflow = 'hidden'

    return () => {
      if (scrollableElement) scrollableElement.removeAttribute('style')
    }
  }, [isOpen, scrollableRef])

  if (menuSlotElement === null) return null

  let menuContent: React.ReactElement | null = null

  if (children) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    menuContent = <>{ children }</>
  } else if (MenuComponent) {
    menuContent = (
      <MenuComponent
        variant={ variant }
        onClose={ onClose } />
    )
  }

  // TODO (Dani): Trap focus either here or in export function BaseNavbar

  const portalContent = (
    <AnimatePresence mode="wait">
      <Stack
        key={ id }
        ref={ menuRef }
        component={ motion.div }
        initial={{
          y: '-100%',
        }}
        animate={{
          y: 0,
        }}
        exit={{
          y: '-100%',
        }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          boxShadow: `${DEFAULT_BORDER_BOTTOM_SHADOW}, 0 calc(-2 * ${getNavbarHeight('default')}) 0 0 ${NAV_MENU_BACKGROUND.background}`,
          maxHeight: `${maxHeight}%`,
          overflowX: 'hidden',
          overflowY: 'auto',
          zIndex: 100,
          ...NAV_MENU_BACKGROUND,
        }}>
        { menuContent }
      </Stack>
    </AnimatePresence>
  )

  return createPortal(
    portalContent,
    menuSlotElement,
  )
}
