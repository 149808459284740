import { Stack } from '@mui/material'
import { useAuth } from '@ng-mono/sdk'
import { useCallback, useMemo } from 'react'

import { BaseMenuProps } from './base-menu.types'
import { MenuActionItem } from './items/action/menu-action-item.component'
import { BaseMenuItemPropsAnConfig } from './items/base/menu-base-item.types'
import { MenuCaptionItem } from './items/caption/menu-caption-item.component'
import { MenuChildrenItem } from './items/children/menu-children-item.component'
import { MenuCustomItem, MenuCustomItemBaseProps } from './items/custom/menu-custom-item.component'
import { MenuDividerItem } from './items/divider/menu-divider-item.component'
import { MenuLinkItem } from './items/link/menu-link-item.component'

const menuItemComponents = {
  action: MenuActionItem,
  caption: MenuCaptionItem,
  children: MenuChildrenItem,
  divider: MenuDividerItem,
  link: MenuLinkItem,
  custom: MenuCustomItem,
} as const

export function BaseMenu<A extends string = string, P extends MenuCustomItemBaseProps = MenuCustomItemBaseProps>({
  variant,
  menuItems,
  onAction,
}: BaseMenuProps<A, P>) {
  const { userRole, realUserRole } = useAuth()

  const handleActionClicked = useCallback((
    idOrEvent: string | React.MouseEvent<HTMLButtonElement>,
    actionOrUndefined?: string,
    valueOrUndefined?: string,
  ) => {
    if (!onAction) return

    let id = ''
    let action = '' as A

    if (typeof idOrEvent === 'object') {
      id = 'default'
      action = (idOrEvent.currentTarget.dataset.action || '') as A
    } else if (actionOrUndefined) {
      id = idOrEvent
      action = actionOrUndefined as A
    }

    if (id) onAction(id, action, valueOrUndefined)
  }, [onAction])

  const menuItemElements = useMemo(() => menuItems.map(({ type, ...menuItemProps }, i) => {
    const MenuItemComponent = menuItemComponents[type] as React.ComponentType<BaseMenuItemPropsAnConfig>

    let key = `${type}`

    // eslint-disable-next-line no-prototype-builtins
    if (menuItemProps.hasOwnProperty('id')) {
      key = `${key}-${(menuItemProps as { id: string }).id}`
    // eslint-disable-next-line no-prototype-builtins
    } else if (menuItemProps.hasOwnProperty('label')) {
      key = `${key}-${(menuItemProps as { label: string }).label}`
      // eslint-disable-next-line no-prototype-builtins
    } else if (menuItemProps.hasOwnProperty('title')) {
      key = `${key}-${menuItemProps.title}`
    } else {
      key = `${key}${i}]`
    }

    return (
      <MenuItemComponent
        key={ key }
        root="li"
        userRole={ userRole }
        realUserRole={ realUserRole }
        variant={ variant }
        onAction={ handleActionClicked }
        { ...menuItemProps } />
    )
  }), [menuItems, userRole, realUserRole, variant, handleActionClicked])

  return (
    <Stack
      component="ul"
      sx={{
        p: variant === 'navigation' ? (({ gaps }) => gaps.outerPadding) : 0,
      }}>
      { menuItemElements }
    </Stack>
  )
}
