import { endpoints } from '../../../utils/server/endpoints/server-endpoints.constants'
import { fetchCampaign } from '../base/base-campaign.utils'

async function fetchAFreshStartCampaign() {
  return fetchCampaign(
    endpoints.fetchAFreshStartCampaignFlow,
    endpoints.fetchAFreshStartCampaignAvatar,
  )
}

export const AFreshStartCampaignService = {
  fetchAFreshStartCampaign,
}
