import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../../stores/sdk/sdk.store'
import { FetchCelebrityCampaignAvatarURLParams } from '../../../utils/server/endpoints/server-endpoints.constants'
import { BaseStore } from '../../../utils/stores/common/common-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../../utils/stores/readonly/readonly-store.utils'
import { CampaignData } from '../campaigns.types'

import { CelebritiesCampaignService } from './celebrities-campaign.service'

export class CelebritiesCampaignStore implements BaseStore {
  campaignDataResource = createEmptyReadOnlyStoreResource<CampaignData>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore
  }

  get campaignData() {
    return this.campaignDataResource.data || null
  }

  async fetchCampaignData(params: FetchCelebrityCampaignAvatarURLParams) {
    return fetchStoreResource(
      this.campaignDataResource,
      CelebritiesCampaignService.fetchCelebrityCampaign,
      params,
    )
  }

  clearCampaignData() {
    clearReadOnlyStoreResource(this.campaignDataResource)
  }
}
