import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { FormOption } from '../../utils/common/form-options/form-option.types'
import { sortOptionsByLabel } from '../../utils/common/form-options/form-options.utils'
import { forceUpdate } from '../../utils/mobx/mobx.utils'
import { FetchAvatarOutfitURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { isMutationResultSuccess } from '../../utils/server/mutation/server-mutation.types'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearMutableStoreResource, createEmptyMutableStoreResource, updateStoreResource } from '../../utils/stores/mutation/mutable-store.utils'
import { OptimisticallyUpdatableStoreMapOptions } from '../../utils/stores/optimistic-updates/optimistic-updates.types'
import { optimisticallyUpdateStoreResource, optimisticallyUpdateStoreResourceMap } from '../../utils/stores/optimistic-updates/optimistic-updates.utils'
import { ReadOnlyStoreMapOptions } from '../../utils/stores/readonly-map/readonly-map-store.types'
import { clearResourcesCacheSet, createResourcesCacheSet, fetchStoreResourceMap } from '../../utils/stores/readonly-map/readonly-map-store.utils'
import { ReadOnlyStoreOptions } from '../../utils/stores/readonly/readonly-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'

import { AvatarService, UpdateAvatarOutfitParams } from './avatar.service'
import { AvatarOutfitAndConfig, BackendTraitValues } from './avatar.types'
import { backendTraitValuesToTraitValues } from './avatar.utils'

export class AvatarStore implements BaseStore {
  // NFMe options list (for the selector):

  avatarOutfitOptionsResource = createEmptyReadOnlyStoreResource<FormOption<string>[]>()

  // Actual NFMes loaded in the editor (no more than 4 loaded at a time):

  avatarOutfitAndConfigResourcesCacheSet = createResourcesCacheSet<string, AvatarOutfitAndConfig>({
    slots: 4,
    ttl: 5 * 60000, // 5 min
  })

  // TODO (Dani): Refactor this so that we have single action (one action per resource) or multi action resources maps:

  createAvatarOutfitMutation = createEmptyMutableStoreResource<AvatarOutfitAndConfig>()

  updateAvatarOutfitMutation = createEmptyMutableStoreResource()

  deleteAvatarOutfitMutation = createEmptyMutableStoreResource()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore
  }

  // AVATAR OUTFIT OPTIONS:

  get avatarOutfitOptions() {
    return this.avatarOutfitOptionsResource?.data || null
  }

  fetchAvatarOutfitOptions(storeOptions: ReadOnlyStoreOptions = {}) {
    return fetchStoreResource(
      this.avatarOutfitOptionsResource,
      AvatarService.fetchAvatarOutfitOptions,
      null,
      storeOptions,
    )
  }

  clearAvatarOutfitOptions() {
    clearReadOnlyStoreResource(this.avatarOutfitOptionsResource)
  }

  // AVATAR OUTFIT & CONFIG:

  async fetchAvatarOutfitAndConfig(params: FetchAvatarOutfitURLParams, storeOptions: ReadOnlyStoreMapOptions = {}) {
    const fetchStoreResult = await fetchStoreResourceMap<string, AvatarOutfitAndConfig, FetchAvatarOutfitURLParams>(
      params.outfitID,
      this.avatarOutfitAndConfigResourcesCacheSet,
      AvatarService.fetchAvatarOutfitAndConfig,
      params,
      {
        createAs: null,
        ...storeOptions,
      },
    )

    return fetchStoreResult
  }

  clearAvatarOutfitAndConfig() {
    clearResourcesCacheSet(this.avatarOutfitAndConfigResourcesCacheSet)
  }

  // CREATE / RENAME / UPDATE / DELETE AVATAR:

  async createAvatarOutfit(
    tempID: string,
    label: string,
    backendTraitValues?: BackendTraitValues,
  ) {
    const { creatorRendererConfig } = this.rootStore.creatorRendererConfigStore

    const { avatarOutfitOptions: prevAvatarOutfitOptions } = this

    if (!creatorRendererConfig) throw new Error('Missing this.creatorRendererConfig')

    const undoOptimisticAvatarOutfitOptionsUpdate = prevAvatarOutfitOptions
      ? optimisticallyUpdateStoreResource(this.avatarOutfitOptionsResource, {
        optimisticUpdate: sortOptionsByLabel([...prevAvatarOutfitOptions, {
          value: tempID,
          label,
        }]),
      }) : () => { /* Do nothing */ }

    const { traits } = this.rootStore.traitsStore

    const traitValues = backendTraitValues && traits
      ? backendTraitValuesToTraitValues(backendTraitValues, traits)
      : creatorRendererConfig.defaultTraitValues

    const updatedAvatarOutfitParams: AvatarOutfitAndConfig = {
      outfit: {
        id: tempID,
        label,
        layeringVersion: creatorRendererConfig.layeringVersion,
        traitValues,
        paletteID: creatorRendererConfig.defaultPaletteID,
      },
      config: creatorRendererConfig,
    }

    const undoOptimisticAvatarOutfitAndConfigUpdate = optimisticallyUpdateStoreResourceMap(
      tempID,
      this.avatarOutfitAndConfigResourcesCacheSet,
      {
        optimisticUpdate: updatedAvatarOutfitParams,
        action: 'add',
      },
    )

    const updateStoreResourceReturn = await updateStoreResource(
      this.createAvatarOutfitMutation,
      AvatarService.createAvatarOutfit,
      { label, backendTraitValues },
    )

    if (updateStoreResourceReturn instanceof Error) {
      undoOptimisticAvatarOutfitOptionsUpdate()
      undoOptimisticAvatarOutfitAndConfigUpdate()
    } else if (isMutationResultSuccess(updateStoreResourceReturn)) {
      undoOptimisticAvatarOutfitAndConfigUpdate()

      const newAvatarOutfitID = updateStoreResourceReturn.data.outfit.id
      const newAvatarOutfitLabel = updateStoreResourceReturn.data.outfit.label

      if (prevAvatarOutfitOptions) {
        optimisticallyUpdateStoreResource(this.avatarOutfitOptionsResource, {
          optimisticUpdate: sortOptionsByLabel([...prevAvatarOutfitOptions, {
            value: newAvatarOutfitID,
            label: newAvatarOutfitLabel,
          }]),
        })
      }

      optimisticallyUpdateStoreResourceMap(newAvatarOutfitID, this.avatarOutfitAndConfigResourcesCacheSet, {
        optimisticUpdate: updateStoreResourceReturn.data,
        action: 'add',
      })
    }

    return updateStoreResourceReturn
  }

  async updateAvatarOutfit(
    updatedAvatarOutfitParams: UpdateAvatarOutfitParams,
    options: OptimisticallyUpdatableStoreMapOptions<AvatarOutfitAndConfig> = {},
  ) {
    if (!this.avatarOutfitOptions) throw new Error('Missing this.avatarOutfitOptions')

    const {
      id: updatedAvatarOutfitID,
      label: updatedAvatarOutfitLabel,
    } = updatedAvatarOutfitParams.updatedAvatarOutfit

    const undoOptimisticAvatarOutfitOptionsUpdate = optimisticallyUpdateStoreResource(this.avatarOutfitOptionsResource, {
      optimisticUpdate: sortOptionsByLabel(
        this.avatarOutfitOptions.map((option) => (
          option.value === updatedAvatarOutfitID ? {
            value: updatedAvatarOutfitID,
            label: updatedAvatarOutfitLabel,
          } : option
        )),
      ),
    })

    const undoOptimisticAvatarOutfitAndConfigUpdate = optimisticallyUpdateStoreResourceMap(
      updatedAvatarOutfitID,
      this.avatarOutfitAndConfigResourcesCacheSet,
      options,
    )

    forceUpdate(this, 'avatarOutfitAndConfigResourcesCacheSet')

    const updateStoreResourceReturn = await updateStoreResource(
      this.updateAvatarOutfitMutation,
      AvatarService.updateAvatarOutfit,
      updatedAvatarOutfitParams,
    )

    if (updateStoreResourceReturn instanceof Error) {
      undoOptimisticAvatarOutfitOptionsUpdate()
      undoOptimisticAvatarOutfitAndConfigUpdate()
    }

    return updateStoreResourceReturn
  }

  async deleteAvatarOutfit(deletedOutfitID: string) {
    if (!this.avatarOutfitOptions) throw new Error('Missing this.avatarOutfitOptions')

    const undoOptimisticAvatarOutfitOptionsDelete = optimisticallyUpdateStoreResource(this.avatarOutfitOptionsResource, {
      optimisticUpdate: sortOptionsByLabel(
        this.avatarOutfitOptions.filter((option) => option.value !== deletedOutfitID),
      ),
    })

    const undoOptimisticAvatarOutfitAndConfigDelete = optimisticallyUpdateStoreResourceMap(
      deletedOutfitID,
      this.avatarOutfitAndConfigResourcesCacheSet,
      { action: 'delete' },
    )

    forceUpdate(this, 'avatarOutfitAndConfigResourcesCacheSet')

    const deleteStoreResourceReturn = await updateStoreResource(
      this.deleteAvatarOutfitMutation,
      AvatarService.deleteAvatarOutfit,
      { outfitID: deletedOutfitID },
    )

    if (deleteStoreResourceReturn instanceof Error) {
      undoOptimisticAvatarOutfitOptionsDelete()
      undoOptimisticAvatarOutfitAndConfigDelete()
    }

    return deleteStoreResourceReturn
  }

  clearAvatarOutfitMutation() {
    clearMutableStoreResource(this.updateAvatarOutfitMutation)
  }
}
