import { canUserSeeAs, CAMPAIGN_FLOW_STEPS, CAMPAIGN_TYPE_SLUGS, CampaignsService, CampaignFlowStep, CampaignTypeSlug, RoutePaths } from '@ng-mono/sdk'
import { Params } from 'react-router-dom'

import { RouteAuth } from '../../routes/routes.types'
import { GuardProps } from '../guards.types'
import { arePathnamesEqual } from '../guards.utils'

const PATHNAME_PARTS_WITHOUT_STEP: Record<CampaignTypeSlug, number> = {
  celebrities: 4,
  'nfme-of-the-week': 2,
  'a-fresh-start': 2,
}

// TODO (Dani): This should probably be dynamically loaded:
export function campaignFlowGuard<P extends Params<string>>({
  pathname,
  params,
  path,
  auth,
  availableTo,
  isAuthenticated,
  userRole,
  realUserRole,
  emailVerified,
  profileCompleted,
}: GuardProps<P>) {
  // Parsing URL to extract campaign slug type, step index and step:
  const pathnameParts = pathname.split('/').filter(Boolean)
  const campaignTypeSlug = pathnameParts[1] as CampaignTypeSlug
  const campaignSlug = params.celebritySlug || campaignTypeSlug
  const campaignStepIndex = PATHNAME_PARTS_WITHOUT_STEP[campaignTypeSlug] || 2
  const campaignStep = pathnameParts.length === campaignStepIndex + 1
    ? pathnameParts[campaignStepIndex] as CampaignFlowStep : null

  // Function to generate new campaign paths with the step we choose:
  const pathnameWithoutStep = pathnameParts.slice(0, campaignStepIndex).join('/')
  const pathnameWithStep = (step: CampaignFlowStep) => `/${pathnameWithoutStep}/${step}/`

  // Authenticated users get trapped into the profile step until they complete their profile details, but they can still
  // go back to the /create step, in case they visit a different campaign flow:
  if (isAuthenticated && !profileCompleted && campaignStep !== 'create') {
    return arePathnamesEqual(pathname, pathnameWithStep('profile')) ? null : pathnameWithStep('profile')
  }

  // Authenticated users will be redirected to a different page depending on whether they already created an NFMe for the
  // current campaign (/download-and-share if an NFMe has already been created, /create otherwise):
  const campaignEntryPointStep = isAuthenticated && CampaignsService.isCampaignVisited(campaignSlug) ? 'download-and-share' : 'create'

  // Validate current pathname:

  const isPathnameMissingStep = !campaignStep || !CAMPAIGN_FLOW_STEPS.includes(campaignStep)
  const isPathnameInvalid = isPathnameMissingStep || !CAMPAIGN_TYPE_SLUGS.includes(campaignTypeSlug)

  // No match, so we try to redirect to the most specific URL we can come up with:
  if (path === null || auth === null || isPathnameInvalid) {
    // If the URL doesn't include the step, we add the right one:
    if (isPathnameMissingStep) return pathnameWithStep(campaignEntryPointStep)

    // Alternatively, there's nothing we can do, so we just redirect
    return isAuthenticated ? RoutePaths.Home : RoutePaths.Login
  }

  // This line is commented on purpose. Even if the email is verified, the user can go into the EmailVerification page,
  // as he might be changing it. The checks to kick them out if they don't come from the ChangeEmail page are there too.
  // if (isAuthenticated && emailVerified && profileCompleted && path === RoutePaths.EmailVerification) return RoutePaths.MyProfile

  // Once these flows are completed, users can't get back to these views:
  if (isAuthenticated && emailVerified && profileCompleted && arePathnamesEqual(pathname, pathnameWithStep('profile'))) {
    return pathnameWithStep(campaignEntryPointStep)
  }

  // Auth required but missing, redirect to login:
  if (auth === RouteAuth.Required && !isAuthenticated) {
    return pathnameWithStep('sign-up')
  }

  // Auth is required and present but the user doesn't have tie right role:
  if (canUserSeeAs(userRole, realUserRole, availableTo).isHidden) {
    return pathnameWithStep(campaignEntryPointStep)
  }

  // Only for non-authenticated users, but authenticated (e.g. /login):
  if (auth === RouteAuth.Anonymous && isAuthenticated) {
    return pathnameWithStep(campaignEntryPointStep)
  }

  // Anything else is fine:
  return null
}
