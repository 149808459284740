import { BackendPath, DBPath, Path } from '../paths/paths.types'

export enum PushPathType {
  Blocking = 'Blocking',
  Anywhere = 'Anywhere',
  InPath = 'InPath',
}

export interface BasePushPathPriority {
  type: PushPathType;
  level: number;
}

export interface BlockingPushPathPriority extends BasePushPathPriority {
  type: PushPathType.Blocking;
}

export interface AnywherePushPathPriority extends BasePushPathPriority {
  type: PushPathType.Anywhere;
  skips: number; // TODO (Dani): To be implemented. Add also min. wait criteria (before skip).
  insertAt: 'start' | 'in' | 'end'; // TODO (Dani): To be implemented.
  minWait: number; // TODO (Dani): To be implemented.
  activitiesNeeded: number; // TODO (Dani): Use progress % instead (or additionally, if float).
  pathsNeeded: number; // TODO (Dani): Update this to account for positive/negative streaks.
}

export interface InPathPushPathPriority extends BasePushPathPriority {
  type: PushPathType.InPath;
  skips: number; // TODO (Dani): To be implemented. Add also min. wait criteria (before skip).
  insertAt: 'start' | 'in' | 'end'; // TODO (Dani): To be implemented.
  minWait: number; // TODO (Dani): To be implemented.
  activitiesNeeded: number; // TODO (Dani): Use progress % instead (or additionally, if float).
  pathsNeeded: number; // TODO (Dani): Update this to account for positive/negative streaks.
}

/*
TODO (Dani): Maybe only need one type with a highPriority prop inside?.

export interface BackendPushPath extends BackendPath {
  skips: number; // TODO (Dani): To be implemented. Add also min. wait criteria (before skip).
  displayIn: 'path' | 'outside' | 'anywhere';
  insertAt: 'start' | 'in' | 'end'; // TODO (Dani): To be implemented.
  showOwnProgress: boolean;
  minWait: number; // TODO (Dani): To be implemented.
  activitiesNeeded: number; // TODO (Dani): Use progress % instead (or additionally, if float).
  pathsNeeded: number; // TODO (Dani): Update this to account for positive/negative streaks.
}

*/

export type PushPathPriority = BlockingPushPathPriority | AnywherePushPathPriority | InPathPushPathPriority

export interface DBPushPath extends Omit<DBPath, 'topics' | 'toc' | 'content'> {
  priority: PushPathPriority;
}

export interface BackendPushPath extends Omit<BackendPath, 'toc' | 'content'> {
  priority: PushPathPriority;
}

export interface PushPath extends Omit<Path, 'toc' | 'content'> {
  priority: PushPathPriority;
}

export function isBlockingPushPathPriority(pushPathPriority: PushPathPriority): pushPathPriority is BlockingPushPathPriority {
  return pushPathPriority.type === PushPathType.Blocking
}

export function isAnywherePushPathPriority(pushPathPriority: PushPathPriority): pushPathPriority is AnywherePushPathPriority {
  return pushPathPriority.type === PushPathType.Anywhere
}

export function isInPathPushPathPriority(pushPathPriority: PushPathPriority): pushPathPriority is InPathPushPathPriority {
  return pushPathPriority.type === PushPathType.InPath
}

export function isPushPath(path: Path | PushPath): path is PushPath {
  return path.hasOwnProperty('priority')
}
