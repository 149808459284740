import { AvatarSettingsService, NFMeSettings } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AvatarControllerStore } from '../avatar-controller.store'

export class AvatarSettingsControllerStore {
  avatarControllerStore: AvatarControllerStore

  settings: NFMeSettings = AvatarSettingsService.loadSettings()

  constructor(avatarControllerStore: AvatarControllerStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.avatarControllerStore = avatarControllerStore
  }

  updateSettings(settings: NFMeSettings) {
    this.settings = settings

    AvatarSettingsService.saveSettings(settings)
  }
}
