import { TraitValues, TraitTypeId, ColorKey, EditorLabel, UserAnalyticsService, to } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AvatarControllerStore } from '../avatar-controller.store'

interface AvatarControllerTraitChange {
  changeType: 'trait';
  traitTypeId: TraitTypeId;
  traitId: string;
}

interface AvatarControllerColorChange {
  changeType: 'color';
  traitTypeId: TraitTypeId;
  traitColorKey: ColorKey | null;
  traitColor: string | null;
}

interface AvatarControllerRandomChange {
  changeType: 'random';
  traitTypeId: TraitTypeId;
  traitValues: TraitValues;
}

type AvatarControllerChange = AvatarControllerTraitChange
| AvatarControllerColorChange
| AvatarControllerRandomChange

type ChangeType = AvatarControllerChange['changeType']

export class AvatarHistoryControllerStore {
  static HISTORY_SIZE_LIMIT = 128

  history: AvatarControllerChange[] = []

  historyIndex = 0

  avatarControllerStore: AvatarControllerStore

  constructor(avatarControllerStore: AvatarControllerStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.avatarControllerStore = avatarControllerStore
  }

  // Analytics:

  static log(label: EditorLabel, eventValue?: null | string) {
    UserAnalyticsService.pushEvent({
      name: 'NFMe Editor',
      label,
      eventValue,
    })
  }

  clear() {
    this.history = []
    this.historyIndex = 0
  }

  // Undo / Redo:

  private getCurrentStateAsChange(changeType: ChangeType): AvatarControllerChange {
    const { avatarControllerStore } = this

    switch (changeType) {
      case 'trait': {
        return to<AvatarControllerTraitChange>({
          changeType: 'trait',
          traitTypeId: avatarControllerStore.selectedCategory,
          traitId: avatarControllerStore.nfmeSelectedItemID,
        })
      }

      case 'color': {
        return to<AvatarControllerColorChange>({
          changeType: 'color',
          traitTypeId: avatarControllerStore.selectedCategory,
          traitColorKey: avatarControllerStore.selectedColorSlot,
          traitColor: avatarControllerStore.nfmeSelectedItemColor,
        })
      }

      case 'random': {
        const traitValues = avatarControllerStore.nfme?.traitValues

        if (!traitValues) throw new Error('Can\'t find `traitValues`.')

        return to<AvatarControllerRandomChange>({
          changeType: 'random',
          traitTypeId: avatarControllerStore.selectedCategory,
          traitValues,
        })
      }

      default: {
        throw new Error(`Incorrect \`changeType\` value (${changeType}).`)
      }
    }
  }

  pushChange(changeType: ChangeType) {
    // We store what has changed (what was there before, not the current state);
    const change = this.getCurrentStateAsChange(changeType)

    if (!change) return

    // If the cursor is not at the end (we have used Undo) and we make a new change manually,
    // all changes after the cursor are discarded:
    this.history.splice(this.historyIndex)

    // The new change is stored
    this.history.push(change)

    // Limit the size of the array:
    this.history.splice(0, this.history.length - AvatarHistoryControllerStore.HISTORY_SIZE_LIMIT)

    // Every time the user manually changes something, the cursor moves at the end (so undo is possible, but not redo):
    this.historyIndex = this.history.length
  }

  private makeTabsChange(change: AvatarControllerChange) {
    const { avatarControllerStore } = this
    const { changeType } = change

    avatarControllerStore.selectCategory(change.traitTypeId)

    if (changeType === 'color') avatarControllerStore.selectColorSlot(change.traitColorKey)
  }

  private makeStateChange(change: AvatarControllerChange) {
    const { avatarControllerStore } = this
    const { changeType } = change

    if (changeType === 'color') {
      avatarControllerStore.updateNFMeItemAndColor(avatarControllerStore.nfmeSelectedItemID, change.traitColor || null)
    } else if (changeType === 'trait') {
      avatarControllerStore.updateNFMeItemAndColor(change.traitId, '')
    } else if (changeType === 'random') {
      avatarControllerStore.updateNFMe({ traitValues: change.traitValues })
    }
  }

  get canUndo() {
    return this.historyIndex > 0
  }

  get canRedo() {
    return this.historyIndex < this.history.length
  }

  undo() {
    if (!this.canUndo) return

    AvatarHistoryControllerStore.log('Undo')

    --this.historyIndex

    // We get the previous state we want to apply from here (to undo the last change, replacing the current state):
    const changeToUndo = this.history[this.historyIndex]

    this.makeTabsChange(changeToUndo)

    // Before undoing, we want to keep the current state that we are about to change (to allow users to redo it):
    const redoChange = this.getCurrentStateAsChange(changeToUndo.changeType)

    // Undo the change / apply previous state:
    this.makeStateChange(changeToUndo)

    // Replace the change we have just undone (the state we have just applied) with the previous state so that we can redo it:
    this.history[this.historyIndex] = redoChange
  }

  redo() {
    if (!this.canRedo) return

    AvatarHistoryControllerStore.log('Redo')

    // We get the previous state we want to re-apply from here (to redo the last change, replacing the current state):
    const changeToRedo = this.history[this.historyIndex]

    this.makeTabsChange(changeToRedo)

    // Before redoing, we want to keep the current state that we are about to change (to allow users to undo it again):
    const undoChange = this.getCurrentStateAsChange(changeToRedo.changeType)

    // Redo the change / apply previous state:
    this.makeStateChange(changeToRedo)

    // Replace the change we have just redone (the state we have just applied) with the previous state so that we can undo it:
    this.history[this.historyIndex] = undoChange

    ++this.historyIndex
  }
}
