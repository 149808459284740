import { RestRequest, ResponseComposition, RestContext } from 'msw'

import { COLOR_PALETTES } from './palettes.mocks'

export function fetchColorPalettesHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  return res(
    ctx.status(200),
    ctx.json(COLOR_PALETTES),
  )
}
