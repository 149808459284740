export enum NotificationType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface StoreNotification {
  id?: string;
  type?: NotificationType;
  message: string;
  duration?: number;
}
