import { useLocation } from 'react-router-dom'

import { CommonBackgroundConfig, CommonBackgroundProps } from '../../../libs/ui/components/background/base-background.types'

import { AppStarsBackground } from './stars-background/stars-background.component'

export type AppBackgroundConfig = CommonBackgroundConfig

export type AppBackgroundProps = CommonBackgroundProps & AppBackgroundConfig

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AppBackground(props: AppBackgroundProps) {
  const { pathname } = useLocation()

  return pathname === '/team' ? <AppStarsBackground { ...props } /> : null
}
