import { worker, clearMocks, exportMocks, importMocks, restoreMocks, clearDevLog, disableDevLog, enableDevLog, monkeyPatchConsole, getAppEnvironment, getVersionLog } from '@ng-mono/sdk'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './App'
import { reactRootElement } from './libs/ui/components/app-config/app-config.constants'

// See https://github.com/vitejs/vite/issues/2618:
if (window && !window.global) window.global = window

// Make console.log work with Proxies (the function already checks if we are in the development ENV):
monkeyPatchConsole()

// As we are only implementing a demo right now, this will be there in both
// development and production:

worker.start({
  quiet: getAppEnvironment() === 'production',
  onUnhandledRequest: 'bypass',
})

// Load mock data:
restoreMocks()

// Init viewport dimensions:
if (visualViewport) {
  const updateViewportDimensions = () => {
    document.documentElement.style.setProperty('--viewportHeight', `${window.innerHeight}px`)
  }

  updateViewportDimensions()

  visualViewport.addEventListener('resize', updateViewportDimensions)
}

// Init the App:
if (reactRootElement) {
  const root = createRoot(reactRootElement)

  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  )

  const loadingProgress = document.getElementById('loading-progress')

  if (loadingProgress) {
    loadingProgress.remove()
  }
}

// Log the current build version:
window.v = getVersionLog

// Dev logs:
window.enableDevLog = enableDevLog
window.disableDevLog = disableDevLog
window.clearDevLog = clearDevLog

if (process.env.NODE_ENV !== 'production') {
  // Mock handling:
  window.exportMocks = exportMocks
  window.importMocks = importMocks
  window.clearMocks = clearMocks
}
