import Axios from 'axios'
import * as rax from 'retry-axios'

import { sleep } from '../utils/common/sleep/sleep.utils'
import { devLog, DevLogGroup } from '../utils/logs/logs.utils'
import { BASE_URL } from '../utils/server/endpoints/server-endpoints.constants'

const AUTHORIZATION_HEADER = 'Authorization'

// Axios client:

export const axios = Axios.create({
  baseURL: BASE_URL,
  headers: {
    common: {
      // 'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  },
})

// Auth:

export function setAxiosHeader(key: string, value: string | null) {
  const commonHeaders = axios.defaults.headers.common

  if (value) commonHeaders.Authorization = value
  else delete commonHeaders[key]
}

export function setAxiosBearer(token: string | null) {
  setAxiosHeader(AUTHORIZATION_HEADER, token)
}

// Auto-retry:

axios.defaults.raxConfig = {
  instance: axios,
  onRetryAttempt: process.env.NODE_ENV === 'development' ? (err) => {
    const cfg = rax.getConfig(err)

    // eslint-disable-next-line no-console
    console.log(`Retry attempt #${cfg?.currentRetryAttempt}`)
  } : undefined,
}

if (process.env.NODE_ENV === 'production') {
  rax.attach(axios)
}

// Fake delay:

if (process.env.NODE_ENV !== 'production') {
  const fakeNetworkDelays = {
    None: [0, 0],
    Fast: [250, 250],
    Inconsistent: [1000, 5000],
    Slow: [2500, 2500],
    SuperSlow: [5000, 5000],
  }

  const envFakeNetworkDelayKey = (process.env.GH_FAKE_NETWORK_DELAY || '') as keyof typeof fakeNetworkDelays
  const envFakeNetworkDelayConfig = fakeNetworkDelays[envFakeNetworkDelayKey]
  const fakeNetworkDelayConfig = envFakeNetworkDelayConfig || fakeNetworkDelays.None
  const [baseDelay, variableDelay] = fakeNetworkDelayConfig

  devLog(DevLogGroup.Init, `🐢 Fake Network Delay = ${envFakeNetworkDelayKey || 'Inconsistent'} = ${baseDelay} ms + [0, ${variableDelay}) ms`)

  if (baseDelay > 0 || variableDelay > 0) {
    axios.interceptors.response.use(async (response) => {
      await sleep(baseDelay + Math.random() * variableDelay)

      return response
    })
  }
}
