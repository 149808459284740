export const BASE_URL = `${process.env.SST_REST_API_URL || 'https://rest.preview.nfmegalaxy.com/v1/'}`.slice(0, -3)

const DEFAULT_API_VERSION = 'v1'

const CELEBRITIES_CAMPAIGN_FOLDER = '/db/celebrities'
const NFME_OF_THE_WEEK_CAMPAIGN_FOLDER = '/db/nfme-of-the-week'
const A_FRESH_START_CAMPAIGN_FOLDER = '/db/a-fresh-start'

function getClientEndpoint(path: string) {
  return `/${DEFAULT_API_VERSION}${path}`
}

export function getMockEndpoint(clientEndpoint: string) {
  return `${BASE_URL.slice(0, -1)}${clientEndpoint}`.replace(/\?.*/, '')
}

export interface PaginatedResourceURLParams {
  page: number;
}

export interface FetchNextPathURLParams {
  topicID: string;
}

export interface UpdatePathAnswersURLParams {
  pathID: string;
}

export interface UpdatePushPathAnswersURLParams {
  pushPathID: string;
}

export interface FetchAvatarOutfitURLParams {
  outfitID: string;
}

export interface FetchCelebrityCampaignFlowURLParams {
  celebritySlug: string;
}

export interface FetchCelebrityCampaignAvatarURLParams extends FetchCelebrityCampaignFlowURLParams {
  celebrityID: string;
}

export const endpoints = {
  // User:
  fetchUser: getClientEndpoint('/user'),
  updateUser: getClientEndpoint('/user'),

  // UserItems by User:
  fetchUserItemsByUser: getClientEndpoint('/user-items-by-user'),

  // UserItems by Item:
  fetchUserItemsByItem: getClientEndpoint('/user-items-by-item'),

  // UserItems by ItemTypeId:
  fetchUserItemsByItemTypeId: getClientEndpoint('/user-items-by-item-type-id'),

  // Item:
  fetchItem: getClientEndpoint('/item'),
  updateItem: getClientEndpoint('/item'),

  // Item:
  fetchItemByTypeId: getClientEndpoint('/item'),

  // Color Palettes:
  fetchColorPalettes: getClientEndpoint('/color-palettes'),

  // CreatorRendererConfig:
  fetchCreatorRendererConfig: getClientEndpoint('/creator-renderer-config'),

  // UserCreatorConfig (not used currently):
  // fetchUserCreatorConfig: getClientEndpoint('/user-creator-config'),
  // updateUserCreatorConfig: getClientEndpoint('/user-creator-config'),

  // NFMes:
  fetchNFMes: getClientEndpoint('/nfmes'),
  updateNFMe: getClientEndpoint('/nfme'),

  // NFMeItems:
  updateNFMeItem: getClientEndpoint('/nfme-item'),

  // Topics:
  fetchAllTopics: getClientEndpoint('/topics'),

  // Paths:
  fetchNextPath: ({ topicID }: FetchNextPathURLParams) => getClientEndpoint(`/paths/next/?topicID=${topicID}`),
  updatePathAnswers: ({ pathID }: UpdatePathAnswersURLParams) => getClientEndpoint(`/paths/${pathID}`),

  // Paths Answered:
  fetchAnsweredPaths: ({ page }: PaginatedResourceURLParams) => getClientEndpoint(`/paths/?answered&page=${page}`),

  // PushPaths:
  fetchPushPaths: getClientEndpoint('/push-paths'),
  updatePushPathAnswers: ({ pushPathID }: UpdatePushPathAnswersURLParams) => getClientEndpoint(`/push-paths/${pushPathID}`),

  // Rewards:
  fetchRewards: getClientEndpoint('/rewards'),

  // Achievements
  fetchAchievements: getClientEndpoint('/achievements'),

  // Traits
  fetchTraits: getClientEndpoint('/traits'),

  // Avatar Outfits & Config:
  fetchAvatarOutfitOptions: getClientEndpoint('/avatar-outfits/options'),
  fetchAvatarOutfit: ({ outfitID }: FetchAvatarOutfitURLParams) => getClientEndpoint(`/avatar-outfits/${outfitID}`),
  createAvatarOutfit: getClientEndpoint('/avatar-outfits/'),
  updateAvatarOutfit: ({ outfitID }: FetchAvatarOutfitURLParams) => getClientEndpoint(`/avatar-outfits/${outfitID}`),
  deleteAvatarOutfit: ({ outfitID }: FetchAvatarOutfitURLParams) => getClientEndpoint(`/avatar-outfits/${outfitID}`),

  // User Analytics:
  postUserAnalyticsEvent: getClientEndpoint('/user-a'),

  // Campaigns (Celebrities):
  fetchCelebrityCampaignFlow: ({ celebritySlug }: FetchCelebrityCampaignFlowURLParams) => `${CELEBRITIES_CAMPAIGN_FOLDER}/${celebritySlug}/${celebritySlug}-flow.json`,
  fetchCelebrityCampaignAvatar: ({
    celebritySlug,
    celebrityID,
  }: FetchCelebrityCampaignAvatarURLParams) => `${CELEBRITIES_CAMPAIGN_FOLDER}/${celebritySlug}/${celebritySlug}-${celebrityID}.json`,

  // Campaigns (NFMe of the week):
  fetchNFMeOfTheWeekCampaignFlow: `${NFME_OF_THE_WEEK_CAMPAIGN_FOLDER}/nfme-of-the-week-flow.json`,
  fetchNFMeOfTheWeekCampaignAvatar: `${NFME_OF_THE_WEEK_CAMPAIGN_FOLDER}/nfme-of-the-week-base-avatar.json`,

  // Campaigns (A fresh start):
  fetchAFreshStartCampaignFlow: `${A_FRESH_START_CAMPAIGN_FOLDER}/a-fresh-start-flow.json`,
  fetchAFreshStartCampaignAvatar: `${A_FRESH_START_CAMPAIGN_FOLDER}/a-fresh-start-base-avatar.json`,
}
