import { NONE_TRAIT_ID } from '../creator/renderer-config/creator-renderer-config.constants'
import { TraitTypeId } from '../creator/renderer-config/creator-renderer-config.types'
import { UnlockedTraits } from '../traits/traits.types'

import { TraitValues, BackendTraitValues } from './avatar.types'

export function traitValuesToBackendTraitValues(traitValues: TraitValues) {
  return Object.fromEntries(
    Object
      .entries(traitValues)
      .map(([traitTypeID, avatarTraitValue]) => [traitTypeID, {
        traitId: avatarTraitValue.trait?.id || NONE_TRAIT_ID,
        traitColors: avatarTraitValue.traitColors,
      }]),
  ) as BackendTraitValues
}

export function backendTraitValuesToTraitValues(backendTraitValues: BackendTraitValues, traits: UnlockedTraits) {
  return Object.fromEntries(
    Object
      .entries(backendTraitValues)
      .map(([traitTypeID, backendAvatarTraitValue]) => {
        const { traitId } = backendAvatarTraitValue

        return [traitTypeID, {
          trait: (traits[traitTypeID as TraitTypeId] || []).find((trait) => trait.id === traitId) || NONE_TRAIT_ID,
          traitColors: backendAvatarTraitValue.traitColors,
        }]
      }),
  ) as TraitValues
}

export function sanitizeBackgroundTraitValue(traitValues: TraitValues) {
  return traitValues.background.traitColors[0] === '#FFFFFF00' ? {
    ...traitValues,

    // Background:

    background: {
      trait: traitValues.background.trait,
      traitColors: {
        ...traitValues.background.traitColors,
        0: '#FFFFFF',
      },
    },
  } : traitValues
}

export function isAvatarTraitValues(traitValues: BackendTraitValues | TraitValues): traitValues is TraitValues {
  return Object.values(traitValues).every((traitValue) => traitValue.hasOwnProperty('trait') && traitValue.hasOwnProperty('traitColors') && typeof traitValue.trait === 'object' && typeof traitValue.traitColors === 'object')
}

export function isAvatarBackendTraitValues(traitValues: BackendTraitValues | TraitValues): traitValues is BackendTraitValues {
  return Object.values(traitValues).every((traitValue) => traitValue.hasOwnProperty('traitId') && traitValue.hasOwnProperty('traitColors') && typeof traitValue.traitId === 'string' && typeof traitValue.traitColors === 'object')
}

export function validateAvatarTraitValues(traitValues: BackendTraitValues | TraitValues): boolean {
  return isAvatarTraitValues(traitValues) || isAvatarBackendTraitValues(traitValues)
}
