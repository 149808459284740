import Color from 'color'

import { SHADE_BASE_INDEX } from '../creator/renderer-config/creator-renderer-config.constants'
import { ColorShadeIndex } from '../traits/traits.types'

function getColorShade(format = 'rgb') {
  return (baseColorHEX: string, shade: ColorShadeIndex, baseIndex: ColorShadeIndex = SHADE_BASE_INDEX): string => {
    if (shade === baseIndex) return baseColorHEX

    // Implementation based on https://hihayk.github.io/scale/#6/6/100/100/0/0/0/0/1D9A6C/29/154/108/white
    // Source code: https://github.com/hihayk/scale/blob/69b766bba2db046d3e8cb4026ae32a32c897f9ff/src/components/gallery-app.js#L58

    const colorsAmount = baseIndex
    const colorsShiftAmount = 100
    const mixColor = shade < baseIndex ? 'black' : 'white'
    const rotate = 0
    const saturation = 0
    const step = shade < baseIndex ? (baseIndex - shade) : (shade - baseIndex)
    const stepPercent = step / colorsAmount

    const color = Color(baseColorHEX)
      .rotate(stepPercent * -rotate)
      .saturate(stepPercent * (saturation / 100))
      // Better alternative to .mix():
      // See the tables here in https://www.w3schools.com/colors/colors_picker.asp
      // .lightness()
      .mix(Color(mixColor), stepPercent * (colorsShiftAmount / 100))

    return format === 'hex' ? color.hex() : color.string()
  }
}

export const getRGBShade = getColorShade('rgb')

export const getHEXShade = getColorShade('hex')

export function isValidColorHEX(value: string) {
  return /^#(\d{3}|\d{6})$/.test(value)
}

export function hexToRgb(rawHex: string): [number, number, number] {
  const hex = rawHex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => `${r}${r}${g}${g}${b}${b}`)

  const parts = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)

  return parts ? [
    parseInt(parts[1], 16),
    parseInt(parts[2], 16),
    parseInt(parts[3], 16),
  ] : [255, 255, 255]
}
