import { useAuth, canUserSeeAs, usePushPaths, deepMerge, UserAnalyticsService, devLog, DevLogGroup, getAppEnvironment, SyntheticRoutePaths, getPageTitle } from '@ng-mono/sdk'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom'

import { AppFooterConfig } from '../components/app/footer/app-footer.component'
import { AppLayout, AppLayoutConfig } from '../components/app/layout/app-layout.component'
import { AppNavbarConfig } from '../components/app/navbar/app-navbar.component'
import { PageMotion } from '../components/PageMotion/PageMotion'
import { CommonBackgroundConfig } from '../libs/ui/components/background/base-background.types'
import { useHasHoverClassName } from '../libs/ui/hooks/hover/hover.hook'
import { watchForDuplicateIDsOnThePage } from '../utils/debug/debug.utils'

import { RouteConfig } from './routes/routes.types'
import { deepMergeRouteProps, matchRoutes } from './routes/routes.utils'

export interface AppRoutesProps {
  routes: RouteConfig[];
}

function AppRoutesComponent({ routes }: AppRoutesProps) {
  if (process.env.NODE_ENV === 'development' && routes.find((routeConfig) => routeConfig.path === '*')) {
    throw new Error('Do not add path = `*` in `config`. Instead, use the `notFoundPage` prop in `AppRoutes`.')
  }

  // Load authentication, user and push paths:

  const { isAuthLoading, isAuthenticated, userError, user, userRole, realUserRole } = useAuth()

  const { updateNextPushPath, pushPath: storePushPath } = usePushPaths({
    cache: false,
    auto: true,
    poll: 30000,
    skip: !isAuthenticated,
  })

  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location

  // We need to get the next PushPath, if any, before routing occurs. Therefore, we cannot simply call
  // `updateNextPushPath('routing')` or `updateStats('routing')` because in that case the Route will be rendered first,
  // then the PushPathsStore's `pushPath` will be updated and trigger an additional re-render (so the Route that
  // actually has to wait for the PushPath to be completed will flash briefly):

  // TODO (Dani): Alternatively, this `updateNextPushPath('routing')` could be made by intercepting history.push/replace. If we can
  // let the PushPathsStore know when navigation occurs (so that it recalculates if it needs to show a PushPath) before
  // this function runs, so that the selected pushPath is included in the `state` param, this might become simpler:

  const pushPath = useMemo(() => {
    if (!pathname || storePushPath) return storePushPath

    return updateNextPushPath('routing')
  }, [pathname, storePushPath, updateNextPushPath])

  // Match route, including for synthetic routes:

  const routeMatches = matchRoutes(routes, location, {
    isAuthLoading,
    isAuthenticated,
    userRole,
    realUserRole,
    userError,
    emailVerified: !!user?.meta?.emailVerified,
    profileCompleted: !!user?.meta?.profileCompleted,
    pushPath,
  })

  const requestedPathname = pathname
  const mostSpecificMatchIndex = routeMatches.length - 1
  const matchedPathname = routeMatches[mostSpecificMatchIndex].pathname
  const matchedRoute = routeMatches[mostSpecificMatchIndex].route
  const matchedTitle = routeMatches.slice(0).reverse().map(({ route: { title } }) => title).join(' - ')
  const matchedAnalyticsLabel = matchedRoute.analyticsLabel || matchedTitle
  const matchedRoutePath = matchedRoute.path
  const isMatchedRouteRedirect = matchedRoute.isRedirect

  useEffect(() => {
    if (isMatchedRouteRedirect && matchedPathname !== requestedPathname) {
      devLog(DevLogGroup.Routing, `Router > Current pathname is ${requestedPathname} but guard redirects to ${matchedPathname}`)

      navigate(matchedPathname, { replace: true })
    } else {
      devLog(DevLogGroup.Routing, `Router > ${isMatchedRouteRedirect ? 'Redirected to' : 'Reached'} ${matchedPathname}${matchedRoutePath === '*' ? ' (*)' : ''}`)

      if (getAppEnvironment() !== 'production') watchForDuplicateIDsOnThePage()

      if (matchedPathname !== SyntheticRoutePaths.AuthLoading) {
        UserAnalyticsService.pushEvent({
          name: 'Nav',
          label: matchedAnalyticsLabel,
          pageVisitTitle: matchedTitle,
          pageVisitURL: matchedPathname,
        })
      }
    }
  }, [
    requestedPathname,
    matchedPathname,
    matchedTitle,
    matchedAnalyticsLabel,
    matchedRoutePath,
    isMatchedRouteRedirect,
    navigate,
  ])

  useEffect(() => {
    document.title = getPageTitle(matchedTitle)
  }, [matchedTitle])

  useHasHoverClassName()

  // TODO (Dani): Extract this logic and / or move it to `matchRoutes`:

  let mergedLayout: undefined | AppLayoutConfig
  let mergedNav: undefined | boolean | AppNavbarConfig
  let mergedFooter: undefined | boolean | AppFooterConfig
  let mergedBackground: undefined | boolean | CommonBackgroundConfig

  const routeObjects: RouteObject[] = []

  let routeObjectsChildrenCursor = routeObjects

  routeMatches.forEach(({
    pathname: routeMatchPathname,
    route: {
      path,
      page: MatchedPage,
      layout,
      nav,
      footer,
      background,
    },
  }) => {
    const matchedPageElement = (
      <PageMotion key={ routeMatchPathname }>
        <MatchedPage />
      </PageMotion>
    )

    mergedLayout = deepMergeRouteProps(mergedLayout, layout)
    mergedNav = deepMergeRouteProps(mergedNav, nav)
    mergedFooter = deepMergeRouteProps(mergedFooter, footer)
    mergedBackground = deepMerge(mergedBackground, background)

    const matchedRouteObject = {
      path,
      element: matchedPageElement,
      children: [],
    }

    routeObjectsChildrenCursor.push(matchedRouteObject)

    routeObjectsChildrenCursor = matchedRouteObject.children
  })

  // Default values:
  mergedNav ??= true
  mergedFooter ??= false
  mergedBackground ??= true

  // We already computed `routeMatches` but we still need to pass them (as `RouteObject`) to `react-router` for it to
  // handle params. Using `useRoutes()` is the same as using `<Routes>` + `<Route>` (as that's what they internally use):
  const routeElements = useRoutes(routeObjects)

  const content = (
    <AnimatePresence mode="wait">
      { routeElements }
    </AnimatePresence>
  )

  return (
    <AppLayout
      { ...mergedLayout }
      contentSx={ canUserSeeAs(userRole, realUserRole, matchedRoute.availableTo).visibilitySx }
      navConfig={ mergedNav }
      footerConfig={ mergedFooter }
      backgroundConfig={ mergedBackground }
      content={ content } />
  )
}

export const AppRoutes = observer(AppRoutesComponent)
