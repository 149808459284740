import { ResponseComposition, RestContext, RestRequest } from 'msw'

export function isAuthMissing(req: RestRequest) {
  return !req.headers.get('Authorization')
}

export function respond400(res: ResponseComposition, ctx: RestContext) {
  return res(
    ctx.status(400),
    ctx.json({
      errorMessage: 'Bad request',
    }),
  )
}

export function respond401(res: ResponseComposition, ctx: RestContext) {
  return res(
    ctx.status(401),
    ctx.json({
      errorMessage: 'Unauthorized',
    }),
  )
}

export function respond403(res: ResponseComposition, ctx: RestContext) {
  return res(
    ctx.status(403),
    ctx.json({
      errorMessage: 'Forbidden',
    }),
  )
}

export function respond404(res: ResponseComposition, ctx: RestContext) {
  return res(
    ctx.status(404),
    ctx.json({
      errorMessage: 'Not found',
    }),
  )
}

export function getSinglePathParam(req: RestRequest, key: string) {
  const param = req.params[key]

  return typeof param === 'string' ? param : param[0]
}

export function getMultiPathParam(req: RestRequest, key: string) {
  const params = req.params[key]

  return typeof params === 'string' ? [params] : params
}

export function bypassHandler(req: RestRequest) {
  return req.passthrough()
}
