import { RoutePaths } from '@ng-mono/sdk'

import { DEMO_MODE_ENABLED } from '../../pages/demo/demo.constants'
import { asyncComponentLoader } from '../../utils/loader/loader'

import { getCampaignStepsRoutes } from './campaign/campaign-routes.config'
import { RouteAuth, RouteConfig } from './routes.types'

// GitHub Pages open demo:
const GitHubPagesHomePage = asyncComponentLoader(() => import('../../pages/github-pages-home/github-pages-home.page'))
const UnavailableForDemoPage = asyncComponentLoader(() => import('../../pages/demo/UnavailableForDemo/UnavailableForDemo.page'))

// Activities:
const HomePage = asyncComponentLoader(() => import('../../pages/home/home.page'))

// Campaigns:
const CelebritiesPage = asyncComponentLoader(() => import('../../pages/campaigns/celebrities/celebrities.page'))
const NFMeOfTheWeekPage = asyncComponentLoader(() => import('../../pages/campaigns/nfme-of-the-week/nfme-of-the-week.page'))
const AFreshStartPage = asyncComponentLoader(() => import('../../pages/campaigns/a-fresh-start/a-fresh-start.page'))

// User-Owned Stuff:
const NFMeCreatorPage = asyncComponentLoader(() => import('../../pages/nfme-creator/nfme-creator.page'))
const MyProgressPage = asyncComponentLoader(() => import('../../pages/my-progress/my-progress.page'))
// const MyNFMesPage = asyncComponentLoader(() => import('../../pages/collections/my-nfmes/my-nfmes.page'))
const AccountPage = asyncComponentLoader(() => import('../../pages/account/account.page'))

// Auth:
const ChangeEmailPage = asyncComponentLoader(() => import('../../pages/auth/ChangeEmail/ChangeEmail.page'))
const ChangePasswordPage = asyncComponentLoader(() => import('../../pages/auth/ChangePassword/ChangePassword.page'))
const ForgotPasswordPage = asyncComponentLoader(() => import('../../pages/auth/ForgotPassword/ForgotPassword.page'))
const LoginPage = asyncComponentLoader(() => import('../../pages/auth/Login/Login.page'))
const OnBoardingPage = asyncComponentLoader(() => import('../../pages/auth/OnBoarding/OnBoarding.page'))
const SignUpPage = asyncComponentLoader(() => import('../../pages/auth/SignUp/SignUp.page'))
const AccountConfirmationPage = asyncComponentLoader(() => import('../../pages/auth/AccountConfirmation/AccountConfirmation.page'))
const EmailVerificationPage = asyncComponentLoader(() => import('../../pages/auth/EmailVerification/EmailVerification.page'))

// Dev:
const ErrorTestsPage = asyncComponentLoader(() => import('../../pages/dev/ErrorTests/ErrorTests.page'))
const OgGeneratorPage = asyncComponentLoader(() => import('../../pages/dev/og-generator/og-generator.page'))

const ROUTES: RouteConfig[] = [{
  // Topics, Paths & Activities:

  title: 'Home',
  path: DEMO_MODE_ENABLED ? RoutePaths.Team : RoutePaths.Home,
  auth: DEMO_MODE_ENABLED ? RouteAuth.None : RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? GitHubPagesHomePage : HomePage,
}, {
  // User-Owned Stuff:

  title: 'NFMe Creator',
  path: DEMO_MODE_ENABLED ? RoutePaths.Home : RoutePaths.NFMeCreator,
  auth: RouteAuth.None,
  // auth: RouteAuth.Required,
  // availableTo: ['partner', 'admin'],
  page: NFMeCreatorPage,
  nav: { variant: 'compact', content: 'toolbar', logo: false },
  layout: { variant: 'noScroll' },
}, {
  title: 'My Progress',
  path: RoutePaths.MyProgress,
  auth: RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : MyProgressPage,
// }, {
//   title: 'My NFMes',
//   path: RoutePaths.MyNFMes,
//   auth: RouteAuth.Required,
//   availableTo: ['admin'],
//   page: MyNFMesPage,
}, {
  title: 'Account',
  path: RoutePaths.Account,
  auth: RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : AccountPage,
}, {
  // Auth:

  title: 'Change Email',
  path: RoutePaths.ChangeEmail,
  auth: RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : ChangeEmailPage,
}, {
  title: 'Change Password',
  path: RoutePaths.ChangePassword,
  auth: RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : ChangePasswordPage,
}, {
  title: 'Forgot Password',
  path: RoutePaths.ForgotPassword,
  auth: RouteAuth.Anonymous,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : ForgotPasswordPage,
  nav: { action: 'logIn' },
}, {
  title: 'Sign In',
  path: RoutePaths.Login,
  auth: RouteAuth.Anonymous,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : LoginPage,
  nav: { action: 'signUp' },
}, {
  title: 'Onboarding',
  path: RoutePaths.Onboarding,
  auth: RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : OnBoardingPage,
  nav: { action: 'logOut' },
}, {
  title: 'Sign Up',
  path: RoutePaths.SignUp,
  auth: RouteAuth.Anonymous,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : SignUpPage,
  nav: { action: 'logIn' },
}, {
  title: 'Account Confirmation',
  path: RoutePaths.AccountConfirmation,
  auth: RouteAuth.Anonymous,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : AccountConfirmationPage,
  nav: { action: 'logOut' },
}, {
  title: 'Email Verification',
  path: RoutePaths.EmailVerification,
  auth: RouteAuth.Required,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : EmailVerificationPage,
  nav: { action: 'logOut' },
}, {
  // Campaigns:

  title: 'Celebrities',
  path: RoutePaths.Celebrities(),
  auth: RouteAuth.None,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : CelebritiesPage,
  nav: { action: 'none' },
  children: getCampaignStepsRoutes(RoutePaths.Celebrities),
}, {
  title: 'NFMe of the Week',
  path: RoutePaths.NFMeOfTheWeek(),
  auth: RouteAuth.None,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : NFMeOfTheWeekPage,
  nav: { action: 'none' },
  children: getCampaignStepsRoutes(RoutePaths.NFMeOfTheWeek),
}, {
  title: 'A Fresh Start',
  path: RoutePaths.AFreshStart(),
  auth: RouteAuth.None,
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : AFreshStartPage,
  nav: { action: 'none', content: 'toolbar' },
  children: getCampaignStepsRoutes(RoutePaths.AFreshStart),
}, {
  // Development:

  title: 'Dev › OG Generator',
  path: RoutePaths.OgGenerator,
  auth: RouteAuth.Required,
  availableTo: ['admin'],
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : OgGeneratorPage,
  layout: { variant: 'noScroll' },
}, {
  title: 'Dev › Error Tests',
  path: RoutePaths.ErrorTests,
  auth: RouteAuth.None,
  availableTo: ['admin'],
  page: DEMO_MODE_ENABLED ? UnavailableForDemoPage : ErrorTestsPage,
}]

export const ACTIVE_ROUTES = ROUTES.filter((route) => !route.disabled)
