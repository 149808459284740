import { AppEnvironment } from '../../domains/user-analytics/mixpanel/user-analytics-mixpanel.types'
import { toFormattedDateTime } from '../datetime/datetime.utils'

export function getBuildDate() {
  return toFormattedDateTime(process.env.BUILD_DATE) || undefined
}

export function getFullCommitHash() {
  return process.env.COMMIT_HASH_FULL
}

export function getShortCommitHash() {
  return process.env.COMMIT_HASH_SHORT
}

export function getCommitLink() {
  return `${process.env.REPO_URL}commit/${getFullCommitHash()}`
}

export function getVersionLabel() {
  return `${getBuildDate()} | ${getFullCommitHash()}`
}

export function getVersionLog() {
  return `${getVersionLabel()} | ${getCommitLink()}`
}

export function getAppVersion() {
  return getShortCommitHash()
}

export function getAppEnvironment(): AppEnvironment {
  const { hostname } = window.location

  if (hostname === 'localhost') return 'localhost'
  if (hostname.startsWith('app.preview')) return 'preview'

  return 'production'
}
