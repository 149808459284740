import { ColorKey, getHEXShade, hexToRgb, Palette, PaletteColorGroup, TraitTypeId, TraitValues } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AvatarControllerStore } from '../avatar-controller.store'

export class PalettesControllerStore {
  avatarControllerStore: AvatarControllerStore

  static getPaletteColorGroups(palette: Palette | null) {
    const { shadesConfig, colors = [] } = palette || {}

    if (!shadesConfig || colors.length === 0) return null

    return colors.map((color) => {
      const baseIndex = color === '#FFFFFF' ? shadesConfig.shadeOffsets[shadesConfig.shadeOffsets.length - 1] : color === '#000000' ? shadesConfig.shadeOffsets[0] : shadesConfig.shadeBaseIndex
      const shadesGroup = shadesConfig.shadeOffsets.map((shadeOffset) => getHEXShade(color, shadeOffset, baseIndex))

      const baseShadeIndex = color === '#FFFFFF' ? shadesGroup.length - 1 : color === '#000000' ? 0 : Math.floor(shadesGroup.length / 2)

      return {
        baseShadeIndex,
        shades: shadesGroup,
      }
    })
  }

  static flattenColorGroupsGroups(paletteColorGroups: PaletteColorGroup[] | null) {
    return paletteColorGroups?.flatMap((paletteColorGroup) => paletteColorGroup.shades) || null
  }

  static getColorDistance(rgbColorA: [number, number, number], rgbColorB: [number, number, number]) {
    return Math.sqrt((rgbColorA[0] - rgbColorB[0]) ** 2 + (rgbColorA[1] - rgbColorB[1]) ** 2 + (rgbColorA[2] - rgbColorB[2]) ** 2)
  }

  static getClosestColor(color: string, colors: string[]) {
    let minDistance = Infinity
    let closestColorIndex = 0

    const rgbColors = colors.map(hexToRgb)

    rgbColors.forEach((rgbColor, i) => {
      const distance = PalettesControllerStore.getColorDistance(hexToRgb(color), rgbColor)

      if (distance < minDistance) {
        minDistance = distance
        closestColorIndex = i
      }
    })

    return colors[closestColorIndex]
  }

  static migrateTraitColors(traitColors: Partial<Record<ColorKey, string>>, colors: string[]) {
    return Object.entries(traitColors).reduce((acc, [colorKey, color]) => {
      acc[colorKey as ColorKey] = PalettesControllerStore.getClosestColor(color, colors)

      return acc
    }, {} as Partial<Record<ColorKey, string>>)
  }

  constructor(avatarControllerStore: AvatarControllerStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.avatarControllerStore = avatarControllerStore
  }

  get selectedPaletteID() {
    return this.avatarControllerStore.nfme?.paletteID || this.avatarControllerStore.nfmeConfig?.defaultPaletteID || null
  }

  get palette() {
    return this.avatarControllerStore.sdkStore.palettesStore.colorPalettes?.find(({ id }) => id === this.selectedPaletteID) || null
  }

  get paletteLabel() {
    return this.palette?.label || null
  }

  get paletteColorGroups(): null | PaletteColorGroup[] {
    return PalettesControllerStore.getPaletteColorGroups(this.palette)
  }

  get paletteColors(): null | string[] {
    return PalettesControllerStore.flattenColorGroupsGroups(this.paletteColorGroups)
  }

  migrateColors(paletteID: string, traitValues: TraitValues) {
    const palette = this.avatarControllerStore.sdkStore.palettesStore.colorPalettes?.find(({ id }) => id === paletteID) || null

    const paletteColors = PalettesControllerStore.flattenColorGroupsGroups(
      PalettesControllerStore.getPaletteColorGroups(palette),
    )

    if (!paletteColors) return null

    return Object.entries(traitValues).reduce((acc, [categoryID, traitValue]) => {
      acc[categoryID as TraitTypeId] = {
        trait: traitValue.trait,
        traitColors: PalettesControllerStore.migrateTraitColors(traitValue.traitColors, paletteColors),
      }

      return acc
    }, {} as TraitValues)
  }

  getRandomColor() {
    const { paletteColors } = this

    if (!paletteColors) return '' // TODO: Use white as default / fallback?

    const randomIndex = Math.floor(Math.random() * paletteColors.length)

    return paletteColors[randomIndex]
  }
}
