export interface MutationError {
  errorID: string;
  errorDescription?: string;
  at?: string;
}

export enum MutationType {
  Create = 'Create', // POST
  Update = 'Update', // PATCH
  Delete = 'Delete', // DELETE
  Upsert = 'Upsert', // PUT
  Mixed = 'Mixed',
}

export type MutationResultSuccess<D = undefined> = {
  type: MutationType;
} & (D extends undefined ? {} : {
  data: D;
})

export interface MutationResultFailure {
  type: MutationType;
  error: MutationError;
  errors: MutationError[];
}

export type MutationResult<R = undefined> = MutationResultSuccess<R> | MutationResultFailure

export function isMutationResultFailure<R = undefined>(
  mutationResult?: null | MutationResult<R>,
): mutationResult is MutationResultFailure {
  return !!mutationResult && (mutationResult.hasOwnProperty('error') || mutationResult.hasOwnProperty('errors'))
}

export function isMutationResultSuccess<R = undefined>(
  mutationResult?: null | MutationResult<R>,
): mutationResult is MutationResultSuccess<R> {
  return !!mutationResult && !isMutationResultFailure(mutationResult)
}
