import { Chip, Divider, Stack } from '@mui/material'
import { canUserSeeAs } from '@ng-mono/sdk'

import { BaseMenuItemConfig, BaseMenuItemProps, MenuItemType } from '../base/menu-base-item.types'

export interface MenuDividerItemConfig extends BaseMenuItemConfig {
  type: MenuItemType.Divider;
  category: string;
}

export type MenuDividerItemProps = BaseMenuItemProps & Omit<MenuDividerItemConfig, 'type'>

export function MenuDividerItem({
  // BaseMenuItemProps:
  root = 'div',
  sx: sxProp,
  userRole = null,
  realUserRole = null,
  variant,
  // onAction,

  // BaseMenuItemConfig:
  title,
  availableTo,
  hidden,

  // MenuDividerItemProps:
  category,
}: MenuDividerItemProps) {
  const { isHidden, visibilitySx } = canUserSeeAs(userRole, realUserRole, availableTo, hidden)

  if (isHidden) return null

  const rootSx = visibilitySx ? { ...sxProp, ...visibilitySx as any } : sxProp

  return (
    <Stack
      component={ root }
      direction="row"
      sx={{
        alignItems: 'center',
        mx: ({ gaps }) => -gaps.outerPadding,
        my: variant === 'toolbar' ? 0 : ({ gaps }) => gaps.outerPadding,
        ...rootSx,
      }}>

      { category ? (
        <Chip
          label={ category }
          title={ title }
          variant="outlined"
          color="primary"
          size="small"
          sx={{
            position: 'relative',
            mr: ({ gaps }) => gaps.outerPadding,
            ml: ({ gaps }) => 2 * gaps.outerPadding,

            '&::before': {
              content: '""',
              position: 'absolute',
              top: '50%',
              right: '100%',
              width: ({ gaps, spacing }) => spacing(gaps.outerPadding),
              mr: ({ gaps }) => gaps.outerPadding,
              transform: 'translate(0, -50%)',
              borderBottom: ({ borders }) => borders.default(),
            },
          }} />
      ) : null }

      <Divider sx={{ width: '100%' }} />

    </Stack>
  )
}
