import { TraitTypeId } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AvatarControllerStore } from '../avatar-controller.store'

export class AvatarHighlighterControllerStore {
  highlightTimeoutID = 0

  lastUpdatedCategory: TraitTypeId | null = null

  avatarControllerStore: AvatarControllerStore

  constructor(avatarControllerStore: AvatarControllerStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.avatarControllerStore = avatarControllerStore
  }

  highlightLastUpdatedTraitTypeHold() {
    const { avatarSettings, selectedCategory } = this.avatarControllerStore
    const { itemSelectorHoldBehavior } = avatarSettings.settings

    if (itemSelectorHoldBehavior === 'no') return

    this.highlightTimeoutID = window.setTimeout(() => {
      this.lastUpdatedCategory = selectedCategory
    }, itemSelectorHoldBehavior === 'immediately' ? 0 : 300)
  }

  highlightLastUpdatedTraitTypeAbort() {
    window.clearTimeout(this.highlightTimeoutID)

    this.lastUpdatedCategory = null
  }

  highlightLastUpdatedTraitType() {
    const { avatarSettings, selectedCategory } = this.avatarControllerStore
    const { itemSelectorChangeBehavior } = avatarSettings.settings

    if (itemSelectorChangeBehavior === 'none') return

    const animationStepsCount = itemSelectorChangeBehavior === 'blink-once' ? 2 : 4

    this.lastUpdatedCategory = selectedCategory

    const animationSteps = [{
      lastUpdatedCategory: selectedCategory,
      duration: 300,
    }, {
      lastUpdatedCategory: null,
      duration: 300,
    }, {
      lastUpdatedCategory: selectedCategory,
      duration: 300,
    }, {
      lastUpdatedCategory: null,
      duration: 300,
    }].filter((_, i) => i < animationStepsCount)

    const animationTick = () => {
      window.clearTimeout(this.highlightTimeoutID)

      const nextStep = animationSteps.shift()

      if (nextStep) {
        this.lastUpdatedCategory = nextStep.lastUpdatedCategory

        window.setTimeout(animationTick, nextStep.duration)
      } else {
        window.clearInterval(this.highlightTimeoutID)
      }
    }

    animationTick()
  }
}
