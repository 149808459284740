import { to } from '../../utils/typescript/typescript.utils'

import { RewardTypes, TextCodeReward } from './rewards.types'
import { getRandomNFTTraitReward } from './rewards.utils'

export const REWARD_MOCKS_BY_ID = {

  // Blockchain Path 1:

  hairStacy: getRandomNFTTraitReward(),
  hairBuns: getRandomNFTTraitReward(),
  hairPonytail: getRandomNFTTraitReward(),
  hairFemaleBeach: getRandomNFTTraitReward(),
  hairLilo: getRandomNFTTraitReward(),
  hairNormie: getRandomNFTTraitReward(),
  hairAfro: getRandomNFTTraitReward(),
  hairProdigy: getRandomNFTTraitReward(),
  hairQuiff: getRandomNFTTraitReward(),
  hairRobert: getRandomNFTTraitReward(),
  hairShaved: getRandomNFTTraitReward(),
  hairSidepartLeft: getRandomNFTTraitReward(),
  hairSidepartRight: getRandomNFTTraitReward(),
  hairTerminator: getRandomNFTTraitReward(),

  clothingCardigan: getRandomNFTTraitReward(),
  clothingJacket: getRandomNFTTraitReward(),
  clothingShirtThreePick: getRandomNFTTraitReward(),
  clothingShirtTwoPick: getRandomNFTTraitReward(),
  clothingShirtVNeck: getRandomNFTTraitReward(),
  clothingStrapDress: getRandomNFTTraitReward(),
  clothingVestVNeck: getRandomNFTTraitReward(),

  // Clogs Topic:

  eyewearBigThickRoundGlasses: getRandomNFTTraitReward(),
  eyewearBigThickOvalCroppedTopBottomGlasses: getRandomNFTTraitReward(),
  eyewearMediumOvalCroppedTopGlasses: getRandomNFTTraitReward(),
  eyewearMediumThinOvalGlasses: getRandomNFTTraitReward(),

  faceHairChinLine: getRandomNFTTraitReward(),
  faceHairFiveOClockRound: getRandomNFTTraitReward(),
  faceHairGoatee: getRandomNFTTraitReward(),
  faceHairHalfFace: getRandomNFTTraitReward(),
  faceHairSoulStubble: getRandomNFTTraitReward(),
  faceHairStachJazzdotChinline: getRandomNFTTraitReward(),
  faceHairStachJazzdotSoulstubble: getRandomNFTTraitReward(),
  faceHairStachJazzdot: getRandomNFTTraitReward(),
  faceHairDutchBeardShort: getRandomNFTTraitReward(),

  milkaChocolateDiscount: to<TextCodeReward>({
    id: 'milkaChocolateDiscount',
    type: RewardTypes.TextCode,
    name: 'Milka Chocolate 10% OFF',
    code: 'WILLYMILKA',
  }),

  // Milka Path 2:

  tShirt: getRandomNFTTraitReward(),

  milkaShakeDiscount: to<TextCodeReward>({
    id: 'milkaShakeDiscount',
    type: RewardTypes.TextCode,
    name: 'Milka Chocolate 10% OFF',
    code: 'MILKAKE',
  }),

  // All Paths Achievement:

  empireSun: getRandomNFTTraitReward(),
}
