import { Box, Stack } from '@mui/material'
import { EventExtra, SocialEventExtra, getEventLabelWithCampaign } from '@ng-mono/sdk'

import { withHasActiveClassName, withHasHoverClassName } from '../../hooks/hover/hover.hook'
import { BaseButton } from '../buttons/base/base-button.component'

import { SOCIAL_MEDIA } from './social-media.constants'

interface SocialMediaProps {
  size?: 'sm' | 'md';
  fullWidth?: boolean;

  // Analytics:
  analyticsCampaignName?: string;
  analyticsBaseLabel?: string;
  analyticsExtra?: EventExtra;
}

export function SocialMedia({
  size = 'md',
  fullWidth,

  // Analytics:
  analyticsCampaignName,
  analyticsBaseLabel = 'Social Icon',
  analyticsExtra,
}: SocialMediaProps) {
  const iconSize = size === 'sm' ? 32 : 40

  return (
    <Stack
      component="ul"
      direction="row"
      justifyContent={ fullWidth ? 'space-between' : 'flex-start' }
      alignItems="center"
      sx={{ width: fullWidth ? '100%' : 'initial' }}>

      { SOCIAL_MEDIA.map(({ label, socialNetwork, iconSRC, link }) => {
        const analyticsLabel = getEventLabelWithCampaign(`${analyticsBaseLabel} ${label}`, analyticsCampaignName)

        const itemAnalyticsMeta = {
          ...analyticsExtra,
          socialNetwork,
        } satisfies EventExtra & SocialEventExtra

        return (
          <Box key={ label } component="li">

            <BaseButton
              href={ link }
              analyticsLabel={ analyticsLabel }
              analyticsExtra={ itemAnalyticsMeta }
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                transition: 'all 0.2s ease-in-out',
                userSelect: 'none',
                p: 0,

                [withHasHoverClassName('&:hover')]: {
                  transform: 'scale(1.05)',
                },

                [withHasActiveClassName('&:active')]: {
                  transform: 'scale(0.98)',
                },
              }}>

              { /* TODO (Dani): Replace with actual SVGs (rendered by react) */ }
              <Box
                component="img"
                src={ iconSRC }
                alt={ `${label} logo` }
                title={ label }
                width={ iconSize }
                height={ iconSize } />

            </BaseButton>

          </Box>
        )
      }) }

    </Stack>
  )
}
