import { AxiosRequestConfig } from 'axios'

import { FetchCelebrityCampaignAvatarURLParams, endpoints } from '../../../utils/server/endpoints/server-endpoints.constants'
import { fetchCampaign } from '../base/base-campaign.utils'

async function fetchCelebrityCampaign(
  params: FetchCelebrityCampaignAvatarURLParams,
  axiosConfig?: AxiosRequestConfig,
) {
  return fetchCampaign(
    endpoints.fetchCelebrityCampaignFlow({ celebritySlug: params.celebritySlug }),
    endpoints.fetchCelebrityCampaignAvatar({ celebritySlug: params.celebritySlug, celebrityID: params.celebrityID }),
    axiosConfig,
  )
}

export const CelebritiesCampaignService = {
  fetchCelebrityCampaign,
}
