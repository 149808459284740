import { NotificationType } from '../../store/notifications/notifications.types'

export const ACCOUNT_CONFIRMATION_SUCCESS_MESSAGE = 'Account Verified'
export const CHANGE_EMAIL_SUCCESS_MESSAGE = 'Email Updated'
export const CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Password Updated'
export const EMAIL_VERIFICATION_SUCCESS_MESSAGE = 'Email Verified'
export const FORGOT_PASSWORD_SUCCESS_MESSAGE = 'Email Sent'
export const RESET_PASSWORD_SUCCESS_MESSAGE = 'Password Updated'
export const LOGIN_SUCCESS_MESSAGE = 'Welcome Back'
export const PROFILE_SUCCESS_MESSAGE = 'Profile Updated'
export const ONBOARDING_SUCCESS_MESSAGE = 'Onboarding Completed'
export const SIGNUP_SUCCESS_MESSAGE = 'Account Created'

export const VERIFICATION_CODE_RESENT_SUCCESS_MESSAGE = 'Code Sent'
export const COPY_TO_CLIPBOARD_SUCCESS_MESSAGE = 'Copied'

export const DOWNLOAD_NFME_START_INFO_MESSAGE = 'Starting Download...'
export const DOWNLOAD_NFME_SUCCESS_MESSAGE = 'NFMe Downloaded, check your files'
export const DOWNLOAD_NFME_ERROR_MESSAGE = 'Download failed. Please try again later'

export const DOWNLOAD_NOTIFICATIONS = {
  success: {
    type: NotificationType.Success,
    message: DOWNLOAD_NFME_SUCCESS_MESSAGE,
    duration: 2000,
  },
  info: {
    type: NotificationType.Info,
    message: DOWNLOAD_NFME_START_INFO_MESSAGE,
    duration: 1000,
  },
  error: {
    type: NotificationType.Error,
    message: DOWNLOAD_NFME_ERROR_MESSAGE,
    duration: 2000,
  },
}

export const SAVE_SETTINGS_SUCCESS_MESSAGE = 'Settings Updated'
