import { NavbarVariant } from './base-navbar.types'

export const navbarHeightVar = 'var(--navbarHeight, 72px)'

export function getNavbarHeight(variant?: NavbarVariant) {
  if (variant === 'compact') return '48px'
  if (variant === 'none') return '0px'

  return '72px'
}
