import { Box } from '@mui/material'
import { useRef, useEffect } from 'react'

import { Notifications } from '../../../../components/notifications/notifications.component'
import { asCSSVars } from '../../utils/styles/styles.utils'
import { SCROLLABLE_CONTAINER_ID } from '../app-config/app-config.constants'
import { getNavbarHeight } from '../nav/base-navbar.utils'

import { CommonLayoutPropsAndConfig } from './base-layout.types'

export function BaseLayout({
  variant,
  contentSx,
  pathname,
  navConfig,
  navComponent: NavComponent,
  footerConfig,
  footerComponent: FooterComponent,
  backgroundConfig,
  backgroundComponent: BackgroundComponent,
  contentComponent = 'main',
  content,
  children,
}: CommonLayoutPropsAndConfig) {
  const scrollableRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (pathname === undefined) return

    const scrollable = scrollableRef.current

    if (scrollable) scrollable.scrollTop = 0

    window.scrollTo(0, 0)
  }, [pathname])

  const navElement = NavComponent && navConfig !== false ? (
    <NavComponent
      scrollableRef={ scrollableRef }
      { ...(navConfig === true ? undefined : navConfig) } />
  ) : null

  const footerElement = FooterComponent && footerConfig !== false ? (
    <FooterComponent
      { ...(footerConfig === true ? undefined : footerConfig) } />
  ) : null

  const backgroundElement = BackgroundComponent && backgroundConfig !== false ? (
    <BackgroundComponent
      scrollableRef={ scrollableRef }
      { ...(backgroundConfig === true ? undefined : backgroundConfig) } />
  ) : null

  const navbarHeight = getNavbarHeight((typeof navConfig === 'boolean' ? undefined : navConfig?.variant))

  return (
    <>
      { navElement }

      <Box
        id={ SCROLLABLE_CONTAINER_ID }
        component={ contentComponent }
        ref={ scrollableRef }
        style={ asCSSVars({ navbarHeight }) }
        sx={{
          position: 'relative',
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          pt: getNavbarHeight(typeof navConfig === 'boolean' ? undefined : navConfig?.variant),
          transition: 'padding linear 150ms',
          display: variant === 'noScroll' ? 'flex' : undefined,
          flexDirection: variant === 'noScroll' ? 'column' : undefined,
          ...contentSx,
        }}>
        { content }
        { footerElement }
      </Box>

      { children }

      { backgroundElement }

      <Notifications />
    </>
  )
}
