import { AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationDetailsData,
  ICognitoUserPoolData,
  ISignUpResult } from 'amazon-cognito-identity-js'

import { setAxiosBearer } from '../../axios/axios.constants'
import { removeSpaces } from '../../utils/parsing/parsing.utils'
import { UserAnalyticsService } from '../user-analytics/user-analytics.service'

import { CognitoUserAttributeData } from './auth.types'

class AuthServiceClass {
  private userPool: CognitoUserPool | null = null

  private currentUser: CognitoUser | null = null

  private onAuthenticationChange: (token: string | null) => void

  constructor(
    cognitoUserPoolData: ICognitoUserPoolData,
    onAuthenticationChange: (token: string | null) => void = () => {
    },
  ) {
    if (cognitoUserPoolData.ClientId && cognitoUserPoolData.UserPoolId) this.userPool = new CognitoUserPool(cognitoUserPoolData)

    this.onAuthenticationChange = onAuthenticationChange
  }

  getCurrentUser() {
    if (this.currentUser) return this.currentUser

    if (!this.userPool) return null

    this.currentUser = this.userPool.getCurrentUser()

    return this.currentUser
  }

  getCognitoUser(email: string) {
    if (!this.userPool) return null

    return new CognitoUser({
      Username: email,
      Pool: this.userPool,
    })
  }

  async getSession() {
    return new Promise<CognitoUserSession | null>((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) throw new Error('Could not fetch Cognito user (in AuthService.getSession).')

      currentUser.getSession((getSessionErr: Error | null, session: CognitoUserSession | null) => {
        if (getSessionErr) return reject(getSessionErr)

        currentUser.getUserData((currentUserErr: Error | undefined) => {
          if (currentUserErr) return reject(currentUserErr)

          // `getUserData`'s `userData` contains the right attributes already, but we want to use `session` as our
          // single source of truth for authentication. Therefore, this `getUserData` call here is only here to force
          // the session data to update.

          this.onAuthenticationChange(session?.getAccessToken().getJwtToken() || null)

          resolve(session)
        }, { bypassCache: true })
      })
    }).catch((err: Error) => {
      throw err
    })
  }

  async signUpWithEmail(email: string, password: string) {
    return new Promise<ISignUpResult | null>((resolve, reject) => {
      const attributeList: CognitoUserAttribute[] = [
        new CognitoUserAttribute({
          Name: 'email',
          Value: email,
        }),
      ]

      if (!this.userPool) return

      this.userPool.signUp(
        email,
        password,
        attributeList,
        [],
        (err: Error | undefined, signUpResult: ISignUpResult | undefined) => {
          if (err) return reject(err)

          resolve(signUpResult || null)
        },
      )
    }).catch((err: Error) => {
      throw err
    })
  }

  async confirmRegistration(email: string, confirmationCode: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) return

      cognitoUser.confirmRegistration(removeSpaces(confirmationCode), true, (err: Error | null, result) => {
        // TODO (Dani): Define a custom type for result here.

        if (err) return reject(err)

        resolve(result)
      })
    }).catch((err: Error) => {
      throw err
    })
  }

  async resendConfirmationCode(email: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) return

      /*
      if (!cognitoUser) {
        reject(`could not find ${email}`)
        return
      }
      */

      cognitoUser.resendConfirmationCode((err: Error | undefined, result: any) => {
        // TODO (Dani): Define a custom type for result here.

        if (err) return reject(err)

        resolve(result)
      })
    }).catch((err) => {
      throw err
    })
  }

  async signInWithEmail(email: string, password: string) {
    return new Promise<CognitoUserSession | null>((resolve, reject) => {
      const authenticationData: IAuthenticationDetailsData = {
        Username: email,
        Password: password,
      }

      const authenticationDetails = new AuthenticationDetails(authenticationData)

      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) return

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async () => {
          const userSession = await this.getSession()

          resolve(userSession)
        },
        onFailure: (err: Error) => {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  async signOut() {
    return new Promise<void>((resolve) => {
      this.onAuthenticationChange(null)

      const currentUser = this.getCurrentUser()

      if (currentUser) {
        currentUser.signOut(() => {
          resolve()
        })
      } else {
        // eslint-disable-next-line no-console
        console.warn('Could not fetch Cognito user (in AuthService.signOut).')

        resolve()
      }
    })
  }

  async getUserAttributes() {
    return new Promise<CognitoUserAttribute[] | null>((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) throw new Error('Could not fetch Cognito user (in AuthService.getAttributes).')

      currentUser.getUserAttributes((err: Error | undefined, attributes: CognitoUserAttribute[] | undefined) => {
        if (err) return reject(err)

        resolve(attributes || null)
      })
    }).catch((err) => {
      throw err
    })
  }

  async updateAttributes(userAttributes: CognitoUserAttributeData[]) {
    return new Promise<string | null>((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) throw new Error('Could not fetch Cognito user (in AuthService.setUserAttribute).')

      currentUser.updateAttributes(userAttributes, async (err: Error | undefined, res: string | undefined) => {
        if (err) return reject(err)

        const currentSession = await this.getSession()

        if (currentSession) {
          currentUser.refreshSession(currentSession.getRefreshToken(), (refreshErr) => {
            if (refreshErr) reject(refreshErr)
            else resolve(res || null)
          })
        } else {
          resolve(res || null)
        }
      })
    }).catch((err) => {
      throw err
    })
  }

  async verifyAttribute(attributeName: string, confirmationCode: string) {
    return new Promise<string>((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) throw new Error('Could not fetch Cognito user (in AuthService.verifyAttribute).')

      currentUser.verifyAttribute(attributeName, confirmationCode, {
        onSuccess: async (res: string) => {
          if (res !== 'SUCCESS') return resolve(res)

          const currentSession = await this.getSession()

          if (currentSession) {
            currentUser.refreshSession(currentSession.getRefreshToken(), (refreshErr) => {
              if (refreshErr) reject(refreshErr)
              else resolve(res)
            })
          } else {
            resolve(res)
          }
        },
        onFailure(err: Error) {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  async getAttributeVerificationCode(attributeName: string) {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) throw new Error('Could not fetch Cognito user (in AuthService.getAttributeVerificationCode).')

      currentUser.getAttributeVerificationCode(attributeName, {
        onSuccess(res: string) {
          resolve(res)
        },
        onFailure(err: Error) {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  async forgotPassword(email: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) return

      /*
      if (!cognitoUser) {
        reject(`could not find ${email}`)
        return
      }
      */

      cognitoUser.forgotPassword({
        onSuccess(res) {
          resolve(res)
        },
        onFailure(err: Error) {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  async confirmPassword(email: string, verificationCode: string, newPassword: string) {
    return new Promise<string>((resolve, reject) => {
      const cognitoUser = this.getCognitoUser(email)

      if (!cognitoUser) return

      /*
      if (!cognitoUser) {
        reject(`could not find ${email}`)
        return
      }
      */

      cognitoUser.confirmPassword(removeSpaces(verificationCode), newPassword, {
        onSuccess(success: string) {
          resolve(success)
        },
        onFailure(err: Error) {
          reject(err)
        },
      })
    })
  }

  async changePassword(oldPassword: string, newPassword: string) {
    return new Promise<'SUCCESS' | null>((resolve, reject) => {
      const currentUser = this.getCurrentUser()

      if (!currentUser) throw new Error('Could not fetch Cognito user (in AuthService.changePassword).')

      currentUser.changePassword(oldPassword, newPassword, (err: Error | undefined, res: 'SUCCESS' | undefined) => {
        if (err) return reject(err)

        resolve(res || null)
      })
    })
  }
}

const DEMO_MODE_ENABLED = true

export const AuthService = new AuthServiceClass(DEMO_MODE_ENABLED ? {
  UserPoolId: '',
  ClientId: '',
} : {
  UserPoolId: `${process.env.SST_COGNITO_USER_POOL_ID || ''}`,
  ClientId: `${process.env.SST_COGNITO_USER_POOL_CLIENT_ID || ''}`,
}, (token: string | null) => {
  setAxiosBearer(token)

  if (token) {
    UserAnalyticsService.enable()
  } else {
    UserAnalyticsService.disable()
  }
})
