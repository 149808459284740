import { getAppVersion, getAppEnvironment } from '../../utils/versioning/versioning.utils'

import { OS, Browser, Device, AnalyticsDeviceInfo, CommonAnalyticsEventMeta } from './mixpanel/user-analytics-mixpanel.types'
import { NOT_AVAILABLE } from './user-analytics.constants'

// adapted from https://patrickhlauke.github.io/touch/touchscreen-detection/
export function hasTouch(): boolean {
  let result = false

  if (window.PointerEvent && 'maxTouchPoints' in window.navigator) {
    // if Pointer Events are supported, just check maxTouchPoints
    if (window.navigator.maxTouchPoints > 0) {
      result = true
    }
  } else {
    // no Pointer Events...
    // eslint-disable-next-line no-lonely-if
    if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
      // check for any-pointer:coarse which mostly means touchscreen
      result = true
    } else if (window.TouchEvent || 'ontouchstart' in window) {
      // last resort - check for exposed touch events API / event handler
      result = true
    }
  }

  return result
}

// adapted from https://github.com/mixpanel/mixpanel-js/blob/34e5d159c64fb70cd379d8f18e8f3e50dbfb8a49/mixpanel.js#L1287-L1305
// ensures consistency with what would be tracked from mixpanel's client SDK
export function parseOS(userAgent: string): OS {
  if (/Windows/i.test(userAgent)) {
    return /Phone/.test(userAgent) ? 'Windows Mobile' : 'Windows'
  }

  if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    return 'iOS'
  }

  if (/Android/.test(userAgent)) {
    return 'Android'
  }

  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry'
  }

  if (/Mac/i.test(userAgent)) {
    return 'Mac OS X'
  }

  if (/Linux/.test(userAgent)) {
    return 'Linux'
  }

  return ''
}

// adapted from https://github.com/mixpanel/mixpanel-js/blob/34e5d159c64fb70cd379d8f18e8f3e50dbfb8a49/mixpanel.js#L1252-L1284
// ensures consistency with what would be tracked from mixpanel's client SDK
export function parseBrowser(userAgent: string, vendor = ''): Browser {
  const isOpera = userAgent.includes('OPR') || userAgent.includes('Opera')

  if (isOpera) {
    return userAgent.includes('Mini') ? 'Opera Mini' : 'Opera'
  }

  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry'
  }

  if (userAgent.includes('FBIOS')) {
    return 'Facebook Mobile'
  }

  if (userAgent.includes('Chrome')) {
    return 'Chrome'
  }

  if (userAgent.includes('CriOS')) {
    return 'Chrome iOS'
  }

  if (userAgent.includes('Firefox')) {
    return 'Firefox'
  }

  if (vendor.includes('Apple')) {
    return userAgent.includes('Mobile') ? 'Mobile Safari' : 'Safari'
  }

  if (userAgent.includes('Android')) {
    return 'Android Mobile'
  }

  if (userAgent.includes('Konqueror')) {
    return 'Konqueror'
  }

  if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer'
  }

  if (userAgent.includes('Gecko')) {
    return 'Mozilla'
  }

  return ''
}

// Adapted from https://github.com/mixpanel/mixpanel-js/blob/34e5d159c64fb70cd379d8f18e8f3e50dbfb8a49/mixpanel.js#L1307=L1323
// Even though this is in common package (because we want the return type), you should only call it in a browser
// environment as it expects window to exist.
export function parseDevice(userAgent: string): Device {
  if (/iPad/.test(userAgent)) {
    return 'iPad'
  }

  if (/iPod/.test(userAgent)) {
    return 'iPod Touch'
  }

  if (/iPhone/.test(userAgent)) {
    return 'iPhone'
  }

  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry'
  }

  if (/Windows Phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/Android/.test(userAgent)) {
    return 'Android'
  }

  if (hasTouch() && parseOS(userAgent) === 'Mac OS X') {
    return Math.min(window.innerHeight, window.innerWidth) > 414 ? 'iPad' : 'iPhone'
  }

  return 'Desktop or other'
}

export function isMobileDevice() {
  if ('userAgentData' in navigator && 'mobile' in (navigator.userAgentData as any)) {
    return !!(navigator.userAgentData as any).mobile
  }

  const { userAgent } = navigator

  return parseDevice(userAgent) !== 'Desktop or other' || /Mobi/i.test(userAgent)
}

export function getDeviceInfo(): AnalyticsDeviceInfo {
  const { userAgent, vendor } = window.navigator

  return {
    os: parseOS(userAgent),
    browser: parseBrowser(userAgent, vendor),
    device: parseDevice(userAgent),
    screenSize: {
      width: window.screen.width,
      height: window.screen.height,
    },
  }
}

export function getCommonEventMeta(): CommonAnalyticsEventMeta {
  return {
    URL: window.location.href,
    appVersion: getAppVersion() || NOT_AVAILABLE,
    appEnvironment: getAppEnvironment() || NOT_AVAILABLE,
  }
}
