import { deepMergeWithLimit } from '../../common/object/object.utils'
import { CacheSet } from '../cache/cache.utils'
import { ReadOnlyStoreResource } from '../readonly/readonly-store.types'
import { createEmptyReadOnlyStoreResource } from '../readonly/readonly-store.utils'

import { OptimisticallyUpdatableStoreMapOptions, OptimisticallyUpdatableStoreOptions } from './optimistic-updates.types'

export function optimisticallyUpdateStoreResource<D>(
  storeResource: ReadOnlyStoreResource<D>,
  options: OptimisticallyUpdatableStoreOptions<D>,
): () => D | null | undefined {
  const previousData = storeResource.data

  Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, {
    data: options.optimisticUpdate || deepMergeWithLimit(
      options.partialOptimisticUpdateLimit || Infinity,
      {} as D,
      previousData || {},
      options.partialOptimisticUpdate || {},
    ),
  })

  return () => {
    Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, {
      data: previousData,
    })

    return previousData
  }
}

// TODO (Dani): Refactor this so that we have single action (one action per resource) or multi action resources maps:

export function optimisticallyUpdateStoreResourceMap<K extends string | number = string | number, V = any>(
  key: K,
  resourcesCacheSet: CacheSet<K, ReadOnlyStoreResource<V>>,
  { action, ...options }: OptimisticallyUpdatableStoreMapOptions<V>,
) {
  const resource = resourcesCacheSet.get(key)

  if (resource) return optimisticallyUpdateStoreResource(resource, options)

  if (action === 'add') {
    const newResource = createEmptyReadOnlyStoreResource<V>()

    optimisticallyUpdateStoreResource(newResource, options)

    resourcesCacheSet.set(key, newResource)

    return () => {
      resourcesCacheSet.delete(key)

      return null
    }
  } if (action === 'delete') {
    resourcesCacheSet.delete(key)

    return () => {
      // TODO: Refactor how the action is passed or create individual function...
      resourcesCacheSet.set(key, resource!)

      return null
    }
  }

  return () => null
}
