import { Stack, Box } from '@mui/material'
import { isValidComponent, isValidElement } from '@ng-mono/sdk'
import React, { useState, useCallback, useEffect } from 'react'

import { NAV_BAR_BACKGROUND } from '../../../../theme/theme'
import { FIX_NAVBAR_AT_VH, MENU_BUTTON_ID, MENU_BUTTON_SIZE, NAVBAR_MENU_PORTAL_ID } from '../app-config/app-config.constants'
import { Logo } from '../logo/logo.component'

import { BaseNavbarProps } from './base-navbar.types'
import { getNavbarHeight } from './base-navbar.utils'
import { NavbarMenuPortal } from './menu-portal/nav-menu-portal.component'

export function BaseNavbar({
  variant = 'default',
  mode = variant === 'default' ? 'visibleOnScrollUp' : 'alwaysVisible',
  scrollableRef,
  contentSlot,
  actionSlot,
  menu: Menu,
  showLogo,
  logoSize,
  logoLink: logoLinkProp,
}: BaseNavbarProps) {
  // Menu state (opened / closed):

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const handleToggleMenu = useCallback(() => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  // Navbar state (visible / hidden):

  const [isNavbarDown, setIsNavbarDown] = useState(true)

  useEffect(() => {
    const scrollable = scrollableRef.current

    if (!scrollable) return

    let prevScrollTop = 0
    let prevDirection: 'up' | 'down' = 'down'
    let deltaSum = 0
    let timeoutID = 0

    function handleScroll() {
      if (!scrollable) return

      window.clearTimeout(timeoutID)

      timeoutID = window.setTimeout(() => {
        deltaSum = 0
      }, 50)

      const { scrollTop, offsetHeight } = scrollable

      const delta = scrollTop - prevScrollTop
      const direction = delta < 0 ? 'up' : 'down'

      if (direction === prevDirection) {
        deltaSum += Math.abs(delta)
      } else {
        deltaSum = 0
      }

      prevScrollTop = scrollTop
      prevDirection = direction

      if (scrollTop <= offsetHeight * FIX_NAVBAR_AT_VH) {
        setIsNavbarDown(true)

        return
      }

      if (deltaSum >= 32) setIsNavbarDown(direction === 'up')
    }

    scrollable.addEventListener('scroll', handleScroll)

    return () => {
      window.clearTimeout(timeoutID)
      scrollable.removeEventListener('scroll', handleScroll)
    }
  }, [scrollableRef])

  const isDefaultVariant = variant === 'default'
  const isCompactVariant = variant === 'compact'
  const navbarHeight = getNavbarHeight(variant)
  const showNavbar = variant !== 'none' && (mode === 'alwaysVisible' || isMenuOpen || isNavbarDown)
  const logoLink = logoLinkProp ?? process.env.GH_WEBSITE_URL

  let actionSlotElement: React.ReactElement | null = null

  if (isValidElement(actionSlot)) {
    actionSlotElement = actionSlot
  } else if (isValidComponent(actionSlot)) {
    const MenuButton = actionSlot

    actionSlotElement = (
      <MenuButton
        id={ MENU_BUTTON_ID }
        height={ MENU_BUTTON_SIZE }
        circle={ isDefaultVariant }
        isOpen={ isMenuOpen }
        onToggle={ handleToggleMenu }
        sx={ isCompactVariant ? null : {
          '&::before': {
            content: '""',
            position: 'absolute',
            width: MENU_BUTTON_SIZE + 16,
            height: navbarHeight,
            top: '50%',
            left: '0',
            transform: 'translate(0, -50%)',
            zIndex: 1,
          },
        } } />
    )
  }

  return (
    <nav>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: 'absolute',
          width: '100%',
          height: navbarHeight,
          top: 0,
          borderBottom: ({ borders }) => borders.default(),
          zIndex: 101,
          px: isCompactVariant ? 0 : 2,
          // pr: isCompactVariant || !actionSlotElement ? 0 : 2,
          // pl: showLogo ? isCompactVariant ? 12 : 14 : (isCompactVariant ? 0 : 2),
          transition: 'transform linear 150ms, height linear 150ms, padding linear 150ms',
          transform: showNavbar ? null : 'translate(0, -100%)',
          overflow: 'hidden',
          ...NAV_BAR_BACKGROUND,

          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
          },
        }}>

        <Logo
          logoLink={ logoLink }
          size={ logoSize }
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            zIndex: 100,
            px: 2,
            transition: 'opacity linear 150ms',
            opacity: showLogo ? 1 : 0,
            pointerEvents: showLogo ? undefined : 'none',
            // background: NAV_BAR_BACKGROUND.background,
          }} />

        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="center"
          sx={{
            position: 'relative',
            height: '100%',
            flex: 1,
          }}>

          { contentSlot }
        </Stack>

        { actionSlotElement }
      </Stack>

      <Box
        id={ NAVBAR_MENU_PORTAL_ID }
        sx={{
          position: 'absolute',
          top: navbarHeight,
          left: 0,
          right: 0,
          bottom: 0,

          '&:empty': {
            display: 'none',
          },

          '&:empty + div': {
            background: 'transparent',
            backdropFilter: 'none',
            pointerEvents: 'none',
          },
        }} />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 98,
          background: 'rgba(0,0,0,.75)',
          backdropFilter: 'blur(8px)',
          pointerEvents: 'auto',
          transition: 'all ease-in 200ms',
        }} />

      { isMenuOpen && Menu ? (
        <NavbarMenuPortal
          id="mainMenu"
          variant="navigation"
          onClose={ handleCloseMenu }
          scrollableRef={ scrollableRef }
          ignoreSelector={ `#${MENU_BUTTON_ID}` }
          component={ Menu } />
      ) : null }
    </nav>
  )
}
