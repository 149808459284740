/**
 * MobX change-detection work-around.
 *
 * TODO (Dani): We might want to investigate and address this differently at some point.
 */
export function forceUpdate<O extends Object>(obj: O, key: keyof O) {
  const originalValue = obj[key]

  // eslint-disable-next-line no-param-reassign
  obj[key] = null as any
  // eslint-disable-next-line no-param-reassign
  obj[key] = originalValue
}
