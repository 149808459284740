import { Trait } from '../traits/traits.types'

export enum RewardTypes {
  NFTTrait = 'NFTTrait',
  // NFTTraitColor = 'NFTTraitColor',
  TextCode = 'TextCode',
  // QRCode = 'QRCode',
  // URL = 'URL', // Same as TextCode?
}

// export interface BaseReward {}

// TODO (Dani): Rewards could have `awardedDate` and awardedFrom: Path | Achievement | Topic.

export interface NFTTraitReward {
  id: string;
  type: RewardTypes.NFTTrait;
  trait: Trait;
}

export interface TextCodeReward {
  id: string;
  type: RewardTypes.TextCode;
  code: string;
  name: string;
  description?: string;
  url?: string;
  iconSrc?: string;
  // expiration?: Date;
}

export type Reward = NFTTraitReward | TextCodeReward

export function isNFTTraitReward(reward: Reward): reward is NFTTraitReward {
  return reward.type === RewardTypes.NFTTrait
}

export function isTextCodeReward(reward: Reward): reward is TextCodeReward {
  return reward.type === RewardTypes.TextCode
}
