import { NFME_GALAXY } from '../brand/brand.constants'
import { REWARD_MOCKS_BY_ID } from '../rewards/rewards.mock'
import { RuleType } from '../rules/rules.types'
import { TOPICS_MOCK_BY_ID } from '../topics-n-categories/topics-n-categories.mock'

import { BackendAchievement } from './achievements.types'

export const BACKEND_ACHIEVEMENT_MOCKS: BackendAchievement[] = [{
  id: 'blockchainMaster',
  toc: {
    name: 'Blockchain Master',
    description: 'Complete the "Intro to Blockchain" Topic',
  },
  rules: [[{
    id: 'R::blockchainMaster',
    type: RuleType.Topic,
    topicID: TOPICS_MOCK_BY_ID.blockchainTopic1.id,
    topicProgress: 1,
  }]],
  rewards: [REWARD_MOCKS_BY_ID.empireSun],
}, {
  id: 'clogsMaster',
  toc: {
    name: 'Clogs Master',
    description: 'Complete the "Co-create with Clogs™" Topic',
  },
  rules: [[{
    id: 'R::BrandXFan',
    type: RuleType.Topic,
    topicID: TOPICS_MOCK_BY_ID.clogsTopic.id,
    topicProgress: 1,
  }]],
}, {
  id: 'maxed',
  toc: {
    name: `${NFME_GALAXY} Power User`,
    description: 'Complete all Paths',
  },
  rules: [[{
    id: 'R1::maxed',
    type: RuleType.Achievement,
    achievementID: 'blockchainMaster',
  }, {
    id: 'R2::maxed',
    type: RuleType.Achievement,
    achievementID: 'clogsMaster',
  }]],
}]
