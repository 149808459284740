import { isInPathPushPathPriority, PushPath, UserRole, deepMerge, devLog, DevLogGroup, RecursivePartial, RoutePaths, SyntheticRoutePaths } from '@ng-mono/sdk'
import React from 'react'
import { matchRoutes as reactRouterMatchRoutes, RouteMatch as ReactRouterRouteMatch, Location, Params } from 'react-router-dom'

import { AppNavbarConfig } from '../../components/app/navbar/app-navbar.component'
import { asyncComponentLoader } from '../../utils/loader/loader'
import { rootGuard } from '../guards/root/root-guard.utils'

import { RouteConfig, RouteAuth, RouteMatch } from './routes.types'

const AuthErrorPage = asyncComponentLoader(() => import('../../pages/AuthError/AuthError.page'))
const AuthLoaderPage = asyncComponentLoader(() => import('../../pages/AuthLoader/AuthLoader.page'))
const PushPathPage = asyncComponentLoader(() => import('../../pages/PushPath/PushPath.page'))

function createSyntheticMatchedRoute(
  syntheticPathname: string,
  syntheticTitle: string,
  page: React.ComponentType,
  nav: boolean | AppNavbarConfig = true,
): ReactRouterRouteMatch<string, RouteMatch> {
  return {
    pathname: syntheticPathname,
    pathnameBase: syntheticPathname,
    params: {},
    route: {
      title: syntheticTitle,
      auth: RouteAuth.None,
      path: '*',
      page,
      nav,
      footer: false,
      background: true,
    },
  }
}

export interface RouterState {
  isAuthLoading: boolean;
  isAuthenticated: boolean;
  userRole: null | UserRole;
  realUserRole: null | UserRole;
  userError: Error | null;
  emailVerified: boolean;
  profileCompleted: boolean;
  pushPath: PushPath | null;
}

const MAX_CHAINED_REDIRECTS = 1

let redirectLogs: string[] = []

export function matchRoutes(
  routesConfig: RouteConfig[],
  location: Location | string,
  state: RouterState,
  depth = 0,
): ReactRouterRouteMatch<string, RouteMatch>[] {
  if (depth === 0) redirectLogs = []

  redirectLogs.push(typeof location === 'string' ? location : location.pathname)

  if (depth > MAX_CHAINED_REDIRECTS) throw new Error(`Redirect loop at depth ${depth}: ${redirectLogs.join(', ')}`)

  const {
    isAuthLoading,
    isAuthenticated,
    userRole,
    realUserRole,
    userError,
    emailVerified,
    profileCompleted,
    pushPath,
  } = state

  if (userError) {
    return [createSyntheticMatchedRoute(SyntheticRoutePaths.AuthError, 'Authentication Error', AuthErrorPage, { variant: 'none' })]
  }

  if (isAuthLoading) {
    return [createSyntheticMatchedRoute(SyntheticRoutePaths.AuthLoading, 'Loading Authentication', AuthLoaderPage, { variant: 'none' })]
  }

  // Check if we have a PushPath available that we need to show immediately:

  const pathname = typeof location === 'string' ? location : location.pathname
  const topicActivitiesRoutePathRegExp = new RegExp(RoutePaths.TopicActivities({ topicID: '\\w+' }))
  const isTopicActivitiesRoute = topicActivitiesRoutePathRegExp.test(pathname)

  // If we are already inside the regular TopicActivities route, then we don't use the synthetic `push-path` route, as
  // TopicActivities can already handle showing PushPaths when available. However, if we are in a different part of the
  // app, we do:
  if (isAuthenticated && !!pushPath && !isTopicActivitiesRoute && !isInPathPushPathPriority(pushPath.priority)) {
    return [createSyntheticMatchedRoute(SyntheticRoutePaths.PushPath, 'Important Notification', PushPathPage, { variant: 'compact', content: 'toolbar' })]
  }

  // Let's match our routes (assuming we don't use nested routes)...:
  const routeMatches = reactRouterMatchRoutes(routesConfig, location) || []

  let matchedRoutePath: null | string = null
  let matchedRouteParams: null | Params<string> = {}
  let matchedRouteAuth: null | RouteAuth = null
  let matchedRouteAvailableTo : null | UserRole[] = null

  routeMatches.forEach(({ route: { path, auth, availableTo }, params }) => {
    matchedRoutePath = path ?? matchedRoutePath
    matchedRouteParams = { ...matchedRouteParams, ...params }
    matchedRouteAuth = auth ?? matchedRouteAuth
    matchedRouteAvailableTo = availableTo ?? matchedRouteAvailableTo
  })

  const guardRedirect = rootGuard({
    pathname,
    path: matchedRoutePath,
    params: matchedRouteParams,
    auth: matchedRouteAuth,
    availableTo: matchedRouteAvailableTo,
    isAuthenticated,
    userRole,
    realUserRole,
    emailVerified,
    profileCompleted,
  })

  if (guardRedirect === null) return routeMatches

  devLog(DevLogGroup.Routing, 'Redirecting to', guardRedirect)

  // ...and resolve any possible redirect recursively
  const matchedRedirectRoutes = matchRoutes(routesConfig, guardRedirect, state, depth + 1)

  matchedRedirectRoutes.forEach(({ route }) => {
    // eslint-disable-next-line no-param-reassign
    route.isRedirect = true
  })

  // If we can't show the requested route, we already resolve to the redirect route and thus render it straight away
  // while the redirect is happening (*).
  //
  // Step by step example:
  //
  // - The requested route requires auth but the user is not authenticated.
  //
  // - Therefore, `getGuardRedirect` says (returns) the user must go to `login`.
  //
  // - We update the match to indicate this is a redirect but already render the login page while
  //   `navigate('login', { replace: true })`) is happening.
  //
  // (*) If we want to show a loader while the redirect is happening, we can do it like so:
  // matchedRedirectRoute.route.page = AuthLoaderPage

  return matchedRedirectRoutes
}

export function deepMergeRouteProps<T = any>(
  target: T,
  ...sources: (T | RecursivePartial<T>)[]
): T {
  const filteredSources = sources.filter(Boolean)

  if (target === undefined) return filteredSources.length === 0 ? target : deepMerge({} as T, ...filteredSources)

  if (typeof target !== 'object') {
    return filteredSources.reduce((acc, cur) => {
      if (typeof cur === 'object') throw new Error('Can\'t combine objects with primary types while merging route props.')

      return acc ?? cur
    }, target) as T
  }

  return deepMerge(target, ...filteredSources)
}
