import { NFME_GALAXY } from '../brand/brand.constants'
import { DocNodeType } from '../doc/doc.types'
import { LifecycleStage } from '../lifecycle/lifecycle.types'
import { MediaType } from '../media/media.types'

import { Activity, DBQuestionActivity, QuestionActivity } from './activities.types'
import { ActivityTypes } from './base/base-activity.types'
import { ContentActivity } from './content/content-activity.types'
import { DBOptionsQuestionActivity,
  FreeQuestionSubtypes,
  OptionsQuestionActivity,
  OptionsQuestionSubtypes } from './question/question-activity.types'
import { RankingActivity } from './ranking/ranking.types'

export const ACTIVITY_MOCK_BLOCKCHAIN_1: ContentActivity = {
  id: 'A1[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Chain+of+red+boxes.svg',
      width: 200,
      height: 200,
    },
  }, {
    type: DocNodeType.LargeText,
    text: 'Today there are dozens of currencies called cryptocurrencies, but the first was Bitcoin - launched in 2009.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_2: ContentActivity = {
  id: 'A1[1]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Man+wondering+with+box+in+hand.svg',
      width: 200,
      height: 200,
    },
  }, {
    type: DocNodeType.LargeText,
    text: 'Bitcoin was invented by the mysterious Satoshi Nakamoto. No one knows if this is one person, or many.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_3: ContentActivity = {
  id: 'A1[3]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Yellow+Tetris+blocks+man+wondering.svg',
      width: 200,
      height: 200,
    },
  }, {
    type: DocNodeType.LargeText,
    text: 'Before cryptocurrency, transactions relied on 3rd parties like banks and governments for processing and regulation. This is known as a centralized system.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_4: ContentActivity = {
  id: 'A1[4]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Man+wondering+with+box+in+hand.svg',
      width: 200,
      height: 200,
    },
  }, {
    type: DocNodeType.LargeText,
    text: 'Centralized systems have their flaws — exposing users to hacking, surveillance, data sharing, and much more.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_5: ContentActivity = {
  id: 'A1[5]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Red+boxes+with+checkmarks+inside.svg',
      width: 200,
      height: 200,
    },
  }, {
    type: DocNodeType.LargeText,
    text: 'Bitcoin offered a digital currency that was fully secure, and didn’t rely on third parties whatsoever.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_6: DBQuestionActivity = {
  id: 'A1[6]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Is Ethereum the first Blockchain?',
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  correctAnswer: false,
  feedbackOk: 'Good',
  feedbackWrong: 'Is there any cryptocurrency worth more than ETH?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_7: DBQuestionActivity = {
  id: 'A1[7]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Select,
  question: 'What was the first cryptocurrency to achieve large scale adoption?',
  options: [
    { value: 'ETH', label: 'Ethereum' },
    { value: 'BTC', label: 'Bitcoin' },
  ],
  correctAnswer: 'BTC',
  feedbackOk: 'Good',
  feedbackWrong: 'What did Satoshi Nakamoto invent?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_8: QuestionActivity = {
  id: 'A1[7]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: FreeQuestionSubtypes.MultilineText,
  inputPlaceholder: 'Write your answer here',
  question: 'What are you most excited about in the world of blockchain?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_9: RankingActivity = {
  id: 'A1[8]',
  stage: LifecycleStage.Active,
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://i.pcmag.com/imagery/articles/02B8wHk9hoL3hX3pNNpv40L-1..v1614889382.jpg',
      width: 1280,
      height: 720,
    },
  }],
  type: ActivityTypes.Ranking,
  question: 'Whats your favorite blockchain?',
  items: [{
    id: 'bitcoin',
    name: 'Bitcoin',
  },
  {
    id: 'ethereum',
    name: 'Ethereum',
  },
  {
    id: 'binance',
    name: 'Binance',
  }],
}

export const ACTIVITIES_MOCK_BLOCKCHAIN: Activity[] = [
  ACTIVITY_MOCK_BLOCKCHAIN_1,
  ACTIVITY_MOCK_BLOCKCHAIN_2,
  ACTIVITY_MOCK_BLOCKCHAIN_3,
  ACTIVITY_MOCK_BLOCKCHAIN_4,
  ACTIVITY_MOCK_BLOCKCHAIN_5,
  ACTIVITY_MOCK_BLOCKCHAIN_6,
  ACTIVITY_MOCK_BLOCKCHAIN_7,
  ACTIVITY_MOCK_BLOCKCHAIN_8,
  ACTIVITY_MOCK_BLOCKCHAIN_9,
]

export const ACTIVITY_MOCK_BLOCKCHAIN_10: ContentActivity = {
  id: 'A3[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: 'A non-regulated cryptocurrency is a type of virtual currency that uses encryption to safeguard transaction, rather than relying on a central issuing or regulating authority.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_11: ContentActivity = {
  id: 'A3[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: 'A CBDC (Central Bank Digital Currency) is a form of virtual central bank money, issued by a central bank.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_12: DBQuestionActivity = {
  id: 'A3[1]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Which one of these is decentralized?',
  options: [
    { value: 'crypto', label: 'Cryptocurrency' },
    { value: 'cbdc', label: 'CBDC' },
  ],
  correctAnswer: 'crypto',
  feedbackOk: 'Good',
  feedbackWrong: 'Who controls CBDCs?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_13: DBQuestionActivity = {
  id: 'A3[2]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Which one of these do governments control?',
  options: [
    { value: 'crypto', label: 'Cryptocurrency' },
    { value: 'cbdc', label: 'CBDC' },
  ],
  correctAnswer: 'cbdc',
  feedbackOk: 'Good',
  feedbackWrong: 'What does the "CB" in CBDC stand for?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_14: ContentActivity = {
  id: 'A3[3]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: 'The value of a CBDC is based on a country\'s fiat currency\'s value.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_15: DBQuestionActivity = {
  id: 'A3[4]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Which one of these is more volatile?',
  options: [
    { value: 'crypto', label: 'Cryptocurrency' },
    { value: 'cbdc', label: 'CBDC' },
  ],
  correctAnswer: 'crypto',
  feedbackOk: 'Good',
  feedbackWrong: 'Are you more likely to buy a pizza with a Bitcoin or with a digital dollar?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_16: ContentActivity = {
  id: 'A3[5]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: 'CBDCs simplify the implementation of monetary and fiscal policies, opening the door for new mechanism to control inflation, consumption, spending, and lending, to name a few.',
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_17: ContentActivity = {
  id: 'A3[6]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.YouTube,
    media: {
      type: MediaType.YouTube,
      src: 'https://www.youtube.com/embed/3WMQpGZdlWQ',
      width: 383,
      height: 681,
    },
  }],
}

export const ACTIVITY_MOCK_BLOCKCHAIN_18: DBQuestionActivity = {
  id: 'A3[7]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Who will know how you spend your money when using CBDCs?',
  options: [
    { value: 'noOne', label: 'No one' },
    { value: 'centralBank', label: 'The Central Bank' },
    { value: 'government', label: 'The Government' },
    { value: 'both', label: 'Both' },
  ],
  correctAnswer: 'both',
  feedbackOk: 'Good',
  feedbackWrong: 'Who is behind CBDCs?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_19: DBQuestionActivity = {
  id: 'A3[8]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Which one of these offers a higher level of privacy?',
  options: [
    { value: 'fiat', label: 'Fiat' },
    { value: 'cbdc', label: 'CBDC' },
  ],
  correctAnswer: 'fiat',
  feedbackOk: 'Good',
  feedbackWrong: 'What did Lagarde say about the Digital Euro?',
}

export const ACTIVITY_MOCK_BLOCKCHAIN_20: DBQuestionActivity = {
  id: 'A3[9]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: 'Do you like the idea of CBDCs?',
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
}

// TODO (Dani): This question could be conditional based on the previous answer (e.g. Yes => Why? / No => Why not=)

export const ACTIVITY_MOCK_BLOCKCHAIN_21: QuestionActivity = {
  id: 'A3[10]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: FreeQuestionSubtypes.MultilineText,
  inputPlaceholder: 'Write your answer here',
  question: 'Why / why not?',
}

export const ACTIVITIES_MOCK_BLOCKCHAIN_2: Activity[] = [
  ACTIVITY_MOCK_BLOCKCHAIN_10,
  ACTIVITY_MOCK_BLOCKCHAIN_11,
  ACTIVITY_MOCK_BLOCKCHAIN_12,
  ACTIVITY_MOCK_BLOCKCHAIN_13,
  ACTIVITY_MOCK_BLOCKCHAIN_14,
  ACTIVITY_MOCK_BLOCKCHAIN_15,
  ACTIVITY_MOCK_BLOCKCHAIN_16,
  ACTIVITY_MOCK_BLOCKCHAIN_17,
  ACTIVITY_MOCK_BLOCKCHAIN_18,
  ACTIVITY_MOCK_BLOCKCHAIN_19,
  ACTIVITY_MOCK_BLOCKCHAIN_20,
  ACTIVITY_MOCK_BLOCKCHAIN_21,
]

export const ACTIVITY_MOCK_CLOGS_1: ContentActivity = {
  id: 'A2[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.Image,
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Clogs+Style+Dark+Brown.svg',
      width: 200,
      height: 200,
    },
  }, {
    type: DocNodeType.LargeText,
    text: 'Welcome to the cloggy workshop! Customize your Clogs™ NFT to match your vibe.',
  }],
}

export const ACTIVITY_MOCK_CLOGS_2: OptionsQuestionActivity<string> = {
  id: 'A2[1]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Select,
  question: 'What’s your fav Clogs™ color?',
  options: [{
    value: '#FFB400',
    label: 'Yellow',
    iconSrc: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Color+Swatch+%23FFB400.svg',
  }, {
    value: '#5091F1',
    label: 'Blue',
    iconSrc: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Color+Swatch+%235091F1.svg',
  }, {
    value: '#FF6B51',
    label: 'Red',
    iconSrc: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Color+Swatch+%23FF6B51.svg',
  }, {
    value: '#00FFC9',
    label: 'Green',
    iconSrc: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Color+Swatch+%2300FFC9.svg',
  }],
}

export const ACTIVITY_MOCK_CLOGS_3: OptionsQuestionActivity<string> = {
  id: 'A2[2]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Select,
  question: 'Choose an image that best describes your Clogs™ style',
  options: [{
    value: 'Dark Brown',
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Clogs+Style+Dark+Brown.svg',
      width: 200,
      height: 200,
    },
  }, {
    value: 'Green',
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Clogs+Style+Green.svg',
      width: 200,
      height: 200,
    },
  }, {
    value: 'Pink',
    media: {
      type: MediaType.Image,
      src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Clogs+Style+Pink.svg',
      width: 200,
      height: 200,
    },
  }],
}

export const ACTIVITIES_MOCK_CLOGS = [
  ACTIVITY_MOCK_CLOGS_1,
  ACTIVITY_MOCK_CLOGS_2,
  ACTIVITY_MOCK_CLOGS_3,
  // ACTIVITY_MOCK_CLOGS_4,
  // ACTIVITY_MOCK_CLOGS_5,
  // ACTIVITY_MOCK_CLOGS_6,
  // ACTIVITY_MOCK_CLOGS_7,
  // ACTIVITY_MOCK_CLOGS_8,
]

// Activities for PushPaths:

export const ACTIVITIES_TERMS_N_CONDITIONS: [
  ContentActivity,
  ContentActivity,
] = [{
  id: 'A3[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: 'Important Update: We have updated our terms and conditions.',
  }],
}, {
  id: 'A3[1]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'accept',
  content: [{
    type: DocNodeType.LargeText,
    text: 'Important Update: Do you accept our terms and conditions?',
  }],
}]

export const ACTIVITIES_SURVEY: [
  ContentActivity,
  DBOptionsQuestionActivity<boolean>,
] = [{
  id: 'A4[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: `Help us improve ${NFME_GALAXY} by answering a short survey.`,
  }],
}, {
  id: 'A4[1]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Question,
  subtype: OptionsQuestionSubtypes.Binary,
  question: `Do you enjoy using ${NFME_GALAXY}?`,
  // TODO (Dani): This should be a rating activity and feedback should be embed on each answer...
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  correctAnswer: true,
  feedbackOk: 'Thanks',
  feedbackWrong: 'We are sorry about that...',
}]

export const ACTIVITIES_AD: [
  ContentActivity,
] = [{
  id: 'A5[0]',
  stage: LifecycleStage.Active,
  type: ActivityTypes.Content,
  actionVariant: 'next',
  content: [{
    type: DocNodeType.LargeText,
    text: 'This is an ad. Just wait...',
  }],
  // TODO (Dani): Add timeout/mintime (this should be a default setting in the controller) props...
  minSeconds: 5,
}]
