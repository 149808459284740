import { alpha, createTheme } from '@mui/material'
import React from 'react'

import '@mui/material/Typography'

import { withHasActiveClassName } from '../libs/ui/hooks/hover/hover.hook'

interface ColorSet {
  zero?: string;
  one?: string;
  two?: string;
  three?: string;
  four?: string;
  five?: string;
}

export type Border = (color?: string) => string

// @TODO (Paloma): Actually get this from the palette arg:
const defaultBorderColor = 'rgba(255, 255, 255, .125)'
const defaultButtonBorderColor = 'rgba(255, 255, 255, 0.25)'
const defaultDisabledButtonBorderColor = 'rgba(255, 255, 255, 0.125)'
const defaultInputBorderColor = 'rgba(255, 255, 255, 0.125)'
const defaultDisabledInputBorderColor = 'rgba(255, 255, 255, 0.0625)'

export const DEFAULT_BORDER_BOTTOM_SHADOW = '0 2px 0 0 rgb(255 255 255 / 13%)'
export const DEFAULT_BORDER_BOTTOM_SHADOW_INSET = 'inset 0 -2px 0 0 rgb(255 255 255 / 13%)'

export interface Gaps {
  // Default / generic:
  xs: number;
  sm: number;
  md: number;
  lg: number;

  // App-specific:
  outerPadding: number;
  cardPadding: number;

  /**
   * Vertical separation between independent fields:
   */
  formGap: number;

  /**
   * Horizontal separation between combo fields:
   */
  fieldGap: number;

  /**
   * Default separation between list items such as in List or AppMenu
   */
  listGap: number;

  /**
   * Larger separation for things that need to be spread out further:
   */
  spreadOut: number;
}

export interface Borders {
  default: Border;
  button: Border;
  buttonDisabled: Border;
  input: Border;
  inputDisabled: Border;
}

// TODO (Dani): Automatically get as `keyof Shape`
export type BorderRadiusVariant = 'noBorderRadius' | 'subtleBorderRadius' | 'roundedBorderRadius'

declare module '@mui/material/styles/createTheme' {
  export interface ThemeOptions {
    gaps: Gaps;
    borders: Borders;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    bglevels: ColorSet;
  }

  interface PaletteOptions {
    bglevels: ColorSet;
  }

  interface Theme {
    gaps: Gaps;
    borders: Borders;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

// Update Shape's variant prop options
declare module '@mui/system' {
  interface Shape {
    borderRadius: number;
    noBorderRadius: 0;
    subtleBorderRadius: number | string;
    roundedBorderRadius: number | string;
  }
}

// export const TITLE_TEXT_COLOR = '#F96868'
// export const TEXT_COLOR = '#FFFFFF'
// export const BUTTON_COLOR = '#F9BDBD'
// export const BUTTON_COLOR_DARK = '#F96868'

// https://hihayk.github.io/scale/#0/4/100/20/0/0/0/0/060b16/6/11/22/white
// Note each color is 5% more white than the previous (thus the 20% lightness, shared among 4 steps)
const BACKGROUND_COLORS = [
  '#060b16',
  '#121722',
  '#1F232D',
  '#2B3039',
  '#383C45',
]

// https://hihayk.github.io/scale/#1/1/56/56/0/0/0/0/F96868/249/104/104/white
// Note the lightest one is visually identical to the light pink fill in the avatar logo in Figma (but the code is a bit different):
const PRIMARY_COLORS = [
  '#6E2E2E',
  '#F96868',
  '#FCBDBD',
]

// TODO (Dani): These should not be exported. Instead, they should be added to the theme:

export const NAV_BAR_BACKGROUND = {
  backdropFilter: 'blur(5px)',
  background: BACKGROUND_COLORS[1],
}

export const NAV_MENU_BACKGROUND = {
  backdropFilter: 'blur(5px)',
  background: BACKGROUND_COLORS[0],
}

// Use this to paint things that you want to spot but that should not actually be there:
const HIGHLIGHT_COLOR = '#FF00FF'

const baseTheme = createTheme({
  breakpoints: {
    keys: [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
    ],
    values: {
      xs: 500,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,

    },
    unit: 'px',
  },

  direction: 'ltr',

  palette: {
    mode: 'dark',

    background: {
      default: BACKGROUND_COLORS[0],
      paper: BACKGROUND_COLORS[1],
    },

    common: {
      black: '#000',
      white: '#FFF',
    },

    primary: {
      dark: PRIMARY_COLORS[0],
      main: PRIMARY_COLORS[1],
      light: PRIMARY_COLORS[2],
      contrastText: '#FFF',
    },

    secondary: {
      main: HIGHLIGHT_COLOR,
      light: HIGHLIGHT_COLOR,
      dark: HIGHLIGHT_COLOR,
      contrastText: '#FFF',
    },

    error: {
      main: '#a11939',
      light: '#9f324b',
      dark: HIGHLIGHT_COLOR,
      contrastText: '#FFF',
    },

    warning: {
      main: '#FFFF00',
      light: HIGHLIGHT_COLOR,
      dark: HIGHLIGHT_COLOR,
      contrastText: '#000',
    },

    info: {
      main: '#FFFFFF',
      light: HIGHLIGHT_COLOR,
      dark: HIGHLIGHT_COLOR,
      contrastText: '#000',
    },

    success: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#FFF',
    },

    // TODO (Dani): Move to `background`
    bglevels: {
      zero: BACKGROUND_COLORS[0],
      one: BACKGROUND_COLORS[1],
      two: BACKGROUND_COLORS[2],
      three: BACKGROUND_COLORS[3],
      four: BACKGROUND_COLORS[4],
    },

    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#676767',
      800: '#424242',
      900: '#212121',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },

    contrastThreshold: 3,

    tonalOffset: 0.2,

    text: {
      primary: '#FFF',
      secondary: 'rgba(255, 255, 255, 0.6)',
      disabled: 'rgba(255, 255, 255, 0.1)',
    },

    divider: HIGHLIGHT_COLOR,

    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(255, 255, 255, 0.16)',
      selectedOpacity: 0.16,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(255, 255, 255, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.24,
    },
  },

  shape: {
    borderRadius: 14,
    noBorderRadius: 0,
    subtleBorderRadius: '2px',
    roundedBorderRadius: 1024,
  },

  spacing: 8, // https://mui.com/material-ui/customization/spacing/

  gaps: {
    // Default / generic:
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,

    // App-specific:
    outerPadding: 2,
    cardPadding: 2,
    formGap: 3,
    fieldGap: 2,
    listGap: 2,
    spreadOut: 4,
  },

  borders: {
    default: (borderColor = defaultBorderColor) => `2px solid ${borderColor}`,
    button: (borderColor = defaultButtonBorderColor) => `2px solid ${borderColor}`,
    buttonDisabled: (borderColor = defaultDisabledButtonBorderColor) => `2px solid ${borderColor}`,
    input: (borderColor = defaultInputBorderColor) => `2px solid ${borderColor}`,
    inputDisabled: (borderColor = defaultDisabledInputBorderColor) => `2px solid ${borderColor}`,
  },

  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px)': {
        '@media (orientation: landscape)': {
          minHeight: 48,
        },
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Nunito", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    h1: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 800,
      fontSize: '6rem',
      lineHeight: '5rem',
      letterSpacing: '-0.01562em',
    },

    h2: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 700,
      fontSize: '3.75rem',
      lineHeight: '3.5rem',
      letterSpacing: '-0.00833em',
    },

    h3: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      fontSize: '1.875rem',
      lineHeight: '2.25rem',
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      fontSize: '1.625rem',
      lineHeight: '2.25rem',
      letterSpacing: '0.00735em',
    },

    h5: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },

    h6: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '0.85rem',
      lineHeight: 1.43,
      letterSpacing: '0.0075em',
    },

    subtitle1: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },

    subtitle2: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },

    body1: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },

    body2: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },

    body3: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '0.85rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },

    button: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },

    caption: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },

    overline: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },

  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
})

export const theme = createTheme(baseTheme, {

  components: {
    MuiButton: {
      variants: [{
        props: { variant: 'text' },
        style: {
          [withHasActiveClassName('&:active')]: {
            transform: 'scale(.95)',
          },
        },
      }, {
        props: { variant: 'outlined' },
        style: {
        },
      }, {
        props: { variant: 'contained' },
        style: {
        },
      }],
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: baseTheme.shape.borderRadius,
          transition: 'transform linear 200ms',
          minHeight: 40,
          minWidth: 40,
          fontWeight: 700,

          [withHasActiveClassName('&:active')]: {
            transform: 'scale(.98)',
          },
        },

        sizeLarge: {
          minHeight: 56,
          minWidth: 56,
          padding: '12px 20px',
          fontSize: 16,
        },

        sizeSmall: {
          minHeight: 32,
          minWidth: 32,
          padding: '4px 8px',
          fontSize: 14,
        },
      },
    },

    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: baseTheme.shape.noBorderRadius,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 56,
          background: 'transparent',
        },

        sizeSmall: {
          minHeight: 48,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
            border: baseTheme.borders.inputDisabled(),
          },
        },

        notchedOutline: {
          border: baseTheme.borders.input(),
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        sizeSmall: {
          transform: 'translate(14px, 12.5px) scale(1)',

          '&.Mui-focused': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },

          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          margin: '-256px 0',
        },

        scroller: {
          padding: '256px 0',
        },

        indicator: {
          height: '100%',
          zIndex: -1,
          background: 'transparent',
          WebkitBackdropFilter: 'blur(32px)',
          filter: 'blur(32px)',
        },
      },
    },

    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          border: 0,
          borderBottom: baseTheme.borders.default(),
        },

        vertical: {
          border: 0,
          borderLeft: baseTheme.borders.default(),
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundClip: 'content-box',
          boxShadow: 'none',
          marginTop: 8,
          marginBottom: 8,
          border: baseTheme.borders.input(baseTheme.palette.background.paper),
          borderRadius: `${baseTheme.shape.borderRadius}px`,
        },
      },
    },

    // MENU (uses Paper):

    MuiMenu: {
      styleOverrides: {
        list: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: `${baseTheme.shape.borderRadius}px`,
        },
      },
    },

    // AUTOCOMPLETE (uses Paper):

    MuiAutocomplete: {
      styleOverrides: {

        inputRoot: {
          paddingRight: '39px !important',
        },

        listbox: {
          paddingLeft: 8,
          paddingRight: 8,
        },

        option: {
          borderRadius: `${baseTheme.shape.borderRadius}px`,
        },

      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.common.white,
        },
      },
    },

    // CARD (uses Paper):

    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          width: ' 100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 0,
          margin: 0,
          border: baseTheme.borders.default(),
          backgroundImage: 'none',
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderTop: baseTheme.borders.default(),

          '&:first-of-type': {
            borderTop: 0,
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: baseTheme.shape.roundedBorderRadius,
          padding: '2px 8px',
          ...baseTheme.typography.button,
        },

        outlined: {
          background: alpha(baseTheme.palette.primary.main, 0.08),
          color: baseTheme.palette.primary.main,
          border: 0,
        },

        sizeSmall: {
          height: '28px',
        },

        label: {
          padding: 0,
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          borderTop: baseTheme.borders.default(),
          padding: 16,

          '&:first-of-type': {
            borderTop: 0,
          },

          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },

  },

})
