import { CacheSetOptions } from '@swyg/memo'

import { isPromise } from '../../common/promise/promise.utils'

export class CacheSet<K extends string | number = string | number, V = any> implements Map<K, V> {
  slots = 8

  ttl = Infinity

  keepPromises = false

  lastUsage = 0

  data: Partial<Record<K, V>> = {}

  dates: Partial<Record<K, number>> = {}

  usages: K[] = []

  constructor(iterableOrObject?: Iterable<[K, V]> | CacheSetOptions, options?: CacheSetOptions) {
    // makeAutoObservable(this, {}, { autoBind: true })

    const iterable = Array.isArray(iterableOrObject) ? iterableOrObject : null
    const actualOptions: CacheSetOptions = options || (typeof iterableOrObject === 'object' ? iterableOrObject : { }) as CacheSetOptions

    this.slots = actualOptions.slots || this.slots
    this.ttl = (actualOptions.ttl || this.ttl) * 1000
    this.keepPromises = actualOptions.keepPromises || this.keepPromises

    if (iterable) {
      iterable.forEach(([k, v]) => this.set(k, v))
    }
  }

  [Symbol.toStringTag]: string = 'CacheSet'

  forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg: CacheSet<K, V> = this): void {
    (Object.entries(this.data) as [K, V][]).forEach(([key, value]) => {
      callbackfn(value, key, thisArg)
    })
  }

  entries(): IterableIterator<[K, V]> {
    return (Object.entries(this.data) as [K, V][])[Symbol.iterator]()
  }

  keys(): IterableIterator<K> {
    return (Object.keys(this.data) as K[])[Symbol.iterator]()
  }

  values(): IterableIterator<V> {
    return (Object.values(this.data) as V[])[Symbol.iterator]()
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.entries()
  }

  get size() {
    return Object.keys(this.data).length
  }

  clear() {
    this.lastUsage = 0
    this.data = {}
    this.dates = {}
    this.usages = []
  }

  delete(key: K): boolean {
    const hasElementToDelete = this.data[key] !== undefined

    if (hasElementToDelete) {
      delete this.data[key]

      const index = this.usages.indexOf(key)

      if (index !== -1) {
        this.usages.splice(index, 1)
      }
    }

    return hasElementToDelete
  }

  get(key: K): V | undefined {
    if (this.has(key)) {
      this.dates[key] = this.lastUsage = Date.now()
      this.usages.splice(this.usages.indexOf(key), 1)
      this.usages.push(key)

      return this.data[key]
    }
  }

  has(key: K): boolean {
    const now = Date.now()

    if (this.size && now - this.lastUsage > this.ttl) {
      this.clear()

      return false
    }

    const found = this.data[key] !== undefined

    if (!found) return false // Not found.

    if (now - this.dates[key]! > this.ttl) {
      this.delete(key)

      return false // Expired.
    }

    return true
  }

  set(key: K, value: V) {
    this.data[key] = value

    if (!this.keepPromises && isPromise(value)) {
      value.then((data: any) => {
        this.data[key] = data
      })
    }

    if (this.size > this.slots) {
      this.delete(this.usages.shift()!)
    }

    this.dates[key] = this.lastUsage = Date.now()

    const index = this.usages.indexOf(key)

    if (index !== -1) {
      this.usages.splice(index, 1)
    }

    this.usages.push(key)

    return this
  }

  resize(slots: number) {
    this.slots = slots

    while (this.size > slots) {
      this.delete(this.usages[0])
    }
  }
}
