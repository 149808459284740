export enum MediaType {
  Audio = 'audio',
  Image = 'image',
  Video = 'video',
  YouTube = 'youtube',
}

export interface BaseMedia {
  // id: string;
  type: MediaType;
  src: string;
  name?: string;
  background?: string;
  padding?: boolean;
}

export type MediaSize = 'cover' | 'contain' | 'none'

export interface AudioMedia extends BaseMedia {
  type: MediaType.Audio;
  // TODO (Dani): With a custom player, we might want the same props as in ImageMedia (think Instagram Stories
  // player with record case preview)
  // coverSrc?: string;
  // duration?: number;
}

export interface ImageMedia extends BaseMedia {
  type: MediaType.Image;
  width?: number;
  height?: number;
  size?: MediaSize;
}

export interface VideoMedia extends BaseMedia {
  type: MediaType.Video;
  width?: number;
  height?: number;
  size?: MediaSize;
  coverSrc?: string;
  // duration?: number;
}

export interface YouTubeMedia extends BaseMedia {
  type: MediaType.YouTube;
  width?: number;
  height?: number;
  size?: MediaSize;
  coverSrc?: string;
}

export type Media = AudioMedia | ImageMedia | VideoMedia | YouTubeMedia
