import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '../../../axios/axios.constants'
import { endpoints } from '../../../utils/server/endpoints/server-endpoints.constants'
import { to } from '../../../utils/typescript/typescript.utils'
import { AvatarTraitValue, TraitValues } from '../../avatar/avatar.types'
import { Trait } from '../../traits/traits.types'

import { BackendAvatarConfigWithTraits, AvatarConfig, TraitTypeConfig, TraitTypeId, BackendAvatarConfig, PreviewSilhouette } from './creator-renderer-config.types'

const FALLBACK_HEAD_ITEM: Trait = {
  id: '',
  type: 'head',
  name: '',
  slots: {},
  colors: {},
  masks: [],
}

const FALLBACK_BODY_ITEM: Trait = {
  id: '',
  type: 'body',
  name: '',
  slots: {},
  colors: {},
  masks: [],
}

export function getCreatorRendererConfig(
  config: BackendAvatarConfig,
  defaultTraits: Trait[],
): AvatarConfig {
  // This seems unnecessary as `avatar.handlers.ts` has `traitsByTraitId: Partial<Record<string, Trait>>`, which thens
  // turns into an array, which then is turned back into an object/map here. Note `avatar.handlers.ts` is a mocked
  // implementation. It is more likely that DynamoDB returns this as an array, so this reduce here will be needed:
  const defaultTraitsByID = defaultTraits.reduce((acc, trait) => {
    acc[trait.id] = trait

    return acc
  }, {} as Record<string, Trait>)

  const defaultTraitValues = (Object.entries(config.traitTypeConfigs) as [TraitTypeId, TraitTypeConfig][])
    .reduce((acc, [traitTypeID, traitTypeConfig]) => {
      acc[traitTypeID] = to<AvatarTraitValue>({
        trait: defaultTraitsByID[traitTypeConfig.defaultTrait] || null,
        traitColors: traitTypeConfig.defaultTraitColors || {},
      })

      return acc
    }, {} as TraitValues)

  const previewSilhouette: PreviewSilhouette = {
    head: defaultTraitValues.head.trait || FALLBACK_HEAD_ITEM,
    body: defaultTraitValues.body.trait || FALLBACK_BODY_ITEM,
  }

  return {
    ...config,
    defaultTraitValues,
    previewSilhouette,
  }
}

function transformCreatorRendererConfig(
  response: AxiosResponse<BackendAvatarConfigWithTraits>,
): AxiosResponse<AvatarConfig> {
  return to<AxiosResponse<AvatarConfig>>({
    ...response,
    data: getCreatorRendererConfig(response.data.config, response.data.configDefaultTraits),
  })
}

export function fetchCreatorRendererConfig(axiosConfig?: AxiosRequestConfig) {
  return axios.get<BackendAvatarConfigWithTraits>(endpoints.fetchCreatorRendererConfig, axiosConfig).then(transformCreatorRendererConfig)
}

export const CreatorRendererConfigService = {
  fetchCreatorRendererConfig,
}
