import { AvatarDownloadService, NFMeDownloadConfig, NFMeFileDownloadFn, NFMeFileDownloadTriggerFn, NFMeFileOptions, OptionalNFMeFileOptions, isPromise } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AvatarControllerStore } from '../avatar-controller.store'

export class AvatarDownloaderControllerStore {
  avatarControllerStore: AvatarControllerStore

  downloadFn: NFMeFileDownloadTriggerFn | null = null

  isDownloading = false

  isDownloaded = false

  downloadConfig: NFMeDownloadConfig = AvatarDownloadService.loadConfig()

  constructor(avatarControllerStore: AvatarControllerStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.avatarControllerStore = avatarControllerStore
  }

  updateConfig(downloadConfig: NFMeDownloadConfig) {
    this.downloadConfig = downloadConfig

    AvatarDownloadService.saveConfig(downloadConfig)
  }

  download(downloadFn: NFMeFileDownloadFn, options: OptionalNFMeFileOptions) {
    const downloadFnOptions: NFMeFileOptions = {
      fileName: options.fileName || this.avatarControllerStore.nfmeLabel,
      type: options.type || 'image/png',
      size: options.size || 2048,
    }

    return new Promise<void>((resolve) => {
      let downloadFnCalled = false

      this.downloadFn = async () => {
        if (downloadFnCalled) return

        downloadFnCalled = true

        const downloadFnReturn = downloadFn(downloadFnOptions)

        if (!isPromise(downloadFnReturn)) {
          this.downloadFn = null

          resolve()

          return
        }

        this.isDownloading = true
        this.isDownloaded = false

        await downloadFnReturn

        this.downloadFn = null
        this.isDownloading = false
        this.isDownloaded = true

        resolve()
      }
    })
  }
}
