import { Alert, Box } from '@mui/material'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'

import { useNotifications } from '../../../store/notifications/notifications.hook'

import { ToastProps } from './toast.types'

function ToastComponent({
  id,
  type,
  message,
  duration,
  dismissible = true,
}: ToastProps) {
  const { removeNotification } = useNotifications()

  const handleRemoveNotification = useCallback(() => {
    removeNotification(id)
  }, [removeNotification, id])

  useEffect(() => {
    const timeout = setTimeout(handleRemoveNotification, duration)

    return () => clearTimeout(timeout)
  }, [handleRemoveNotification, duration])

  return (
    <Box
      component={ motion.div }
      layout
      sx={{
        position: 'relative',
        backgroundColor: '#060b16',
        border: '1px solid rgba(255, 255, 255, .125)',
        px: 1,
        py: 0,
        pr: 2,
        borderRadius: 1,
        pointerEvents: 'auto',
        cursor: dismissible ? 'pointer' : 'not-allowed',

        '&::before': {
          content: '""',
          position: 'absolute',
          top: -5,
          right: -8,
          bottom: -5,
          left: -8,
        },
      }}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
      onClick={ dismissible ? handleRemoveNotification : undefined }>
      <Alert
        severity={ type }
        sx={{
          background: 'none',
          border: 'none',
          p: 0,
          m: 0,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}>
        {message}
      </Alert>
    </Box>
  )
}

export const Toast = observer(ToastComponent)
