export const DATE_DEFAULT_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
}

export const DATE_TIME_DEFAULT_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  ...DATE_DEFAULT_FORMAT_OPTIONS,
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
}

export function normalizeDate(d?: Date | number | string): Date | null {
  if (!d) return null

  const date = typeof d === 'number' || typeof d === 'string' ? new Date(d) : d

  return Number.isNaN(date.getTime()) ? null : date
}

export function toFormattedDateTime(d?: Date | number | string): string | null {
  const date = normalizeDate(d)

  if (date === null) return null

  return date.toLocaleDateString('en-US', DATE_TIME_DEFAULT_FORMAT_OPTIONS)
}

export function toFormattedDate(d?: Date | number | string): string | null {
  const date = normalizeDate(d)

  if (date === null) return null

  return date.toLocaleDateString('en-US', DATE_DEFAULT_FORMAT_OPTIONS)
}

function addOrdinalIndicator(day: number): string {
  if (day % 10 === 1 && day !== 11) {
    return `${day}st`
  }

  if (day % 10 === 2 && day !== 12) {
    return `${day}nd`
  }

  if (day % 10 === 3 && day !== 13) {
    return `${day}rd`
  }
  return `${day}th`
}

export function formatDateWithOrdinalIndicator(isoDate: string): string {
  const publishDate = new Date(isoDate)

  const dayWithOrdinal = addOrdinalIndicator(publishDate.getDate())
  const formattedMonth = publishDate.toLocaleDateString(undefined, { month: 'long' })
  const formattedYear = publishDate.toLocaleDateString(undefined, { year: 'numeric' })

  return `${formattedMonth} ${dayWithOrdinal}, ${formattedYear}`
}
