import { Typography, Box } from '@mui/material'
import { canUserSeeAs, WithChildrenProp } from '@ng-mono/sdk'

import { BaseMenuItemConfig, BaseMenuItemProps, MenuItemType } from '../base/menu-base-item.types'

export interface MenuChildrenItemConfig extends BaseMenuItemConfig, WithChildrenProp {
  type: MenuItemType.Children;
}

export type MenuChildrenItemProps = BaseMenuItemProps & Omit<MenuChildrenItemConfig, 'type'>

export function MenuChildrenItem({
  // BaseMenuItemProps:
  root = 'div',
  sx: sxProp,
  // variant,
  userRole = null,
  realUserRole = null,
  // onAction,

  // BaseMenuItemConfig:
  title,
  availableTo,
  hidden,

  // MenuChildrenItemConfig:
  children,
}: MenuChildrenItemProps) {
  const { isHidden, visibilitySx } = canUserSeeAs(userRole, realUserRole, availableTo, hidden)

  if (isHidden) return null

  const rootSx = visibilitySx ? { ...sxProp, ...visibilitySx as any } : sxProp

  return (
    <Box component={ root } sx={ rootSx }>
      { title ? (
        <Typography
          variant="button"
          sx={{
            position: 'relative',
            minHeight: '48px',
            px: ({ gaps }) => gaps.outerPadding,
            py: 1,
            display: 'flex',
            alignItems: 'center',
            borderBottom: ({ borders }) => borders.default(),
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          { title }
        </Typography>
      ) : null }

      <Box
        sx={{
          position: 'relative',
        }}>
        { children }
      </Box>
    </Box>
  )
}
