import { IS_SERVER } from '../common/common.constants'

/* eslint-disable no-console */
export enum DevLogGroup {
  Init = 'Init',
  Auth = 'Auth',
  Routing = 'Routing',
  PushPaths = 'PushPaths',
  Mocks = 'Mocks',
}

export const devLogGroupEmojis: Record<DevLogGroup, string> = {
  [DevLogGroup.Init]: '💿',
  [DevLogGroup.Auth]: '🔑',
  [DevLogGroup.Routing]: '🗺️',
  [DevLogGroup.PushPaths]: '📢',
  [DevLogGroup.Mocks]: '🚧',
}

export const devLogGroupEnabled: Record<DevLogGroup, boolean> = process.env.NODE_ENV === 'development' ? {
  [DevLogGroup.Init]: !!process.env.GH_DEV_LOG_INIT,
  [DevLogGroup.Auth]: !!process.env.GH_DEV_LOG_AUTH,
  [DevLogGroup.Routing]: !!process.env.GH_DEV_LOG_ROUTING,
  [DevLogGroup.PushPaths]: !!process.env.GH_DEV_LOG_PUSH_PATHS,
  [DevLogGroup.Mocks]: !!process.env.GH_DEV_LOG_MOCKS,
} : {
  [DevLogGroup.Init]: false,
  [DevLogGroup.Auth]: false,
  [DevLogGroup.Routing]: false,
  [DevLogGroup.PushPaths]: false,
  [DevLogGroup.Mocks]: false,
}

let lastLogKey = ''

export function devLog(devLogGroup: DevLogGroup, ...args: any[]) {
  if (IS_SERVER) return

  let devLogEnabled = devLogGroupEnabled[devLogGroup]

  try {
    const localStorageDevLogEnabled = window.localStorage.getItem(`devLog${devLogGroup}`) === 'true'
    devLogEnabled = localStorageDevLogEnabled || devLogEnabled
  } catch (e) {
    console.error(e)
  }

  if (!devLogEnabled) return

  const logKey = JSON.stringify([devLogGroup, ...args])

  // In development, React's Strict Mode will call useEffects twice, so anything logged from there will appear twice.
  // We use this simple `lastLogKey === lastLogKey` check to filter out duplicates:
  if (lastLogKey === logKey) return

  lastLogKey = logKey

  console.log(`${devLogGroupEmojis[devLogGroup]} ${devLogGroup} ›`, ...args)
}

export function enableDevLog(devLogGroup?: DevLogGroup) {
  if (devLogGroup) {
    const devLogKey = `devLog${devLogGroup}`

    console.log(`Enable ${devLogKey}`)

    localStorage.setItem(devLogKey, 'true')

    return
  }

  Object.keys(devLogGroupEnabled).forEach((devLogKey) => {
    enableDevLog(devLogKey as DevLogGroup)
  })
}

export function disableDevLog(devLogGroup?: DevLogGroup) {
  if (devLogGroup) {
    const devLogKey = `devLog${devLogGroup}`

    console.log(`Disable ${devLogKey}`)

    localStorage.setItem(devLogKey, 'false')

    return
  }

  Object.keys(devLogGroupEnabled).forEach((devLogKey) => {
    disableDevLog(devLogKey as DevLogGroup)
  })
}

export function clearDevLog(devLogGroup?: DevLogGroup) {
  if (devLogGroup) {
    const devLogKey = `devLog${devLogGroup}`

    console.log(`Clear ${devLogKey}`)

    localStorage.removeItem(devLogKey)

    return
  }

  Object.keys(devLogGroupEnabled).forEach((devLogKey) => {
    clearDevLog(devLogKey as DevLogGroup)
  })
}

devLog(DevLogGroup.Init, 'Dev Logs', devLogGroupEnabled)

export function monkeyPatchConsole() {
  if (process.env.NODE_ENV !== 'development' || !process.env.GH_MONKEY_PATCH_CONSOLE || IS_SERVER) return

  devLog(DevLogGroup.Init, '🐒 Monkey-patching console methods...')

  // See https://stackoverflow.com/a/49651719/3723993
  const isProxy = (obj: any) => {
    if (typeof obj === 'object' && Array.isArray(obj)) return (obj as any[]).some(isProxy)

    if (typeof obj !== 'object' || obj?.constructor?.name !== 'Object') return false

    try {
      window.postMessage(obj, '*')
    } catch (err) {
      const error = err as any

      return error && error.code === 25
    }

    return false
  }

  // const proxyToObj = (obj: any) => (Array.isArray(obj) ? (obj as any[]).map((childObj) => ({ ...childObj })) : ({ ...obj }))
  const proxyToObj = (obj: any) => JSON.parse(JSON.stringify(obj))

  const monkeyPatchConsoleFn = (consoleFnKey: keyof Console) => {
    const consoleFn = console[consoleFnKey] as Function

    if (typeof consoleFn !== 'function') return

    console[consoleFnKey] = ((...args: any[]) => {
      const transformedArgs = args.map((arg) => (isProxy(arg) ? proxyToObj(arg) : arg))

      return consoleFn(...transformedArgs)
    }) as any
  }

  Object.keys(console).forEach((consoleKey: string) => {
    monkeyPatchConsoleFn(consoleKey as keyof Console)
  })
}
