import { CampaignFlowStep } from '../campaigns/campaigns.types'

export interface TopicInfoRouteParams {
  topicID?: string;
}

export interface TopicActivitiesRouteParams {
  topicID?: string;
}

export interface CelebritiesRouteParams {
  celebritySlug?: string;
  celebrityID?: string;
  step?: CampaignFlowStep;
}

export interface NFMeOfTheWeekRouteParams {
  step?: CampaignFlowStep;
}

export interface AFreshStartRouteParams {
  step?: CampaignFlowStep;
}

export const RoutePaths = {
  Home: '/',
  Team: '/team',

  TopicInfo: ({ topicID = ':topicID' }: TopicInfoRouteParams = {}) => `/topics/${topicID}`,
  TopicActivities: ({ topicID = ':topicID' }: TopicActivitiesRouteParams = {}) => `/topics/${topicID}/activities`,

  // User-Owned Stuff:
  NFMeCreator: '/nfme-creator',
  MyProgress: '/my-progress',
  // MyNFMes: '/my-nfmes',
  Account: '/account',

  // Auth:
  ChangeEmail: '/change-email',
  ChangePassword: '/change-password',
  ForgotPassword: '/forgot-password',
  Login: '/login',
  Onboarding: '/onboarding',
  SignUp: '/signup',
  AccountConfirmation: '/confirm-account',
  EmailVerification: '/verify-email',

  // Campaign - Celebrities:
  Celebrities: ({
    celebritySlug = ':celebritySlug',
    celebrityID = ':celebrityID',
    step,
  }: CelebritiesRouteParams = {}) => `/c/celebrities/${celebritySlug}/${celebrityID}${step ? `/${step}` : ''}`,

  // Campaign - NFMe of the week:
  NFMeOfTheWeek: ({ step }: NFMeOfTheWeekRouteParams = {}) => `/c/nfme-of-the-week/${step ? `/${step}` : ''}`,

  // Campaign - A fresh start:
  AFreshStart: ({ step }: AFreshStartRouteParams = {}) => `/c/a-fresh-start/${step ? `/${step}` : ''}`,

  // Development:
  ErrorTests: '/dev/error-tests',
  OgGenerator: '/dev/og-generator',
} as const

export const SyntheticRoutePaths = {
  AuthLoading: 'auth-loading',
  AuthError: 'auth-error',
  PushPath: 'push-path',
} as const
