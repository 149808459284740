// NAV - BaseNavbar:

import { sanitizeStringForMail, NFME_GALAXY, IS_SERVER } from '@ng-mono/sdk'

export const MENU_BUTTON_ID = 'navbarMenuButton'

export const MENU_BUTTON_SIZE = 46

export const FIX_NAVBAR_AT_VH = 1

// NAV - NavbarMenuPortal:

export const NAVBAR_MENU_PORTAL_ID = 'navbarMenuPortal'

// NAV - NavbarToolbarPortal:

// export const NAVBAR_MENU_PORTAL_ID = 'navbarMenuPortal'

// NAV - Toolbars & Icons:

export const TOOLBAR_BUTTON_DEFAULT_ICON_SIZE = 24

// LAYOUT:
export const SCROLLABLE_CONTAINER_ID = 'scrollableRoot'
export const scrollableElement = IS_SERVER ? null : document.getElementById(SCROLLABLE_CONTAINER_ID) as HTMLElement

// REACT APP:

export const REACT_ROOT_ID = 'root'
export const REACT_ROOT_DESKTOP_ID = 'root-desktop'
export const reactRootElement = IS_SERVER ? null : document.getElementById(REACT_ROOT_ID) as HTMLElement

// NEXT.JS WEBSITE:

// export const NEXT_ROOT_ID = '__next'
// export const nextRootElement = IS_SERVER ? null : document.getElementById(NEXT_ROOT_ID) as HTMLElement

export const FEEDBACK_MAIL = {
  mail: 'team@nfmegalaxy.com',
  subject: `Feedback ${NFME_GALAXY}`,
  body: "Hi team,\nHere's some feedback\n-\n\n\nP.S. I sent this feedback from:",
}

export const MAILTO_FEEDBACK_HREF = `mailto:${FEEDBACK_MAIL.mail}?subject=${sanitizeStringForMail(FEEDBACK_MAIL.subject)}&body=${sanitizeStringForMail(`${FEEDBACK_MAIL.body}`)}`

// Eternal Weekly Avatar
// S3 Bucket
export const S3_BUCKET_BASE_URL = 'https://ng-s3-website-content.s3.eu-central-1.amazonaws.com/public'
const VERSION = 'latest'

export const WEEKLY_AVATAR_S3_URL = `${S3_BUCKET_BASE_URL}/Logo+Eternal_${VERSION}.svg`
