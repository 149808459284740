import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { isServiceFnWithParams, ServiceFnWithoutParams, ServiceFnWithParams } from '../common/common-store.types'
import { isAbortControllerErr } from '../common/common-store.utils'

import { ReadOnlyStoreOptions, ReadOnlyStoreResource } from './readonly-store.types'

export function createEmptyReadOnlyStoreResource<D>(data?: D): ReadOnlyStoreResource<D> {
  return {
    isLoading: false,
    data,
  }
}

export function clearReadOnlyStoreResource<D>(storeResource: ReadOnlyStoreResource<D>) {
  storeResource.abortController?.abort()

  Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, {
    promise: undefined,
    data: undefined,
    error: undefined,
    isLoading: false,
  })
}

function updateStoreResourceLoading<D>(
  storeResource: ReadOnlyStoreResource<D>,
  promise: Promise<AxiosResponse<D>>,
  storeOptions: ReadOnlyStoreOptions,
) {
  Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, {
    promise,
    data: storeOptions?.preserve ? storeResource.data : undefined,
    error: undefined,
    isLoading: true,
  })
}

function updateStoreResourceLoaded<D>(
  storeResource: ReadOnlyStoreResource<D>,
  data: D,
) {
  Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, {
    // promise,
    data,
    error: undefined,
    isLoading: false,
  })
}

function updateStoreResourceError<D>(
  storeResource: ReadOnlyStoreResource<D>,
  error: Error,
) {
  Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, {
    // promise,
    data: undefined,
    error,
    isLoading: false,
  })
}

export async function fetchStoreResource<D>(
  storeResource: ReadOnlyStoreResource<D>,
  serviceFn: ServiceFnWithoutParams<D>,
): Promise<D | Error>
export async function fetchStoreResource<D, P>(
  storeResource: ReadOnlyStoreResource<D>,
  serviceFn: ServiceFnWithParams<D, P>,
  params: P,
  storeOptions?: ReadOnlyStoreOptions,
): Promise<D | Error>
export async function fetchStoreResource<D>(
  storeResource: ReadOnlyStoreResource<D>,
  serviceFn: ServiceFnWithoutParams<D>,
  params: null,
  storeOptions: ReadOnlyStoreOptions,
): Promise<D | Error>
export async function fetchStoreResource<D, P>(
  storeResource: ReadOnlyStoreResource<D>,
  serviceFn: ServiceFnWithoutParams<D> | ServiceFnWithParams<D, P>,
  params?: null | P,
  storeOptions: ReadOnlyStoreOptions = {},
): Promise<null | D | Error> {
  // TODO (Dani): Add prefetch option.
  // TODO (Dani): Add cache/ttl option.

  storeResource.abortController?.abort()

  const abortController = new AbortController()
  const axiosConfig: AxiosRequestConfig = { signal: abortController.signal }

  Object.assign<ReadOnlyStoreResource<D>, Partial<ReadOnlyStoreResource<D>>>(storeResource, { abortController })

  let promise: Promise<AxiosResponse<D, any>>

  if (isServiceFnWithParams(serviceFn) && params) {
    promise = serviceFn(params, axiosConfig)
  } else if (!isServiceFnWithParams(serviceFn)) {
    promise = serviceFn(axiosConfig)
  } else {
    promise = Promise.reject(new Error('Invalid serviceFn arguments.'))
  }

  updateStoreResourceLoading(storeResource, promise, storeOptions)

  try {
    const result = await promise
    const { data } = result

    updateStoreResourceLoaded(storeResource, data)

    return data
  } catch (err) {
    // TODO (Dani): Probably need some parsing here:
    const error = err as Error

    if (isAbortControllerErr(error)) return null

    updateStoreResourceError(storeResource, error)

    return error
  }
}
