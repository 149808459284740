import { Box } from '@mui/material'
import { WithOptionalChildrenProp } from '@ng-mono/sdk'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const NAVBAR_TOOLBAR_PORTAL_ID = 'navbarToolbarPortal'

function getToolbarSlotElement() {
  return document.getElementById(NAVBAR_TOOLBAR_PORTAL_ID) as HTMLDivElement
}

export function NavbarToolbarPortal({ children }: WithOptionalChildrenProp) {
  // Portal setup:

  const [toolbarSlotElement, setToolbarSlotElement] = useState<HTMLDivElement>(getToolbarSlotElement())

  useEffect(() => {
    if (toolbarSlotElement === null) {
      const nextToolbarSlotElement = getToolbarSlotElement()

      if (nextToolbarSlotElement) setToolbarSlotElement(nextToolbarSlotElement)

      // TODO (Dani): Alternatively, try again?
    }
  }, [toolbarSlotElement])

  if (toolbarSlotElement === null) return null

  return createPortal(
    <Box
      component={ motion.div }
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}>
      { children }
    </Box>,
    toolbarSlotElement,
  )
}
