import { Answer, AnswerValue } from '../../answers/answers.types'
import { ActivityDocNode } from '../../doc/doc.types'
import { LifecycleStage } from '../../lifecycle/lifecycle.types'
import { TocEntryOptional } from '../../toc/toc.types'

export type ActivityStatus = 'passed' | 'failed'

export enum ActivityTypes {
  Content = 'content',
  Rating = 'rating',
  Ranking = 'ranking',
  Question = 'question',
}

export interface BaseActivity<V extends AnswerValue = AnswerValue> {
  // Shared with Topic, Path and Activity:
  id: string;
  stage: LifecycleStage;
  toc?: TocEntryOptional;
  content?: ActivityDocNode[];

  // Activity-specific:
  type: ActivityTypes;
  userAnswer?: Answer<V>;

  // TODO (Dani): To be implemented:
  info?: string;
  minSeconds?: number;
  maxSeconds?: number;
}
