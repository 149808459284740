import { BaseMenuItemConfig, MenuItemType } from '../base/menu-base-item.types'
import { MenuButtonItem, MenuButtonItemProps } from '../button/menu-button-item.component'

export interface MenuActionItemConfig<A extends string = string> extends BaseMenuItemConfig {
  type: MenuItemType.Action;
  label: string;
  action: A;
}

export type MenuActionItemProps = MenuButtonItemProps & Omit<MenuActionItemConfig, 'type'>

export function MenuActionItem(menuActionItemsProps: MenuActionItemProps) {
  return <MenuButtonItem { ...menuActionItemsProps } />
}
