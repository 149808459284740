import { to } from '../../utils/typescript/typescript.utils'
import { NFME_GALAXY } from '../brand/brand.constants'
import { CATEGORY_MOCK_BLOCKCHAIN, CATEGORY_MOCK_BRANDS, CATEGORY_MOCK_TECHNOLOGY } from '../categories/categories.mock'
import { getLoremIpsumTextNodes } from '../doc/doc.utils'
import { LifecycleStage } from '../lifecycle/lifecycle.types'
import { MediaType } from '../media/media.types'
import { REWARD_MOCKS_BY_ID } from '../rewards/rewards.mock'

import { BackendTopic } from './topics-n-categories.types'

export const TOPICS_MOCK_BY_ID = {
  blockchainTopic1: to<BackendTopic>({
    id: 'blockchainTopic1',
    stage: LifecycleStage.Active,
    toc: {
      name: 'Intro to Blockchain',
      description: 'Learn about the invention of blockchain, and gain a basic understanding of the technology powering it',
      imgMedia: {
        type: MediaType.Image,
        src: 'https://cdn-icons-png.flaticon.com/512/825/825540.png',
        size: 'contain',
        padding: true,
      },
    },
    content: getLoremIpsumTextNodes(3, 'halfParagraph'),
    progress: 0,
    categories: [CATEGORY_MOCK_BLOCKCHAIN, CATEGORY_MOCK_TECHNOLOGY],
    rewards: [REWARD_MOCKS_BY_ID.hairAfro],
  }),

  howMNworksTopic: to<BackendTopic>({
    id: 'howMNworksTopic',
    stage: LifecycleStage.Draft,
    toc: {
      name: 'How do NFTs work?',
      description: `Learn about the ${NFME_GALAXY} platform and how to get your first free Soulbound NFT!`,
      imgMedia: {
        type: MediaType.Image,
        src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Maxed+2+the+moon+1.svg',
        size: 'contain',
        padding: true,
      },
    },
    content: getLoremIpsumTextNodes(5, 'halfParagraph'),
    progress: 0,
    categories: [CATEGORY_MOCK_BLOCKCHAIN, CATEGORY_MOCK_TECHNOLOGY],
  }),
  clogsTopic: to<BackendTopic>({
    id: 'clogsTopic',
    stage: LifecycleStage.Active,
    toc: {
      name: 'Co-create with Clogs™',
      description: 'Clogs™ is looking for your help to co-create their next clogs collection',
      imgMedia: {
        type: MediaType.Image,
        src: 'https://maxednft-in-app-content.s3.eu-central-1.amazonaws.com/in-app-content/Clogs.svg',
        size: 'contain',
        padding: true,
      },
    },
    content: getLoremIpsumTextNodes(1, 'halfParagraph'),
    progress: 0,
    categories: [CATEGORY_MOCK_BRANDS],
  }),

}
