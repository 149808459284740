// Site title:

export const PAGE_NAME = 'NFMe Galaxy'
export const PAGE_TITLE_PREFIX = `${PAGE_NAME} - `
export const PAGE_TITLE_SUFFIX = ` - ${PAGE_NAME}`
export const DEFAULT_PAGE_TITLE = `${PAGE_TITLE_PREFIX}Where your Web3 journey begins 🚀`

// Other site metadata:

export const DEFAULT_PAGE_DESCRIPTION = 'Get your first NFT which you can develop and use as your web3 identity'
export const DEFAULT_PAGE_KEYWORDS_ARR = ['web3', 'blockchain', 'nft', 'revolution', 'soulbound', 'web2', 'nfme', 'nfmegalaxy', 'learn', 'learning', 'eternals']
export const DEFAULT_PAGE_KEYWORDS_STR = DEFAULT_PAGE_KEYWORDS_ARR.join(',')
export const DEFAULT_SHARE_IMAGE_SRC = '/og-images/default-og-image.png'

// Trademark:

export const TM = '\u2122'
export const NFME = `NFMe${TM}`
export const NFMES = `NFMes${TM}`
export const NFME_GALAXY = `NFMe Galaxy${TM}`

// Misc. NFMe wording / labels:

export const NFME_DOWNLOAD_LABEL = 'NFMe'
export const NFME_DEFAULT_MENU_ITEM_LABEL = `My first ${NFME_DOWNLOAD_LABEL}`
