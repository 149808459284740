import { SxProps, Theme } from '@mui/material'

import { UserRole } from '../user/user.types'

export function canUserSeeAs(
  userRole: null | UserRole,
  realUserRole: null | UserRole,
  availableTo?: null | UserRole | UserRole[],
  hidden?: boolean,
) {
  // This should never happen because the router should make sure the auth is loaded:
  if (userRole === null || realUserRole === null) {
    return { isVisible: true, isHidden: false, isFaded: false, visibilitySx: null }
  }

  const availableToArray = Array.isArray(availableTo) ? availableTo : (availableTo ? [availableTo] : null)
  const isVisible = !hidden && (!availableToArray || availableToArray.includes(realUserRole))
  const isHidden = !isVisible
  const isFaded = isVisible && !!availableToArray && !availableToArray.includes(userRole)
  const visibilitySx: SxProps<Theme> = isFaded ? { opacity: 0.25 } : null

  return { isVisible, isHidden, isFaded, visibilitySx }
}

export function getUserRoleFromEmail(email: string) {
  if (email.endsWith('@nfmegalaxy.com')) {
    return 'admin'
  }

  return 'user'
}

export function canEmailSeeAs(
  email: string,
  availableTo?: UserRole | UserRole[],
  hidden?: boolean,
) {
  const userRole = getUserRoleFromEmail(email)

  return canUserSeeAs(userRole, userRole, availableTo, hidden)
}
