import { WithChildrenProp } from '@ng-mono/sdk'
import { createContext, useContext } from 'react'

import { BaseLinkProps } from './base-link.types'

const LinkContext = createContext<React.ComponentType<BaseLinkProps> | null>(null)

export interface LinkComponentProviderProps extends WithChildrenProp {
  linkComponent: React.ComponentType<BaseLinkProps>;
}

export function LinkComponentProvider({
  children,
  linkComponent,
}: LinkComponentProviderProps) {
  return (
    <LinkContext.Provider value={ linkComponent }>
      {children}
    </LinkContext.Provider>
  )
}

export function useLinkComponent() {
  const linkComponent = useContext(LinkContext)

  if (linkComponent === null) {
    throw new Error('A Link component must be provided to LinkComponentProvider.')
  }

  return linkComponent
}
