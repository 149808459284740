import { endpoints } from '../../../utils/server/endpoints/server-endpoints.constants'
import { fetchCampaign } from '../base/base-campaign.utils'

async function fetchNFMeOfTheWeekCampaign() {
  return fetchCampaign(
    endpoints.fetchNFMeOfTheWeekCampaignFlow,
    endpoints.fetchNFMeOfTheWeekCampaignAvatar,
  )
}

export const NFMeOfTheWeekCampaignService = {
  fetchNFMeOfTheWeekCampaign,
}
