import { getMocks } from '../../mocks/mock-state.utils'
import { SocialNetwork } from '../social/social.types'
import { isChannelValidSocialNetwork } from '../user/user.utils'

import { LAST_CHANNEL_LS_KEY, LAST_CHANNEL_LS_TTL, VISITED_CAMPAIGNS_SLUGS_LS_KEY } from './campaigns.constants'

let visitedCampaignsSlugs: string[] = []

try {
  visitedCampaignsSlugs = JSON.parse(localStorage.getItem(VISITED_CAMPAIGNS_SLUGS_LS_KEY) || '[]') || []

  if (!Array.isArray(visitedCampaignsSlugs)) throw new Error(`Could not load ${VISITED_CAMPAIGNS_SLUGS_LS_KEY}.`)
} catch (err) { /* Do nothing */ }

function getCampaignNFMe(slug: string) {
  const avatarMocks = getMocks()

  return Object.values(avatarMocks.outfits).find(({ label }) => label.includes(slug))
}

function isCampaignVisited(slug: string) {
  return visitedCampaignsSlugs.includes(slug) && !!getCampaignNFMe(slug)
}

function markCampaignAsVisited(slug: string) {
  if (visitedCampaignsSlugs.includes(slug)) return

  visitedCampaignsSlugs.push(slug)

  localStorage.setItem(VISITED_CAMPAIGNS_SLUGS_LS_KEY, JSON.stringify(visitedCampaignsSlugs))
}

function getLastChannel(): SocialNetwork | null {
  try {
    const item = localStorage.getItem(LAST_CHANNEL_LS_KEY)

    if (!item) return null

    const { created, channel } = JSON.parse(item)

    const currentTime = new Date().getTime()

    const elapsedTime = currentTime - created

    if (elapsedTime > LAST_CHANNEL_LS_TTL || !isChannelValidSocialNetwork(channel)) {
      localStorage.removeItem(LAST_CHANNEL_LS_KEY)
      return null
    }

    return channel as SocialNetwork
  } catch {
    localStorage.removeItem(LAST_CHANNEL_LS_KEY)
    return null
  }
}

function updateLastChannel(channel: SocialNetwork) {
  const created = new Date().getTime()
  const item = JSON.stringify({ channel, created })

  localStorage.setItem(LAST_CHANNEL_LS_KEY, item)
}

export const CampaignsService = {
  // Campaign data:
  isCampaignVisited,
  getCampaignNFMe,
  markCampaignAsVisited,

  // Channel:
  getLastChannel,
  updateLastChannel,
}
