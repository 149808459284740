import { COUNTRIES } from '../../data/countries/countries.constants'
import { Country } from '../../data/countries/countries.types'
import { SOCIAL_NETWORKS } from '../social/social.constants'
import { SocialNetwork } from '../social/social.types'

import { Gender, UserChannel } from './user.types'

export function getValidGender(gender: string): Gender | null {
  return gender === '' || gender === 'male' || gender === 'female' || gender === 'non-binary' ? gender : null
}

export function getValidCountry(address: string): Country | null {
  return COUNTRIES.find((country) => country.isoCode === address) || null
}

export interface ParsedISODate {
  year: number;
  month: number;
  day: number;
}

export function parseISODate(date: string): ParsedISODate | null {
  const match = date.match(/(\d{4})-(\d{2})-(\d{2})/)

  return match && match.length === 4 ? {
    year: parseInt(match[1], 10),
    month: parseInt(match[2], 10),
    day: parseInt(match[3], 10),
  } : null
}

export function parsedISODateToString(parsedDate?: ParsedISODate | null): string {
  return parsedDate ? `${
    parsedDate.year
  }-${
    `0${parsedDate.month}`.slice(-2)
  }-${
    `0${parsedDate.day}`.slice(-2)
  }T00:00:00` : ''
}

export function parsedISODateToDate(parsedDate?: ParsedISODate | null): Date {
  return new Date(parsedDate ? parsedISODateToString(parsedDate) : '')
}

export function isChannelValidSocialNetwork(channelParam: UserChannel | null): channelParam is SocialNetwork {
  return !!channelParam && channelParam !== 'unknown' && SOCIAL_NETWORKS.includes(channelParam)
}
