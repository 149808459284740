import { useMediaQuery } from '@mui/material'
import { useEffect } from 'react'

const hasHoverQuery = '(any-hover: hover)'

export const hasFocusClassName = 'hasFocus'
export const hasActiveClassName = 'hasActive'
export const hasHoverClassName = 'hasHover'

export function withHasFocusClassName(selector: string) {
  return `.${hasFocusClassName} ${selector}`
}

export function withHasActiveClassName(selector: string) {
  return `.${hasActiveClassName} ${selector}`
}

export function withHasHoverClassName(selector: string, includeActive = false) {
  const hoverClassName = `.${hasHoverClassName} ${selector}`

  return includeActive ? `${hoverClassName}, ${withHasActiveClassName(selector.replaceAll(':hover', ':active'))}` : hoverClassName
}

export function useHasHover() {
  return useMediaQuery(hasHoverQuery)
}

export function useHasHoverClassName() {
  const hasHover = useHasHover()

  useEffect(() => {
    if (hasHover) document.body.classList.add(hasHoverClassName)
    else document.body.classList.remove(hasHoverClassName)
  }, [hasHover])

  return hasHover
}
