import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { AxiosError } from 'axios'
import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { camelCaseToTitleCase } from '../../utils/common/format/format.utils'
import { removeSpaces } from '../../utils/parsing/parsing.utils'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { UserProfileChangeLabel } from '../user-analytics/mixpanel/user-analytics-mixpanel.types'
import { AuthError } from '../user-analytics/sentry/errors/auth/auth-error.class'
import { UserAnalyticsService } from '../user-analytics/user-analytics.service'
import { UserService } from '../user/user.service'
import { UserData, UserInfo, UserRole, UserSource } from '../user/user.types'
import { getValidCountry, getValidGender } from '../user/user.utils'

import { AuthService } from './auth.service'
import { CognitoIdTokenPayload, CognitoUserAttributeData } from './auth.types'
import { canUserSeeAs, getUserRoleFromEmail } from './auth.utils'

/**
 * Cognito examples: https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js
 */
export class AuthStore implements BaseStore {
  private role: UserRole | null = null

  private realRole: UserRole | null = null

  isAuthInitialized = false

  isAuthenticated = false

  userError: Error | AxiosError | null = null

  user: UserInfo | null = null

  session: CognitoUserSession | null = null

  rootStore: SdkRootStore

  resendConfirmationCode = AuthService.resendConfirmationCode.bind(AuthService)

  signUpWithEmail = AuthService.signUpWithEmail.bind(AuthService)

  confirmRegistration = AuthService.confirmRegistration.bind(AuthService)

  getAttributeVerificationCode = AuthService.getAttributeVerificationCode.bind(AuthService)

  forgotPassword = AuthService.forgotPassword.bind(AuthService)

  confirmPassword = AuthService.confirmPassword.bind(AuthService)

  changePassword = AuthService.changePassword.bind(AuthService)

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore

    this.initAuth(true)
  }

  get isAuthLoading() {
    return !this.isAuthInitialized
  }

  async initAuth(firstTime = false) {
    this.isAuthInitialized = false
    this.unsetUser()
    this.userError = null
    this.session = null

    if (!firstTime) {
      await this.signOut()
    }

    // Call getSession to init or when requesting isAuthenticated (might not need to call it that much)

    // TODO (Dani): isAuthLoading only in the first load, but not updates?

    this.getSession().finally(() => {
      this.isAuthInitialized = true
    })
  }

  async getSession() {
    const session = await AuthService.getSession().catch(() => null)

    await this.updateSession(session)
  }

  async signInWithEmail(email: string, password: string) {
    const session = await AuthService.signInWithEmail(email, password)

    this.role = null
    this.realRole = null

    await this.updateSession(session)

    return this.user
  }

  async updateUser(userData: UserData, userSource?: UserSource) {
    const { user } = this

    if (!user) return

    const { subId, countryOfResidenceName, meta, ...prevUserData } = user

    await UserService.updateUser({
      updatedUserData: {
        ...prevUserData,
        ...userData,
        source: userSource ?? prevUserData.source,
      },
    })

    // Update Cognito Email:

    const newEmail = userData.email === user.email ? undefined : (userData.email || undefined)

    if (newEmail) {
      const requiredUserAttributes: CognitoUserAttributeData[] = [{
        Name: 'email',
        Value: newEmail,
      }]

      await AuthService.updateAttributes(requiredUserAttributes)
    }

    // Send individual events to Mixpanel for every updated field:

    Object.entries(userData).forEach(([key, newValue]: [string, string]) => {
      const oldValue = user[key as keyof UserInfo] || null

      if (newValue === oldValue || typeof oldValue === 'object') return

      const label = camelCaseToTitleCase(key) as UserProfileChangeLabel

      UserAnalyticsService.pushEvent({
        name: 'User Profile Change',
        label,
        oldValue,
        newValue,
      })
    })

    await this.getSession()
  }

  async resetUser() {
    return this.updateUser({
      email: '',
      firstName: '',
      lastName: '',
      countryOfResidenceCode: '',
      gender: null,
      birthdate: '',
    })
  }

  async verifyEmail(confirmationCode: string) {
    await AuthService.verifyAttribute('email', removeSpaces(confirmationCode))

    await this.getSession()
  }

  async signOut() {
    UserAnalyticsService.pushEvent({ name: 'Logout' })

    await AuthService.signOut()

    await this.getSession()

    this.role = null
    this.realRole = null
  }

  private handleUserError(error?: Error) {
    this.unsetUser()
    this.userError = error || new Error('User profile loading error')

    UserAnalyticsService.captureException(new AuthError('User profile loading error', error))
  }

  private async updateSession(session: CognitoUserSession | null) {
    this.session = session

    const isAuthenticated = !!session && session.isValid()

    if (isAuthenticated) {
      try {
        const cognitoTokenData = session.getIdToken().payload as CognitoIdTokenPayload
        const fetchUserResponse = await UserService.fetchUser()
        const backendUserData = fetchUserResponse.data
        const country = getValidCountry(backendUserData.countryOfResidenceCode || '')

        this.user = {
          subId: cognitoTokenData.sub || '',
          userId: backendUserData.userId,
          email: cognitoTokenData.email || '',
          firstName: backendUserData.firstName,
          lastName: backendUserData.lastName,
          countryOfResidenceCode: country?.isoCode,
          countryOfResidenceName: country?.isoName,
          gender: getValidGender(backendUserData.gender || ''),
          birthdate: backendUserData.birthdate,
          milestones: {
            firstNFMeCreated: backendUserData.milestones?.firstNFMeCreated || false,
            NFMeDownloaded: backendUserData.milestones?.NFMeDownloaded || false,
            publicProfileCreated: backendUserData.milestones?.publicProfileCreated || false,
          },
          meta: {
            emailVerified: cognitoTokenData.email_verified,
            profileCompleted: backendUserData.meta?.profileCompleted || false,
          },
          source: {
            ...backendUserData.source,
            channel: backendUserData.source?.channel || 'unknown',
            URL: backendUserData.source?.URL || '',
          },
        }

        this.realRole = this.user?.role || getUserRoleFromEmail(this.user?.email || '')
        this.role ||= this.realRole

        this.isAuthenticated = true
      } catch (err) {
        this.handleUserError(err as Error)
      }
    } else {
      this.unsetUser()
    }
  }

  private unsetUser() {
    this.user = null
    this.isAuthenticated = false
    this.role = null
    this.realRole = null
  }

  setUserRole(role: UserRole) {
    this.role = role
  }

  canUserSeeAs(availableTo?: UserRole | UserRole[], hidden?: boolean) {
    return canUserSeeAs(this.userRole, this.realUserRole, availableTo, hidden)
  }

  get userRole() {
    return this.role
  }

  get realUserRole() {
    return this.realRole
  }

  get hasPendingTasks() {
    const { meta, milestones = {} } = this.user || {}

    return meta?.profileCompleted && Object.values(milestones).some((status) => !status)
  }

  get getAuthToken() {
    return this.session?.getIdToken().getJwtToken() || null
  }
}
