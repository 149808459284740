import { FormOption } from '../../utils/common/form-options/form-option.types'

import { UserRole } from './user.types'

export const USER_ROLE_OPTIONS: FormOption<UserRole>[] = [{
  value: 'admin',
  label: 'Admin (Default)',
}, {
  value: 'partner',
  label: 'Partner',
}, {
  value: 'user',
  label: 'User',
}]
