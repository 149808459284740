export type NumberRange = [number, number]

export type NumberRangeOrMax = number | [number, number]

export function getRange(rangeOrMax: NumberRangeOrMax): NumberRange {
  return typeof rangeOrMax === 'number' ? [0, rangeOrMax] : rangeOrMax
}

export function getRangeMin(rangeOrMax: NumberRangeOrMax) {
  return getRange(rangeOrMax)[0]
}

export function getRangeMax(rangeOrMax: NumberRangeOrMax) {
  return getRange(rangeOrMax)[1]
}

/**
 * Returns a random number between 0 and MAX, both inclusive.
 */
export function random(max: number): number

/**
 * Returns a random number between MIN and MAX, both inclusive.
 */
export function random(min: number, max: number): number

export function random(minOrMax: number, maxOrUndefined?: number) {
  let min = maxOrUndefined === undefined ? 0 : minOrMax
  let max = maxOrUndefined === undefined ? minOrMax : maxOrUndefined

  if (min > max) [min, max] = [max, min]

  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * Returns a random number in the given range.
 */
export function randomInRange(rangeOrMax: NumberRangeOrMax) {
  const [min, max] = getRange(rangeOrMax)

  return random(min, max)
}

/**
 * TODO TSDoc
 */
export function randomDecimal(min: number, max: number): number {
  return Math.round((Math.random() * (max - min) + min) * 10) / 10
}

/**
 * Returns a random angle between 0 and 2 * PI, both inclusive.
 */
export function randomAngle(): number

/**
 * Returns a random angle between 0 and MAX * PI, both inclusive.
 */
export function randomAngle(max: number): number

/**
 * Returns a random number between MIN * PI and MAX * PI , both inclusive.
 */
export function randomAngle(min: number, max: number): number

export function randomAngle(minOrMax?: number, maxOrUndefined?: number) {
  let min = maxOrUndefined === undefined ? 0 : (minOrMax ?? 0)
  // eslint-disable-next-line no-nested-ternary
  let max = maxOrUndefined === undefined ? (minOrMax === undefined ? 2 : minOrMax) : maxOrUndefined

  if (min > max) [min, max] = [max, min]

  const minAngle = Math.PI * min
  const maxAngle = Math.PI * max

  return Math.random() * (maxAngle - minAngle) + minAngle
}

/**
 * Clamps a value between MIN and MAX, both inclusive.
 */
export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}
