import { UserAnalyticsService, WithChildrenProp } from '@ng-mono/sdk'
import { useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { AppErrorBoundaryFallback } from '../../../error-handling/fallbacks/AppErrorBoundaryFallback/AppErrorBoundaryFallback'

export function OuterErrorBoundary({ children }: WithChildrenProp) {
  const errorHandler = useCallback((error: Error, { componentStack }: { componentStack: string }) => {
    // No router or stores available here:
    UserAnalyticsService.captureCrash({ error, componentStack, errorBoundary: 'out' })
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ AppErrorBoundaryFallback } onError={ errorHandler }>
      { children }
    </ErrorBoundary>
  )
}
