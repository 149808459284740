import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { BaseLayout } from '../../../libs/ui/components/layout/base-layout.component'
import { CommonLayoutConfig, CommonLayoutProps } from '../../../libs/ui/components/layout/base-layout.types'
import { useFormSubmission } from '../../forms/form/form-submission.provider'
import { Loader } from '../../Loader/Loader'
import { AppBackground, AppBackgroundConfig } from '../background/app-background.component'
import { AppFooterConfig, AppFooter } from '../footer/app-footer.component'
import { AppNavbarConfig, AppNavbar } from '../navbar/app-navbar.component'

export type AppLayoutConfig = CommonLayoutConfig<
AppNavbarConfig,
AppFooterConfig,
AppBackgroundConfig>

export type AppLayoutProps = CommonLayoutProps & AppLayoutConfig

export function AppLayout(baseLayoutProps: AppLayoutProps) {
  const { pathname } = useLocation()
  const { isSubmitting, isCoverFormActive } = useFormSubmission()

  useEffect(() => {
    if (isSubmitting) {
      const cancelEvent = (e: KeyboardEvent) => {
        e.preventDefault()
      }

      window.addEventListener('keydown', cancelEvent)

      return () => {
        window.removeEventListener('keydown', cancelEvent)
      }
    }
  }, [isSubmitting])

  return (
    <>
      <BaseLayout
        { ...baseLayoutProps }
        pathname={ pathname }
        navComponent={ AppNavbar }
        footerComponent={ AppFooter }
        backgroundComponent={ AppBackground } />
      { isSubmitting && isCoverFormActive ? (
        <Box sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(6, 11, 22, 0.85)',
        }}>
          <Loader />
        </Box>
      ) : null }
    </>
  )
}
