import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints, UpdatePushPathAnswersURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { MutationResult } from '../../utils/server/mutation/server-mutation.types'
import { to } from '../../utils/typescript/typescript.utils'
import { Answer } from '../answers/answers.types'

import { BackendPushPath, PushPath } from './push-paths.types'

// TODO (Dani): Frontend should be able to skip a path as there might be a frontend error on submission?

function transformFetchPushPaths(response: AxiosResponse<BackendPushPath[]>): AxiosResponse<PushPath[]> {
  const backendPushPaths = response.data || []

  return to<AxiosResponse<PushPath[]>>({
    ...response,
    data: backendPushPaths.map((backendPushPath) => ({
      ...backendPushPath,
      activityIndex: 0,
      totalActivities: backendPushPath.activities.length,
    })),
  })
}

export function fetchPushPaths(axiosConfig?: AxiosRequestConfig) {
  return axios.get<PushPath[]>(endpoints.fetchPushPaths, axiosConfig).then(transformFetchPushPaths)
}

export interface UpdatePushPathAnswerParams extends UpdatePushPathAnswersURLParams {
  answers: Answer[];
}

export function updatePushPathAnswers({ answers, ...urlParams }: UpdatePushPathAnswerParams, axiosConfig?: AxiosRequestConfig) {
  return axios.put<MutationResult>(endpoints.updatePushPathAnswers(urlParams), answers, axiosConfig)
}

export const PushPathsService = {
  fetchPushPaths,
  updatePushPathAnswers,
}
