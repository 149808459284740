import { useCallback } from 'react'

import { useSdkStore } from '../../../../../stores/sdk/sdk.provider'
import { UserAnalyticsService } from '../../../user-analytics.service'
import { SentryReportInfo } from '../../user-analytics-sentry.types'

export function useSentryError() {
  const sdkStore = useSdkStore()

  const reportSentryError = useCallback((sentryReportInfo: SentryReportInfo) => {
    UserAnalyticsService.captureCrash({
      subId: sdkStore.authStore.user?.subId || '',
      ...sentryReportInfo,
    })
  }, [sdkStore])

  return { reportSentryError }
}
