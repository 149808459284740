import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'
import { TraitTypeId } from '../creator/renderer-config/creator-renderer-config.types'

import { TraitsService } from './traits.service'
import { UnlockedTraits } from './traits.types'

export class TraitsStore implements BaseStore {
  traitsResource = createEmptyReadOnlyStoreResource<UnlockedTraits>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore
  }

  get traits() {
    return this.traitsResource.data || null
  }

  getTraitsByType(traitType: TraitTypeId) {
    return this.traits?.[traitType] || []
  }

  getTraitsByTypeAndId(traitType: TraitTypeId, traitID: string) {
    // TODO (Dani): Create a map by traitID instead:
    return this.getTraitsByType(traitType).find(({ id }) => id === traitID) || null
  }

  fetchTraits() {
    return fetchStoreResource(this.traitsResource, TraitsService.fetchTraits)
  }

  clearTraits() {
    clearReadOnlyStoreResource(this.traitsResource)
  }
}
