import { CampaignFlowStep } from '@ng-mono/sdk'

import { asyncComponentLoader } from '../../../utils/loader/loader'
import { RouteAuth, RouteConfig } from '../routes.types'

const AccountConfirmationCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/account-confirmation.step'))
const CreateCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/create.step'))
const DownloadAndShareCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/download-and-share.step'))
const ForgotPasswordCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/forgot-password.step'))
const ProfileCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/profile.step'))
const SignInCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/sign-in.step'))
const SignUpCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/sign-up.step'))
const ThankYouCampaignStep = asyncComponentLoader(() => import('../../../components/campaigns/celebrities/steps/thank-you.step'))

export function getCampaignStepsRoutes(routePathFn: ({ step }: { step: CampaignFlowStep }) => string): RouteConfig[] {
  return [{
    title: 'Create',
    path: routePathFn({ step: 'create' }),
    auth: RouteAuth.None,
    page: CreateCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Sign In',
    path: routePathFn({ step: 'sign-in' }),
    auth: RouteAuth.Anonymous,
    page: SignInCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Forgot Password',
    path: routePathFn({ step: 'forgot-password' }),
    auth: RouteAuth.Anonymous,
    page: ForgotPasswordCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Sign Up',
    path: routePathFn({ step: 'sign-up' }),
    auth: RouteAuth.Anonymous,
    page: SignUpCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Account Confirmation',
    path: routePathFn({ step: 'account-confirmation' }),
    auth: RouteAuth.Anonymous,
    page: AccountConfirmationCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Profile',
    path: routePathFn({ step: 'profile' }),
    auth: RouteAuth.Required,
    page: ProfileCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Download and Share',
    path: routePathFn({ step: 'download-and-share' }),
    auth: RouteAuth.Required,
    page: DownloadAndShareCampaignStep,
    nav: { content: 'toolbar' },
  }, {
    title: 'Thank You',
    path: routePathFn({ step: 'thank-you' }),
    auth: RouteAuth.Required,
    page: ThankYouCampaignStep,
    nav: { content: 'toolbar' },
  }]
}
