import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { MutationResult } from '../../server/mutation/server-mutation.types'
import { isServiceFnWithParams, ServiceFnWithoutParams, ServiceFnWithParams } from '../common/common-store.types'
import { isAbortControllerErr } from '../common/common-store.utils'

import { MutableStoreResource } from './mutable-store.types'

export function createEmptyMutableStoreResource<D = undefined>(): MutableStoreResource<D> {
  return {
    isUpdating: false,
  }
}

export function clearMutableStoreResource<D = undefined>(mutableStoreResource: MutableStoreResource<D>) {
  mutableStoreResource.abortController?.abort()

  Object.assign<MutableStoreResource<D>, Partial<MutableStoreResource<D>>>(mutableStoreResource, {
    promise: undefined,
    result: undefined,
    error: undefined,
    isUpdating: false,
  })
}

function updateMutableStoreResourceUpdating<D = undefined>(
  mutableStoreResource: MutableStoreResource<D>,
  promise: Promise<AxiosResponse<MutationResult<D>>>,
) {
  Object.assign<MutableStoreResource<D>, Partial<MutableStoreResource<D>>>(mutableStoreResource, {
    promise,
    result: undefined,
    error: undefined,
    isUpdating: true,
  })
}

function updateMutableStoreResourceUpdated<D = undefined>(
  mutableStoreResource: MutableStoreResource<D>,
  result: MutationResult<D>,
) {
  Object.assign<MutableStoreResource<D>, Partial<MutableStoreResource<D>>>(mutableStoreResource, {
    // promise,
    result,
    error: undefined,
    isUpdating: false,
  })
}

function updateMutableStoreResourceError<D = undefined>(
  mutableStoreResource: MutableStoreResource<D>,
  error: Error,
) {
  Object.assign<MutableStoreResource<D>, Partial<MutableStoreResource<D>>>(mutableStoreResource, {
    // promise,
    result: undefined,
    error,
    isUpdating: false,
  })
}

export async function updateStoreResource<D, P = undefined>(
  mutableStoreResource: MutableStoreResource<D>,
  updateFn: ServiceFnWithoutParams<MutationResult<D>> | ServiceFnWithParams<MutationResult<D>, P>,
  params: P,
): Promise<null | MutationResult<D> | Error> {
  mutableStoreResource.abortController?.abort()

  const abortController = new AbortController()
  const axiosConfig: AxiosRequestConfig = { signal: abortController.signal }

  Object.assign<MutableStoreResource<D>, Partial<MutableStoreResource<D>>>(mutableStoreResource, { abortController })

  let promise: Promise<AxiosResponse<MutationResult<D>, any>>

  if (isServiceFnWithParams(updateFn) && params) {
    promise = updateFn(params, axiosConfig)
  } else if (!isServiceFnWithParams(updateFn)) {
    promise = updateFn(axiosConfig)
  } else {
    promise = Promise.reject(new Error('Invalid updateFn arguments.'))
  }

  updateMutableStoreResourceUpdating(mutableStoreResource, promise)

  try {
    const result = await promise
    const { data } = result

    updateMutableStoreResourceUpdated(mutableStoreResource, data)

    return data
  } catch (err) {
    // TODO (Dani): Probably need some parsing here:
    const error = err as Error

    if (isAbortControllerErr(error)) return null

    updateMutableStoreResourceError(mutableStoreResource, error)

    return error
  }
}
