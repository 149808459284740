import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints } from '../../utils/server/endpoints/server-endpoints.constants'

import { Reward } from './rewards.types'

export async function fetchRewards(axiosConfig?: AxiosRequestConfig) {
  return axios.get<Reward[]>(endpoints.fetchRewards, { ...axiosConfig })
}

export const RewardsService = {
  fetchRewards,
}
