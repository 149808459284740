import { clamp, NumberRange } from '@ng-mono/sdk'

import { Star } from '../star/star.class'

export interface GalaxyPhysicsUpdate {
  accelerateY: number;
}

export class Galaxy {
  canvas: HTMLCanvasElement | null = null

  ctx: CanvasRenderingContext2D | null = null

  width = 0

  height = 0

  // TODO (Dani): Maybe make it some kind of start density that depends on the screen size:
  maxStars = 1000

  stars: Star[] = []

  requestAnimationFrameID = 0

  resizeTimeoutID = 0

  speedY = 0

  maxSpeed = 225

  scrollBufferRange = [5, 25]

  constructor(canvas: HTMLCanvasElement) {
    // TODO (Dani): Configure maxStars

    this.tick = this.tick.bind(this)

    this.init(canvas)
  }

  init(canvas: HTMLCanvasElement) {
    // 1. Get canvas and CTX:
    this.canvas = canvas
    this.ctx = canvas.getContext('2d')

    // 2. Update dimensions:
    this.updateDimensions()

    // 3. Instantiate all stars:
    this.createStars()

    // 4. Automatically star the animation:
    this.start()
  }

  updateDimensions() {
    const { canvas } = this

    if (!canvas) return

    canvas.width = this.width = canvas.offsetWidth
    canvas.height = this.height = canvas.offsetHeight
  }

  createStars() {
    // Instantiate cached gradient for the stars glow effect:
    const glowCanvas = Star.createGlowCanvas()

    if (!glowCanvas) return

    // Instantiate all stars:
    const maxDimension = Math.max(this.width, this.height)
    const hypotenuse = Math.round(Math.sqrt(maxDimension * maxDimension + maxDimension * maxDimension))
    const orbitRadiusRange: NumberRange = [0, hypotenuse / 2]

    for (let i = 0; i < this.maxStars; ++i) {
      this.stars[i] = new Star({
        glowCanvas,
        orbitX: this.width / 2,
        orbitY: this.height / 2,
        orbitRadiusRange,
        secondsToFullRotation: 60 * 5,
      })
    }
  }

  resizeCanvas() {
    this.stop(true)

    window.clearTimeout(this.resizeTimeoutID)

    this.resizeTimeoutID = window.setTimeout(() => {
      this.updateDimensions()
      this.createStars()
      this.start()
    }, 250)
  }

  start() {
    this.tick()
  }

  stop(hideCanvas = false) {
    cancelAnimationFrame(this.requestAnimationFrameID)

    if (hideCanvas && this.canvas) {
      // TODO (Dani): Configurable style OR class:
      // TODO (Dani): Also revert when we start again:
      this.canvas.style.opacity = '0'
    }
  }

  tick() {
    const {
      ctx, width, height, stars,
    } = this

    if (!ctx) return

    ctx.clearRect(0, 0, width, height)

    ctx.globalCompositeOperation = 'source-over'
    ctx.globalAlpha = 0.8
    ctx.fillStyle = 'transparent'

    ctx.fillRect(0, 0, width, height)

    ctx.globalCompositeOperation = 'lighter'

    const absSpeedY = Math.abs(this.speedY)

    if (absSpeedY <= 0.01) {
      this.speedY = 0
    } else {
      const halfSpeed = this.maxSpeed / 2

      let quadFactor = 0.99

      if (absSpeedY >= halfSpeed) {
        // absSpeedY = halfSpeed => factor = 0
        // - quadFactor stays the same = 0.99
        // - less momentum (re-center slow)

        // absSpeedY = maxSpeed  => factor = 1
        // - quadFactor becomes smaller = 0.985
        // - strong momentum (re-center fast)

        const factor = clamp((absSpeedY - halfSpeed) / halfSpeed, 0, 1)

        quadFactor -= 0.01 * factor
      }

      this.speedY *= quadFactor
    }

    for (let i = 0; i < stars.length; ++i) {
      stars[i].draw(
        ctx,
        this.speedY,
      )
    }

    this.requestAnimationFrameID = requestAnimationFrame(this.tick)
  }

  updatePhysics({
    accelerateY,
  }: GalaxyPhysicsUpdate) {
    if (accelerateY !== undefined) {
      const factor = clamp(
        Math.abs(this.speedY),
        this.scrollBufferRange[0],
        this.scrollBufferRange[1],
      ) / this.scrollBufferRange[1]

      this.speedY = clamp(this.speedY + factor * accelerateY, -this.maxSpeed, this.maxSpeed)
    }
  }
}
