import { v4 as uuidv4 } from 'uuid'

import { getMocks, persistMocks } from '../../mocks/mock-state.utils'
import { MOCKED_DEFAULT_TRAIT_VALUES, MOCKED_CREATOR_RENDERER_CONFIG } from '../creator/renderer-config/creator-renderer-config.mocks'

import { BackendTraitValues, BackendAvatarOutfit } from './avatar.types'

// Accessor methods:

export function getAvatarOutfits() {
  return getMocks().outfits
}

export function getAvatarOutfit(outfitID: string) {
  return getAvatarOutfits()[outfitID]
}

export function createAvatarOutfit(label: string, backendTraitValues?: BackendTraitValues) {
  const mocksState = getMocks()
  const newNFMeID = uuidv4()

  const newAvatarOutfit: BackendAvatarOutfit = {
    id: newNFMeID,
    label,
    layeringVersion: MOCKED_CREATOR_RENDERER_CONFIG.layeringVersion,
    traitValues: { ...MOCKED_DEFAULT_TRAIT_VALUES, ...backendTraitValues },
    paletteID: MOCKED_CREATOR_RENDERER_CONFIG.defaultPaletteID,
  }

  mocksState.outfits[newNFMeID] = newAvatarOutfit

  persistMocks()

  return newAvatarOutfit
}

export function saveAvatarOutfit(outfitID: string, updatedAvatarOutfit: BackendAvatarOutfit) {
  const mocksState = getMocks()

  mocksState.outfits[outfitID] = updatedAvatarOutfit

  persistMocks()
}

export function deleteAvatarOutfit(outfitID: string) {
  const mocksState = getMocks()

  delete mocksState.outfits[outfitID]

  persistMocks()
}
