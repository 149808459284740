import type { ActivityStatus } from '../activities/base/base-activity.types'
import type { PathStatus } from '../paths/paths.types'

export enum RuleType {
  Automation = 'Automation',
  Whitelist = 'Whitelist',
  Blacklist = 'Blacklist',
  Topic = 'Topic',
  Path = 'Path',
  Activity = 'Activity',
  Achievement = 'Achievement',
}

export interface BaseRule {
  id: string;
  type: RuleType;
}

export interface AutomationRule extends BaseRule {
  type: RuleType.Automation;
  queryID: string;
}

export interface WhitelistRule extends BaseRule {
  type: RuleType.Whitelist;
  listID: string;
}

export interface BlacklistRule extends BaseRule {
  type: RuleType.Blacklist;
  listID: string;
}

export interface TopicRule extends BaseRule {
  type: RuleType.Topic;
  topicID: string;
  topicProgress: number;
}

export interface PathRule extends BaseRule {
  type: RuleType.Path;
  pathID: string;
  pathStatus: PathStatus;
}

export interface ActivityRule extends BaseRule {
  type: RuleType.Activity;
  pathID: string;
  activityID: string;
  activityStatus: ActivityStatus | RegExp;
}

export interface AchievementRule extends BaseRule {
  type: RuleType.Achievement;
  achievementID: string;
}

export type Rule = AutomationRule | WhitelistRule | BlacklistRule | TopicRule | PathRule | ActivityRule | AchievementRule

export interface RulesMatch {
  match: boolean;
  matchCount: number;
  matchProgress: number;
}

export interface RulesSetMatch {
  match: boolean;
  matchCount: number;
  matchProgress: number;
  matches: RulesMatch[]
}
