import { WithChildrenProp } from '@ng-mono/sdk'
import { createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState } from 'react'

import { DEMO_MODE_ENABLED } from '../../../../pages/demo/demo.constants'

import type { DynamicLogoContextData } from './logo.component'

const DynamicLogoContext = createContext<DynamicLogoContextData>({
  imageSrc: '',
  loading: true,
})

export const useDynamicLogo = () => useContext(DynamicLogoContext)

interface DynamicLogoProviderProps extends WithChildrenProp {
  initialSrc: string;
}

const fetchAndVerifyImage = async (url: string): Promise<boolean> => {
  if (DEMO_MODE_ENABLED) return true

  try {
    const response = await fetch(url, { mode: 'no-cors' })

    if (response.ok && response.headers.get('Content-Type')?.startsWith('image')) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export function DynamicLogoProvider({ children, initialSrc }: DynamicLogoProviderProps) {
  const [imageSrc, setImageSrc] = useState(initialSrc)
  const [loading, setLoading] = useState(true)

  const fetchImage = useCallback(async () => {
    setLoading(true)
    const isValid = await fetchAndVerifyImage(initialSrc)
    if (isValid) {
      setImageSrc(initialSrc)
    } else {
      setImageSrc('/default-avatar.svg') // Replace with the path to your fallback image
    }
    setLoading(false)
  }, [initialSrc])

  useEffect(() => {
    fetchImage()
  }, [fetchImage])

  const value = useMemo(() => ({ imageSrc, loading }), [imageSrc, loading])

  return (
    <DynamicLogoContext.Provider value={ value }>
      {children}
    </DynamicLogoContext.Provider>
  )
}
