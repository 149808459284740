import { BackendTraitValues } from '../../avatar/avatar.types'
import { MOCKED_DEFAULT_PALETTE_ID } from '../../palettes/palettes.mocks'

import { DEFAULT_TRAIT_COLOR } from './creator-renderer-config.constants'
import { BackendAvatarConfig, RenderingLayerId, TraitTypeConfig, TraitTypeId } from './creator-renderer-config.types'

// Version:

const MOCKED_LAYERING_VERSION = '1.0.0'

// Rendering layers:

export const MOCKED_RENDERING_LAYERS_IDS = [
  'background--back',
  'background-overlay--middle',
  'body-accessory--back',
  'jacket--back',
  'clothing--back',
  'headgear--back',
  'hair--back',
  'body--middle',
  'skin-decoration--back',
  'clothing--middle',
  'neck-accessory--back',
  'jacket--middle',
  'body-accessory--front',
  'clothing-accessory--middle',
  'hair--middle',
  'ears--back',
  'ear-accessory--back',
  'headgear--middle',
  'head--front',
  'skin-decoration--middle',
  'ears--front',
  'eyes--back',
  'eye-adornment--middle',
  'stubble--middle',
  'mouth--back',
  'nose--back',
  'nose-accessory--back',
  'eyebrows--middle',
  'hair--front',
  'eyewear--back',
  'clothing--front',
  'jacket--front',
  'ear-accessory--front',
  'neckgear--middle',
  'eyebrows--front',
  'facehair--front',
  'stache--front',
  'lip-piercing--middle',
  'eyewear--front',
  'nose--front',
  'nose-accessory--front',
  'face-cover--front',
  'hair--top',
  'headgear--front',
  'clothing--top',
  'jacket--top',
  'headgear--top',
  'neckgear--front',
  'mouth-accessory--middle',
  'overlay--middle',
  'appendage--middle',
  'frame-overlay--middle',
] as const

export const MOCKED_TRAIT_TYPES_IDS: TraitTypeId[] = [...new Set(MOCKED_RENDERING_LAYERS_IDS.map((layerId) => layerId.split('--')[0]))] as TraitTypeId[]

export type RenderingLayersIdsType = typeof MOCKED_RENDERING_LAYERS_IDS[number]

// Masks config:

// See https://www.notion.so/nfmegalaxy/MASKS-5fc495d264d94933abfe44206bc1c9a6

export const MOCKED_MASK_FILTER_CONFIGS: Record<RenderingLayerId | string, RenderingLayerId[]> = {
  // Item type mappings:

  jacket: [
    // All clothing:
    'clothing--back', 'clothing--middle', 'clothing--front', 'clothing--top',
  ],

  headgear: [
    // All hair:
    'hair--back', 'hair--middle', 'hair--front', 'hair--top',
    // All clothing:
    'clothing--back', 'clothing--middle', 'clothing--front', 'clothing--top',
    // All jacket:
    // 'jacket--back', 'jacket--middle', 'jacket--front', 'jacket--top',
  ],

  // Alias mappings:

  hoodie: [
    // All hair:
    'hair--back', 'hair--middle', 'hair--front', 'hair--top',
    // All eyebrows:
    'eyebrows--middle', 'eyebrows--front',
    // All eye-adornment:
    'eye-adornment--middle',
    // All eyewear:
    'eyewear--back', 'eyewear--front',
    // All ears:
    'ears--back', 'ears--front',
    // All ear-accessory:
    'ear-accessory--back', 'ear-accessory--front',
    // All nose:
    'nose--back', 'nose--front',
    // All nose-accessory:
    'nose-accessory--back', 'nose-accessory--front',
    // All mouth:
    'mouth--back',
    // All stubble:
    'stubble--middle',
    // All facehair:
    'facehair--front',
  ],

  'closed-jacket': ['hair--middle'],

  'clothing-neck': [
    'facehair--front',
    'stache--front',
  ],
  // 'voluminous-beard': [
  //   // All clothing:
  //   'clothing--back', 'clothing--middle', 'clothing--front', 'clothing--top',
  //   // All jacket:
  //   'jacket--back', 'jacket--middle', 'jacket--front', 'jacket--top',
  // ],
}

// Trait type configs:

const MOCKED_RARE_TRAIT_TYPE_CONFIG: TraitTypeConfig = {
  required: false,
  defaultTrait: 'none',
  defaultTraitProbability: 0.9,
}

const MOCKED_TRAIT_TYPE_CONFIGS: Record<TraitTypeId, TraitTypeConfig> = {
  // Generic:

  background: {
    required: true,
    defaultTrait: 'background.1.colour',
    defaultTraitColors: { 0: DEFAULT_TRAIT_COLOR },
    defaultTraitProbability: 0.7071,
  },

  body: {
    required: true,
    defaultTrait: 'body.1.og',
    defaultTraitColors: { 0: DEFAULT_TRAIT_COLOR },
  },

  head: {
    required: true,
    defaultTrait: 'head.1.og',
    inheritColorsFrom: 'body',
    defaultTraitColors: { 0: DEFAULT_TRAIT_COLOR },
  },

  'background-overlay': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'frame-overlay': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  // Basic face:

  hair: {
    required: false,
    defaultTrait: 'none',
  },

  ears: {
    required: true,
    defaultTrait: 'ears.1.nude',
    inheritColorsFrom: 'body',
    defaultTraitProbability: 0.5,
  },

  eyes: {
    required: true,
    defaultTrait: 'eyes.1.standard-issue',
    defaultTraitProbability: 0.9,
    // inheritDefaultColorsFrom: 'body',
  },

  'eye-adornment': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  eyebrows: {
    required: false,
    defaultTrait: 'none',
    defaultTraitProbability: 0.9,
    // inheritDefaultColorsFrom: 'hair',
  },

  nose: {
    required: true,
    defaultTrait: 'nose.1.standard-issue',
    inheritColorsFrom: 'body',
    defaultTraitProbability: 0.5,
  },

  mouth: {
    required: true,
    defaultTrait: 'mouth.1.standard-issue',
    defaultTraitProbability: 0.5,
  },

  stubble: {
    required: false,
    defaultTrait: 'none',
    defaultTraitProbability: 0.7071,
    // inheritDefaultColorsFrom: 'hair',
  },

  facehair: {
    required: false,
    defaultTrait: 'none',
    defaultTraitProbability: 0.7071,
    // inheritDefaultColorsFrom: 'hair',
  },

  stache: {
    required: false,
    defaultTrait: 'none',
    defaultTraitProbability: 0.7071,
    // inheritDefaultColorsFrom: 'hair',
  },

  // Basic body:

  appendage: MOCKED_RARE_TRAIT_TYPE_CONFIG,

  clothing: {
    required: false,
    defaultTrait: 'none',
  },

  jacket: {
    required: false,
    defaultTrait: 'none',
  },

  // Accessories / decoration:

  headgear: MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'ear-accessory': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  eyewear: {
    required: false,
    defaultTrait: 'none',
    defaultTraitProbability: 0.5,
  },

  'nose-accessory': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'mouth-accessory': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'lip-piercing': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'neck-accessory': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  neckgear: MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'body-accessory': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'clothing-accessory': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'skin-decoration': MOCKED_RARE_TRAIT_TYPE_CONFIG,

  overlay: MOCKED_RARE_TRAIT_TYPE_CONFIG,

  'face-cover': MOCKED_RARE_TRAIT_TYPE_CONFIG,
}

export const MOCKED_CREATOR_RENDERER_CONFIG: BackendAvatarConfig = {
  layeringVersion: MOCKED_LAYERING_VERSION,
  layers: MOCKED_RENDERING_LAYERS_IDS,
  maskFiltersConfigs: MOCKED_MASK_FILTER_CONFIGS,
  traitTypeConfigs: MOCKED_TRAIT_TYPE_CONFIGS,
  defaultPaletteID: MOCKED_DEFAULT_PALETTE_ID,
}

export const MOCKED_DEFAULT_TRAIT_VALUES: BackendTraitValues = (
  Object.entries(MOCKED_TRAIT_TYPE_CONFIGS) as [TraitTypeId, TraitTypeConfig][]
).reduce((acc, [traitTypeID, traitTypeConfig]) => {
  acc[traitTypeID] = {
    traitId: traitTypeConfig.defaultTrait,
    traitColors: traitTypeConfig.defaultTraitColors || {},
  }

  return acc
}, {} as BackendTraitValues)
