import { AxiosRequestConfig } from 'axios'

import { axios } from '../../axios/axios.constants'
import { endpoints, PaginatedResourceURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { Path } from '../paths/paths.types'

export function fetchAnsweredPaths(params: PaginatedResourceURLParams, axiosConfig?: AxiosRequestConfig) {
  // TODO (Dani): Add specific types for Paths/Activities with and without answers
  return axios.get<Path[]>(endpoints.fetchAnsweredPaths(params), axiosConfig)
}

export const PathsAnsweredService = {
  fetchAnsweredPaths,
}
