import { Stack } from '@mui/material'
import { useAuth, USER_ROLE_OPTIONS, UserRole, clearMocks } from '@ng-mono/sdk'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'

import { BaseMenu } from '../../../libs/ui/components/menu/base-menu.component'
import { BasePortalMenuProps } from '../../../libs/ui/components/menu/base-menu.types'
import { MenuDividerItem } from '../../../libs/ui/components/menu/items/divider/menu-divider-item.component'
import { DEMO_MODE_ENABLED } from '../../../pages/demo/demo.constants'
import { useNotifications } from '../../../store/notifications/notifications.hook'
import { NotificationType } from '../../../store/notifications/notifications.types'
import { SelectField } from '../../forms/inputs/select/SelectField'
import { TextField } from '../../forms/inputs/text/TextField'
import { COPY_TO_CLIPBOARD_SUCCESS_MESSAGE } from '../../notifications/notifications.constants'
import { AppFooter } from '../footer/app-footer.component'

import { APP_MENU_ITEMS } from './app-menu.constants'

export function AppMenuComponent({
  variant,
  onClose,
}: BasePortalMenuProps) {
  const { isAuthLoading, isAuthenticated, signOut, setUserRole, userRole, realUserRole, getAuthToken } = useAuth()
  const { addNotification } = useNotifications()

  const handleUserRoleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserRole(event.target.value as UserRole)
  }, [setUserRole])

  const handlers: Record<string, () => void> = useMemo(() => ({
    logout: signOut,
    reset: clearMocks,
  }), [signOut])

  const handleCopyClick = () => {
    const token = getAuthToken

    if (token) {
      navigator.clipboard.writeText(token)

      addNotification({
        message: COPY_TO_CLIPBOARD_SUCCESS_MESSAGE,
        type: NotificationType.Success,
      })
    }
  }

  const handleMenuAction = useCallback((_: string, action?: string) => {
    onClose()

    const handler = action ? handlers[action] : null

    if (handler) handler()
  }, [onClose, handlers])

  // TODO (Dani): Show loading state / placeholder:
  if (!DEMO_MODE_ENABLED && (isAuthLoading || !isAuthenticated)) return null

  return (
    <>
      <BaseMenu
        variant={ variant }
        menuItems={ APP_MENU_ITEMS }
        onAction={ handleMenuAction } />
      { userRole && realUserRole === 'admin' && (
        <Stack
          flexDirection="column"
          gap={ 2 }
          sx={{
            px: 2,
            pb: 4,
          }}>
          <MenuDividerItem category="Admin Zone" sx={{ mt: 0 }} />
          <SelectField
            label="View as..."
            name="userRole"
            value={ userRole }
            onChange={ handleUserRoleChange }
            options={ USER_ROLE_OPTIONS } />
          <TextField
            readOnly
            inputProps={{
              style: {
                cursor: 'pointer',
              },
            }}
            label="Auth token"
            value={ getAuthToken || '' }
            onClick={ handleCopyClick } />
        </Stack>
      ) }
      <AppFooter />
    </>
  )
}

export const AppMenu = observer(AppMenuComponent)
