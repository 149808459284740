import { getDeviceInfo, hasTouch, isMobileDevice } from '../../domains/user-analytics/user-analytics.utils'
import { IS_BROWSER } from '../common/common.constants'

// FEATURE FLAGS:

let isIOsFlag = false
let isSafariIos = false
let isChromeFlag = false
let hasTouchFlag = false
let isMobile = false

// FEATURE DETECTION:

if (IS_BROWSER) {
  const { os, browser, device } = getDeviceInfo()

  isIOsFlag = os === 'iOS' && ['iPhone', 'iPad', ''].includes(device)
  isSafariIos = isIOsFlag && browser === 'Mobile Safari'
  isChromeFlag = browser === 'Chrome'
  hasTouchFlag = hasTouch()
  isMobile = isMobileDevice()
}

// EXPORTS:

export const IS_IOS = isIOsFlag
export const IS_SAFARI_IOS = isSafariIos
export const IS_CHROME = isChromeFlag
export const HAS_TOUCH = hasTouchFlag
export const IS_MOBILE = isMobile
