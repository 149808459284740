import { AchievementsStore } from '../../domains/achievements/achievements.store'
import { AuthStore } from '../../domains/auth/auth.store'
import { AvatarStore } from '../../domains/avatar/avatar.store'
import { AFreshStartCampaignStore } from '../../domains/campaigns/a-fresh-start/a-fresh-start-campaign.store'
import { CelebritiesCampaignStore } from '../../domains/campaigns/celebrities/celebrities-campaign.store'
import { NFMeOfTheWeekCampaignStore } from '../../domains/campaigns/nfme-of-the-week/nfme-of-the-week-campaign.store'
import { CreatorRendererConfigStore } from '../../domains/creator/renderer-config/creator-renderer-config.store'
import { FlowControllerStore } from '../../domains/flow-controller/flow-controller.store'
import { PalettesStore } from '../../domains/palettes/palettes.store'
import { PathsAnsweredStore } from '../../domains/paths-answered/paths-answered.store'
import { PathsStore } from '../../domains/paths/paths.store'
import { PushPathsStore } from '../../domains/push-paths/push-paths.store'
import { RewardsStore } from '../../domains/rewards/rewards.store'
import { TopicsAndCategoriesStore } from '../../domains/topics-n-categories/topics-n-categories.store'
import { TraitsStore } from '../../domains/traits/traits.store'
// import { WebsocketService } from '../../domains/websocket/websocket.service'

export class SdkRootStore {
  authStore: AuthStore

  topicsAndCategoriesStore: TopicsAndCategoriesStore

  pathsStore: PathsStore

  pushPathsStore: PushPathsStore

  pathsAnsweredStore: PathsAnsweredStore

  flowControllerStore: FlowControllerStore

  rewardsStore: RewardsStore

  achievementsStore: AchievementsStore

  traitsStore: TraitsStore

  avatarStore: AvatarStore

  celebritiesCampaignStore: CelebritiesCampaignStore

  nfmeOfTheWeekCampaignStore: NFMeOfTheWeekCampaignStore

  aFreshStartCampaignStore: AFreshStartCampaignStore

  palettesStore: PalettesStore

  creatorRendererConfigStore: CreatorRendererConfigStore

  // wsService = WebsocketService

  constructor() {
    this.authStore = new AuthStore(this)
    this.topicsAndCategoriesStore = new TopicsAndCategoriesStore(this)
    this.pathsStore = new PathsStore(this)
    this.pushPathsStore = new PushPathsStore(this)
    this.pathsAnsweredStore = new PathsAnsweredStore(this)
    this.flowControllerStore = new FlowControllerStore(this)
    this.rewardsStore = new RewardsStore(this)
    this.achievementsStore = new AchievementsStore(this)
    this.traitsStore = new TraitsStore(this)
    this.avatarStore = new AvatarStore(this)
    this.celebritiesCampaignStore = new CelebritiesCampaignStore(this)
    this.nfmeOfTheWeekCampaignStore = new NFMeOfTheWeekCampaignStore(this)
    this.aFreshStartCampaignStore = new AFreshStartCampaignStore(this)
    this.palettesStore = new PalettesStore(this)
    this.creatorRendererConfigStore = new CreatorRendererConfigStore(this)
  }
}
