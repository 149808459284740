import { RecursivePartial } from '../../typescript/typescript.utils'

export function isObject<T>(obj?: T): obj is T {
  return (!!obj && typeof obj === 'object' && !Array.isArray(obj))
}

export function deepMergeWithLimit<T = any>(
  limit: number,
  target: T,
  ...sources: (T | RecursivePartial<T>)[]
): T {
  const source = sources.shift()

  if (!source) return target

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (limit <= 0) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        target[key] = source[key]

        return
      }

      // @ts-ignore
      if (isObject(source[key])) {
        // @ts-ignore
        if (!target[key]) Object.assign(target, { [key]: {} })
        // @ts-ignore
        deepMergeWithLimit(limit - 1, target[key], source[key])
      } else {
        // @ts-ignore
        Object.assign(target, { [key]: source[key] })
      }
    })
  }

  return deepMergeWithLimit(limit - 1, target, ...sources)
}

export function deepMerge<T = any>(
  target: T,
  ...sources: (T | RecursivePartial<T>)[]
): T {
  return deepMergeWithLimit(Infinity, target, ...sources)
}

export function removeKeys(obj: Record<string, any>, keysToRemove: string[]): Record<string, any> {
  return Object.keys(obj)
    .filter((key) => !keysToRemove.includes(key))
    .reduce((acc, curr) => {
      acc[curr] = obj[curr]
      return acc
    }, {} as Record<string, any>)
}

export function deepClone<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T
}
