import { makeAutoObservable } from 'mobx'

import type { SdkRootStore } from '../../stores/sdk/sdk.store'
import { FetchNextPathURLParams } from '../../utils/server/endpoints/server-endpoints.constants'
import { BaseStore } from '../../utils/stores/common/common-store.types'
import { clearMutableStoreResource, createEmptyMutableStoreResource, updateStoreResource } from '../../utils/stores/mutation/mutable-store.utils'
import { clearReadOnlyStoreResource, createEmptyReadOnlyStoreResource, fetchStoreResource } from '../../utils/stores/readonly/readonly-store.utils'
import { PathResult } from '../answers/answers.types'

import { PathsService, UpdatePathAnswerParams } from './paths.service'
import { Path } from './paths.types'

export class PathsStore implements BaseStore {
  pathResource = createEmptyReadOnlyStoreResource<Path>()

  pathMutation = createEmptyMutableStoreResource<PathResult>()

  rootStore: SdkRootStore

  constructor(rootStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  // GET NEXT PATH:

  get path() {
    return this.pathResource.data || null
  }

  fetchPath(params: FetchNextPathURLParams) {
    // TODO (Dani): Replace this with fetchNextPath = getFetchStoreResourceHandler(this.pathResource, PathsService.fetchNextPath)
    return fetchStoreResource(this.pathResource, PathsService.fetchNextPath, params)
  }

  clearPath() {
    clearReadOnlyStoreResource(this.pathResource)
  }

  // UPDATE PATH ANSWERS:

  // TODO (Dani): Reuse logic here for the other store:
  // This is automatically called after saving the last answer (from FlowController.store.ts):
  updatePathAnswers() {
    const { data } = this.pathResource

    // TODO (Dani): Handle error:
    if (!data) return

    const pathID = data.id
    const activities = data.activities || []

    // TODO (Dani): Handle error:
    if (activities.length === 0) return

    const params: UpdatePathAnswerParams = {
      pathID,
      answers: activities.map((activity) => activity.userAnswer).filter((value) => value !== undefined) as any,
    }

    return updateStoreResource(this.pathMutation, PathsService.updatePathAnswers, params)
  }

  clearUpdatePathAnswersMutation() {
    clearMutableStoreResource(this.pathMutation)
  }
}
