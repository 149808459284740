import { useInterval } from '@swyg/corre'
import { useEffect } from 'react'

import { DEFAULT_STORE_OPTIONS } from '../../stores/base/base.constants'
import { UseStoreOptions } from '../../stores/base/base.types'
import { useSdkStore } from '../../stores/sdk/sdk.provider'

export function usePushPaths({
  cache,
  auto,
  skip,
  poll = null,
}: UseStoreOptions = DEFAULT_STORE_OPTIONS) {
  const { pushPathsStore } = useSdkStore()

  // TODO (Dani): Extract this reusable logic (useEffects, etc. also used in `topics.hook`).

  useEffect(() => () => {
    if (!cache && pushPathsStore.pushPaths) {
      pushPathsStore.clearPushPaths()
    }
  }, [cache, pushPathsStore])

  useEffect(() => {
    if (!skip && auto) {
      pushPathsStore.fetchPushPaths()
    }

    // This is intentional, we don't want to re-fetch automatically if the params (auto) change:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])

  useInterval(() => {
    pushPathsStore.fetchPushPaths()
  }, skip ? null : poll)

  return pushPathsStore
}
