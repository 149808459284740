import { WithChildrenProp } from '@ng-mono/sdk'
import { createContext, useContext, useState, useCallback, useMemo } from 'react'

export interface FormSubmissionContextData {
  isSubmitting: boolean;
  isCoverFormActive: boolean;
  setCoverFormSubmitting: (isCoverFormSubmitting: boolean) => void;
  setCoverFormActive: (isActive: boolean) => void;
}

const FormSubmissionContext = createContext<FormSubmissionContextData>({
  isSubmitting: false,
  isCoverFormActive: false,
  setCoverFormSubmitting: () => {},
  setCoverFormActive: () => {},
})

export function useFormSubmission() {
  return useContext(FormSubmissionContext)
}

interface FormSubmissionProviderProps extends WithChildrenProp {
}

export function FormSubmissionProvider({ children }: FormSubmissionProviderProps) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCoverFormActive, setIsCoverFormActive] = useState(false)

  const setCoverFormSubmitting = useCallback((isCoverFormSubmitting: boolean) => {
    setIsSubmitting(isCoverFormSubmitting)
  }, [])

  const setCoverFormActive = useCallback((isActive: boolean) => {
    setIsCoverFormActive(isActive)
  }, [])

  const value = useMemo(() => ({
    isSubmitting,
    isCoverFormActive,
    setCoverFormSubmitting,
    setCoverFormActive,
  }), [isSubmitting, isCoverFormActive, setCoverFormSubmitting, setCoverFormActive])

  return (
    <FormSubmissionContext.Provider value={ value }>
      {children}
    </FormSubmissionContext.Provider>
  )
}
