import { SdkRootStore } from '@ng-mono/sdk'
import { makeAutoObservable } from 'mobx'

import type { AppRootStore, BaseAppStore } from '../app-root/app-root.store'

export class LayoutStore implements BaseAppStore {
  appStore: AppRootStore

  sdkStore: SdkRootStore

  constructor(appStore: AppRootStore, sdkStore: SdkRootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.appStore = appStore
    this.sdkStore = sdkStore
  }
}
