import { RestRequest, ResponseComposition, RestContext } from 'msw'

import { to } from '../../../utils/typescript/typescript.utils'
import { TRAIT_MOCKS_BY_ID } from '../../traits/traits.mocks'
import { Trait } from '../../traits/traits.types'

import { MOCKED_CREATOR_RENDERER_CONFIG } from './creator-renderer-config.mocks'
import { BackendAvatarConfigWithTraits } from './creator-renderer-config.types'

export function fetchCreatorRendererConfigHandler(
  req: RestRequest,
  res: ResponseComposition,
  ctx: RestContext,
) {
  // Fake auth removed as this is now used by anonymous users in the campaign flows:
  // if (isAuthMissing(req)) return respond401(res, ctx)

  const configDefaultTraitsById: Record<string, Trait> = {}

  Object.values(MOCKED_CREATOR_RENDERER_CONFIG.traitTypeConfigs).forEach((traitTypeConfig) => {
    const defaultTraitId = traitTypeConfig.defaultTrait
    const defaultTrait = defaultTraitId ? TRAIT_MOCKS_BY_ID[defaultTraitId] : null

    if (defaultTrait && !configDefaultTraitsById[defaultTrait.id]) configDefaultTraitsById[defaultTrait.id] = defaultTrait
  })

  return res(
    ctx.status(200),
    ctx.json(to<BackendAvatarConfigWithTraits>({
      config: MOCKED_CREATOR_RENDERER_CONFIG,
      configDefaultTraits: Object.values(configDefaultTraitsById),
    })),
  )
}
