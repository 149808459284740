export function AppErrorBoundaryFallback() {
  return (
    <section
      style={{
        flexDirection: 'column',
        alignSelf: 'center',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}>

      <p>Oops, an unexpected error happened :(</p>

      <p>
        <button
          // TODO (Dani): If error again, redirect to Home instead of reloading the current page:
          onClick={ () => window.location.reload() }
          style={{
            marginTop: '32px',
            background: '#F96868',
            color: '#FFFFFF',
            borderRadius: '14px',
            minHeight: '56px',
            minWidth: '40px',
            padding: '12px 20px',
            fontWeight: '700',
            fontSize: '1rem',
            lineHeight: '1.75',
            letterSpacing: '0.02857em',
          }}>
          Reload app
        </button>
      </p>
    </section>
  )
}
