import { CircularProgress, Stack, SxProps, Theme, Typography } from '@mui/material'

export type LoaderVariant = 'spinner' | 'wait' | 'load' | 'auth'

export interface LoaderProps {
  variant?: LoaderVariant;
  label?: string;
  text?: string;
  sx?: SxProps<Theme>;
}

const LOADER_LABEL_BY_VARIANT: Record<LoaderVariant, (label?: string) => string> = {
  spinner: () => '',
  wait: () => 'Searching the Galaxy...',
  load: (label = '') => `Loading ${label.trim()}...`,
  auth: (label = '') => `Authenticating ${label.trim()}...`,
}

export function Loader({
  variant = 'wait',
  label,
  text,
  sx,
}: LoaderProps) {
  const message = text || `${LOADER_LABEL_BY_VARIANT[variant](label)}`

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      sx={{
        p: ({ gaps }) => gaps.outerPadding,
        gap: ({ gaps }) => gaps.cardPadding,
        flex: 1,
        ...sx,
      }}>

      <CircularProgress color="primary" />

      { message && <Typography>{ message }</Typography> }

    </Stack>
  )
}
